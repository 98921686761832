import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import {
  addNewBreadcrumbsMethod,
  clearBreadcrumbs,
} from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch, useSelector } from "react-redux";

const AccountsCard = ({ sections, setSections }) => {
  const [accounts, setAccounts] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = "accounts";
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const accountData = useSelector((state) => state?.AccountData?.data);

  const handleDetailsDispatch = () => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
    };

    const handleDispatch = () => {
      // BreadCrumbsObject.title = "Dashboard";
      dispatch(
        addNewBreadcrumbsMethod({
          ...BreadCrumbsObject,
          url: "/dashboard",
          title: "Dashboard",
          type: "dashboard",
        })
      );
    };
    handleDispatch();
    if (Number(accounts?.totalcount) > 1) {
      if (
        Number(isPermission({ type: "dashboard", name: "AccountListing" })) ===
        1
      ) {
        BreadCrumbsObject.title = "Account Listing";
        dispatch(
          addNewBreadcrumbsMethod({
            ...BreadCrumbsObject,
            url: `/accounts-listing`,
          })
        );
      }
    } else {
      if (
        Number(isPermission({ type: "dashboard", name: "AccountDetails" })) ===
        1
      ) {
        BreadCrumbsObject.title = `${accountData?.accountdetail?.account_name} Details`;
        dispatch(
          addNewBreadcrumbsMethod({
            ...BreadCrumbsObject,
            url: `/account-details/${accounts?.account_id}/Details`,
          })
        );
      }
    }
  };

  useEffect(() => {
    // Dispatch clearBreadcrumbs action when component is mounted
    dispatch(clearBreadcrumbs());
  }, [dispatch]);

  const navigateAccount = () => {
    if (Number(accounts?.totalcount) > 1) {
      if (
        Number(isPermission({ type: "dashboard", name: "AccountListing" })) ===
        1
      ) {
        handleDetailsDispatch();
        navigate("/accounts-listing");
      }
    } else {
      if (
        Number(isPermission({ type: "dashboard", name: "AccountDetails" })) ===
        1
      ) {
        handleDetailsDispatch();
        navigate(`/account-details/${accounts?.account_id}/Details`);
      }
    }
  };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setAccounts(matched);
  }, [sections]);

  return (
    <>
      <div
        className="p-4 rounded-lg"
        style={{ cursor: Number(accounts?.totalcount) > 0 ? "pointer" : "" }}
        onClick={() => Number(accounts?.totalcount) > 0 && navigateAccount()}
      >
        <h3 id="account-card" className="text-center text-lg font-bold mb-2">
          {accounts?.totalcount}
        </h3>
        <p className="text-center"> Accounts</p>
      </div>
    </>
  );
};

export default AccountsCard;
