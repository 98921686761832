import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { CallPOSTAPI } from "../../../services";
import { getToken } from "../../../helper";
import PaginateAutoComplete from "../../custom-ui/PaginateAutoComplete";

const SwitchUserModel = ({ openSwitchUser, setOpenSwitchUser }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [contactKey, setContactKey] = useState(Date.now());
  const [errors, setErrors] = useState({
    account: "",
    contact: "",
  });

  useEffect(() => {
    // Reset contact only if account changes
    if (selectedAccount) {
      setSelectedContact("");
    }
  }, [selectedAccount]);

  const handleAccountSelect = (e, val) => {
    const newAccount = val
      ? {
          accountId: val.account_id,
          accountName: val.account_name,
        }
      : null;

    setSelectedAccount(newAccount);
    setSelectedContact(null);

    // Reset errors only on submit attempt
    if (isSubmitted) {
      setErrors({
        account: newAccount ? "" : "Please select an account",
        contact: "",
      });
    }
  };

  const handleContactSelect = (e, val) => {
    if (!selectedAccount) {
      toast.error("Please select an account first");
      return;
    }

    const newContact = val
      ? {
          contactId: val.contact_id,
          contactName: val.contact_name,
        }
      : null;

    setSelectedContact(newContact);

    // Reset errors only on submit attempt
    if (isSubmitted) {
      setErrors((prev) => ({
        ...prev,
        contact: newContact ? "" : "Please select a contact",
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    // Validation only on submit
    const newErrors = {
      account: !selectedAccount ? "Please select an account" : "",
      contact: !selectedContact ? "Please select a contact" : "",
    };

    setErrors(newErrors);

    // Check if there are any errors
    if (newErrors.account || newErrors.contact) {
      toast.error("Please fill all required fields");
      return;
    }

    setLoading(true);
    try {
      const payload = {
        account_id: selectedAccount.accountId,
        contact_id: selectedContact.contactId,
      };

      const res = await CallPOSTAPI("user/switch-user", payload);
      const { token, refreshtoken, permission } = res?.data?.data || {};

      if (token) {
        sessionStorage.removeItem("ross_token");
        sessionStorage.removeItem("ross_rtoken");
        redirectToUserDashboard({ token, refreshtoken, permission });
        toast.msg("user switched successfully");
      } else {
        toast.error(res?.data?.msg);
      }
    } catch (error) {
      // toast.error("An error occurred while switching user");
    } finally {
      setLoading(false);
    }
  };

  const redirectToUserDashboard = (tokenData) => {
    const adminToken = getToken();
    if (!adminToken) {
      toast.error("Token not found. Please try again.");
      return;
    }

    const { token, refreshtoken, permission } = tokenData;
    const userProfileUrl = permission ? "/dashboard" : "";

    const newTab = window.open(userProfileUrl, "_blank");
    newTab.onload = () => {
      newTab.sessionStorage.setItem("ross_token", token);
      newTab.sessionStorage.setItem("refresh_Token", refreshtoken);
      newTab.sessionStorage.setItem("Permission", permission);
      newTab.sessionStorage.removeItem("breadcrumbs", []);
      newTab.localStorage.removeItem("breadcrumbs", []);
      newTab.sessionStorage.setItem("is_user", 1);
    };

    setOpenSwitchUser(false);
  };

  useEffect(() => {
    // When account changes or is removed
    if (!selectedAccount) {
      setContactKey(Date.now()); // This will force re-render of PaginateAutoComplete
    }
  }, [selectedAccount]);

  return (
    <Modal
      show={openSwitchUser}
      onHide={() => setOpenSwitchUser(false)}
      size="lg"
      style={{ maxWidth: "500px", marginLeft: "30%" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Switch User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} noValidate>
          <div
            className="bottom-border-blue py-4 px-2"
            style={{ background: "#eee" }}
          >
            <div className="row">
              <div className="col-md-12 form-group">
                <label className="my-2">Account List</label>
                <PaginateAutoComplete
                  dropDownName="account_id"
                  apiEndpoint="account/account-list-dropdown"
                  idKey="account_id"
                  valueKey="account_name"
                  parentKey="allAccount"
                  onSelect={handleAccountSelect}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  isSearchable={true}
                  isAsync={true}
                  multiple={false}
                />
                {isSubmitted && errors.account && (
                  <div className="text-danger small mt-1">{errors.account}</div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <label className="my-2">User List</label>
                <PaginateAutoComplete
                  key={contactKey} // Add this key prop
                  dropDownName="contact_id"
                  apiEndpoint={`permission/active-user-contacts-list/${selectedAccount?.accountId}`}
                  idKey="contact_id"
                  valueKey="contact_name"
                  parentKey="finaldata"
                  onSelect={handleContactSelect}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  isSearchable={true}
                  isAsync={true}
                  multiple={false}
                  isDisabled={!selectedAccount}
                />
                {isSubmitted && errors.contact && (
                  <div className="text-danger small mt-1">{errors.contact}</div>
                )}
              </div>
            </div>

            <div className="row pb-2 py-5">
              <div className="col-12 content-flex-right">
                <button
                  className="btn btn-danger text-uppercase"
                  type="button"
                  onClick={() => setOpenSwitchUser(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success text-uppercase ms-2"
                  type="submit"
                  disabled={loading || !selectedAccount || !selectedContact}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SwitchUserModel;
