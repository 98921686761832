import React, { useState } from "react";
import AedGeneralInfo from "../AED/AedGeneralInfo";
import { Box } from "@mui/material";
import { Form } from "react-bootstrap";
import BatterySection from "../AED/BatterySection";
import PadSection from "../AED/PadSection";
import ChargePakSection from "../AED/ChargePakSection";
import PreviewTable from "../../Table/PreviewTable";
import ImportResultsModal from "../Modal/ImportResultsModal";
import ResponseTable from "../../Table/ResponseTable";
import DownloadAedCSV from "../AED/DownloadAedCSV";

const aedType_list = {
  BATTERY: "Battery",
  PAD: "Pad",
  CHARGE_PAK: "Charge Pak",  
};

const AedsTab = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [aedType, setAedType] = useState([
    { value: "Battery", label: "Battery" },
    { value: "Pad", label: "Pad" },
    { value: "Charge Pak", label: "Charge Pak" },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [reportModal, setreportModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);

  const [uploadFunction, setUploadFunction] = useState(false);
  const [rejectedRecords, setRejectedRecords] = useState([]); // stores rejcteded and failed records
  const [editFunction, setEditFunction] = useState(false);

  const handleSelect = (e) => {
    const value = e.target.value;
    if (value) {
      setSelectedOption(value);
      if (value !== selectedOption) {
        setFileData([]);
        setSelectedFile(null);
        setResultData([]);
      }
    }
  };

  const handleUpload = () => {
    setUploadFunction(true);
  };

  const handleEdit = () => {
    setEditFunction(true);
  };

  return (
    <div className="mt-3 mb-4">
      {/* AED General Information Section */}
      <div className="mb-4">
        <Box className="text-left pb-2">
          <h5>Import AED General Information</h5>
        </Box>
        <AedGeneralInfo />
      </div>

      <hr />

      {/* AED Inventory Section */}
      <div className="mt-1">
        <div className="mb-2">
          <Box className="text-left pb-2">
            <h5>Download Sample CSV Files</h5>
          </Box>
          <DownloadAedCSV />
        </div>

        <div className="mt-4">
          <h5>Import AED Inventory</h5>
          <div className="mt-4">
            <h6>Select AED Type</h6>

            {/* Container for dropdown and content */}
            <div className="d-flex align-items-center gap-4 mt-2">
              {/* Dropdown */}
              <div style={{ minWidth: "250px" }}>
                <Form.Select value={selectedOption} onChange={handleSelect}>
                  <option value="" disabled>
                    -- Select One --
                  </option>
                  {aedType.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </div>

              {/* Section Content */}
              <div style={{ flex: 1, marginTop: "-30px" }}>
                {selectedOption === "Battery" && (
                  <BatterySection
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    fileData={fileData}
                    setFileData={setFileData}
                    loading={loading}
                    setLoading={setLoading}
                    resultData={resultData}
                    setResultData={setResultData}
                    reportModal={reportModal}
                    setreportModal={setreportModal}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                    setHeaderColumns={setHeaderColumns}
                    uploadFunction={uploadFunction}
                    setUploadFunction={setUploadFunction}
                    setRejectedRecords={setRejectedRecords}
                    editFunction={editFunction}
                    setEditFunction={setEditFunction}
                    aedType_list={aedType_list}
                  />
                )}
                {selectedOption === "Pad" && (
                  <PadSection
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    fileData={fileData}
                    setFileData={setFileData}
                    loading={loading}
                    setLoading={setLoading}
                    resultData={resultData}
                    setResultData={setResultData}
                    reportModal={reportModal}
                    setreportModal={setreportModal}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                    setHeaderColumns={setHeaderColumns}
                    uploadFunction={uploadFunction}
                    setUploadFunction={setUploadFunction}
                    setRejectedRecords={setRejectedRecords}
                    editFunction={editFunction}
                    setEditFunction={setEditFunction}
                    aedType_list={aedType_list}
                  />
                )}
                {selectedOption === "Charge Pak" && (
                  <ChargePakSection
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    fileData={fileData}
                    setFileData={setFileData}
                    loading={loading}
                    setLoading={setLoading}
                    resultData={resultData}
                    setResultData={setResultData}
                    reportModal={reportModal}
                    setreportModal={setreportModal}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                    setHeaderColumns={setHeaderColumns}
                    uploadFunction={uploadFunction}
                    setUploadFunction={setUploadFunction}
                    setRejectedRecords={setRejectedRecords}
                    editFunction={editFunction}
                    setEditFunction={setEditFunction}
                    aedType_list={aedType_list}
                  />
                )}
              </div>
            </div>
          </div>

          <ImportResultsModal
            show={reportModal}
            onHide={() => setreportModal(false)}
            results={resultData}
          />

          {resultData && resultData?.summary?.total_records > 0 && (
            <div className="mt-4">
              <div style={{ display: "flex", gap: "0.5rem" }}>
                {resultData?.summary?.rejected_records > 0 ||
                resultData?.summary?.failed_records > 0 ? (
                  <button
                    className="btn btn-warning"
                    onClick={() => setreportModal(true)}
                    style={{
                      padding: "0.5rem",
                      borderRadius: "4px",
                      textAlign: "center",
                      width: "180px",
                      fontSize: "1rem",
                    }}
                  >
                    See Details
                  </button>
                ) : null}

                {[
                  {
                    label: "Total Records",
                    value: resultData?.summary?.total_records || 0,
                    color: "#0d6efd",
                  },
                  {
                    label: "Successful Records",
                    value: resultData?.summary?.successful_records || 0,
                    color: "#2e7d32",
                  },
                  {
                    label: "Rejected Records",
                    value:
                      (resultData?.summary?.rejected_records || 0) +
                      (resultData?.summary?.failed_records || 0),
                    color: "#D32F2F",
                  },
                ].map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      backgroundColor: item.color,
                      color: "white",
                      padding: "0.5rem",
                      borderRadius: "4px",
                      textAlign: "center",
                      width: "auto",
                      fontSize: "1rem",
                    }}
                  >
                    <span>
                      {item.label} : {item.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Preview Table */}
          {fileData.length > 0 && (
            <div className="mt-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <h5> Preview </h5>
                <button
                  style={{
                    padding: "0.5rem 1rem",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleUpload}
                >
                  {loading ? "Uploading..." : "Upload File"}
                </button>
              </div>

              <PreviewTable
                tableData={fileData}
                isSearchable={true}
                headerColumns={headerColumns}
                setSelectedFile={setSelectedFile}
              />
            </div>
          )}

          {/* Edit Response Table */}
          {(resultData?.summary?.rejected_records > 0 ||
            resultData?.summary?.failed_records > 0) && (
            <div className="mt-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <h5> Rejected Records: </h5>
                <button
                  style={{
                    padding: "0.5rem 1rem",
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleEdit}
                >
                  {loading ? "Uploading..." : "Upload Changes"}
                </button>
              </div>

              <ResponseTable
                isSearchable={true}
                tableData={rejectedRecords}
                headerColumns={headerColumns}
                setUpdatedData={setUpdatedData}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AedsTab;
