import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./layout.scss";
import Footer from "./Footer";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { DecryptToken } from "../../helper";
import UserSidebar from "./UserSidebar";
import { handleAccount } from "../../helper/permission";
import { useDispatch } from "react-redux";
import { CallGETAPI } from "../../services";
import { fetchPermissionAPI } from "../../helper/BasicFn";
import ScrollTopButton from "../ScrollTopButton";
// import Breadcrumbs from '../breadcrumbsComp/Breadcrumbs';

const Layout = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const user = DecryptToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setShow(false);
  }, [navigate]);

  useEffect(() => {
    // if (user?.user_type > 0)
    handleAccount(dispatch);
  }, [dispatch]);

  return (
    <div className="dashboard" id="main-layout">
      <Header handleClose={handleClose} handleShow={handleShow} />

      {Number(user?.user_type) === 0 ? (
        <Sidebar show={show} setShow={setShow} handleClose={handleClose} />
      ) : (
        ""
      )}

      {Number(user?.user_type) === 3 ||
      (Number(user?.user_type) === 3 && user?.is_admin) ? (
        <UserSidebar show={show} setShow={setShow} handleClose={handleClose} />
      ) : (
        ""
      )}
      {/* <Breadcrumbs /> */}
      <Container className="my-container" fluid>
        <Row>
          <Col style={{ minHeight: "85.1vh" }}>{children}</Col>
        </Row>
      </Container>

      <ToastContainer />

      {/* scroll to top button*/}
      <ScrollTopButton />

      <Footer />
    </div>
  );
};

export default Layout;
