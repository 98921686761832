import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form } from "react-bootstrap";
import { CallPOSTAPI } from "../../../../common/services/index";
import { useDispatch, useSelector } from "react-redux";
import PaginateAutoComplete from "../../../../common/components/custom-ui/PaginateAutoComplete";
import { prepareAedBrandModelOptions } from "../../../../common/helper/Common";
import CustomAEDBrandModalMultiDropdown from "../../../../common/components/custom-ui/CustomAEDBrandModalMultiDropdown";
import {
  addAEDFilterData,
  addAEDPayloadData,
  removeAEDFilterData,
  removeAEDPayloadData,
  updateAEDFilterData,
} from "../../../../store/slices/AEDListingFilter";
import { getPer } from "../../../../common/helper/BasicFn";

export default function AEDFilter({ setOpen, search = "", clearBtn }) {
  // const [allToggle, setAllToggle] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [formData, setFormData] = useState({
    brandmodel: [],
    sites: [],
    state: [],
  });
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.AEDListFilter.AEDFilterData);
  const payloadData = useSelector(
    (state) => state.AEDListFilter.AEDPayloadData
  );

  // drawer header
  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "space-between",
  }));

  // close drawer function
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // handle select change
  const handleSelectChange = (data, key) => {
    let valueArray = [];
    data.map((item, index) => {
      valueArray.push({
        label: item.label,
        value: item.value,
      });
    });
    setFormData((old) => ({ ...old, [key]: valueArray }));
  };

  // handle select change
  const handleSearchSelectChange = (data, key, idKey, valueKey) => {
    let valueArray = [];
    data.forEach((item) => {
      valueArray.push({
        label: item?.[valueKey],
        value: item?.[idKey],
      });
    });
    setFormData((old) => ({ ...old, [key]: valueArray }));
  };

  // handle select change
  // const handleSelectSubmitData = (data) => {
  //   let valueArray = [];
  //   data.map((item) => {
  //     valueArray.push(item.value);
  //   });

  //   return valueArray;
  // };

  const handleSelectSubmitData = (data) => {
    // Return empty string if data is undefined or null
    if (!data) return "";

    // If data is not an array, convert it to array if possible
    const dataArray = Array.isArray(data) ? data : [];

    let valueArray = [];
    dataArray.forEach((item) => {
      if (item && item.value) {
        valueArray.push(item.value);
      }
    });

    // Join values with comma and return string
    return valueArray.join(",");
  };

  // clear filter
  const handleClearFilter = async (e) => {
    e.preventDefault();
    handleDrawerClose();

    if (clearBtn) {
      setFormData((old) => ({
        ...old,
        brandmodel: [],
        sites: [],
        state: [],
      }));
      dispatch(removeAEDFilterData());
      dispatch(removeAEDPayloadData());
    }
  };

  const stringifyArrays = (data) => {
    const stringifiedData = {};
    for (const key in data) {
      if (Array.isArray(data[key])) {
        stringifiedData[key] = data[key].join(",");
      } else {
        stringifiedData[key] = data[key];
      }
    }
    return stringifiedData;
  };

  const handleSubmit = async (e) => {
    handleDrawerClose();
    let payloadData = {
      brandmodel:
        formData?.brandmodel?.length > 0
          ? handleSelectSubmitData(formData?.brandmodel)
          : [],
      sites:
        formData?.sites?.length > 0
          ? handleSelectSubmitData(formData?.sites)
          : [],
      state:
        formData?.state?.length > 0
          ? handleSelectSubmitData(formData?.state)
          : [],
    };
    const payloadDataStringified = stringifyArrays(payloadData);

    setShowLoading(true);

    let results = await CallPOSTAPI("aed/equipment-list-filter", {
      ...payloadDataStringified,
      page: 1,
      pageSize: 50,
      search: search,
    });
    if (results?.status) {
      if (Object.keys(filterData).length !== 0) {
        dispatch(updateAEDFilterData(formData));
      } else {
        dispatch(addAEDFilterData(formData));
      }
      dispatch(addAEDPayloadData(payloadDataStringified));
    }
    setShowLoading(false);
  };

  useEffect(() => {
    if (Object.keys(filterData).length !== 0) {
      setFormData({
        brandmodel: filterData?.brandmodel || [],
        sites: filterData?.sites || [],
        state: filterData?.state || [],
      });
    }
  }, []);

  return (
    <div style={{ background: "#000", height: "100%" }}>
      {/* drawer header */}
      <DrawerHeader>
        <div className="left-btns">
          <IconButton
            onClick={handleDrawerClose}
            className="my-account-list-btn"
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </DrawerHeader>
      {/* main content of filter */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "90%",
        }}
      >
        <div className="content px-4">
          {/* AED Brand/Model */}
          <Form.Group className="mb-3" style={{ maxWidth: "200px !important" }}>
            <Form.Label>AED Brand/Model</Form.Label>
            {/* /fetch-filter-brand-model */}
            <CustomAEDBrandModalMultiDropdown
              dropDownName={"aed_brand-model"}
              apiEndpoint={
                Number(getPer()) === 1
                  ? "aed/sitebar-aed-brandmodel-filter"
                  : "user/sitebar-aed-brandmodel-filter"
              }
              idKey={"model_id"}
              valueKey={"model_name"}
              parentKey={"BrandModel"}
              showSelectedCount={true}
              onSelect={(e) => {
                const resultData = e.map((it) => ({
                  value: it?.model_id,
                  label: it?.model_name,
                }));
                handleSelectChange(resultData, "brandmodel");
              }}
              shouldFetchOnOpen={false}
              isCache={true}
              className={"react-select-container custom-select"}
              selectDisabled={false}
              isMultiSelect={true}
              closeMenuSelect={false}
              prepareOptions={prepareAedBrandModelOptions}
              initialSelectedValue={
                Array.isArray(formData?.brandmodel) &&
                formData?.brandmodel.length > 0
                  ? formData.brandmodel.map((it) => ({
                      model_id: it?.value,
                      model_name: it?.label,
                    }))
                  : []
              }
            />
          </Form.Group>

          {/* Account Status */}
          <Form.Group className="mb-3">
            <Form.Label>Sites</Form.Label>
            <PaginateAutoComplete
              dropDownName={"sites"}
              apiEndpoint={
                Number(getPer()) === 1
                  ? `aed/sitebar-aed-site-list-filter`
                  : `user/sitebar-aed-site-filter`
              }
              idKey={"account_site_info_id"}
              valueKey={"account_site_name"}
              parentKey={"site"}
              showCountsOnly={1}
              onSelect={(e, value) => {
                handleSearchSelectChange(
                  value,
                  "sites",
                  "account_site_info_id",
                  "account_site_name"
                );
              }}
              placeholder="-- Select --"
              shouldFetchOnOpen={false}
              isCache={true}
              className={"react-select-container"}
              selectDisabled={false}
              closeMenuSelect={false}
              multiple={true}
              showCheckBox={true}
              defaultValue={
                formData?.sites?.length > 0
                  ? formData.sites.map((it) => ({
                      account_site_info_id: it.value,
                      account_site_name: it.label,
                    }))
                  : null
              }
              getResults={(res) => {
                const result = res?.data?.data?.site || [];
                const totalCountsVar = res?.data?.data?.totalCount || 0;
                return [result, totalCountsVar];
              }}
            />
          </Form.Group>

          {/* State */}
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <PaginateAutoComplete
              dropDownName={"state"}
              apiEndpoint={
                Number(getPer()) === 1
                  ? `aed/sitebar-aed-state-list-filter`
                  : `user/sitebar-aed-state-filter`
              }
              idKey={"state_id"}
              valueKey={"state_name"}
              parentKey={"state"}
              showCountsOnly={1}
              onSelect={(e, value) => {
                handleSearchSelectChange(
                  value,
                  "state",
                  "state_id",
                  "state_name"
                );
              }}
              placeholder="-- Select --"
              shouldFetchOnOpen={false}
              isCache={true}
              className={"react-select-container"}
              selectDisabled={false}
              closeMenuSelect={false}
              multiple={true}
              showCheckBox={true}
              defaultValue={
                Array.isArray(formData?.state) && formData?.state?.length > 0
                  ? formData.state.map((it) => ({
                      state_id: it.value,
                      state_name: it.label,
                    }))
                  : []
              }
              getResults={(res) => {
                const result = res?.data?.data?.state || [];
                const totalCountsVar = res?.data?.data?.totalCount || 0;
                return [result, totalCountsVar];
              }}
            />
          </Form.Group>
        </div>

        {/* submit buttons */}
        <div className="mt-4 pb-2 mx-4 btn-align">
          <button
            className="btn btn-light btn-clear-filter"
            onClick={(e) => {
              handleClearFilter(e);
            }}
          >
            Clear
          </button>
          <button
            className="btn btn-light ms-2 btn-submit-filter"
            style={{
              backgroundColor: "#4fe14fe6",
              color: "#FFF",
              border: "none",
            }}
            type="button"
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
