import React, { forwardRef, useRef, useImperativeHandle } from "react";
import GeneralInfo from "../GeneralInfo";
import ExternalRMSInfo from "../ExternalRMSInfo";
import OutOfService from "../OutOfService";
import StorageInformation from "../StorageInformation";
import BuiltInRMSInformation from "../BuiltInRMSInformation";

const NewAedForm = forwardRef(
  (
    {
      addRemoveBtn,
      AccountList,
      BrandList,
      formData,
      setFormData,
      setPermission,
      Permissins,
      RmsDropdown,
      all_condition_true,
      is_edit = false,
      DefaultValue,
      aedList,
      loanerList,
      setAccId,
      AccId,
      siteId,
      PermissionRedux,
      handleCheckBox,
      handleInput,
      addMorebattery_info,
    },
    ref
  ) => {
    const generalInfoRef = React.useRef(null);

    useImperativeHandle(ref, () => ({
      scrollToError: (errors) => {
        // First try to find errors in GeneralInfo component
        const generalInfoFields = [
          "serial_number",
          "account_id",
          "site_id",
          "brand",
          "model_name",
        ];
        const firstGeneralInfoError = Object.keys(errors).find((field) =>
          generalInfoFields.includes(field)
        );

        if (firstGeneralInfoError) {
          generalInfoRef.current?.scrollToError(firstGeneralInfoError);
          return;
        }

        // Then check other sections
        const sectionMap = {};

        const firstErrorField = Object.keys(errors).find(
          (field) => sectionMap[field]?.current
        );
        if (firstErrorField) {
          sectionMap[firstErrorField].current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      },
    }));

    return (
      <>
        <div className="">
          <GeneralInfo
            addRemoveBtn={addRemoveBtn}
            ref={generalInfoRef}
            BrandList={BrandList}
            AccountList={AccountList}
            formData={formData}
            setFormData={setFormData}
            setPermission={setPermission}
            Permissins={Permissins}
            is_edit={is_edit}
            DefaultValue={DefaultValue}
            setAccId={setAccId}
            AccId={AccId}
          />

          {formData?.out_of_service_toggle || all_condition_true ? (
            <OutOfService
              BrandList={BrandList}
              AccountList={AccountList}
              formData={formData}
              setFormData={setFormData}
              aedList={aedList}
              loanerList={loanerList}
              siteId={siteId}
            />
          ) : (
            ""
          )}

          <StorageInformation
            BrandList={BrandList}
            AccountList={AccountList}
            formData={formData}
            setFormData={setFormData}
            keyName={"keyName"}
            Permissins={Permissins}
          />

          {PermissionRedux?.has_builtin_rms || all_condition_true ? (
            <div className=" bg-gray py-4 px-4 mt-4" id="built_in_RMS_info">
              <h2 className="heading">{"Built-In RMS Information"} </h2>

              {formData?.BuiltInRMSInformation?.map((item, index) => (
                <BuiltInRMSInformation
                  keyName={"BuiltInRMSInformation"}
                  title="BuiltInRMSInformation"
                  crrIndex={index}
                  formData={formData}
                  setFormData={setFormData}
                  handleCheckBox={handleCheckBox}
                  handleInput={handleInput}
                  crrFormData={item}
                  addMore={addMorebattery_info}
                />
              ))}
            </div>
          ) : (
            ""
          )}

          {formData?.RMS_toggle || all_condition_true ? (
            <ExternalRMSInfo
              BrandList={BrandList}
              AccountList={AccountList}
              formData={formData}
              setFormData={setFormData}
              RmsDropdown={RmsDropdown}
            />
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
);

export default NewAedForm;
