import { useEffect, useState } from "react";
import DNDDashboard from "./DNDDashboard";
import Filter from "./filter";
import {
  fetchAedTechBarGraphData,
  fetchDashboardCards1,
  fetchDashboardPermission,
  fetchPieChartDataWithSection,
  updateSections,
} from "../components/Services";
import { printConsole } from "../../../common/helper/Common";

const initialDashboardState = [
  {
    id: "section1",
    title: "Key Metrics",
    items: [
      {
        id: "accounts",
        componentName: "AccountsCard",
        totalcount: "36,011",
        account_id: "357",
        isVisible: 1,
        color: "#ff0000",
      },
      {
        id: "sites",
        componentName: "SitesCard",
        totalcount: "14,011",
        site_id: "",
        isVisible: 1,
        color: "#00bfff",
      },
      {
        id: "contacts",
        componentName: "ContactsCard",
        totalcount: "4,011",
        contact_id: "",
        isVisible: 1,
        color: "#00ff00",
      },
      {
        id: "aeds",
        componentName: "AedsCard",
        totalcount: "15,601",
        aed_id: "",
        isVisible: 1,
        color: "#8a2be2",
      },
      {
        id: "accessories",
        componentName: "AccessoriesCard",
        totalcount: "34,011",
        accessory_id: "",
        isVisible: 1,
        color: "#ffd700",
      },
    ],
  },
  {
    id: "section2",
    title: "AED Status",
    items: [
      {
        id: "aedsAssigned",
        componentName: "AedAssignedCard",
        aed_id: "",
        aed_accountID: "",
        isVisible: 1,
        primary: 4,
        backup: 4,
        color: "#00bfff",
      },
      {
        id: "aedChecks",
        componentName: "AedCheckCard",
        aed_id: "",
        aed_accountID: "",
        isVisible: 1,
        primary: 5,
        backup: 5,
        color: "#ff0000",
      },
    ],
  },
  {
    id: "section3",
    title: "Charts",
    items: [
      {
        id: "supportTickets",
        componentName: "SupportHalfPieChart",
        isVisible: 1,
        color: "",
        title: "Support Tickets",
        type: "chart",
        data: [],
      },
      {
        id: "delinquentChecks",
        componentName: "DeliquentCheckHalfPieChart",
        isVisible: 1,
        color: "",
        title: "Delinquent Checks",
        type: "chart",
      },
      {
        id: "aedsOwned",
        componentName: "AedOwnedPieChart",
        isVisible: 1,
        color: "",
        title: "AEDs Owned",
        type: "chart",
      },
      {
        id: "aedDistribution",
        componentName: "AedDistributionPieChart",
        isVisible: 1,
        color: "",
        title: "AED Distribution",
        type: "chart",
      },
    ],
  },
  {
    id: "section4",
    title: "LineGraph",
    items: [
      {
        id: "AedTechServicing",
        componentName: "LineGraph",
        isVisible: 1,
        color: "",
        type: "chart",
        data: [],
      },
    ],
  },
  {
    id: "section5",
    title: "DashBarGraph",
    items: [
      {
        id: "aedTechAssignments",
        componentName: "DashBarGraph",
        isVisible: 1,
        color: "",
        type: "chart",
        data: [],
      },
    ],
  },
];

const Dahsboard = () => {
  const [sections, setSections] = useState(initialDashboardState);
  const [loading, setLoading] = useState(true);
  const [permission, setPermission] = useState({});

  const CheckChartTypeData = (Itemdata) => {
    if (!Itemdata) {
      return 0;
    }
    return 1;
  };

  const filterOnBasedData = async (sections) => {
    printConsole({ sections1: sections }); // print console.log
    const checkSection = await fetchPieChartDataWithSection(sections);
    printConsole({ checkSection }); // print console.log
    const resut = true;
    //   checkSection.sections.map((section) => {
    //   printConsole({ items2929299: section.items }); // print console.log
    //   const items = (section.items || []).filter((item) => {
    //     // Filter items based on type
    //     return (
    //       item.type !== "chart" ||
    //       (item.type === "chart" && CheckChartTypeData(item?.data))
    //     );
    //   });
    //   const Result = {
    //     ...section,
    //     items,
    //   };
    //   printConsole({ InternalRsult: Result }); // print console.log
    //   // Return the updated section
    //   return sections;
    // });
    printConsole({ ExternalResult: resut }); // print console.log
    return checkSection;
  };

  const fetchOnLoad = async () => {
    setLoading(true);
    const cards1Data = await fetchDashboardCards1();
    const permission = await fetchDashboardPermission();
    setPermission(permission);
    const fetchData = cards1Data?.assign_component;

    const dataPemission = updateSections({
      fetchData: fetchData,
      permission: permission,
    });

    const finalSections = await filterOnBasedData(dataPemission);

    // const finalSections = await filterOnBasedData(fetchData);
    printConsole({ finalSections: finalSections?.sections, fetchData }); // print console.log

    setSections(finalSections?.sections || fetchData);
    setLoading(false);
  };

  useEffect(() => {
    fetchOnLoad();
  }, []);

  return (
    <>
      <DNDDashboard
        loading={loading}
        sections={sections}
        setSections={setSections}
        initialDashboardState={initialDashboardState}
      />
      <Filter
        sections={sections}
        permission={permission}
        setSections={setSections}
        initialDashboardState={initialDashboardState}
        fetchOnLoad={fetchOnLoad}
      />
    </>
  );
};

export default Dahsboard;
