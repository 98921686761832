import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const ProgressBar = ({ progress }) => {

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <Box position="relative" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress
          style={{ color: 'white' }}
          // variant="determinate"
          // value={progress}
          size={70}
          thickness={2.5}
        />
        {/* <Box
          position="absolute"
          top="50%"
          left="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ transform: 'translate(-50%, -50%)', color: 'white' }}
        >
          <div>{`${progress}%`}</div>
        </Box> */}
      <div 
       style={{ position: "absolute", top: "100%", display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}
      >
        Uploading...</div>

      </Box>
    </div>
  );
};

export default ProgressBar;
