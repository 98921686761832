import React, { useEffect, useState } from "react";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import { Button as FixedButton } from "@mui/material";
// import { Alert } from 'react-bootstrap'
// import { Snackbar } from '@mui/material'
import SubHeading from "../../dashboard/components/header/SubHeading";
// import { Box } from 'devextreme-react'
import {
  DecryptToken,
  getPer,
  truncateText,
} from "../../../common/helper/BasicFn";
import Moment from "react-moment";
import { Alert, Box, Drawer, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addNewBreadcrumbs,
  clearBreadcrumbs,
} from "../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { isPermission } from "../../../common/helper/PermissionManager";
import AEDFilter from "../components/Filters/AEDFilter";
import { useSelector } from "react-redux";
import {
  removeAEDFilterData,
  removeAEDPayloadData,
} from "../../../store/slices/AEDListingFilter";
function SideEquipmentList() {
  const user = DecryptToken();
  const [openModel, setOpenModel] = React.useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AEDFilterData = useSelector(
    (state) => state.AEDListFilter.AEDFilterData
  );
  const AEDPayloadData = useSelector(
    (state) => state.AEDListFilter.AEDPayloadData
  );

  let is_user = false;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAedClick = (item) => {
    if (user.user_type > 1) {
      is_user = true;
      // let permissions = localStorage.getItem("permissions");
      // let permissionsArr = permissions.split(",");
      if (Number(isPermission({ type: "link", name: "serialNumber" })) === 1) {
        // is_aed_details = true;
        handleDetailsDispatch(
          item.aed_id,
          item.serial_number,
          item.account_id,
          item.account_name
        );
        navigate("/account/aed-details/" + item?.aed_id + "/Details");
        //   {
        //   state: { is_user: true, privileges: permissionsArr },
        // });
      }
    } else {
      handleDetailsDispatch(
        item.aed_id,
        item.serial_number,
        item.account_id,
        item.account_name
      );
      navigate("/account/aed-details/" + item?.aed_id + "/Details");
    }
  };

  const handleDetailsDispatch = (
    aedId,
    serial_number,
    accountId,
    accountName
  ) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
    };
    const handleDispatch = () => {
      BreadCrumbsObject.title = "Aed Listing";
      // BreadCrumbsObject.tab = "Details";
      // BreadCrumbsObject.type = "site";
      dispatch(
        addNewBreadcrumbs({ ...BreadCrumbsObject, url: "/Admin/Aed-listing" })
      );
    };
    handleDispatch();
    BreadCrumbsObject.title = serial_number + " Details";
    BreadCrumbsObject.tab = "Details";
    // BreadCrumbsObject.type = "site";
    BreadCrumbsObject.account_Name = accountName;
    BreadCrumbsObject.accountId = accountId;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed-details/${aedId}/Details`,
      })
    );
  };
  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModel(false);
  };

  const headerColumns = [
    {
      Header: "AED Brand / Model",
      accessor: "AED_brands",
      Cell: ({ row }) => (
        <span
          className="link"
          style={{
            textDecoration: "none",
            cursor:
              Number(isPermission({ type: "link", name: "aedBrand" })) === 1
                ? "pointer"
                : "default",
          }}
          onClick={() => {
            handleAedClick(row.original);
          }}
          title={
            row.original.AED_brands?.length > 15
              ? row.original.AED_brands
              : undefined // Tooltip only if the text is truncated
          }
        >
          {row.original.AED_brands} {truncateText(row.original.model_name, 18)}
        </span>
      ),
      width: "18%",
    },
    {
      Header: "Serial #",
      accessor: "serial_number",
      width: "15%",
      Cell: ({ row }) => (
        <span
          className="link"
          style={{
            textDecoration: "none",
            cursor:
              Number(isPermission({ type: "link", name: "serialNumber" })) === 1
                ? "pointer"
                : "default",
          }}
          onClick={() => handleAedClick(row.original)}
          title={
            row.original.serial_number?.length > 20
              ? row.original.serial_number
              : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row.original.serial_number, 25)}
        </span>
      ),
    },
    {
      Header: "Last Check",
      accessor: "last_check_date",
      width: "12%",
      Cell: ({ row }) => (
        <Moment
          date={row.original.last_check_date}
          format="MM/DD/YYYY h:mm:ss"
        />
      ),
    },
    {
      Header: "Last Service",
      accessor: "last_service_date",
      width: "12%",
      Cell: ({ row }) => (
        <Moment
          date={row.original.last_service_date}
          format="MM/DD/YYYY h:mm:ss"
        />
      ),
    },

    {
      Header: "Account",
      accessor: "account_name",
      width: "15%",
      Cell: ({ row }) => (
        <span
          title={
            row.original.account_name?.length > 20
              ? row.original.account_name
              : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row.original.account_name, 20)}
        </span>
      ),
    },
    {
      Header: "Site Name",
      accessor: "account_site_name",
      width: "15%",
      Cell: ({ row }) => (
        <span
          title={
            row.original.account_site_name?.length > 20
              ? row.original.account_site_name
              : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row.original.account_site_name, 20)}{" "}
        </span>
      ),
    },
  ];

  const handleClearFilterData = () => {
    dispatch(removeAEDFilterData());
    dispatch(removeAEDPayloadData());
  };

  const defaultUserPayload = {
    sites: "",
    brandmodel: "",
    state: "",
  };

  return (
    <>
      <div>
        {AEDFilterData && Object.keys(AEDFilterData).length !== 0 ? (
          <>
            <div style={{ paddingBottom: "28px" }}>
              <FixedButton
                className="btn-style-listing-cancel-filter"
                onClick={handleClearFilterData}
              >
                Clear Filter
              </FixedButton>
            </div>
          </>
        ) : null}

        <FixedButton className="btn-style-filter" onClick={handleDrawerOpen}>
          Advanced Filters
        </FixedButton>

        <Drawer
          sx={{
            width: "400px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "400px",
              boxSizing: "border-box",
              background: "#000",
            },
          }}
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
        >
          {/* filter component  */}
          <AEDFilter
            setOpen={setOpen}
            clearBtn={AEDFilterData && Object.keys(AEDFilterData).length !== 0}
          />
        </Drawer>
      </div>

      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>
      <div className="mt-4">
        <SubHeading
          hideNew={true}
          hideHierarchy={true}
          title={"AED Listing"}
          subHeading={true}
        />

        <div style={{ minHeight: "84.6vh" }}>
          <Box className="py-4">
            <NewGlobalTable
              method={
                AEDPayloadData && Object.keys(AEDPayloadData).length > 0
                  ? "POST"
                  : Number(getPer()) === 1
                  ? "GET"
                  : "POST"
              }
              isSearchable={true}
              isCache={true}
              pageSize={60}
              // payloadData={
              //   AEDPayloadData && Object.keys(AEDPayloadData).length > 0
              //     ? AEDPayloadData
              //     : null
              // }
              payloadData={
                AEDPayloadData && Object.keys(AEDPayloadData).length > 0
                  ? AEDPayloadData
                  : Number(getPer()) === 1
                  ? null
                  : defaultUserPayload // Use default payload for user equipment list
              }
              apiEndPoint={
                AEDPayloadData && Object.keys(AEDPayloadData).length > 0
                  ? "aed/equipment-list-filter"
                  : Number(getPer()) === 1
                  ? "aed/equipment-list"
                  : "user/account-equipment-list"
              }
              keyAPiName={
                AEDPayloadData && Object.keys(AEDPayloadData).length > 0
                  ? "resultData"
                  : Number(getPer()) === 1
                  ? "resultData"
                  : "aed"
              }
              headerColumns={headerColumns}
            />
          </Box>
        </div>
      </div>
    </>
  );
}

export default SideEquipmentList;
