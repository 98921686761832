import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Form } from "react-bootstrap";
import { BASE_API } from "../../../../common/services";
import ProgressBar from "../ProgressBar";
import PaginateAutoComplete from "../../../../common/components/custom-ui/PaginateAutoComplete";

const ChargePakSection = ({
  selectedFile,
  setSelectedFile,
  fileData,
  setFileData,
  loading,
  setLoading,
  resultData,
  setResultData,
  updatedData,
  setUpdatedData,
  setHeaderColumns,
  uploadFunction,
  setUploadFunction,
  editFunction,
  setEditFunction,
  aedType_list,
  setRejectedRecords,
}) => {
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleFileChange = (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    fileInput.value = null; // Reset file input

    if (file) {
      const maxSizeInBytes = 26214400; // 25 MB

      if (file.size > maxSizeInBytes) {
        toast.error("File size must be less than 25MB.");
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
        setResultData([]);
        setUploadFunction(false);
      }
    } else {
      setSelectedFile(null);
    }
  };

  // // this function stand for  date convert into proper date format from the excel
  // function excelSerialDateToJSDate(serial) {
    
  //   if (serial === "unknown") {
  //     return "unknown";
  //   }

  //   if (serial === "" || serial === null || serial === undefined) {
  //     return "";
  //   }

  //   try {
  //     // console.log("Initial serial value:", serial);
  //     // console.log("Type of initial serial value:", typeof serial);

  //     // If the serial is already a Date object, format it
  //     if (serial instanceof Date && !isNaN(serial.getTime())) {
  //       return formatDate(serial);
  //     }

  //     // Parse string to number if needed
  //     if (typeof serial === "string") {
  //       const dateFromString = new Date(serial);
  //       if (!isNaN(dateFromString.getTime())) {
  //         return formatDate(dateFromString);
  //       }
  //       serial = parseFloat(serial);
  //     }

  //     // Validate serial number
  //     if (typeof serial !== "number" || serial <= 0 || isNaN(serial)) {
  //       console.error(`Invalid serial number detected: ${serial}`);
  //       return "";
  //     }

  //     // Adjust for Excel's leap year bug
  //     if (serial > 59) {
  //       serial -= 1;
  //     }

  //     // Excel epoch (January 1, 1900 at midnight UTC)
  //     const EXCEL_EPOCH = new Date(Date.UTC(1900, 0, 1));
  //     const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

  //     // Calculate days and fractional days
  //     const totalDays = Math.floor(serial);
  //     const fractionalDay = serial - totalDays;

  //     // Calculate the date
  //     const dateValue = new Date(
  //       EXCEL_EPOCH.getTime() + (totalDays - 1) * MILLISECONDS_PER_DAY
  //     );

  //     // Add the time portion
  //     if (fractionalDay > 0) {
  //       dateValue.setMilliseconds(fractionalDay * MILLISECONDS_PER_DAY);
  //     }

  //     return formatDate(dateValue);
  //   } catch (error) {
  //     console.error("Error converting Excel serial to date:", error);
  //     return "";
  //   }
  // }

  // // Helper function to format the date
  // function formatDate(date) {
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   return `${month}/${day}/${year}`;
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!selectedAccount){
      toast.error("Please select a account.");
      return;
    }

    if (!selectedFile) {
      toast.error("Please select a file.");
      return;
    }

    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: null, raw: false }); // Convert sheet to JSON data

        // const dateFields = [
        //   "battery_expiration",
        //   "pad_1_expiration",
        //   "pad_2_expiration",
        // ];

        // const formattedData = jsonData.map((row) => {
        //   const updatedRow = { ...row };

        //   dateFields.forEach((field) => {
        //     if (updatedRow[field]) {
        //       updatedRow[field] = excelSerialDateToJSDate(updatedRow[field]);
        //     }
        //   });

        //   return updatedRow;
        // });

        // console.log({ formattedData });
      
        // setFileData(jsonData); // Store data in the state
        if (jsonData.length === 0) {
          // If no data, generate an object with columns as keys and null as values
          const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] || [];
          const emptyRow = headers.reduce((acc, header) => {
            acc[header] = null;
            return acc;
          }, {});
          setFileData([emptyRow]); 
        } else {
          setFileData(jsonData); // Store data with valid values
        }        
      };

      reader.readAsBinaryString(selectedFile); // Read the file as binary string
    } catch (error) {
      toast.error("Error processing file.");
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!selectedFile) {
  //     toast.error("Please select a file.");
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const fileData = e.target.result;
  //       const workbook = XLSX.read(fileData, { type: "binary" });
  //       const sheetName = workbook.SheetNames[0]; // Get the first sheet
  //       const sheet = workbook.Sheets[sheetName];
  //       const jsonData = XLSX.utils.sheet_to_json(sheet); // Convert sheet to JSON data
  //       setFileData(jsonData); // Store data in the state
  //     };
  //     reader.readAsBinaryString(selectedFile); // Read the file as binary string
  //   } catch (error) {
  //     toast.error("Error processing file.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      let Key = Object.keys(aedType_list).find((key) => aedType_list[key] === "Charge Pak");

      const res = await fetch(`${BASE_API}import/charge-pak-import/${selectedAccount?.accountId}?aed_type=${Key}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (res.status) {
        const result = await res.json();

        const successRecords = result?.data?.successful_records || [];
        if (successRecords.length > 0) {
          toast.success("File uploaded successfully!");
        } else {
          toast.error(result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records
          ? rsData?.rejected_records.map((item) => item.record)
          : [];
        const failedData = rsData?.failed_records
          ? rsData?.failed_records.map((item) => item.record)
          : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);

        setResultData(result?.data);
        setSelectedFile(null);
        setFileData([]);
        setUploadFunction(false);

        let rejected = result?.data?.summary?.rejected_records || 0;
        let failed = result?.data?.summary?.failed_records || 0;
        if(rejected === 0 && failed === 0) {
          setSelectedAccount("");
        }
      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (uploadFunction === true) {
      handleUpload();
    } else if (editFunction === true) {
      handleEdit();
    }
  }, [uploadFunction, editFunction]);

  const headerColumns = [
    {
      Header: "is_spare",
      accessor: "is_spare",
      disableSortBy: true,
    },
    {
      Header: "serial_number",
      accessor: "serial_number",
      disableSortBy: true,
    },
    {
      Header: "battery_expiration",
      accessor: "battery_expiration",
      disableSortBy: true,
    },
    {
      Header: "battery_part",
      accessor: "battery_part",
      disableSortBy: true,
    },
    {
      Header: "battery_lot",
      accessor: "battery_lot",
      disableSortBy: true,
    },
    {
      Header: "charge_pak_uid",
      accessor: "charge_pak_uid",
      disableSortBy: true,
    },
    {
      Header: "pad_1_lot",
      accessor: "pad_1_lot",
      disableSortBy: true,
    },
    {
      Header: "pad_1_expiration",
      accessor: "pad_1_expiration",
      disableSortBy: true,
    },
    {
      Header: "pad_1_pad_part",
      accessor: "pad_1_pad_part",
      disableSortBy: true,
    },
    {
      Header: "pad_2_lot",
      accessor: "pad_2_lot",
      disableSortBy: true,
    },
    {
      Header: "pad_2_expiration",
      accessor: "pad_2_expiration",
      disableSortBy: true,
    },
    {
      Header: "pad_2_pad_part",
      accessor: "pad_2_pad_part",
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    if (headerColumns) {
      setHeaderColumns(headerColumns);
    }
  }, []);

  // const columns = ["is_spare", "serial_number",	"battery_expiration",	"battery_type_id",	"battery_lot",	"charge_pak_uid", "pad_1_lot",	"pad_1_expiration",	"pad_1_pad_type_id",	"pad_2_lot",	"pad_2_expiration",	"pad_2_pad_type_id"];

  // const convertToCSV = (columns) => {
  //   // Create a single row for the columns (no data, just the headers)
  //   return columns.join(",") + "\n";
  // };

  // const handleDownload = (columns, filename = "Charge-pak-upload.csv") => {
  //   const csvContent = convertToCSV(columns);
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = filename;
  //   link.click();
  // };

  //  // Extracting the "record" only from the rejected_records.
  //  const rejectedData = resultData?.rejected_records ? resultData?.rejected_records.map(item => item.record) : [];
  //  const failedData = resultData?.failed_records ? resultData?.failed_records.map(item => item.record) : [];
  //  const rejectedRecords = [...rejectedData, ...failedData];

  const handleEdit = async () => {
    const convertToCSV = (data) => {
      const entries = Object.values(data);
      const headers = Object.keys(entries[0]);

      const rows = entries.map((row) =>
        headers.map((header) => `"${row[header] ?? ""}"`).join(",")
      );
      return [headers.join(","), ...rows].join("\n");
    };

    const csvString = convertToCSV(updatedData);

    const blob = new Blob([csvString], { type: "text/csv" });
    const formData = new FormData();
    formData.append("file", blob, "editedData.csv");

    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      let Key = Object.keys(aedType_list).find((key) => aedType_list[key] === "Charge Pak");

      const res = await fetch(`${BASE_API}import/charge-pak-import/${selectedAccount?.accountId}?aed_type=${Key}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (res.status) {
        const result = await res.json();

        const successRecords = result?.data?.successful_records || [];
        if (successRecords.length > 0) {
          toast.success("File uploaded successfully!");
        } else {
          toast.error(result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records ? rsData?.rejected_records.map((item) => item.record) : [];
        const failedData = rsData?.failed_records ? rsData?.failed_records.map((item) => item.record) : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);

        setResultData(result?.data);
        setUpdatedData([]);
        setEditFunction(false);

        let rejected = result?.data?.summary?.rejected_records || 0;
        let failed = result?.data?.summary?.failed_records || 0;
        if(rejected === 0 && failed === 0) {
          setSelectedAccount("");
        }
      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="d-flex" style={{ gap: "1rem" }}>
        {/* File Input Section */}
        <Form onSubmit={handleSubmit}>
          <div className="d-flex">
            <Form.Group style={{ width: "250px", marginRight: "5px" }}>
              <Form.Label>Select Account*</Form.Label>
              <PaginateAutoComplete
                dropDownName={"account_id"}
                apiEndpoint={"account/account-list-dropdown"}
                idKey={"account_id"}
                valueKey={"account_name"}
                parentKey={"allAccount"}
                onSelect={(e, val) => {
                  setSelectedAccount({
                    accountId: val?.account_id,
                    accountName: val?.account_name,
                  });
                }}
                className={"mt-1"}
                shouldFetchOnOpen={false}
                isCache={true}
                selectDisabled={false}
                isSearchable={true}
                isAsync={true}
                pageSize={20}
                defaultValue={{
                  account_id: selectedAccount?.accountId,
                  account_name: selectedAccount?.accountName,
                }}
              />
            </Form.Group>

            <Form.Group
              controlId="fileInput"
              className="d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <Form.Label>Charge-Pak File*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Select Input File"
                  defaultValue={selectedFile?.name}
                  readOnly
                  style={{ width: "250px" }}
                />
              </div>
              <div className="ms-2 file-input-div">
                <Button
                  variant="link"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    padding: 0,
                  }}
                >
                  <img
                    src="/assets/images/upload.svg"
                    alt="upload"
                    style={{ width: "30px", height: "30px", marginTop: "30px" }}
                  />
                  <Form.Control
                    type="file"
                    name="file_name"
                    accept=".xlsx, xls, .csv"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
            </Form.Group>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ marginTop: "35px" }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Box>

      {/* Progress Bar */}
      {loading && <ProgressBar />}
    </>
  );
};

export default ChargePakSection;
