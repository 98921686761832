import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Modal, Button, Badge, Table, Tabs, Tab } from "react-bootstrap";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

const RecordsTable = ({ records, variant }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  // Memoize totalPages to prevent recalculation
  const totalPages = useMemo(
    () => Math.ceil(records.length / recordsPerPage),
    [records.length]
  );

  // Memoize columns calculation
  const columns = useMemo(() => {
    if (!records?.length) return [];
    const allKeys = new Set();
    records.forEach((item) => {
      Object.keys(item.record || {}).forEach((key) => allKeys.add(key));
    });

    return Array.from(allKeys).map((key) => ({
      key,
      header: key
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
    }));
  }, [records]);

  // Memoize paginated records calculation
  const paginatedRecords = useMemo(() => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    return records.slice(startIndex, startIndex + recordsPerPage);
  }, [records, currentPage, recordsPerPage]);

  // Reset page only when records array reference changes
  useEffect(() => {
    setCurrentPage(1);
  }, [records]);

  // Memoize page change handlers to prevent recreation
  const handlePrevPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(1, prev - 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(totalPages, prev + 1));
  }, [totalPages]);

  const handlePageSelect = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  if (!records?.length) {
    return (
      <div className="text-center p-4">
        <em>No records found</em>
      </div>
    );
  }

  return (
    <>
      <div className="table-responsive">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: "50px" }}>#</th>
              <th style={{ width: "40%" }}>Errors</th>
              {columns.map((col, idx) => (
                <th key={idx}>{col.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedRecords.map((record, index) => (
              <tr key={index}>
                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                <td className={`text-${variant}`}>
                  {Array.isArray(record.errors) ? (
                    <ul className="list-unstyled m-0">
                      {record.errors.map((error, i) => (
                        <li key={i}>• {error}</li>
                      ))}
                    </ul>
                  ) : (
                    record.error || "-"
                  )}
                </td>
                {columns.map((col, idx) => (
                  <td key={idx}>{record.record[col.key] ?? "-"}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {totalPages > 1 && (
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateBefore />
                </button>
              </li>
              {currentPage > 3 && (
                <>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageSelect(1)}
                    >
                      1
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                </>
              )}
              {[...Array(5)].map((_, idx) => {
                const pageNumber = currentPage - 2 + idx;
                return (
                  pageNumber > 0 &&
                  pageNumber <= totalPages && (
                    <li key={pageNumber} className="page-item">
                      <button
                        className={`btn mx-1 ${
                          currentPage === pageNumber
                            ? "btn-primary"
                            : "btn-light"
                        }`}
                        onClick={() => handlePageSelect(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  )
                );
              })}
              {currentPage < totalPages - 2 && (
                <>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageSelect(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                </>
              )}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateNext />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

const ImportResultsModal = ({ show, onHide, results }) => {
  const [activeTab, setActiveTab] = useState("rejected");

  // If results exist, set the initial active tab based on available records
  useEffect(() => {
    if (results) {
      if (results.rejected_records?.length > 0) {
        setActiveTab("rejected");
      } else if (results.failed_records?.length > 0) {
        setActiveTab("failed");
      } else if (results.successful_records?.length > 0) {
        setActiveTab("successful");
      }
    }
  }, [results]);

  if (!show || !results) return null;

  const {
    summary,
    failed_records = [],
    rejected_records = [],
    successful_records = [],
  } = results;

  const renderSummary = () => {
    if (!summary) return null;

    return (
      <div className="mb-3 d-flex gap-3">
        <Badge bg="secondary" className="p-2">
          Total: {summary.total_records || 0}
        </Badge>
        {successful_records.length > 0 && (
          <Badge bg="success" className="p-2">
            Successful: {summary.successful_records || 0}
          </Badge>
        )}
        {rejected_records.length > 0 && (
          <Badge bg="warning" className="p-2">
            Rejected: {summary.rejected_records || 0}
          </Badge>
        )}
        {failed_records.length > 0 && (
          <Badge bg="danger" className="p-2">
            Failed: {failed_records.length}
          </Badge>
        )}
      </div>
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Import Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderSummary()}
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
        >
          {rejected_records.length > 0 && (
            <Tab
              eventKey="rejected"
              title={
                <span>
                  Rejected Records{" "}
                  <Badge bg="warning">{rejected_records.length}</Badge>
                </span>
              }
            >
              <RecordsTable records={rejected_records} variant="warning" />
            </Tab>
          )}
          {failed_records.length > 0 && (
            <Tab
              eventKey="failed"
              title={
                <span>
                  Failed Records{" "}
                  <Badge bg="danger">{failed_records.length}</Badge>
                </span>
              }
            >
              <RecordsTable records={failed_records} variant="danger" />
            </Tab>
          )}
          {successful_records.length > 0 && (
            <Tab
              eventKey="successful"
              title={
                <span>
                  Successful Records{" "}
                  <Badge bg="success">{successful_records.length}</Badge>
                </span>
              }
            >
              <RecordsTable records={successful_records} variant="success" />
            </Tab>
          )}
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportResultsModal;
