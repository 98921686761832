import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import {
  CallGETAPI,
  CallGETAPINEW,
  CallPOSTAPI,
} from "../../../../common/services/index";
import {
  CheckAEDBatteryTblCol,
  CheckSpareBatteryTblCol,
  GetAedBrands,
  RenderDate,
} from "../../../../common/helper/BasicFn";
import {
  FormatDate,
  FormatDateWithTime,
} from "../../../../common/helper/Common";
import { toast } from "react-toastify";
import BackButton from "../../../../common/components/BackButton";
import AEDGenInfoTable from "./AEDGenInfoTable ";
import AEDDetailsTable from "./AEDInfoTable";
import AEDStorageInfoTables from "./AEDStorageInfoTables ";
import SupportTicketTable from "./SupportTicketTable";
import PadsInfoDetailsTbl from "./aed-checks-comps/pads/PadsInfoDetailsTbl";
import AllBatteryInfoAedChecks from "./aed-checks-comps/battery/AllBatteryInfoAedChecks";
import DynamicTable from "./aed-checks-comps/battery/DynamicTable";
import FinalNewBreadcrumbs from "../../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";

const filterData = (arr, key, value) => {
  const data = arr?.find((a) => a[key] == value);
  return data;
};

const AEDChecksDetails = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { aedId, checkId } = useParams();
  const [accountId, setAccountID] = useState("");
  const [aedDetails, setAedDetails] = useState({});
  const [aedData, setAEDData] = useState({});
  const [aedData1, setAedData1] = useState({});
  const [storageInfoList, setStorageInfoList] = useState([]);
  const [rmsBrandList, setrmsBrandList] = useState([]);
  const [aedBattryTypeList, setaedBattryTypeList] = useState([]);
  const [aedPadTypeList, setaedPadTypeList] = useState([]);
  const [OtherFiled, setOtherFileds] = useState([]);
  const [batteryTypeList, setBatteryTypeList] = useState([]);
  const [batteryInfo, setBatteryInfo] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [completedBy, setCompletedBy] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [loading2, setLoading2] = useState(true);
  const [showLoading, setShowLoading] = useState({
    showAedGeneralInfo: true,
    showAedStorageInfo: true,
    showAedBatteryInfo: true,
    showAedPadInfo: true,
    showSupportInfo: true,
  });
  const [loading, setLoading] = useState(false);
  const [toggleValue, setToggleValue] = useState(0);
  const [warentyYear, setWarentyYear] = useState(0);
  const [formData, setFormData] = useState({
    aed_id: aedId,
    account_id: accountId,
    is_aed_present: 1,
    status_indicator: 1,
    rescue_kit_present: 1,
    aed_storage_currect: 1,
    sti_expiry_date: "",
    sti_alarmed: 0,
    sti_turned_on: 0,
    sti_alarme_sound: 0,
    battery_information_toggle: 1,
    bleeding_kit_located_toggle: 1,
    bleeding_kit_sealed_toggle: 1,
    oxygen_equipment_located_toggle: 1,
    needed_supplies_toggle: 1,
    gauge_show_toggle: 1,
    pads_info_toggle: 1,
    support_tickets_toggle: 1,
    battery_info: [],
    spare_battery_info: [],
    adult_pad_info: [],
    spare_adult_pad_info: [],
    pediatric_pad_info: [],
    spare_padric_pad_info: [],
    gateway_info: [],
    adult_pad_pak_info: [],
    spare_adult_pad_pak_info: [],
    pediatric_pak_pad_info: [],
    spare_padric_pak_pad: [],
    bleeding_kit_located_input: "",
    bleeding_kit_sealed_input: "",
    oxygen_equipment_located_input: "",
    needed_supplies_input: "",
    is_aed_present_input: "",
    status_indicator_input: "",
    rescue_kit_present_input: "",
    issue_type: "",
    issue: "",
    gauge_show_input: "",
  });
  const [GetsupportData, setSupportData] = useState({});

  const getAedSupportInfo = async () => {
    const result = await CallGETAPI(
      "aed/aed-check-support/" + checkId + "?support=true"
    );
    if (result.status) {
      const SupportData = result?.data?.data?.[0] || [];
      const FD = { ...formData };
      FD.SupportData = SupportData;
      FD.issue_type = SupportData?.issue_type;
      FD.issue = SupportData?.issue;
      // console.log({CheckHere:FD?.support_tickets_toggle})

      setSupportData((prev) => ({
        ...prev,
        SupportData: SupportData,
        issue_type: SupportData?.issue_type,
        issue: SupportData?.issue,
        // support_tickets_toggle: FD?.support_tickets_toggle,
        // support_tickets_toggle: SupportData?.support_tickets_toggle,
      }));
      setFormData(FD);
    }
    setShowLoading((prev) => ({
      ...prev,
      showSupportInfo: false,
    }));
  };

  const getAedGeneralInfo = async () => {
    const result = await CallGETAPI("aed/aed-check-details/" + checkId);
    if (result.status) {
      const aedDetails1 = result?.data?.data;

      const FD = { ...formData };
      FD.sti_expiry_date = aedDetails1?.sti_expiry_date;
      FD.is_aed_present = aedDetails1?.is_aed_present;
      FD.status_indicator = aedDetails1?.status_indicator;
      FD.rescue_kit_present = aedDetails1?.rescue_kit_present;
      FD.aed_storage_currect =
        Number(aedDetails1?.aed_storage_currect) === 1 ? 1 : 0; // aedDetails1?.support_tickets_toggle; //aedDetails1?.aed_storage_currect;

      setStorageInfoData((prev) => ({
        ...prev,
        aed_storage_currect: aedDetails1?.aed_storage_currect,
      }));

      // FD.support_tickets_toggle = aedDetails1?.aed_storage_currect// aedDetails1?.support_tickets_toggle; //aedDetails1?.aed_storage_currect;
      // support_tickets_toggle
      setSupportData((prev) => ({
        ...prev,
        support_tickets_toggle: aedDetails1?.support_tickets_toggle,
      }));
      FD.battery_information_toggle = aedDetails1?.battery_information_toggle;
      FD.bleeding_kit_located_toggle = aedDetails1?.bleeding_kit_located_toggle;
      FD.bleeding_kit_sealed_toggle = aedDetails1?.bleeding_kit_sealed_toggle;
      FD.oxygen_equipment_located_toggle =
        aedDetails1?.oxygen_equipment_located_toggle;
      FD.needed_supplies_toggle = aedDetails1?.needed_supplies_toggle;
      FD.gauge_show_toggle = aedDetails1?.gauge_show_toggle;
      FD.pads_info_toggle = aedDetails1?.pads_info_toggle;
      FD.support_tickets_toggle = aedDetails1?.support_tickets_toggle;

      FD.bleeding_kit_located_input = aedDetails1?.bleeding_kit_located_input;
      FD.bleeding_kit_sealed_input = aedDetails1?.bleeding_kit_sealed_input;
      FD.oxygen_equipment_located_input =
        aedDetails1?.oxygen_equipment_located_input;
      FD.needed_supplies_input = aedDetails1?.needed_supplies_input;
      FD.is_aed_present_input = aedDetails1?.is_aed_present_input;
      FD.status_indicator_input = aedDetails1?.status_indicator_input;
      FD.rescue_kit_present_input = aedDetails1?.rescue_kit_present_input;
      FD.spare_battery_info = aedDetails1?.spare_battery_info;
      FD.battery_info = aedDetails1?.battery_info;
      FD.gauge_show_input = aedDetails1?.gauge_show_input;

      aedDetails1.adult_pad_info = JSON.parse(
        aedDetails1?.adult_pad_info || "[]"
      );
      aedDetails1.adult_pad_pak_info = JSON.parse(
        aedDetails1?.adult_pad_pak_info
      );
      aedDetails1.battery_info = JSON.parse(aedDetails1?.battery_info);
      aedDetails1.pediatric_pad_info = JSON.parse(
        aedDetails1?.pediatric_pad_info
      );
      aedDetails1.pediatric_pak_pad_info = JSON.parse(
        aedDetails1?.pediatric_pak_pad_info
      );
      aedDetails1.spare_adult_pad_info = JSON.parse(
        aedDetails1?.spare_adult_pad_info
      );
      aedDetails1.spare_adult_pad_pak_info = JSON.parse(
        aedDetails1?.spare_adult_pad_pak_info
      );
      aedDetails1.spare_battery_info = JSON.parse(
        aedDetails1?.spare_battery_info
      );
      aedDetails1.spare_padric_pad_info = JSON.parse(
        aedDetails1?.spare_padric_pad_info
      );
      aedDetails1.spare_padric_pak_pad = JSON.parse(
        aedDetails1?.spare_padric_pak_pad
      );

      let brandList = await GetAedBrands();
      setBrandList(brandList);

      const filteredBrand = filterData(
        brandList,
        "id",
        aedDetails1?.aed_brand_id
      );
      aedDetails1.brand_name = filteredBrand?.AED_brands;

      setAedDetails(aedDetails1);
      setAEDData(aedDetails1);
      setFormData(FD);
      setCompletedBy({
        createdDate: aedDetails1?.created_date,
        createdBy: aedDetails1?.created_by,
      });
      setBrandName(aedDetails1?.aed_brand);
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedGeneralInfo: false,
    }));
  };
  const [stroageInfoData, setStorageInfoData] = useState({});

  const getAedStorageInfo = async () => {
    const result = await CallGETAPI("aed/aed-check-storageinfo/" + checkId);
    if (result.status) {
      setFormData((prev) => ({
        ...prev,
        sti_storage_type: result?.data?.data?.[0]?.storage_type,
        storage_part_name: result?.data?.data?.[0]?.storage_part_name,
        sti_alarmed: result?.data?.data?.[0]?.alarmed,
        sti_turned_on: result?.data?.data?.[0]?.alarm_status,
        sti_alarme_sound: result?.data?.data?.[0]?.alarme_sound,
        sti_expiry_date: FormatDate(result?.data?.data?.[0]?.expiry_date),
      }));
      const ojb = {
        ...result?.data?.data?.[0],
        sti_storage_type: result?.data?.data?.[0]?.storage_type,
        storage_part_name: result?.data?.data?.[0]?.storage_part_name,
        sti_alarmed: result?.data?.data?.[0]?.alarmed,
        alarm_status: result?.data?.data?.[0]?.alarm_status,
        sti_turned_on: result?.data?.data?.[0]?.alarm_status,
        sti_alarme_sound: result?.data?.data?.[0]?.alarme_sound,
        sti_expiry_date: FormatDate(result?.data?.data?.[0]?.expiry_date),
        // aed_storage_currect: formData?.aed_storage_currect
      };
      setStorageInfoData((prev) => ({ ...prev, ...ojb }));
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedStorageInfo: false,
    }));
  };
  const [getBatteryInfoData, setBatteryInfoData] = useState({});
  const [batteryListData, setBatteryListData] = useState([]);

  const getAedBatteryInfo = async () => {
    const result = await CallGETAPI(
      "aed/aed-check-batteryInfo/" + checkId + "?batter=true"
    );
    if (result.status) {
      const batteryInfo = result?.data?.data?.batteryInfo || [];
      setBatteryInfo(batteryInfo);
      setBatteryListData(batteryInfo);
      const BI = {
        has_battery: [],
        has_9v: [],
        has_10pk: [],
        has_installby: [],
        has_man: [],
      };
      const SPBI = {
        has_battery_spare: [],
        has_9v_spare: [],
        has_10pk_spare: [],
        has_installby_spare: [],
        has_man_spare: [],
      };
      const ABSpares = [];
      const ALLBatteris = [];
      for (let bii = 0; bii < batteryInfo.length; bii++) {
        const b2 = batteryInfo[bii] || false;
        if (!b2) {
          break;
        }
        ALLBatteris.push(b2);
        b2.battery_uid = b2.battery_udi;
        if (b2.is_spare) {
          const key = b2.section_name + "_spare";
          if (SPBI[key]) {
            ABSpares.push(b2);
            SPBI[key].push(b2);
          }
        } else {
          const key = b2.section_name;
          if (BI[key]) {
            BI[key].push(b2);
          }
        }
      }

      setFormData((prev) => ({
        ...prev,
        battery_info: [BI] || [],
        spare_battery_info: [SPBI] || [],
        allBatterySpares: ABSpares || [],
        ALLBatteris: ALLBatteris || [],
      }));

      setBatteryInfoData({
        battery_info: [BI] || [],
        spare_battery_info: [SPBI] || [],
        allBatterySpares: ABSpares || [],
        ALLBatteris: ALLBatteris || [],
      });
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedBatteryInfo: false,
    }));
  };

  const [allPadsData, setAllPadsData] = useState({});
  const getAedPadInfo = async () => {
    const result = await CallGETAPI(
      "aed/aed-check-padInfo/" + checkId + "?allpads=true"
    );
    if (result.status) {
      const allPads = result?.data?.data?.padInfo || [];
      const ar1 = [];
      const SPar1 = [];
      // console.log({allPads,result});
      setAllPadsData(allPads);
      for (let ci = 0; ci < batteryInfo.length; ci++) {
        const ell = batteryInfo[ci];
        if (ell.section_name === "charge_pack") {
          const ChargePakInfoObj = {
            battery_expiration: ell?.battery_expiration,
            battery_lot: ell?.battery_lot,
            charge_pak_part: ell?.charge_pak_uid,
            charge_pak_uiid: ell?.charge_pak_uid,
            charge_pak_uid: ell?.charge_pak_uid,
            pad_1_expiration: "",
            pad_1_lot: "",
            pad_1_part: "",
            pad_2_expiration: "",
            pad_2_lot: "",
            pad_2_part: "",
          };

          for (let i3 = 0; i3 < allPads.length; i3++) {
            const apd = allPads[i3];
            if (apd?.section_name === "charge_pack") {
              if (apd?.pid === Number(ell?.charge_pak_pad_1_id)) {
                ChargePakInfoObj.pad_1_expiration = apd?.pad_expiration;
                ChargePakInfoObj.pad_1_lot = apd?.pad_lot;
                ChargePakInfoObj.pad_1_part = apd?.pad_type_id;
              } else if (apd?.pid === Number(ell?.charge_pak_pad_2_id)) {
                ChargePakInfoObj.pad_2_expiration = apd?.pad_expiration;
                ChargePakInfoObj.pad_2_lot = apd?.pad_lot;
                ChargePakInfoObj.pad_2_part = apd?.pad_type_id;
              }
            }
          }
          if (ell.is_spare) {
            SPar1.push(ChargePakInfoObj);
          } else {
            ar1.push(ChargePakInfoObj);
          }
        }
      }
      aedDetails.charge_pak_info = ar1?.[0];
      aedDetails.spare_charge_pak_info = SPar1;

      const AP = [],
        SAP = [],
        PP = [],
        SPP = [],
        APP = [],
        SAPP = [],
        PPP = [],
        SPPP = [],
        CP = [],
        SCP = [];
      for (let api = 0; api < allPads.length; api++) {
        const APD = allPads[api] || false;
        if (!APD) {
          break;
        }
        APD.battery_uid = APD.battery_udi;
        if (
          APD.section_name === "charge_pack" ||
          APD.section_name === "spare_charge_pack"
        ) {
          if (APD.is_spare) {
            SCP.push(APD);
          } else {
            CP.push(APD);
          }
        }

        if (
          APD.section_name === "adult_pad_info" ||
          APD.section_name === "spare_adult_pad_info"
        ) {
          if (APD.is_spare) {
            SAP.push(APD);
          } else {
            AP.push(APD);
          }
        }

        if (
          APD.section_name === "adult_pad_pak_info" ||
          APD.section_name === "spare_adult_pad_pak_info"
        ) {
          if (APD.is_spare) {
            SAPP.push(APD);
          } else {
            APP.push(APD);
          }
        }

        if (
          APD.section_name === "pediatric_pad_info" ||
          APD.section_name === "spare_pediatric_pad_info" ||
          APD.section_name === "spare_padric_pad_info"
        ) {
          if (APD.is_spare) {
            SPP.push(APD);
          } else {
            PP.push(APD);
          }
        }

        if (
          APD.section_name === "pediatric_pak_pad_info" ||
          APD.section_name === "spare_pediatric_pak_pad_info" ||
          APD.section_name === "spare_padric_pak_pad"
        ) {
          if (APD.is_spare) {
            SPPP.push(APD);
          } else {
            PPP.push(APD);
          }
        }
      }
      setFormData((prev) => ({
        ...prev,
        adult_pad_info: AP,
        spare_adult_pad_info: SAP,
        pediatric_pad_info: PP,
        spare_padric_pad_info: SPP,
        adult_pad_pak_info: APP,
        spare_adult_pad_pak_info: SAPP,
        pediatric_pak_pad_info: PPP,
        spare_padric_pak_pad: SPPP,
      }));

      // setAllPadsData({
      //     adult_pad_info: AP,
      //     spare_adult_pad_info: SAP,
      //     pediatric_pad_info: PP,
      //     spare_padric_pad_info: SPP,
      //     adult_pad_pak_info: APP,
      //     spare_adult_pad_pak_info: SAPP,
      //     pediatric_pak_pad_info: PPP,
      //     spare_padric_pak_pad: SPPP,
      // })
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedPadInfo: false,
    }));
  };

  const BatteryExpTbl = [
    // {
    //   key: "is_spare",
    //   is_default: 1,
    //   title: "Main",
    // },
    // battery_expiration
    {
      key: "battery_type_id",
      is_default: 1,
      title: "Battery Part",
      
    },
    {
      key: "battery_expiration",
      is_default: 1,
      title: "Battery Expiration",
      renderRow: (value) => {
        return RenderDate(value, 1);
      },
    },
    {
      key: "manufactured_date",
      is_default: 0,
      title: "Manufactured Date",
      renderRow: (value) => {
        return RenderDate(value, 0);
      },
    },
    // { key: "battery_expiration", is_default: 1, title: "Battery Expiration" },
    { key: "battery_lot", is_default: 1, title: "Battery Lot" },
    { key: "battery_udi", is_default: 1, title: "Battery UDI" },
    { key: "charge_pak_uid", is_default: 1, title: "Battery UDI" },
    { key: "install_9v_date", is_default: 0, title: "v9 Install" },
    // { key: "serial", is_default: 0, title: "Serial" },
    // {
    //   key: "v9_install",
    //   is_default: 0,
    //   title: "v9 Install",
    // },
    // {
    //   key: "install_before_date",
    //   is_default: 0,
    //   title: "Install Before Date",
    // },
    // {
    //   key: "date_installed",
    //   is_default: 0,
    //   title: "Date Installed",
    // },
  ];

  const print_battery_part = (bid) => {
    let findName = batteryTypeList?.find(
      (item) => parseInt(item?.battery_type_id) === parseInt(bid)
    );
    return findName?.battery_part_number || bid;
  };

  useEffect(() => {
    // fecthIssueType();
    getAedGeneralInfo();
    getAedSupportInfo();
    getAedStorageInfo();
    getAedBatteryInfo();
    getAedPadInfo();
  }, []);

  const handleToggleChange = (event) => {
    const newValue = event.target.checked ? 1 : 0;
    setFormData((prev) => ({
      ...prev,
      [event.target.name]: newValue,
    }));

    setToggleValue(newValue);
  };

  const HIC = (e) => {
    const newValue = e.target.value;
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await CallPOSTAPI("account/aed-checks", formData);
    if (res.status) {
      toast.success("AED checked Saved Successfully");
    } else {
      toast.error("Something went wrong please try again");
    }
    setLoading(false);
  };

  console.log({batteryListData})

  return (
    <>
      <div
        className="mt-4 table-main-20002"
        style={{ width: "100%", paddingInline: "2%" }}
      >
        <FinalNewBreadcrumbs />

        <div className="col py-2">
          <h2 className="heading">General Information</h2>

          <AEDGenInfoTable
            showGeneralInfo={showLoading.showAedGeneralInfo}
            brandName={brandName}
            aedData={aedData}
          />
        </div>
        <form className="pb-5" onSubmit={handleSubmit}>
          <div className="col py-2">
            <h2 className="heading">AED Information</h2>

            <AEDDetailsTable
              showAedGeneralInfo={showLoading.showAedGeneralInfo}
              formData={formData}
              handleToggleChange={handleToggleChange}
              HIC={HIC}
            />
          </div>

          {!formData?.is_aed_present ? (
            ""
          ) : (
            <>
              {formData?.sti_storage_type !== "" && (
                <div className="col py-2">
                  <h2 className="heading">AED Storage</h2>

                  <AEDStorageInfoTables
                    showLoading={showLoading.showAedStorageInfo}
                    formData={stroageInfoData}
                    handleToggleChange={handleToggleChange}
                    FormatDate={FormatDate}
                  />
                </div>
              )}

              {batteryListData?.length > 0 ? (
                <>
                  <div className="col py-2">
                    <h2 className="heading">Battery Information</h2>
                    <DynamicTable
                      columns={BatteryExpTbl}
                      data={batteryListData}
                      battery_information_toggle={
                        formData?.battery_information_toggle
                      }
                    />

                    {/* <AllBatteryInfoAedChecks 
                   showLoading={showLoading.showAedBatteryInfo}
                   formData={formData}
                   setFormData={setFormData}
                   handleToggleChange={handleToggleChange}
                   BatteryExpTbl={BatteryExpTbl}
                   batteryTypeList={batteryInfo}
                   print_battery_part={print_battery_part}
                   RenderDate={RenderDate}
                   CheckAEDBatteryTblCol={CheckAEDBatteryTblCol}
                   getBatteryInfoData={getBatteryInfoData}
                   allBatteryDataList={batteryListData}
                  /> */}

                    {/* <AedChecksBatteryInfoTables
                    showLoading={showLoading.showAedBatteryInfo}
                    formData={formData}
                    setFormData={setFormData}
                    handleToggleChange={handleToggleChange}
                    BatteryExpTbl={BatteryExpTbl}
                    batteryTypeList={batteryInfo}
                    print_battery_part={print_battery_part}
                    RenderDate={RenderDate}
                    CheckAEDBatteryTblCol={CheckAEDBatteryTblCol}
                    getBatteryInfoData={getBatteryInfoData}
                  /> */}
                  </div>
                </>
              ) : (
                ""
              )}
              {/* {JSON.stringify({allPadsData})} */}
              {allPadsData.length > 0 ? (
                <div className="col py-2">
                  <h2 className="heading">Pads Information</h2>
                  <PadsInfoDetailsTbl
                    aedPadTypeList={aedPadTypeList}
                    RenderDate={RenderDate}
                    pads_info_toggle={formData?.pads_info_toggle}
                    adultPadInfo={formData?.adult_pad_info}
                    spareAdultPadInfo={formData?.spare_adult_pad_info}
                    pediatricPadInfo={formData?.pediatric_pad_info}
                    sparePadricPadInfo={formData?.spare_padric_pad_info}
                    gatewayInfo={formData?.gateway_info}
                    adultPadPakInfo={formData?.adult_pad_pak_info}
                    spareAdultPadPakInfo={formData?.spare_adult_pad_pak_info}
                    pediatricPadPakInfo={formData?.pediatric_pak_pad_info}
                    sparePadricPadPakInfo={formData?.spare_padric_pak_pad}
                    formData={allPadsData}
                    setFormData={setFormData}
                    toggle={0} //{formData?.pads_info_toggle}
                    readOnly={1}
                    show={1}
                    // is_disabled={true}
                    isLoading={showLoading.showAedPadInfo}
                    handleToggleChange={handleToggleChange}
                  />
                </div>
              ) : (
                ""
              )}

              {0 ? (
                <div className="col py-2">
                  <h2 className="heading">Oxygen Information</h2>

                  <table className="theme-table">
                    <tr>
                      <td>
                        <p>
                          Is the Oxygen equipment located in the designated
                          location?{" "}
                        </p>
                      </td>
                      <td>
                        <CustomToggleButton2
                          ToggleName="oxygen_equipment_located_toggle"
                          ToggleValue={
                            formData?.oxygen_equipment_located_toggle
                          }
                          changeHandler={handleToggleChange}
                          is_read_only={true}
                        />
                      </td>
                    </tr>
                    {!formData?.oxygen_equipment_located_toggle && (
                      <tr>
                        <td colSpan={2}>
                          <textarea
                            className="form-control"
                            id="oxygen_equipment_located_input"
                            name="oxygen_equipment_located_input"
                            placeholder="Enter text Here"
                            value={formData?.oxygen_equipment_located_input}
                            onChange={HIC}
                            readOnly
                          ></textarea>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        <p>Does the gauge show that it is full?</p>
                      </td>
                      <td>
                        <CustomToggleButton2
                          ToggleName="gauge_show_toggle"
                          ToggleValue={formData?.gauge_show_toggle}
                          changeHandler={handleToggleChange}
                          is_read_only={true}
                        />
                      </td>
                    </tr>

                    {!formData?.gauge_show_toggle && (
                      <tr>
                        <td colSpan={2}>
                          <textarea
                            className="form-control"
                            id="gauge_show_input"
                            name="gauge_show_input"
                            placeholder="Enter text Here"
                            value={formData?.gauge_show_input}
                            onChange={HIC}
                            readOnly
                          ></textarea>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        <p>
                          Are all the needed supplies available and in good
                          condition?
                        </p>
                      </td>
                      <td>
                        <CustomToggleButton2
                          ToggleName="needed_supplies_toggle"
                          ToggleValue={formData?.needed_supplies_toggle}
                          changeHandler={handleToggleChange}
                          is_read_only={true}
                        />
                      </td>
                    </tr>

                    {!formData?.needed_supplies_toggle && (
                      <tr>
                        <td colSpan={2}>
                          <textarea
                            className="form-control"
                            id="needed_supplies_input"
                            name="needed_supplies_input"
                            placeholder="Enter text Here"
                            value={formData?.needed_supplies_input}
                            onChange={HIC}
                            readOnly
                          ></textarea>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              ) : (
                ""
              )}
              <div className="col py-2">
                <h2 className="heading">Support Tickets</h2>
                <SupportTicketTable
                  isLoading={showLoading.showSupportInfo}
                  formData={GetsupportData}
                  handleToggleChange={handleToggleChange}
                  HIC={HIC}
                />
              </div>
            </>
          )}
        </form>

        <div className="col py-2">
          <h2 className="heading">Completion Information</h2>

          <table className="theme-table">
            <thead>
              <tr>
                <td>Checked By</td>
                <td>Checked Date</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{completedBy?.createdBy}</td>

                <td style={{ width: "400px" }}>
                  {FormatDateWithTime(completedBy?.createdDate)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default AEDChecksDetails;
