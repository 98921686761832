import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Select from "react-select";
import { validatePhone } from "../../../common/helper";
import StateField from "../../../common/components/StateField";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";

export default function AdminMultiTrainingFrom({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  handleInputChange,
  allDropDowns,
  noBtns,
  type,
  fieldsRequired = true,
  countriesList,
  setTrainingPhoneValidations,
  setValidateField = "",
  validated,
  sectionId = "",
  sectionName = "",
}) {
  const [main, setMain] = useState(0);
  const [selectedCountry, setSelectedCountry] = React.useState({
    account_site_country: { label: "United States", value: "231" },
    account_alternate_traning_location_country: { label: "United States", value: "231" },
    account_billing_info_country: { label: "United States", value: "231" },
    account_shipping_info_country: { label: "United States", value: "231" },
  });

  console.log({ selectedCountry })
  const [phoneValidations, setPhoneValidations] = React.useState({});

  const updateFieldChanged = (index) => (e) => {
    if (e.target.value !== " ") {
      let newArr = [...altTrainerForm]; // copying the old datas array
      if (e.target.name == "alternative_phone") {
        e.target.value = e.target.value.replace(/[^0-9 ]/g, "").trim();
        e.target.value = e.target.value.slice(0, 10);

        const phoneValidate = validatePhone(e.target.value);
        setPhoneValidations((old) => ({
          ...old,
          [index]: phoneValidate ? false : true,
        }));
        setTrainingPhoneValidations(phoneValidate ? false : true);
      }

      if (e.target.type == "checkbox") {
        let value = e.target.checked ? 1 : 0;
        newArr[index][e.target.name] = value;
      } else {
        newArr[index][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to
      }
      setSubFormData(newArr);
    }
  };

  // handle select change
  const handleSelectChange = (data, key, index) => {
    let newArr = [...altTrainerForm];
    newArr[index][key] = data.value;
    setSubFormData(newArr);

    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data?.label,
        value: data?.value,
      },
    }));
  };

  console.log({ altTrainerForm })

  // useEffect(() => {
  //   if (countriesList) {
  //     if (altTrainerForm[0]?.account_alternate_traning_location_country) {
  //       const country = countriesList.find(
  //         (country) =>
  //           country.value ==
  //           parseInt(
  //             altTrainerForm[0]?.account_alternate_traning_location_country
  //           )
  //       );
  //       setSelectedCountry((old) => ({
  //         ...old,
  //         account_alternate_traning_location_country: {
  //           label: country?.label,
  //           value: country?.value,
  //         },
  //       }));
  //     } else {
  //       setSelectedCountry((old) => ({
  //         ...old,
  //         account_alternate_traning_location_country: {
  //           label: countriesList[0]?.label,
  //           value: countriesList[0]?.value,
  //         },
  //       }));
  //     }
  //   }
  // }, [countriesList]);


  return (
    <>
      {altTrainerForm.map((singleForm, index) => (
        <div key={index} id={`${sectionId}_${index}`}>
          <div
            className="row mb-4 "
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="col-4">
              <div className="d-flex mb-2" style={{ alignItems: "center" }}>
                <Form.Label>Company Name</Form.Label>
                {type != "trainingEdit" && (
                  <>
                    <button
                      onClick={increaseAlternative}
                      className="btn mx-2 btn-sm btn-primary "
                      type="button"
                      name={`${sectionName}_plus`}
                    >
                      +
                    </button>
                    <button
                      onClick={decreaseAlternative}
                      className="btn mx-2 btn-sm btn-danger "
                      type="button"
                      name={`${sectionName}_minus`}
                    >
                      -
                    </button>
                  </>
                )}
              </div>

              <Form.Group className={"col"}>
                <Form.Control
                  type="text"
                  name="account_alternate_traning_location_company_name"
                  value={
                    singleForm.account_alternate_traning_location_company_name
                  }
                  onChange={updateFieldChanged(index)}
                />
              </Form.Group>
            </div>

            <div className="col-8 row">
              <Form.Group className={"col-md-4"}>
                <div className="d-flex mb-2" style={{ alignItems: "center" }}>
                  <Form.Label>Phone </Form.Label>
                  {noBtns && (
                    <>
                      <button
                        onClick={increaseAlternative}
                        type="button"
                        className="btn mx-2 btn-sm btn-primary "
                      >
                        +
                      </button>
                      <button
                        onClick={decreaseAlternative}
                        type="button"
                        className="btn mx-2 btn-sm btn-danger "
                      >
                        -
                      </button>
                    </>
                  )}
                </div>

                <Form.Control
                  type="text"
                  name="alternative_phone"
                  value={singleForm.alternative_phone}
                  onChange={updateFieldChanged(index)}
                  // required
                  minLength="10"
                  className={
                    phoneValidations[index]
                      ? "phone-invalid-input col-md-4"
                      : "col-md-4"
                  }
                />

                {phoneValidations[index] ? (
                  <>
                    <div className="phone-invalid">
                      Please Enter Exact 10 digits.
                    </div>
                  </>
                ) : (
                  <>
                    <Form.Control.Feedback type="invalid">
                      Please Enter Exact 10 digits.
                    </Form.Control.Feedback>
                  </>
                )}
              </Form.Group>

              <Form.Group className={"col-md-4"}>
                <div className="mb-2">
                  <Form.Label>Ext</Form.Label>
                </div>

                <Form.Control
                  type="number"
                  name="alternative_ext"
                  onChange={updateFieldChanged(index)}
                  value={singleForm.alternative_ext}
                />
              </Form.Group>
            </div>
          </div>

          <div className="row my-4">
            <Form.Group className={"col relative"}>
              <Form.Label>Country </Form.Label>
              {/* <Select
                value={
                  selectedCountry?.account_alternate_traning_location_country
                }
                options={countriesList}
                onChange={(data) => {
                  handleSelectChange(
                    data,
                    "account_alternate_traning_location_country",
                    index
                  );
                }}
              /> */}

              <GlobalDropdown
                dropDownName={"account_alternate_traning_location_country"}
                apiEndpoint={"account/get-country"}
                idKey={"id"}
                valueKey={"country_name"}
                parentKey={"country"}
                onSelect={(data, value) => {
                  const selectedText =
                    data.target.options[data.target.selectedIndex].text;
                  const data2 = {
                    label: selectedText,
                    value: value,
                  };
                  handleSelectChange(
                    data2,
                    "account_alternate_traning_location_country"
                  );
                }}
                shouldFetchOnOpen={true}
                isCache={true}
                initialSelectedValue={"231"}
                defaultValue={{
                  id: "231",
                  value: "United States",
                }}
                selectDisabled={true}
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Country.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={"col"}>
              <Form.Label>Address *</Form.Label>
              <Form.Control
                type="text"
                name="account_alternate_traning_location_address1"
                value={singleForm.account_alternate_traning_location_address1}
                onChange={updateFieldChanged(index)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Address.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={"col"}>
              <Form.Label>Address 2</Form.Label>
              <Form.Control
                type="text"
                name="account_alternate_traning_location_address2"
                value={singleForm.account_alternate_traning_location_address2}
                onChange={updateFieldChanged(index)}
              />
            </Form.Group>

            <Form.Group className={"col"}>
              <Form.Label>City *</Form.Label>
              <Form.Control
                type="text"
                name="account_alternate_traning_location_city"
                value={singleForm.account_alternate_traning_location_city}
                onChange={updateFieldChanged(index)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter City.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={"col"}>
              <Form.Label>State *</Form.Label>

              <StateField
                setFormData={setSubFormData}
                valueKey="account_alternate_traning_location_state"
                selectedCountry={
                  selectedCountry?.account_alternate_traning_location_country?.value
                }
                validated={validated}
                required={fieldsRequired ? true : false}
                type="array"
                index={index}
                altTrainerForm={altTrainerForm}
                stateSelectedValue={
                  singleForm.account_alternate_traning_location_state
                }
              />
            </Form.Group>

            <Form.Group className={"col"}>
              <Form.Label>Zip code *</Form.Label>
              <Form.Control
                type="number"
                name="account_alternate_traning_location_zipcode"
                defaultValue={
                  singleForm.account_alternate_traning_location_zipcode
                }
                onChange={updateFieldChanged(index)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please Enter Zip Code.
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      ))}
    </>
  );
}
