import React, { useState } from "react";
import { Box } from "@mui/material";
import { Form } from "react-bootstrap";
import PreviewTable from "../../Table/PreviewTable";
import ImportResultsModal from "../Modal/ImportResultsModal";
import ResponseTable from "../../Table/ResponseTable";
import DownloadCSV from "../Accessory/DownloadCSV";
import AccPadSection from "../Accessory/AccPadSection";
import AccBatterySection from "../Accessory/AccBatterySection";
import AccChargePakSection from "../Accessory/AccChargePakSection";

const accessory_list = {
  BATTERY: "Battery",
  PAD: "Pad",
  CHARGE_PAK: "Charge Pak",  
  PAD_PAK: "Pad Pak",
  PEDIATRIC_PAD: "Pediatric Pad",	
  PEDIATRIC_PAD_PAK: "Pediatric Pad Pak",	
};

const AccessoriesTab = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [accType, setAccType] = useState([
    { value: accessory_list.BATTERY, label: "Battery" },
    { value: accessory_list.PAD, label: "Pad" },
    { value: accessory_list.CHARGE_PAK , label: "Charge Pak" },
    { value: accessory_list.PAD_PAK, label: "Pad Pak" },
    { value: accessory_list.PEDIATRIC_PAD, label: "Pediatric Pad" },
    { value: accessory_list.PEDIATRIC_PAD_PAK , label: "Pediatric Pad Pak" },
  ]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [reportModal, setreportModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [headerColumns, setHeaderColumns] = useState([]);

  const [uploadFunction, setUploadFunction] = useState(false);
  const [rejectedRecords,setRejectedRecords] = useState([]); // stores rejcteded and failed records 
  const [editFunction, setEditFunction] = useState(false);

  const handleSelect = (e) => {
    const value = e.target.value;
    if (value) {
      setSelectedOption(value);
      if (value !== selectedOption) {
        setFileData([]);
        setSelectedFile(null);
        setResultData([]);
      }
    }
  };

  const handleUpload = () => {
    setUploadFunction(true);  
  };

  const handleEdit = () => {
    setEditFunction(true);
  }

  return (
    <div className="mt-3 mb-4">
      {/* AED General Information Section */}
      <div className="mb-2">
        <Box className="text-left pb-2">
          <h5>Download Sample CSV Files</h5>
        </Box>
        <DownloadCSV />
      </div>

      <hr />

      {/* AED Inventory Section */}
      <div className="mt-4">
        <h5>Import Accessories Inventory</h5>
        <div className="mt-4">
          <h6>Select Accessories Type</h6>

          {/* Container for dropdown and content */}
          <div className="d-flex align-items-center gap-4 mt-2">
            {/* Dropdown */}
            <div style={{ minWidth: "250px" }}>
              <Form.Select value={selectedOption} onChange={handleSelect}>
                <option value="" disabled>
                  -- Select One --
                </option>
                {accType.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Form.Select>
            </div>

            {/* Section Content */}
            <div style={{ flex: 1, marginTop: "-30px" }}>
              {selectedOption === "Battery" && (
                <AccBatterySection
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  fileData={fileData}
                  setFileData={setFileData}
                  loading={loading}
                  setLoading={setLoading}
                  resultData={resultData}
                  setResultData={setResultData}
                  reportModal={reportModal}
                  setreportModal={setreportModal}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  setHeaderColumns={setHeaderColumns}
                  uploadFunction={uploadFunction}
                  setUploadFunction={setUploadFunction}
                  setRejectedRecords={setRejectedRecords}
                  editFunction={editFunction}
                  setEditFunction={setEditFunction}
                  accessory_list={accessory_list}
                />
              )}
              {selectedOption === "Pad" && (
                <AccPadSection
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  fileData={fileData}
                  setFileData={setFileData}
                  loading={loading}
                  setLoading={setLoading}
                  resultData={resultData}
                  setResultData={setResultData}
                  reportModal={reportModal}
                  setreportModal={setreportModal}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  setHeaderColumns={setHeaderColumns}
                  uploadFunction={uploadFunction}
                  setUploadFunction={setUploadFunction}
                  setRejectedRecords={setRejectedRecords}
                  editFunction={editFunction}
                  setEditFunction={setEditFunction}
                  accessory_list={accessory_list}
                />
              )}
              {selectedOption === "Charge Pak" && (
                <AccChargePakSection
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  fileData={fileData}
                  setFileData={setFileData}
                  loading={loading}
                  setLoading={setLoading}
                  resultData={resultData}
                  setResultData={setResultData}
                  reportModal={reportModal}
                  setreportModal={setreportModal}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  setHeaderColumns={setHeaderColumns}
                  uploadFunction={uploadFunction}
                  setUploadFunction={setUploadFunction}
                  setRejectedRecords={setRejectedRecords}
                  editFunction={editFunction}
                  setEditFunction={setEditFunction}
                  accessory_list={accessory_list}
                />
              )}
               {selectedOption === "Pad Pak" && (
                <AccPadSection
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  fileData={fileData}
                  setFileData={setFileData}
                  loading={loading}
                  setLoading={setLoading}
                  resultData={resultData}
                  setResultData={setResultData}
                  reportModal={reportModal}
                  setreportModal={setreportModal}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  setHeaderColumns={setHeaderColumns}
                  uploadFunction={uploadFunction}
                  setUploadFunction={setUploadFunction}
                  setRejectedRecords={setRejectedRecords}
                  editFunction={editFunction}
                  setEditFunction={setEditFunction}
                  accessory_list={accessory_list}
                  sectionKey={"Pad Pak"}
                />
              )}
               {selectedOption === "Pediatric Pad" && (
                <AccPadSection
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  fileData={fileData}
                  setFileData={setFileData}
                  loading={loading}
                  setLoading={setLoading}
                  resultData={resultData}
                  setResultData={setResultData}
                  reportModal={reportModal}
                  setreportModal={setreportModal}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  setHeaderColumns={setHeaderColumns}
                  uploadFunction={uploadFunction}
                  setUploadFunction={setUploadFunction}
                  setRejectedRecords={setRejectedRecords}
                  editFunction={editFunction}
                  setEditFunction={setEditFunction}
                  accessory_list={accessory_list}
                  sectionKey={"Pediatric Pad"}
                />
              )}
               {selectedOption === "Pediatric Pad Pak" && (
                <AccPadSection
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  fileData={fileData}
                  setFileData={setFileData}
                  loading={loading}
                  setLoading={setLoading}
                  resultData={resultData}
                  setResultData={setResultData}
                  reportModal={reportModal}
                  setreportModal={setreportModal}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  setHeaderColumns={setHeaderColumns}
                  uploadFunction={uploadFunction}
                  setUploadFunction={setUploadFunction}
                  setRejectedRecords={setRejectedRecords}
                  editFunction={editFunction}
                  setEditFunction={setEditFunction}
                  accessory_list={accessory_list}
                  sectionKey={"Pediatric Pad Pak"}
                />
              )}
            </div>
          </div>
        </div>

        <ImportResultsModal show={reportModal} onHide={() => setreportModal(false)} results={resultData} />

        {resultData && resultData?.summary?.total_records > 0 && (
          <div className="mt-4">
            <div style={{ display: "flex", gap: "0.5rem" }}>
              {resultData?.summary?.rejected_records > 0 || resultData?.summary?.failed_records > 0 ? (
                <button
                  className="btn btn-warning"
                  onClick={() => setreportModal(true)}
                  style={{
                    padding: "0.5rem",
                    borderRadius: "4px",
                    textAlign: "center",
                    width: "180px",
                    fontSize: "1rem",
                  }}
                >
                  See Details
                </button>
              ) : null}

              {[
                { label: "Total Records", value: resultData?.summary?.total_records || 0, color: "#0d6efd" },
                { label: "Successful Records", value: resultData?.summary?.successful_records || 0, color: "#2e7d32" },
                { label: "Rejected Records", value: (resultData?.summary?.rejected_records || 0) + (resultData?.summary?.failed_records || 0), color: "#D32F2F" },
              ].map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    backgroundColor: item.color,
                    color: "white",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    textAlign: "center",
                    width: "auto",
                    fontSize: "1rem",
                  }}
                >
                  <span>{item.label} : {item.value}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Preview Table */}
        {fileData.length > 0 && (
          <div className="mt-4">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <h5> Preview </h5>
              <button
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={handleUpload}
              >
                {loading ? "Uploading..." : "Upload File"}
              </button>
            </div>

            <PreviewTable
              tableData={fileData}
              isSearchable={true}
              headerColumns={headerColumns}
              setSelectedFile={setSelectedFile}
            />
          </div>
        )}

        {/* Edit Response Table */}
        {(resultData?.summary?.rejected_records > 0 || resultData?.summary?.failed_records > 0) && (
          <div className="mt-4">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <h5> Rejected Records: </h5>
              <button
                style={{
                  padding: "0.5rem 1rem",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={handleEdit}
              >
                {loading ? "Uploading..." : "Upload Changes"}
              </button>
            </div>

            <ResponseTable
              isSearchable={true}
              tableData={rejectedRecords}
              headerColumns={headerColumns}
              setUpdatedData={setUpdatedData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AccessoriesTab;
