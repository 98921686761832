import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { truncateText } from "../helper/BasicFn";

export const TextWithTooltip = ({
  text,
  maxLength = 30,
  className = "",
  placement = "top",
}) => {
  // If text is not longer than maxLength, render as is
  if (!text || text.length <= maxLength) {
    return <span className={className}>{text}</span>;
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id={`tooltip-${Math.random()}`}>{text}</Tooltip>}
    >
      <span className={className}>{truncateText(text, maxLength)}</span>
    </OverlayTrigger>
  );
};
