import React from "react";
import { useState } from "react";
import moment from "moment";
import EditMoveAccePlacement from "./EditMoveAccePlacement";
import check from "../../../../common/img/Check.svg";
import cancel from "../../../../common/img/Cancel.svg";
import { Skeleton } from "@mui/material";
import { truncateText } from "../../../../common/helper/BasicFn";
import { TextWithTooltip } from "../../../../common/components/toolTipForLongerText";

function MoveAccessoryTr({
  item,
  index,
  selectedIds,
  onLoad,
  handleQtyChange,
  loading,
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {/* {!loading ? (<>  */}
      <tr
        key={index}
        className="border"
        style={{ background: index % 2 === 0 ? "white" : "#E4E4E4" }}
      >
        <td style={{ background: "transparent" }}>
          <input
            type="number"
            placeholder="Enter Quantity"
            value={
              item.hasOwnProperty("moveQty")
                ? item?.moveQty
                : item?.moveQty || 0
            }
            inputmode="numeric"
            min={0}
            onWheel={(event) => event.preventDefault()}
            onScroll={(event) => event.preventDefault()}
            // onMouseDown={(event) => event.preventDefault()}
            // onMouseUp={(event) => event.preventDefault()}
            onKeyPress={(e) => {
              const disallowedChar = [
                ".",
                "-",
                "/",
                "*",
                "+",
                "@",
                "#",
                "$",
                "%",
                ")",
                "(",
                "!",
                "^",
                "&",
                "~",
                "`",
                "=",
              ];
              if (disallowedChar.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => handleQtyChange(e, item, index)}
            style={{
              height: 30,
              width: "100%",
              border: "none",
              background: "transparent",
            }}
          />
        </td>
        <td style={{ background: "transparent" }}>
          {/* {item.hasOwnProperty("manufactured_date")
            ? "Battery"
            : item?.section_name.includes("pediatric")
              ? "Pediatric"
              : item?.section_name.includes("pak")
                ? "Pad Pak"
                : "Pad"} */}
          {item?.accessory_type}
        </td>

        <td style={{ background: "transparent", minWidth: "130px" }}>
          {item.hasOwnProperty("manufactured_date")
            ? item?.battery_part
              ? item?.battery_part
              : "N/A"
            : item?.pad_part
            ? item?.pad_part
            : "N/A"}
        </td>

        <td style={{ background: "transparent", minWidth: "150px" }}>
          <img
            src={
              item.hasOwnProperty("manufactured_date")
                ? item?.manufactured_date
                  ? "/assets/images/BatteryMfgr.svg"
                  : item?.battery_expiration
                  ? "/assets/images/Battery.png"
                  : "/assets/images/installby.svg"
                : item?.pad_expiration && item?.is_pediatric === 1
                ? "/assets/images/child-Vector.png"
                : "/assets/images/people-Group.svg"
            }
            style={{
              width: item?.battery_expiration ? 15 : 25,
              height: item?.battery_expiration ? 30 : 30,
              marginRight: "2%",
            }}
          />

          {item.hasOwnProperty("manufactured_date")
            ? item?.manufactured_date
              ? moment(item?.manufactured_date).format("MM/DD/YYYY")
              : item?.battery_expiration
              ? moment(item?.battery_expiration).format("MM/DD/YYYY")
              : item?.install_date
              ? moment(item?.install_date).format("MM/DD/YYYY")
              : "N/A"
            : item?.pad_expiration
            ? moment(item?.pad_expiration).format("MM/DD/YYYY")
            : "N/A"}

          <div style={{ margin: "5% 0" }} />

          {item?.section_name === "charge_pack" && item?.pad_expiration_1 ? (
            <img
              src="/assets/images/people-Group.svg"
              style={{ marginRight: "2%" }}
            />
          ) : (
            <></>
          )}
          {item.section_name === "charge_pack" && item?.pad_expiration_1
            ? moment(item.pad_expiration_1).format("MM/DD/YYYY")
            : null}

          <div style={{ margin: "5% 0" }} />

          {item?.section_name === "charge_pack" && item?.pad_expiration_2 ? (
            <img
              src="/assets/images/people-Group.svg"
              style={{ marginRight: "2%" }}
            />
          ) : (
            <></>
          )}
          {item.section_name === "charge_pack" && item?.pad_expiration_2
            ? moment(item.pad_expiration_2).format("MM/DD/YYYY")
            : null}
        </td>

        <td
          style={{
            background: "transparent",
            // maxWidth: "130px",
            // overflow: "hidden",
            // textOverflow: "ellipsis",
            // whiteSpace: "wrap",
          }}
        >
          {item.hasOwnProperty("manufactured_date") ? (
            item?.battery_lot ? (
              <TextWithTooltip
                text={item?.battery_lot}
                maxLength={10}
                placement="top"
              />
            ) : (
              "N/A"
            )
          ) : item?.pad_lot ? (
            <TextWithTooltip
              text={item?.pad_lot}
              maxLength={10}
              placement="top"
            />
          ) : (
            "N/A"
          )}
        </td>
        <td
          style={{
            background: "transparent",
            maxWidth: "130px",
            // overflow: "hidden",
            // textOverflow: "ellipsis",
            // whiteSpace: "nowrap",
          }}
        >
          {item?.section_name === "charge_pack" ? (
            item?.charge_pak_uid
          ) : (
            <>
              {item.hasOwnProperty("manufactured_date") ? (
                item?.battery_udi ? (
                  <TextWithTooltip
                    text={item?.battery_udi}
                    maxLength={10}
                    placement="top"
                  />
                ) : (
                  "N/A"
                )
              ) : item?.pad_udi ? (
                <TextWithTooltip
                  text={item?.pad_udi}
                  maxLength={10}
                  placement="top"
                />
              ) : (
                "N/A"
              )}
            </>
          )}
        </td>

        <td style={{ background: "transparent", minWidth: "70px" }}>
          {item?.quantity}
        </td>

        <td style={{ background: "transparent", maxWidth: "100px" }}>
          {<img src={item?.dni ? check : cancel} alt="Status" height={12} />}
        </td>
      </tr>

      {/* </>): (<>
        <Skeleton count={5} width={'90%'} height={30} style={{ margin:"10px"}}/>
        </> )} */}

      <EditMoveAccePlacement
        show={showModal}
        id={item.sgid}
        onHide={() => {
          setShowModal(false);
          onLoad();
        }}
        aedDetails={item.placement}
      />
    </>
  );
}

export default MoveAccessoryTr;
