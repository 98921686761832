import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import { toast } from "react-toastify";
import { useState } from "react";
// import { CallGETAPI } from "../../../common/services";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  textAlign: "center",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

export default function ModelNamechangePopup({
  // setModelChange,
  updateModal,
  setUpdateModal,
  edit,
  setedit,
  contactID,
  fetchCreateTblData,
}) {
  const [loading, setLoading] = useState(false);
  const handleClose = () => setUpdateModal(false);

  const handleUpdate = async () => {
    setLoading(true);
    // setModelChange(true);
    setedit(false);
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Modal open={updateModal} onClose={handleClose}>
        <Box sx={style}>
          <h2 style={{ margin: 0, fontWeight: 600, fontSize: 30 }}>
            Are you sure?
          </h2>
          <h5 style={{ marginTop: 7, fontWeight: 600, fontSize: 20 }}>
            You will lose the accessory information and do you want to proceed?
          </h5>

          <div
            className="col-12 content-flex-center"
            style={{ marginTop: "5%" }}
          >
            <button
              className="btn text-uppercase cancel-button"
              type="button"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              onClick={handleUpdate}
              className="btn text-uppercase ms-4 submit-button"
              type="submit"
              disabled={loading}
            >
              {loading ? "Loading..." : "Yes"}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
