import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import { InfoIcon } from "../../../../common/helper/icons";
import { AEDDetailsSkeleton } from "./AedSkeletons/AEDDetailsSkeleton";

const AEDDetailsContent = forwardRef(
  (
    {
      formData,
      handleToggleChange,
      HIC,
      setToggleInvalid,
      setInfoModal,
      toggleInvalid,
    },
    ref
  ) => {
    // Create refs
    const aedPresentInputRef = React.useRef(null);
    const statusIndicatorInputRef = React.useRef(null);
    const rescueKitInputRef = React.useRef(null);

    // Expose refs to parent component
    useImperativeHandle(ref, () => ({
      aedPresentInputRef: aedPresentInputRef.current,
      statusIndicatorInputRef: statusIndicatorInputRef.current,
      rescueKitInputRef: rescueKitInputRef.current,
    }));

    // Add effect to handle scrolling to error fields
    useEffect(() => {
      if (toggleInvalid.aed_present && !formData?.is_aed_present) {
        aedPresentInputRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        aedPresentInputRef.current?.focus();
      }
      if (toggleInvalid.status_indicator && !formData?.status_indicator) {
        statusIndicatorInputRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        statusIndicatorInputRef.current?.focus();
      }
      if (toggleInvalid.rescue_kit && !formData?.rescue_kit_present) {
        rescueKitInputRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        rescueKitInputRef.current?.focus();
      }
    }, [toggleInvalid, formData]);

    return (
      <table className="table table-striped-columns">
        <tbody className="">
          <>
            <tr>
              <td colSpan={3}> Is The AED Present?</td>
              <td colSpan={1} style={{ width: "50px" }}>
                {" "}
                <CustomToggleButton2
                  ToggleName="is_aed_present"
                  ToggleValue={formData?.is_aed_present}
                  changeHandler={(e) => {
                    handleToggleChange(e);
                    setToggleInvalid({
                      ...toggleInvalid,
                      aed_present: false,
                    });
                  }}
                  is_read_only={false}
                />
              </td>
            </tr>
            {!formData?.is_aed_present && (
              <tr>
                <td colSpan={4}>
                  <textarea
                    ref={aedPresentInputRef}
                    className="form-control"
                    name="is_aed_present_input"
                    placeholder="Enter text here."
                    value={formData?.is_aed_present_input}
                    style={{ resize: "none" }}
                    onChange={(e) => {
                      HIC(e);
                      setToggleInvalid({
                        ...toggleInvalid,
                        aed_present: false,
                      });
                    }}
                  ></textarea>
                  {toggleInvalid.aed_present && (
                    <p className="text-danger m-0 ps-1 mt-1">
                      This field is required.
                    </p>
                  )}
                </td>
              </tr>
            )}
          </>

          {!formData?.is_aed_present ? (
            ""
          ) : (
            <>
              <tr>
                <td colSpan={3}> Does the status indicator show ready?</td>
                <td colSpan={1}>
                  {" "}
                  <CustomToggleButton2
                    ToggleName="status_indicator"
                    ToggleValue={formData?.status_indicator}
                    changeHandler={(e) => {
                      handleToggleChange(e);
                      setToggleInvalid({
                        ...toggleInvalid,
                        status_indicator: false,
                      });
                    }}
                    is_read_only={false}
                  />
                </td>
              </tr>
              {!formData?.status_indicator && (
                <tr>
                  <td colSpan={4}>
                    <textarea
                      ref={statusIndicatorInputRef}
                      style={{ resize: "none" }}
                      className="form-control"
                      placeholder="Enter text here."
                      value={formData?.status_indicator_input}
                      name="status_indicator_input"
                      onChange={(e) => {
                        HIC(e);
                        setToggleInvalid({
                          ...toggleInvalid,
                          status_indicator: false,
                        });
                      }}
                    ></textarea>
                    {toggleInvalid.status_indicator && (
                      <p className="text-danger m-0 ps-1 mt-1">
                        This field is required.
                      </p>
                    )}
                  </td>
                </tr>
              )}

              <tr>
                <td colSpan={3}>
                  {" "}
                  Is there a AED rescue kit present and in good condition?
                  <span
                    role="button"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Hello world!"
                    data-tooltip-place="top"
                    onClick={() => setInfoModal(true)}
                  >
                    <InfoIcon />
                  </span>
                </td>
                <td colSpan={1}>
                  {" "}
                  <CustomToggleButton2
                    ToggleName="rescue_kit_present"
                    ToggleValue={formData?.rescue_kit_present}
                    changeHandler={(e) => {
                      handleToggleChange(e);
                      setToggleInvalid({
                        ...toggleInvalid,
                        rescue_kit: false,
                      });
                    }}
                    is_read_only={false}
                  />
                </td>
              </tr>
              {!formData?.rescue_kit_present && (
                <tr>
                  <td colSpan={4}>
                    <textarea
                      ref={rescueKitInputRef}
                      style={{ resize: "none" }}
                      className="form-control"
                      placeholder="Enter text here."
                      name="rescue_kit_present_input"
                      value={formData?.rescue_kit_present_input}
                      onChange={(e) => {
                        HIC(e);
                        setToggleInvalid({
                          ...toggleInvalid,
                          rescue_kit: false,
                        });
                      }}
                    ></textarea>
                    {toggleInvalid.rescue_kit && (
                      <p className="text-danger m-0 ps-1 mt-1">
                        This field is required.
                      </p>
                    )}
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    );
  }
);

const AEDDetailsTable = ({
  showAedGeneralInfo,
  formData,
  handleToggleChange,
  HIC,
  setToggleInvalid,
  toggleInvalid,
  setInfoModal,
}) => {
  return showAedGeneralInfo ? (
    <AEDDetailsSkeleton />
  ) : (
    <AEDDetailsContent
      formData={formData}
      handleToggleChange={handleToggleChange}
      HIC={HIC}
      setToggleInvalid={setToggleInvalid}
      toggleInvalid={toggleInvalid}
      setInfoModal={setInfoModal}
    />
  );
};

export default AEDDetailsTable;
