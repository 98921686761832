import { useState } from "react";
import React, { useEffect } from "react";
import "../css/MoveAed.scss";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  AccountSiteList,
  GetAccountList,
} from "../../../../common/helper/BasicFn";
import { CallGETAPI, CallPOSTAPI } from "../../../../common/services/index";
import { toast } from "react-toastify";
import BackButton from "../../../../common/components/BackButton";
import MoveAedTr from "../components/tabs/MoveAedTr";
import PaginateAutoComplete from "../../../../common/components/custom-ui/PaginateAutoComplete";
import FinalNewBreadcrumbs from "../../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";
import { useDispatch } from "react-redux";
import { removeBreadcrumb } from "../../../../store/slices/breadcrumbsSlice";
import ContactUnassignModal from "../components/ContactUnassignModal";
import { Spinner } from "react-bootstrap";

const overlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  zIndex: 2,
};

function MoveAed() {
  const location = useLocation();
  const [selectedAccount, setSelectedAccount] = useState(
    location?.state?.selectedAccount || {}
  );
  const params = useParams();
  const { accountId, siteId } = params;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assignUser, setAssignUser] = useState([]);
  const [selectedAcc, setSelectedAcc] = useState(accountId);
  const [selectedSite, setSelectedSite] = useState(
    location?.state?.selectedSite?.siteId
  );
  const [AedSiteList, setAedSiteList] = useState([]);
  const [AedSiteData, setAedSiteData] = useState([]);
  const [siteName, setSiteName] = useState(
    location?.state?.selectedSite?.siteName || ""
  );
  const [btnLoad, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [AccountName, setAccountName] = useState(
    location?.state?.selectedAccount?.accountName || ""
  );
  const [selectedIds, setSelectedIds] = useState([]);
  const dispatch = useDispatch();

  const onLoad = async () => {
    setLoading(true);
    const AedSiteDataRes = await CallGETAPI(
      `aed/fetchAedBySite/${accountId}/${siteId}`
    );
    const AedSiteData2 = AedSiteDataRes?.data?.data?.AEDData || [];
    setAedSiteData(AedSiteData2);
    setLoading(false);
  };

  const onChangeAccount = async (id, onload = false) => {
    setSelectedAcc(id);
    if (!id) {
      setAedSiteList([]);
      return;
    }
    const accSiteListres = await AccountSiteList(id);
    const accSiteList = accSiteListres?.site_details || [];

    setAedSiteList(accSiteList);
  };

  useEffect(() => {
    // onLoad();
    onLoad();
    onChangeAccount(selectedAccount?.accountId, true);
  }, []);

  const SelectAll = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const arr = AedSiteData.map((item) => item.aed_id);
      setSelectedIds(arr);
    } else {
      setSelectedIds([]);
    }
  };
  const handleCheckboxChange = (id) => {
    // Check if the ID is already in the selectedIds array
    const isSelected = selectedIds.includes(id);

    if (isSelected) {
      // If ID is already in the list, remove it
      const updatedIds = selectedIds.filter((selectedId) => selectedId !== id);
      setSelectedIds(updatedIds);
    } else {
      // If ID is not in the list, add it
      setSelectedIds([...selectedIds, id]);
    }
  };

  useEffect(() => {
    if (AedSiteData && Array.isArray(selectedIds) && selectedIds.length > 0) {
      const selectedData = AedSiteData.filter((item) =>
        selectedIds.includes(item.aed_id)
      );
      const filteredData = selectedData.filter(
        (item) => item.assign_user !== null
      );
      setAssignUser(filteredData);
    }
  }, [AedSiteData, selectedIds]);

  const handleMove = async () => {
    if (selectedIds.length === 0) {
      toast.error("Please Select AED");
      return;
    }

    if (!selectedAcc) {
      toast.error("Please Select  Account");
      return;
    }

    if (!selectedSite) {
      toast.error("Please Select  Site");
      return;
    }

    if (assignUser.length > 0 && accountId !== selectedAcc) {
      setIsModalOpen(true);
      return;
    }

    ContinueMove();
  };

  const ContinueMove = async () => {
    if (!selectedAcc) {
      toast.error("Please Select  Account");
      return;
    }

    if (!selectedSite) {
      toast.error("Please Select  Site");
      return;
    }

    const sendData = {
      account_id: selectedAcc,
      site_id: selectedSite,
      id: selectedIds,
    };
    setBtnLoading(true);
    const res = await CallPOSTAPI("aed/move-aed", sendData);
    setBtnLoading(false);
    if (res.data.status) {
      toast.success(res.data.msg);
      onLoad();
      onChangeAccount(selectedAcc);
    } else {
      toast.error("Something went Wrong Please Try Again");
    }
  };

  return (
    <>
      <div
        className="mt-4"
        style={{ width: "100%", paddingInline: "45px" }}
        id="move-aed-section"
      >
        {/* <BackButton />*/}
        <FinalNewBreadcrumbs />
        <h2 className="section-title mt-4">Move AED</h2>
        <div className="move-aed">
          <div className="row">
            <div className="col-12 text-center">
              <h5 className="site-name">
                {/* {AccountName} : {siteName ? siteName : "Pending"} */}
                {parseInt(siteId) === 0 && AccountName
                  ? `${AccountName} : Pending`
                  : AccountName
                  ? `${AccountName} : ${siteName}`
                  : ""}
              </h5>
            </div>
            <div className="col-8">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>
                      <lable htmlFor="all">
                        <input
                          type="checkbox"
                          id="all"
                          onChange={SelectAll}
                          checked={selectedIds.length === AedSiteData.length}
                        />{" "}
                        &nbsp;
                        <span>AED Brand / Model</span>
                      </lable>
                    </th>
                    <th>Serial Number</th>
                    <th>AED Placement</th>
                  </tr>
                </thead>
                <tbody>
                  {AedSiteData.length === 0 ? (
                    <>
                      <tr>
                        <td colSpan={3} className="text-center">
                          <h5>{loading ? "Loading..." : "No Data Found"}</h5>
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}

                  {AedSiteData.sort((a, b) =>
                    a.aed_brand_model.localeCompare(b.aed_brand_model)
                  ).map((item, index) => (
                    <MoveAedTr
                      item={item}
                      index={index}
                      handleCheckboxChange={handleCheckboxChange}
                      selectedIds={selectedIds}
                      onLoad={onLoad}
                      setAedSiteData={setAedSiteData}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div
              className="col-4 border"
              style={{ height: "fit-content", padding: "10px" }}
            >
              <div className="move-aed-header">
                <h5>Move To</h5>
              </div>
              <div className=" mt-4">
                <div className="form-group">
                  <label>Account Name</label>

                  <PaginateAutoComplete
                    dropDownName={"account"}
                    apiEndpoint={"account/account-list-dropdown"}
                    idKey={"account_id"}
                    valueKey={"account_name"}
                    parentKey={"allAccount"}
                    onSelect={(e, val) => {
                      const value = e.target.value;
                      setSelectedAccount({
                        accountId: val?.account_id,
                        accountName: val?.account_name,
                      });
                      onChangeAccount(value);
                    }}
                    shouldFetchOnOpen={true}
                    isCache={false}
                    className={"react-select-container"}
                    selectDisabled={false}
                    closeMenuSelect={false}
                    isSearchable={true}
                    isAsync={true}
                    multiple={false}
                    defaultValue={{
                      account_id: selectedAccount?.accountId,
                      account_name: selectedAccount?.accountName,
                    }}
                  />
                </div>

                <div className="form-group">
                  <label>Site Name*</label>

                  <PaginateAutoComplete
                    dropDownName={"account_site_name"}
                    apiEndpoint={`site/account-site-list/${selectedAcc}`}
                    idKey={"account_site_info_id"}
                    valueKey={"account_site_name"}
                    parentKey={"site_details"}
                    onSelect={(e, val) => {
                      const value = e.target.value;
                      setSelectedSite(val?.account_site_info_id);
                      setSiteName(val?.account_site_name);
                    }}
                    shouldFetchOnOpen={true}
                    isCache={false}
                    className={"react-select-container"}
                    selectDisabled={!selectedAcc}
                    closeMenuSelect={false}
                    isSearchable={true}
                    isAsync={true}
                    multiple={false}
                    defaultValue={
                      selectedSite
                        ? {
                            account_site_info_id: selectedSite,
                            account_site_name: siteName,
                          }
                        : null
                    }
                    getResults={(response) => {
                      const siteDetails =
                        response?.data?.data?.site_details || [];
                      const totalCount = siteDetails.length;
                      return [siteDetails, totalCount];
                    }}
                  />
                </div>

                <div
                  className="form-group d-flex"
                  style={{ justifyContent: "right" }}
                >
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    className="btn btn-success"
                    type="button"
                    onClick={() => {
                      handleMove();
                    }}
                    disabled={btnLoad}
                  >
                    {btnLoad ? "Loading..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className=""></div>

          {/* Modal for contact unassign */}
          <ContactUnassignModal
            show={isModalOpen}
            setShow={setIsModalOpen}
            data={assignUser}
            ContinueMove={ContinueMove}
          />
        </div>
      </div>

      {/* Overlay */}
      {btnLoad && (
        <div style={overlay}>
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      )}
    </>
  );
}

export default MoveAed;
