import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import styles from "../css/NewAccount.module.css";
import {
  AccRepsDropDown,
  AssignContectRepList,
  ContactList,
  ContectRepList,
  DecryptToken,
  getPer,
  ModalAccReps,
  ProductsDropDown,
} from "../../../common/helper/BasicFn";
import {
  CallDetails,
  CallPOSTAPI,
  CallPOSTData,
} from "../../../common/services/index";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { FormControlLabel, Switch } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MessageHandler from "../../../common/components/MessageHandler";
import ContactModel from "../../../common/components/modals/ContactModel/index";
import Loading from "../../../common/components/Loader/Loading";
import { printConsole, sortData } from "../../../common/helper/Common";
import SubHeadingOther from "../components/header/SubHeadingOther";
import { MultiSelect } from "react-multi-select-component";
import "../../../global.css";
import { toast } from "react-toastify";
import CustomToggleButton2 from "../../../common/components/toggleSwitch/CustomToggle2";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
import {
  addNewBreadcrumbs,
  addToHistoryStack,
  removeBreadcrumb,
} from "../../../store/slices/breadcrumbsSlice";
import { useDispatch, useSelector } from "react-redux";
import { isPermission } from "../../../common/helper/PermissionManager";
import CustomToggleButton from "../../../common/components/toggleSwitch/CustomToggleButton";

const overlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  zIndex: 2,
};

const AccountEdit = () => {
  const location = useLocation();
  const programDetails = location?.state?.programData;
  const [showLoading, setShowLoading] = React.useState(false);
  const [showLoadingContact, setShowLoadingContact] = React.useState(false);
  const [accountTitle, setAccountTitle] = useState("");
  const [ProductModalData, setProductModalData] = useState([]);
  const [SelectedProductsData, setSelectedProductData] = useState([]);
  const [ShowAccRepsModal, setShowAccRepsModal] = useState(false);
  const [contactShowModel, setContactShowModel] = useState(false);
  const [SelectAccReps, setSelectAccReps] = useState([]);
  const [SelectContact, setSelectContact] = useState([]);
  const dispatch = useDispatch();
  const [AccReps, setAccReps] = useState([]);
  const [AccRepsList, setAccRepsList] = useState([]);
  const [AssignedContact, setAssignedContact] = useState([]);

  const [contactReps, setContactReps] = useState([]);
  const [contactRepsList, setContactRepsList] = useState([]);
  const user = DecryptToken();
  const { accountId } = useParams();
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [resultData, setResultData] = useState("");
  const [repsData, setRepsData] = useState("");
  const [editUrl, seteditUrl] = useState("");
  const [listType, setListType] = React.useState("");

  const [formData, setFormData] = useState({
    user_type: 1,
    account_name: "",
    parent_account_id: "",
    distributor_id: "",
    customer_type_id: "",
    industry_id: "",
    terms_id: "",
    account_status_id: "",
    lead_source_id: "",
    website: "",
    important_note: "",
    product_interest: "",
    // contact_status: "",
    restricted_user: "",
    // two_FA: 0,
    aed_check_length: "",
    extra_fields: "0",
    extra_field1: "",
    extra_field2: "",
    expiration_alert_toggle: 0,

    project_managers: {
      primary: 0,
      backup: 0,
    },
    sales_reps: {
      primary: 0,
      backup: 0,
    },

    account_poc: {
      primary: 0,
      backup: 0,
    },
    aed_auditor: {
      primary: 0,
      backup: 0,
    },
  });
  const [primaryData, setPrimaryData] = useState([]);
  const [salesRepsData, setSalesRepsData] = useState([]);

  const [multiEmailFormCount, setMultiEmailFormCount] = useState([
    {
      email: "",
      email_type: "0",
      main: 0,
    },
  ]);
  //  - parent Name default Value --
  const [accountDefaultVal, setAccountDefaultVal] = useState({
    account_id: location?.state?.accountDetails?.parentAccount?.account_id,
    account_main_contact_firstname:
      location?.state?.accountDetails?.parentAccount
        ?.account_main_contact_firstname,
  });
  useEffect(() => {
    setAccountDefaultVal({
      account_id: location?.state?.accountDetails?.parentAccount?.account_id,
      account_main_contact_firstname:
        location?.state?.accountDetails?.parentAccount
          ?.account_main_contact_firstname,
    });
  }, [
    location?.state?.accountDetails?.parentAccount?.account_id,
    location?.state?.accountDetails?.parentAccount
      ?.account_main_contact_firstname,
  ]);

  const [distributorDefaultVal, setdistributorDefaultVal] = useState({
    dropdown_distributor_id:
      location?.state?.accountDetails?.distributor?.dropdown_distributor_id,
    distributor_name:
      location?.state?.accountDetails?.distributor?.distributor_name,
  });
  useEffect(() => {
    setdistributorDefaultVal({
      dropdown_distributor_id:
        location?.state?.accountDetails?.distributor?.dropdown_distributor_id,
      distributor_name:
        location?.state?.accountDetails?.distributor?.distributor_name,
    });
  }, [
    location?.state?.accountDetails?.distributor?.dropdown_distributor_id,
    location?.state?.accountDetails?.distributor?.distributor_name,
  ]);

  // distributor

  const handleInputChange = (e) => {
    if (e.target.type == "checkbox") {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  const [altTrainerForm, setAltTrainerForm] = useState([]);

  const [altTrainerForm1, setAltTrainerForm1] = useState([
    {
      phone_number: "0",
      ext: "0",
      phone_type_id: "0",
      main: 0,
    },
  ]);

  const handleCheckBox = (e, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: e.target.checked ? 1 : 0,
    }));
  };

  const [allDropDowns, setAllDropDowns] = React.useState([]);

  const getData = async (allProductListData) => {
    let data = await CallDetails(accountId);
    if (data?.status) {
      let accountInfo = data?.data?.data?.accountInfo;
      accountInfo.aed_check_length = accountInfo?.aed_check_length ?? "30 Days";
      accountInfo.extra_fields = accountInfo.extra_fields ?? 0;
      accountInfo.extra_field1 = accountInfo.extra_field1 ?? "";
      accountInfo.extra_field2 = accountInfo.extra_field2 ?? "";
      // setResultData(accountInfo);

      const productInterestIds = accountInfo.product_interest
        .split(",")
        .map((id) => parseInt(id, 10));
      const filteredData = allProductListData.filter((item) =>
        productInterestIds.includes(item.value)
      );
      setSelectedProductData(filteredData);

      setFormData(accountInfo);
      setAccountTitle(accountInfo?.account_name);
      setRepsData(data?.data?.data?.accountReps);

      const RepData = data?.data?.data?.accountReps;
      // const RepsData = coo
      RepData.forEach((item) => {
        const { contact_id, position_id, is_primary, is_backup } = item;

        if (position_id === 1) {
          if (is_primary === 1) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              project_managers: {
                ...prevFormData.project_managers,
                primary: contact_id,
              },
            }));
          }
          if (is_backup === 1) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              project_managers: {
                ...prevFormData.project_managers,
                backup: contact_id,
              },
            }));
          }
        } else if (position_id === 2) {
          if (is_primary === 1) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              sales_reps: {
                ...prevFormData.sales_reps,
                primary: contact_id,
              },
            }));
          }
          if (is_backup === 1) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              sales_reps: {
                ...prevFormData.sales_reps,
                backup: contact_id,
              },
            }));
          }
        } else if (position_id === 2) {
          if (is_primary === 1) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              sales_reps: {
                ...prevFormData.sales_reps,
                primary: contact_id,
              },
            }));
          }
          if (is_backup === 1) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              sales_reps: {
                ...prevFormData.sales_reps,
                backup: contact_id,
              },
            }));
          }
        }
      });

      // setSelectedProductData(accountInfo.product_interest);
      return data?.data?.data?.accountReps;
    }
    return "";
  };

  // prepare options
  const prepareOptions = (optionsData, key, value) => {
    if (optionsData) {
      let allData = [];
      for (let i = 0; i < optionsData.length; i++) {
        let singleData = {};
        singleData.label = optionsData[i][value];
        singleData.value = optionsData[i][key];
        allData.push(singleData);
      }
      allData.sort((a, b) => a.label.localeCompare(b.label));
      return allData;
    }
  };

  const fetchProductInterest = async () => {
    let ProductResult = await ProductsDropDown();
    let allProductListData = [];
    if (ProductResult) {
      allProductListData = prepareOptions(
        ProductResult?.products,
        "dropdown_product_interest_id",
        "dropdown_product_interest_name"
      );
      setProductModalData(allProductListData);

      const productSelected =
        location?.state?.accountDetails?.restData?.productInterest || [];
      const filteredData = allProductListData?.filter((item) =>
        productSelected.includes(item.value)
      );
      setSelectedProductData(filteredData);
    }
  };

  const fetchContact = async () => {
    setShowLoadingContact(true);
    let AccountContactList = await ContactList(accountId);
    let AccountContectRepList = await ContectRepList();
    let AssignContectRepListData = await AssignContectRepList(accountId);

    let CheckContactList = [];
    if (AccountContectRepList) {
      let RepList = [];

      for (let index = 0; index < AccountContectRepList.length; index++) {
        const RepElement = AccountContectRepList[index];
        let obj = { ...RepElement, id: "" };

        let findPPrimaryId = AssignContectRepListData
          ? AssignContectRepListData.find(
              (e) =>
                e.position_id === RepElement.position_id && e.is_primary != 0
            )
          : "";
        let findPBackupId = AssignContectRepListData
          ? AssignContectRepListData.find(
              (e) =>
                e.position_id === RepElement.position_id && e.is_backup != 0
            )
          : "";

        obj.backup = { id: "", val: "", permissions: [] };
        obj.primary = { id: "", val: "", permissions: [] };
        obj.contact_id = obj.contact_id;

        if (findPPrimaryId) {
          obj.id = findPPrimaryId?.id;
          let GetVal = AccountContactList.find(
            (e) =>
              e.contact_id === findPPrimaryId.contact_id &&
              findPPrimaryId.is_primary != 0
          );
          CheckContactList.push(GetVal.contact_id);
          let parr = findPPrimaryId?.permissions
            ? findPPrimaryId?.permissions.split(",")
            : [];
          obj.primary = {
            e_id: findPPrimaryId.id,
            id: findPPrimaryId.contact_id,
            val: GetVal.contact_name,
            permissions: parr,
          };
        }

        if (findPBackupId) {
          obj.id = findPBackupId?.id;
          let GetVal = AccountContactList.find(
            (e) =>
              e.contact_id === findPBackupId.contact_id &&
              findPBackupId.is_backup != 0
          );
          CheckContactList.push(GetVal?.contact_id);
          let parr = findPBackupId.permissions
            ? findPBackupId.permissions.split(",")
            : [];
          obj.backup = {
            e_id: findPBackupId.id,
            id: findPBackupId.contact_id,
            val: GetVal?.contact_name,
            permissions: parr,
          };
        }
        RepList.push(obj);
      }
      setContactRepsList(RepList);
    }

    if (AccountContactList) {
      let List = [];
      for (let index = 0; index < AccountContactList.length; index++) {
        const AccEle = AccountContactList[index];
        let obj = { ...AccEle };
        let FindData = CheckContactList.find((e) => e === obj.contact_id);

        obj.backup = { id: "", val: "", permissions: [] };
        obj.primary = { id: "", val: "", permissions: [] };
        obj.contact_id = obj.contact_id;

        if (FindData) {
          obj.is_selected = false;
        } else {
          obj.is_selected = false;
        }
        List.push(obj);
      }
      // const activeList = List.filter((item) => item.status === 1);
      setContactReps(List);
    }
    setShowLoadingContact(false);
  };

  // const fetchOnload = async () => {
  //   let ProductResult = await ProductsDropDown();
  //   let allProductListData = [];
  //   if (ProductResult) {
  //     // ProductList
  //     allProductListData = prepareOptions(
  //       ProductResult?.products,
  //       "dropdown_product_interest_id",
  //       "dropdown_product_interest_name"
  //     );
  //     setProductModalData(allProductListData);
  //   }

  //   let repsListData = await getData(allProductListData);

  // let AllDResult = await FetchDropDowns();
  // if (AllDResult) {
  //   AllDResult.parentAccount = sortData(
  //     AllDResult?.parentAccount,
  //     "account_main_contact_firstname"
  //   );
  //   AllDResult.distributors = sortData(
  //     AllDResult?.distributors,
  //     "distributor_name"
  //   );
  //   AllDResult.customerType = sortData(
  //     AllDResult?.customerType,
  //     "customer_type_name"
  //   );
  //   AllDResult.industryType = sortData(
  //     AllDResult?.industryType,
  //     "dropdown_industry_name"
  //   );
  //   AllDResult.leadSources = sortData(
  //     AllDResult?.leadSources,
  //     "dropdown_lead_source_name"
  //   );
  //   AllDResult.accountStatus = sortData(
  //     AllDResult?.accountStatus,
  //     "account_status"
  //   );
  //   setAllDropDowns(AllDResult);
  // }

  // let AccResult = await ModalAccReps();
  // let AccreptList = await AccRepsDropDown();
  // let AccountContactList = await ContactList(accountId);
  // let AccountContectRepList = await ContectRepList();
  // let AssignContectRepListData = await AssignContectRepList(accountId);

  // let CheckMarkList = [];
  // if (AccResult) {
  //   let List = [];
  //   for (let index = 0; index < AccResult.length; index++) {
  //     const AccEle = AccResult[index];
  //     let obj = { ...AccEle };
  //     let findPPrimaryId = repsListData.find(
  //       (e) => e.position_id === AccEle.position_id && e.is_primary != 0
  //     );
  //     let findPBackupId = repsListData.find(
  //       (e) => e.position_id === AccEle.position_id && e.is_backup != 0
  //     );

  //     obj.backup = { id: "", val: "" };
  //     obj.primary = { id: "", val: "" };
  //     obj.contact_id = "";

  //     if (findPPrimaryId) {
  //       obj.contact_id = "";
  //       obj.id = findPPrimaryId.id;
  //       let GetVal = AccreptList.find(
  //         (e) =>
  //           e?.account_main_contact_id === findPPrimaryId.contact_id &&
  //           findPPrimaryId.is_primary != 0
  //       );
  //       CheckMarkList.push(GetVal?.account_main_contact_id);
  //       obj.primary = {
  //         e_id: findPPrimaryId.id,
  //         id: findPPrimaryId.contact_id,
  //         val:
  //           GetVal?.account_main_contact_firstname +
  //           " " +
  //           GetVal?.account_main_contact_lastname,
  //       };
  //     }
  //     if (findPBackupId) {
  //       obj.id = findPBackupId.id;
  //       let GetVal = AccreptList.find(
  //         (e) =>
  //           e?.account_main_contact_id === findPBackupId.contact_id &&
  //           findPBackupId.is_backup != 0
  //       );
  //       CheckMarkList.push(GetVal?.account_main_contact_id);
  //       obj.backup = {
  //         e_id: findPBackupId.id,
  //         id: findPBackupId.contact_id,
  //         val:
  //           GetVal?.account_main_contact_firstname +
  //           " " +
  //           GetVal?.account_main_contact_lastname,
  //       };
  //     }
  //     List.push(obj);
  //   }
  //   setAccReps(List);
  // }

  // if (AccreptList) {
  //   let RepList = [];

  //   for (let index = 0; index < AccreptList.length; index++) {
  //     const RepElement = AccreptList[index];
  //     let obj = { ...RepElement };
  //     let FindData = CheckMarkList.find(
  //       (e) => e === RepElement?.account_main_contact_id
  //     );
  //     if (FindData) {
  //       obj.is_selected = false;
  //     } else {
  //       obj.is_selected = false;
  //     }
  //     obj.primary = { id: "", val: "" };
  //     obj.backup = { id: "", val: "" };
  //     RepList.push(obj);
  //   }
  //   setAccRepsList(RepList);
  //   setPrimaryData(RepList);
  //   setSalesRepsData(RepList);
  // }

  // let CheckContactList = [];
  // if (AccountContectRepList) {
  //   let RepList = [];

  //   for (let index = 0; index < AccountContectRepList.length; index++) {
  //     const RepElement = AccountContectRepList[index];
  //     let obj = { ...RepElement, id: "" };

  //     let findPPrimaryId = AssignContectRepListData
  //       ? AssignContectRepListData.find(
  //           (e) =>
  //             e.position_id === RepElement.position_id && e.is_primary != 0
  //         )
  //       : "";
  //     let findPBackupId = AssignContectRepListData
  //       ? AssignContectRepListData.find(
  //           (e) =>
  //             e.position_id === RepElement.position_id && e.is_backup != 0
  //         )
  //       : "";

  //     obj.backup = { id: "", val: "", permissions: [] };
  //     obj.primary = { id: "", val: "", permissions: [] };
  //     obj.contact_id = obj.contact_id;

  //     if (findPPrimaryId) {
  //       obj.id = findPPrimaryId?.id;
  //       let GetVal = AccountContactList.find(
  //         (e) =>
  //           e.contact_id === findPPrimaryId.contact_id &&
  //           findPPrimaryId.is_primary != 0
  //       );
  //       CheckContactList.push(GetVal.contact_id);
  //       let parr = findPPrimaryId?.permissions
  //         ? findPPrimaryId?.permissions.split(",")
  //         : [];
  //       obj.primary = {
  //         e_id: findPPrimaryId.id,
  //         id: findPPrimaryId.contact_id,
  //         val: GetVal.contact_name,
  //         permissions: parr,
  //       };
  //     }

  //     if (findPBackupId) {
  //       obj.id = findPBackupId?.id;
  //       let GetVal = AccountContactList.find(
  //         (e) =>
  //           e.contact_id === findPBackupId.contact_id &&
  //           findPBackupId.is_backup != 0
  //       );
  //       CheckContactList.push(GetVal?.contact_id);
  //       let parr = findPBackupId.permissions
  //         ? findPBackupId.permissions.split(",")
  //         : [];
  //       obj.backup = {
  //         e_id: findPBackupId.id,
  //         id: findPBackupId.contact_id,
  //         val: GetVal?.contact_name,
  //         permissions: parr,
  //       };
  //     }
  //     RepList.push(obj);
  //   }
  //   setContactRepsList(RepList);
  // }

  // if (AccountContactList) {
  //   let List = [];
  //   for (let index = 0; index < AccountContactList.length; index++) {
  //     const AccEle = AccountContactList[index];
  //     let obj = { ...AccEle };
  //     let FindData = CheckContactList.find((e) => e === obj.contact_id);

  //     obj.backup = { id: "", val: "", permissions: [] };
  //     obj.primary = { id: "", val: "", permissions: [] };
  //     obj.contact_id = obj.contact_id;

  //     if (FindData) {
  //       obj.is_selected = false;
  //     } else {
  //       obj.is_selected = false;
  //     }
  //     List.push(obj);
  //   }
  //   const activeList = List.filter((item) => item.status === 1);
  //   setContactReps(activeList);
  // }

  //   setShowLoading(false);
  // };

  // const handleDetailsDispatch = (accountId, account_name) => {
  //   const BreadCrumbsObject = {
  //     title: "",
  //     tab: "",
  //     type: "",
  //   };
  //   BreadCrumbsObject.title = account_name + " Details";
  //   BreadCrumbsObject.tab = "Details";
  //   BreadCrumbsObject.type = "account";
  //   dispatch(
  //     // addNewBreadcrumbs({
  //     //   ...BreadCrumbsObject,
  //     //   url: `/account-details/${accountId}/Details`,
  //     // })
  //   );
  // };
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );
  const handleDispatch = (accountId) => {
    const editPageUrl = "/account/accounts-edit/" + accountId + "/AccountEdit"; // Replace this with your edit page's URL

    const singleAccount = {
      title: "Dashboard",
      url: "/dashboard",
      tab: "Dashboard",
      type: "account",
      accountName: "",
      accountId: "",
    };
    const lastBreadcrumb =
      breadcrumbsFromStore.length === 0
        ? singleAccount
        : breadcrumbsFromStore[breadcrumbsFromStore.length - 1];
    dispatch(addToHistoryStack(lastBreadcrumb));
    dispatch(removeBreadcrumb(editPageUrl));
  };
  const [FormMsg, setFormMsg] = React.useState({ type: true, msg: "" });
  const [loading, setLoading] = React.useState(false);

  const sendData = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    SaveForm();
  };
  const [coordinatorDefaultData, setcoordinatorDefaultData] = useState(
    location?.state?.accountDetails?.coordinatorData?.projectManager || {}
  );
  const [salesRepsDefaultValu, setsalesRepsDefaultValu] = useState(
    location?.state?.accountDetails?.coordinatorData?.salesReps || {}
  );

  const [accountPocDefaultData, setAccountPocDefaultData] = useState(
    location?.state?.accountDetails?.coordinatorData?.account_poc || {}
  );

  const [aedAuditorDefaultValue, setAedAuditorDefaultValue] = useState(
    location?.state?.accountDetails?.coordinatorData?.aed_auditor || {}
  );

  const SaveForm = async () => {
    setLoading(true);
    let arr = formData;
    arr.account_id = accountId;
    arr.restricted_user = arr.restricted_user ? 1 : 0;
    arr.two_FA = arr.restricted_user;
    if (arr.extra_fields === "0") {
      arr.extra_field1 = "";
      arr.extra_field2 = "";
    } else if (arr.extra_fields === "1") {
      arr.extra_field1 = formData?.extra_field1;
      arr.extra_field2 = "";
    }

    // arr.location_phone = altTrainerForm;
    // arr.main_contact_phone = altTrainerForm1;
    // arr.main_contact_email = multiEmailFormCount;
    arr.product_interest = SelectedProductsData.map((item) => item.value).join(
      ","
    );

    let repsValueArray = [];
    if (
      formData?.project_managers?.primary ||
      !formData?.project_managers?.primary
    ) {
      repsValueArray.push({
        id:
          location?.state?.accountDetails?.coordinatorData?.projectManager
            ?.primary?.id || "",
        position_id: 1,
        contact_id: formData?.project_managers?.primary || "",
        is_primary: 1,
        is_backup: 0,
        set_order: 1,
      });
    }
    if (
      formData?.project_managers?.backup ||
      !formData?.project_managers?.backup
    ) {
      repsValueArray.push({
        id:
          location?.state?.accountDetails?.coordinatorData?.projectManager
            ?.backup?.id || "",
        position_id: 1,
        contact_id: formData?.project_managers?.backup || "",
        is_primary: 0,
        is_backup: 1,
        set_order: 2,
      });
    }
    if (formData?.sales_reps?.primary || !formData?.sales_reps?.primary) {
      repsValueArray.push({
        id:
          location?.state?.accountDetails?.coordinatorData?.salesReps?.primary
            ?.id || "",
        position_id: 2,
        contact_id: formData?.sales_reps?.primary || "",
        is_primary: 1,
        is_backup: 0,
        set_order: 3,
      });
    }
    if (formData?.sales_reps?.backup || !formData?.sales_reps?.backup) {
      repsValueArray.push({
        id:
          location?.state?.accountDetails?.coordinatorData?.salesReps?.backup
            ?.id || "",
        position_id: 2,
        contact_id: formData?.sales_reps?.backup || "",
        is_primary: 0,
        is_backup: 1,
        set_order: 4,
      });
    }
    arr.account_reps = repsValueArray;
    handleAccountContactsSave(); // for assign contact

    let result = await CallPOSTData(accountId, arr);
    setFormMsg({ type: result?.data?.status, msg: result?.data?.msg });

    if (result?.data?.status) {
      let url = "";
      if (Number(getPer()) === 1) {
        url = "/account-details/" + formData?.account_id + "/Details";
      } else {
        url = "/account-details/" + formData?.account_id + "/Details";
      }
      // handleDetailsDispatch(formData?.account_id, formData?.account_name)
      handleDispatch(accountId);
      Number(isPermission({ type: "account", name: "Details" })) === 1
        ? navigate(url, {
            state: {
              tab: "Details",
              type: result?.data?.status,
              msg: result?.data?.msg,
            },
          })
        : navigate(-1);
      toast.success(result?.data?.msg);
    } else {
      toast.error(result?.data?.msg);
    }

    setLoading(false);
  };

  useEffect(() => {
    // fetchOnload();
    fetchProductInterest();
  }, []);

  // handle select change
  const handleProductSelect = (data) => {
    let valueArray = [];
    data.map((item, index) => {
      valueArray.push({
        label: item.label,
        value: item.value,
      });
    });
    setSelectedProductData((old) => [...valueArray]);
  };

  const handleProjectPrimarySelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      project_managers: {
        ...prevFormData.project_managers,
        primary: selectedId,
      },
    }));
  };

  const handleProjectBackupSelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      project_managers: {
        ...prevFormData.project_managers,
        backup: selectedId,
      },
    }));
  };

  const handleSalesPrimarySelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sales_reps: {
        ...prevFormData.sales_reps,
        primary: selectedId,
      },
    }));
  };

  const handleSalesBackupSelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sales_reps: {
        ...prevFormData.sales_reps,
        backup: selectedId,
      },
    }));
  };

  const renderSelectedTitleNames = () => {
    return SelectedProductsData?.map((item) => item.label).join(", ");
  };

  const renderSelectTitle = () => {
    return (
      <div>
        {SelectedProductsData?.length === 0
          ? "--Select One--"
          : SelectedProductsData?.length >= 2
          ? `${SelectedProductsData?.length} Selected`
          : renderSelectedTitleNames()}
      </div>
    );
  };

  //Project and Sales
  const [isLoadingProjectSales, setIsLoadingProjectSales] = useState(false);
  const [isOpenProjectSales, setIsOpenProjectSales] = useState(false);

  const fetchProjectSalesData = async () => {
    let AccreptList = await AccRepsDropDown();
    let CheckMarkList = [];
    let RepList = [];
    if (AccreptList) {
      for (let index = 0; index < AccreptList.length; index++) {
        const RepElement = AccreptList[index];
        let obj = { ...RepElement };
        let FindData = CheckMarkList.find(
          (e) => e === RepElement?.account_main_contact_id
        );
        if (FindData) {
          obj.is_selected = false;
        } else {
          obj.is_selected = false;
        }
        obj.primary = { id: "", val: "" };
        obj.backup = { id: "", val: "" };
        RepList.push(obj);
      }
    }
    return RepList;
  };

  useEffect(() => {
    const loadProjectSales = async () => {
      if (!isLoadingProjectSales && isOpenProjectSales) {
        setIsLoadingProjectSales(true);
        try {
          const fetchedAccounts = await fetchProjectSalesData();
          setPrimaryData((prevAccounts) => {
            const newAccounts = [...prevAccounts];
            fetchedAccounts.forEach((account) => {
              if (
                !newAccounts.some(
                  (a) =>
                    a?.account_main_contact_id ===
                    account?.account_main_contact_id
                )
              ) {
                newAccounts.push(account);
              }
            });
            return newAccounts;
          });
          setSalesRepsData((prevAccounts) => {
            const newAccounts = [...prevAccounts];
            fetchedAccounts.forEach((account) => {
              if (
                !newAccounts.some(
                  (a) =>
                    a?.account_main_contact_id ===
                    account?.account_main_contact_id
                )
              ) {
                newAccounts.push(account);
              }
            });
            return newAccounts;
          });
        } catch (error) {
          console.error("Error loading Project and Sales:", error);
        } finally {
          setIsLoadingProjectSales(false);
        }
      }
    };

    loadProjectSales();
  }, [isOpenProjectSales]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      account_name: location?.state?.accountDetails?.restData?.account_name,
      website: location?.state?.accountDetails?.restData?.website,
      important_note:
        location?.state?.accountDetails?.restData?.inportant_notes,
      expiration_alert_toggle:
        location?.state?.accountDetails?.restData?.expiration_alert_toggle,
      aed_check_length: location?.state?.programData?.aed_check_length,
      extra_fields: location?.state?.programData?.extra_fields,
      extra_field1: location?.state?.programData?.extra_field1,
      extra_field2: location?.state?.programData?.extra_field2,
      restricted_user: location?.state?.accountDetails?.restData?.restricted,
      parent_account_id:
        location?.state?.accountDetails?.parentAccount?.account_id || "",
      distributor_id:
        location?.state?.accountDetails?.distributor?.dropdown_distributor_id ||
        "",
      customer_type_id:
        location?.state?.accountDetails?.customer?.dropdown_customer_type_id ||
        "",
      industry_id:
        location?.state?.accountDetails?.industry?.dropdown_industry_id || "",
      terms_id: location?.state?.accountDetails?.terms?.dropdown_terms_id || "",
      lead_source_id:
        location?.state?.accountDetails?.leadSource?.dropdown_lead_source_id ||
        "",
      account_status_id:
        location?.state?.accountDetails?.accountStatus
          ?.drop_account_status_id || "",
    }));

    let projectData = [];

    setFormData((prevFormData) => ({
      ...prevFormData,
      account_poc: {
        ...prevFormData.account_poc,
        primary:
          location?.state?.accountDetails?.coordinatorData?.account_poc?.primary
            ?.account_main_contact_id,
        backup:
          location?.state?.accountDetails?.coordinatorData?.account_poc?.backup
            ?.account_main_contact_id,
      },
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      aed_auditor: {
        ...prevFormData.account_poc,
        primary:
          location?.state?.accountDetails?.coordinatorData?.aed_auditor?.primary
            ?.account_main_contact_id,
        backup:
          location?.state?.accountDetails?.coordinatorData?.aed_auditor?.backup
            ?.account_main_contact_id,
      },
    }));

    if (
      location?.state?.accountDetails?.coordinatorData?.projectManager?.primary
        ?.account_main_contact_id !== ""
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        project_managers: {
          ...prevFormData.project_managers,
          primary:
            location?.state?.accountDetails?.coordinatorData?.projectManager
              ?.primary?.account_main_contact_id,
        },
      }));
      projectData.push(
        location?.state?.accountDetails?.coordinatorData?.projectManager
          ?.primary
      );
    }
    if (
      location?.state?.accountDetails?.coordinatorData?.projectManager?.backup
        ?.account_main_contact_id !== ""
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        project_managers: {
          ...prevFormData.project_managers,
          backup:
            location?.state?.accountDetails?.coordinatorData?.projectManager
              ?.backup?.account_main_contact_id,
        },
      }));
      projectData.push(
        location?.state?.accountDetails?.coordinatorData?.projectManager?.backup
      );
    }
    setPrimaryData(projectData);

    let salesData = [];
    if (
      location?.state?.accountDetails?.coordinatorData?.salesReps?.primary
        ?.account_main_contact_id !== ""
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sales_reps: {
          ...prevFormData.sales_reps,
          primary:
            location?.state?.accountDetails?.coordinatorData?.salesReps?.primary
              ?.account_main_contact_id,
        },
      }));
      salesData.push(
        location?.state?.accountDetails?.coordinatorData?.salesReps?.primary
      );
    }
    if (
      location?.state?.accountDetails?.coordinatorData?.salesReps?.backup
        ?.account_main_contact_id !== ""
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        sales_reps: {
          ...prevFormData.sales_reps,
          backup:
            location?.state?.accountDetails?.coordinatorData?.salesReps?.backup
              ?.account_main_contact_id,
        },
      }));
      salesData.push(
        location?.state?.accountDetails?.coordinatorData?.salesReps?.backup
      );
    }
    setSalesRepsData(salesData);
  }, [location, location?.state?.accountDetails?.coordinatorData]);

  const handleShowContactModal = () => {
    fetchContact();
    setContactShowModel(true);
  };

  const handleAccountPocPrimarySelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      account_poc: {
        ...prevFormData.account_poc,
        primary: selectedId,
      },
    }));
  };

  const handleAccountPocBackupSelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      account_poc: {
        ...prevFormData.account_poc,
        backup: selectedId,
      },
    }));
  };

  // const handleAedAuditorPrimarySelectChange = (e) => {
  //   const selectedId = parseInt(e.target.value);
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     aed_auditor: {
  //       ...prevFormData.aed_auditor,
  //       primary: selectedId,
  //     },
  //   }));
  // };

  const handleAedAuditorBackupSelectChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      aed_auditor: {
        ...prevFormData.aed_auditor,
        backup: selectedId,
      },
    }));
  };

  const handleAedAuditorPrimarySelectChange = (e, value, type = "primary") => {
    // Update formData
    setFormData((prevFormData) => ({
      ...prevFormData,
      aed_auditor: {
        ...prevFormData.aed_auditor,
        [type]: value?.contact_id || "",
      },
    }));

    // Update defaultValue object
    setAedAuditorDefaultValue((prevValue) => ({
      ...prevValue,
      [type]: {
        account_main_contact_id: value?.contact_id || "",
        account_main_contact_firstname: value?.contact_name || "",
      },
    }));
  };

  const handleAccountContactsSave = async () => {
    const contactsValueArray = [];
    const coordinatorData = location?.state?.accountDetails?.coordinatorData;
    let Id1 = accountPocDefaultData?.primary?.account_main_contact_id;
    let Id2 = accountPocDefaultData?.backup?.account_main_contact_id;
    let Id3 = aedAuditorDefaultValue?.primary?.account_main_contact_id;
    let Id4 = aedAuditorDefaultValue?.backup?.account_main_contact_id;

    const addContact = (contactId, contactType, role, positionId) => {
      const formValue = formData?.[contactType]?.[role];
      const existingId = coordinatorData?.[contactType]?.[role]?.id;

      if (formValue || !formValue) {
        contactsValueArray.push({
          id: existingId || "",
          contact_id: contactId || "",
          position_id: positionId,
          permissions: "",
          is_primary: role === "primary" ? 1 : 0,
          is_backup: role === "backup" ? 1 : 0,
        });
      }
    };

    // Add account POC contacts
    addContact(Id1, "account_poc", "primary", 3);
    addContact(Id2, "account_poc", "backup", 3);

    // Add AED auditor contacts
    addContact(Id3, "aed_auditor", "primary", 4);
    addContact(Id4, "aed_auditor", "backup", 4);

    const payload = {
      account_id: accountId,
      account_contact: contactsValueArray,
    };

    await CallPOSTAPI("account/update-acc-contact", payload);
  };

  return (
    <>
      {" "}
      <div className="mt-4">
        <SubHeadingOther
          hideNew={true}
          title={location?.state?.accountDetails?.restData?.account_name}
          subHeading={true}
          hideHierarchy={true}
          bottomLinks={false}
        />

        {/* account resps and products popup buttons */}
        <div className="d-flex mb-3">
          {/* products modal btn */}
          {/*<button
                className="btn text-primary"
                type="button"
                onClick={() => setProductShowModal(true)}
              >
                <img
                  src="/products.svg"
                  alt="svg"
                  style={{ marginRight: "1px" }}
                />
                <span className="ms-2">Products</span>
              </button>*/}

          {/* account reps modal btn */}
          {/*<button
                className="btn text-primary"
                type="button"
                onClick={() => setShowAccRepsModal(true)}
              >
                <img src="/assets/images/reps.svg" alt="svg" style={{ marginRight: "1px" }} />
                <span className="ms-2">Reps</span>
              </button>*/}

          {/* account contacts modal btn */}
          {/* <button
            className="btn text-primary"
            type="button"
            onClick={handleShowContactModal}
          >
            <img
              src="/assets/images/reps.svg"
              alt="svg"
              style={{ marginRight: "1px" }}
            />
            <span className="ms-2">Contacts</span>
          </button> */}
        </div>

        <Form
          className=""
          onSubmit={sendData}
          noValidate
          validated={validated}
          id="edit-account-form"
        >
          <div
            className="container-fluid p-2 mx-3"
            style={{
              background: "#eee",
              borderBottom: "4px solid rgb(13, 110, 253)",
            }}
            id="edit_main_account_section"
          >
            <div className="row mb-4">
              <div className="col-md-12" style={{ marginBottom: "20px" }}>
                <h2 className="text-left d-inline heading">
                  Account Information
                </h2>
              </div>

              <div className="col-md-2 col-lg-3">
                <Form.Group className={""}>
                  <Form.Label className={styles.textlabel}>
                    Account Name *
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      placeholder="Account Name..."
                      required
                      name="account_name"
                      value={formData?.account_name}
                      onChange={handleInputChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Enter Account Name.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="col-md-2 col-lg-3">
                <b>Parent Accounts</b>
                {/* <GlobalDropdown
                  dropDownName="parent_account_id"
                  apiEndpoint={"account/parents-account-dropdowns"}
                  idKey={"account_id"}
                  valueKey={"account_main_contact_firstname"}
                  parentKey={"parentAccount"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.parent_account_id}
                  className={styles.ddLabel}
                  defaultValue={{
                    id: location?.state?.accountDetails?.parentAccount
                      ?.account_id,
                    value:
                      location?.state?.accountDetails?.parentAccount
                        ?.account_main_contact_firstname,
                  }}
                /> */}

                <PaginateAutoComplete
                  dropDownName="parent_account_id"
                  apiEndpoint={"account/parents-account-dropdowns"}
                  idKey={"account_id"}
                  valueKey={"account_main_contact_firstname"}
                  parentKey={"parentAccount"}
                  onSelect={(e, value) => {
                    printConsole({ events: e, value }); // print console.log
                    handleInputChange(e, "account_info");
                    setAccountDefaultVal(value);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  defaultValue={{
                    account_id: accountDefaultVal?.account_id,
                    account_main_contact_firstname:
                      accountDefaultVal?.account_main_contact_firstname,
                  }}
                />
              </div>

              <div className="col-md-2 col-lg-3">
                <b>Distributor</b>

                <PaginateAutoComplete
                  dropDownName="distributor_id"
                  apiEndpoint={"account/distributors-account-dropdowns"}
                  idKey={"dropdown_distributor_id"}
                  valueKey={"distributor_name"}
                  parentKey={"distributors"}
                  onSelect={(e, value) => {
                    handleInputChange(e, "account_info");
                    setdistributorDefaultVal(value);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  defaultValue={{
                    dropdown_distributor_id:
                      distributorDefaultVal?.dropdown_distributor_id,
                    distributor_name: distributorDefaultVal?.distributor_name,
                  }}
                />
                {/*                   
                <GlobalDropdown
                  dropDownName={"distributor_id"}
                  apiEndpoint={"account/distributors-account-dropdowns"}
                  idKey={"dropdown_distributor_id"}
                  valueKey={"distributor_name"}
                  parentKey={"distributors"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.distributor_id}
                  className={styles.ddLabel}
                  defaultValue={{
                    id: location?.state?.accountDetails?.distributor
                      ?.dropdown_distributor_id,
                    value:
                      location?.state?.accountDetails?.distributor
                        ?.distributor_name,
                  }}
                /> */}
              </div>

              <div className="col-md-1 d-flex mb-2 modal-btns">
                <Form.Group
                  className={" text-left "}
                  style={{ margin: "auto 10px" }}
                >
                  <b className={"D-BLOCK"}>Restricted</b>
                  <span className="d-inline-block mt-10-px">
                    <CustomToggleButton2
                      ToggleName="restricted_user"
                      ToggleValue={
                        formData.restricted_user === 1 ||
                        formData.restricted_user
                          ? true
                          : false
                      }
                      changeHandler={handleInputChange}
                      is_read_only={false}
                    />
                  </span>
                </Form.Group>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <b>Customer Type</b>
                <GlobalDropdown
                  dropDownName={"customer_type_id"}
                  apiEndpoint={"account/customer-account-dropdowns"}
                  idKey={"dropdown_customer_type_id"}
                  valueKey={"customer_type_name"}
                  parentKey={"customerType"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.customer_type_id}
                  className={styles.ddLabel}
                  defaultValue={{
                    id: location?.state?.accountDetails?.customer
                      ?.dropdown_customer_type_id,
                    value:
                      location?.state?.accountDetails?.customer
                        ?.customer_type_name,
                  }}
                />
              </div>

              <div className="col">
                <b>Industry</b>
                <GlobalDropdown
                  dropDownName={"industry_id"}
                  apiEndpoint={"account/industry-account-dropdowns"}
                  idKey={"dropdown_industry_id"}
                  valueKey={"dropdown_industry_name"}
                  parentKey={"industryType"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.industry_id}
                  className={styles.ddLabel}
                  defaultValue={{
                    id: location?.state?.accountDetails?.industry
                      ?.dropdown_industry_id,
                    value:
                      location?.state?.accountDetails?.industry
                        ?.dropdown_industry_name,
                  }}
                />
              </div>
              <div className="col">
                <b>Terms</b>
                <GlobalDropdown
                  dropDownName={"terms_id"}
                  apiEndpoint={"account/terms-account-dropdowns"}
                  idKey={"dropdown_terms_id"}
                  valueKey={"dropdown_terms_name"}
                  parentKey={"termsType"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.terms_id}
                  className={styles.ddLabel}
                  defaultValue={{
                    id: location?.state?.accountDetails?.terms
                      ?.dropdown_terms_id,
                    value:
                      location?.state?.accountDetails?.terms
                        ?.dropdown_terms_name,
                  }}
                />
              </div>
              <div className="col">
                <b>Lead Source</b>
                <GlobalDropdown
                  dropDownName={"lead_source_id"}
                  apiEndpoint={"account/leadsource-dropdowns"}
                  idKey={"dropdown_lead_source_id"}
                  valueKey={"dropdown_lead_source_name"}
                  parentKey={"leadSources"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.lead_source_id}
                  className={styles.ddLabel}
                  defaultValue={{
                    id: location?.state?.accountDetails?.leadSource
                      ?.dropdown_lead_source_id,
                    value:
                      location?.state?.accountDetails?.leadSource
                        ?.dropdown_lead_source_name,
                  }}
                />
              </div>

              <div className="col">
                <Form.Group className={"col"} style={{ width: "250px" }}>
                  <Form.Label>Products</Form.Label>
                  <MultiSelect
                    valueRenderer={renderSelectTitle}
                    options={ProductModalData}
                    value={SelectedProductsData || []}
                    onChange={handleProductSelect}
                    labelledBy="--Select One--"
                    hasSelectAll={false}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <b>Account Status</b>
                <GlobalDropdown
                  dropDownName={"account_status_id"}
                  apiEndpoint={"account/account-status-dropdowns"}
                  idKey={"drop_account_status_id"}
                  valueKey={"account_status"}
                  parentKey={"accountStatus"}
                  onSelect={(e) => {
                    handleInputChange(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.account_status_id}
                  className={styles.ddLabel}
                  defaultValue={{
                    id: location?.state?.accountDetails?.accountStatus
                      ?.drop_account_status_id,
                    value:
                      location?.state?.accountDetails?.accountStatus
                        ?.account_status,
                  }}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-5">
                <Form.Group className={""}>
                  <Form.Label className={styles.textlabel}>Website</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Website..."
                    name="website"
                    value={formData?.website}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
              <div className="col-7">
                <Form.Group className={""}>
                  <Form.Label className={styles.textlabel}>
                    Important Notes
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    placeholder="Important Notes..."
                    name="important_note"
                    value={formData?.important_note}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>

              {/* <div className='col-2' ></div> */}
            </div>

            <div className="">
              <MessageHandler
                status={FormMsg.type}
                msg={FormMsg.msg}
                HandleMessage={setFormMsg}
              />
            </div>
          </div>

          {/* aed */}
          <div
            className="container-fluid col-12 my-5 p-2 mx-3"
            style={{
              background: "#eee",
              borderBottom: "4px solid rgb(13, 110, 253)",
            }}
            id="account_aed_section"
          >
            <h2 className="text-left heading" style={{ marginBottom: "20px" }}>
              AED Options
            </h2>
            <div className="row mb-4">
              <div className="col-md-2 col-lg-2">
                <b className={""}>AED Check Length</b>
                <Form.Select
                  className={styles.ddLabel}
                  name="aed_check_length"
                  value={formData?.aed_check_length}
                  onChange={handleInputChange}
                >
                  <option value="">--Select One--</option>
                  <option value={"15 Days"}>15 Days</option>
                  <option value={"30 Days"}>30 Days</option>
                </Form.Select>
              </div>

              <div className="col-md-2 col-lg-2">
                <b className={""}>Extra Fields</b>
                <Form.Select
                  className={styles.ddLabel}
                  name="extra_fields"
                  value={formData?.extra_fields}
                  onChange={handleInputChange}
                >
                  <option value="0" selected>
                    --Select One--
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Form.Select>
              </div>

              {formData?.extra_fields != 0 && (
                <>
                  {formData?.extra_fields == 1 ? (
                    <>
                      {/* field 1 */}
                      <div className="col-md-2 col-lg-2">
                        <Form.Group className={""}>
                          <Form.Label className={styles.textlabel}>
                            Field 1 Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Field 1..."
                            name="extra_field1"
                            value={formData?.extra_field1}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* field 1 */}
                      <div className="col-md-3 col-lg-3">
                        <Form.Group className={""}>
                          <Form.Label className={styles.textlabel}>
                            Field 1 Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Field 1..."
                            name="extra_field1"
                            value={formData?.extra_field1}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </div>

                      {/* field 2 */}
                      <div className="col-md-3 col-lg-3">
                        <Form.Group className={""}>
                          <Form.Label className={styles.textlabel}>
                            Field 2 Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Field 2..."
                            name="extra_field2"
                            value={formData?.extra_field2}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </div>
                    </>
                  )}
                </>
              )}

              <div
                className="col-md-2 col-lg-2"
                style={{ maxWidth: "250px", marginRight: "" }}
              >
                <Form.Group>
                  <b className={"d-block mb-3"}>Expiration Alerts</b>
                  <div className="my-2">
                    <CustomToggleButton
                      ToggleName="expiration_alert_toggle"
                      ToggleValue={formData?.expiration_alert_toggle}
                      changeHandler={(e) =>
                        handleCheckBox(e, "expiration_alert_toggle")
                      }
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>

          {/* Project Managers */}
          <div
            className="container-fluid col-12 my-5 p-2 mx-3"
            style={{
              background: "#eee",
              borderBottom: "4px solid rgb(13, 110, 253)",
            }}
            id="edit_account_project_manager"
          >
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <h2
                className="text-left heading"
                style={{ marginBottom: "0", width: "51%" }}
              >
                Project Managers
              </h2>
              <h2
                className="text-left heading"
                style={{ marginBottom: "0", width: "50%" }}
              >
                Sales Reps
              </h2>
            </div>
            <div className="row my-4 ">
              <Form.Group className={"col"}>
                <Form.Label>Primary</Form.Label>
                {/* <Form.Select
                  className={""}
                  name="primary"
                  value={formData?.project_managers?.primary}
                  onChange={(e) => {
                    handleProjectPrimarySelectChange(e);
                  }}
                  onClick={() => setIsOpenProjectSales(true)}
                >
                  <option value="" selected>
                    --Select One--
                  </option>
                  {primaryData?.map((item, index) => {
                    if (
                      item?.account_main_contact_id !==
                      formData?.project_managers?.backup
                    ) {
                      return (
                        <option
                          value={item?.account_main_contact_id}
                          key={index}
                        >
                          {item?.account_main_contact_firstname}{" "}
                          {item?.account_main_contact_lastname}
                        </option>
                      );
                    }
                  })}
                  {isLoadingProjectSales && (
                    <option disabled>Loading more options...</option>
                  )}
                </Form.Select> */}
                <PaginateAutoComplete
                  dropDownName="primary"
                  apiEndpoint={`account/account-reps-dropdown?is_pm=1`}
                  idKey={"account_main_contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"accountReps"}
                  onSelect={(e, value) => {
                    handleProjectPrimarySelectChange(e, "account_info");
                    const obj = {
                      ...coordinatorDefaultData,
                      primary: {
                        account_main_contact_id: value?.account_main_contact_id,
                        account_main_contact_firstname: value?.contact_name,
                      },
                    };
                    setcoordinatorDefaultData(obj);
                    // setsalesRepsDefaultValu
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.project_managers?.backup]}
                  defaultValue={{
                    account_main_contact_id:
                      coordinatorDefaultData?.primary
                        ?.account_main_contact_id || "",
                    contact_name:
                      coordinatorDefaultData?.primary
                        ?.account_main_contact_firstname,
                  }}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Backup</Form.Label>

                <PaginateAutoComplete
                  dropDownName="backup"
                  apiEndpoint={`account/account-reps-dropdown?is_pm=1`}
                  idKey={"account_main_contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"accountReps"}
                  onSelect={(e, value) => {
                    handleProjectBackupSelectChange(e, "account_info");

                    const obj = {
                      ...coordinatorDefaultData,
                      backup: {
                        account_main_contact_id: value?.account_main_contact_id,
                        account_main_contact_firstname: value?.contact_name,
                      },
                    };
                    setcoordinatorDefaultData(obj);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.project_managers?.primary]}
                  defaultValue={{
                    account_main_contact_id:
                      coordinatorDefaultData?.backup?.account_main_contact_id ||
                      "",
                    contact_name:
                      coordinatorDefaultData?.backup
                        ?.account_main_contact_firstname || "",
                  }}
                />
                {/* <Form.Select
                  className={""}
                  name="backup"
                  value={formData?.project_managers?.backup}
                  onClick={() => setIsOpenProjectSales(true)}
                  onChange={(e) => {
                    handleProjectBackupSelectChange(e);
                  }}
                >
                  <option value="" selected>
                    --Select One--
                  </option>
                  {primaryData.map((item, index) => {
                    if (
                      item?.account_main_contact_id !==
                      formData?.project_managers?.primary
                    ) {
                      return (
                        <option
                          value={item?.account_main_contact_id}
                          key={index}
                        >
                          {item?.account_main_contact_firstname}{" "}
                          {item?.account_main_contact_lastname}
                        </option>
                      );
                    }
                  })}
                  {isLoadingProjectSales && (
                    <option disabled>Loading more options...</option>
                  )}
                </Form.Select> */}
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Primary</Form.Label>
                {/* salesRepsDefaultValu */}
                <PaginateAutoComplete
                  dropDownName="sales_reps_primary"
                  apiEndpoint={`account/account-reps-dropdown?is_sr=1`}
                  idKey={"account_main_contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"accountReps"}
                  onSelect={(e, value) => {
                    handleSalesPrimarySelectChange(e, "account_info");

                    const obj = {
                      ...salesRepsDefaultValu,
                      primary: {
                        account_main_contact_id: value?.account_main_contact_id,
                        account_main_contact_firstname: value?.contact_name,
                      },
                    };
                    setsalesRepsDefaultValu(obj);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.sales_reps?.backup]}
                  defaultValue={{
                    account_main_contact_id:
                      salesRepsDefaultValu?.primary?.account_main_contact_id ||
                      "",
                    contact_name:
                      salesRepsDefaultValu?.primary
                        ?.account_main_contact_firstname || "",
                  }}
                />
                {/* <Form.Select
                  className={""}
                  name="sales_reps_primary"
                  value={formData?.sales_reps?.primary}
                  onClick={() => setIsOpenProjectSales(true)}
                  onChange={handleSalesPrimarySelectChange}
                >
                  <option value="" selected>
                    --Select One--
                  </option>
                  {salesRepsData.map((item, index) => {
                    if (
                      item?.account_main_contact_id !==
                      formData?.sales_reps?.backup
                    ) {
                      return (
                        <option
                          value={item?.account_main_contact_id}
                          key={index}
                        >
                          {item?.account_main_contact_firstname}{" "}
                          {item?.account_main_contact_lastname}
                        </option>
                      );
                    }
                  })}
                  {isLoadingProjectSales && (
                    <option disabled>Loading more options...</option>
                  )}
                </Form.Select> */}
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Backup</Form.Label>

                <PaginateAutoComplete
                  dropDownName="sales_reps_primary"
                  apiEndpoint={`account/account-reps-dropdown?is_sr=1`}
                  idKey={"account_main_contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"accountReps"}
                  onSelect={(e, value) => {
                    printConsole({ e, value }); // print console.log

                    handleSalesBackupSelectChange(e, "account_info");

                    const obj = {
                      ...salesRepsDefaultValu,
                      backup: {
                        account_main_contact_id: value?.account_main_contact_id,
                        account_main_contact_firstname: value?.contact_name,
                      },
                    };
                    setsalesRepsDefaultValu(obj);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.sales_reps?.primary]}
                  defaultValue={{
                    account_main_contact_id:
                      salesRepsDefaultValu?.backup?.account_main_contact_id ||
                      "",
                    contact_name:
                      salesRepsDefaultValu?.backup
                        ?.account_main_contact_firstname || "",
                  }}
                />
                {/* <Form.Select
                  className={""}
                  name="sales_reps_backup"
                  value={formData?.sales_reps?.backup}
                  onClick={() => setIsOpenProjectSales(true)}
                  onChange={handleSalesBackupSelectChange}
                >
                  <option value="" selected>
                    --Select One--
                  </option>
                  {salesRepsData.map((item, index) => {
                    if (
                      item?.account_main_contact_id !==
                      formData?.sales_reps?.primary
                    ) {
                      return (
                        <option
                          value={item?.account_main_contact_id}
                          key={index}
                        >
                          {item?.account_main_contact_firstname}{" "}
                          {item?.account_main_contact_lastname}
                        </option>
                      );
                    }
                  })}
                  {isLoadingProjectSales && (
                    <option disabled>Loading more options...</option>
                  )}
                </Form.Select> */}
              </Form.Group>
            </div>
          </div>

          {/* Assigned Contact */}
          <div
            className="container-fluid col-12 my-5 p-2 mx-3"
            style={{
              background: "#eee",
              borderBottom: "4px solid rgb(13, 110, 253)",
            }}
            id="edit_account_assigned_contacts"
          >
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <h2
                className="text-left heading"
                style={{ marginBottom: "0", width: "51%" }}
              >
                Account POC
              </h2>
              <h2
                className="text-left heading"
                style={{ marginBottom: "0", width: "50%" }}
              >
                AED Auditor
              </h2>
            </div>

            <div className="row my-4 ">
              <Form.Group className={"col"}>
                <Form.Label>Primary</Form.Label>
                <PaginateAutoComplete
                  dropDownName="account_poc_primary"
                  apiEndpoint={`contact/acc-contact-dropdown/${accountId}`}
                  idKey={"contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"contact_list"}
                  onSelect={(e, value) => {
                    handleAccountPocPrimarySelectChange(e, "account_info");
                    const obj = {
                      ...accountPocDefaultData,
                      primary: {
                        account_main_contact_id: value?.contact_id,
                        account_main_contact_firstname: value?.contact_name,
                      },
                    };
                    setAccountPocDefaultData(obj);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.account_poc?.backup]}
                  defaultValue={{
                    contact_id:
                      accountPocDefaultData?.primary?.account_main_contact_id ||
                      "",
                    contact_name:
                      accountPocDefaultData?.primary
                        ?.account_main_contact_firstname,
                  }}
                  getResults={(res) => {
                    const result = res?.data?.data?.contact_list || [];
                    const totalCountsVar = res?.data?.data?.totalCount || 0;
                    return [result, totalCountsVar];
                  }}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Backup</Form.Label>
                <PaginateAutoComplete
                  dropDownName="account_poc_backup"
                  apiEndpoint={`contact/acc-contact-dropdown/${accountId}`}
                  idKey={"contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"contact_list"}
                  onSelect={(e, value) => {
                    handleAccountPocBackupSelectChange(e, "account_info");
                    const obj = {
                      ...accountPocDefaultData,
                      backup: {
                        account_main_contact_id: value?.contact_id,
                        account_main_contact_firstname: value?.contact_name,
                      },
                    };
                    setAccountPocDefaultData(obj);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.account_poc?.primary]}
                  defaultValue={{
                    contact_id:
                      accountPocDefaultData?.backup?.account_main_contact_id ||
                      "",
                    contact_name:
                      accountPocDefaultData?.backup
                        ?.account_main_contact_firstname,
                  }}
                  getResults={(res) => {
                    const result = res?.data?.data?.contact_list || [];
                    const totalCountsVar = res?.data?.data?.totalCount || 0;
                    return [result, totalCountsVar];
                  }}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Primary</Form.Label>
                <PaginateAutoComplete
                  dropDownName="aed_auditor_primary"
                  apiEndpoint={`contact/acc-contact-dropdown/${accountId}`}
                  idKey={"contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"contact_list"}
                  // onSelect={(e, value) => {
                  //   handleAedAuditorPrimarySelectChange(e, "account_info");
                  //   const obj = {
                  //     ...aedAuditorDefaultValue,
                  //     primary: {
                  //       account_main_contact_id: value?.contact_id,
                  //       account_main_contact_firstname: value?.contact_name,
                  //     },
                  //   };
                  //   setAedAuditorDefaultValue(obj);
                  // }}
                  onSelect={(e, value) => {
                    handleAedAuditorPrimarySelectChange(e, value, "primary");
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.aed_auditor?.backup]}
                  defaultValue={{
                    contact_id:
                      aedAuditorDefaultValue?.primary
                        ?.account_main_contact_id || "",
                    contact_name:
                      aedAuditorDefaultValue?.primary
                        ?.account_main_contact_firstname || "",
                  }}
                  getResults={(res) => {
                    const result = res?.data?.data?.contact_list || [];
                    const totalCountsVar = res?.data?.data?.totalCount || 0;
                    return [result, totalCountsVar];
                  }}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Backup</Form.Label>

                <PaginateAutoComplete
                  dropDownName="aed_auditor_backup"
                  apiEndpoint={`contact/acc-contact-dropdown/${accountId}`}
                  idKey={"contact_id"}
                  valueKey={"contact_name"}
                  parentKey={"contact_list"}
                  // onSelect={(e, value) => {
                  //   handleAedAuditorBackupSelectChange(e, "account_info");
                  //   const obj = {
                  //     ...aedAuditorDefaultValue,
                  //     backup: {
                  //       account_main_contact_id: value?.contact_id,
                  //       account_main_contact_firstname: value?.contact_name,
                  //     },
                  //   };
                  //   setAedAuditorDefaultValue(obj);
                  // }}
                  onSelect={(e, value) => {
                    handleAedAuditorPrimarySelectChange(e, value, "backup");
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  className={styles.ddLabel}
                  selectDisabled={false}
                  isSearchable={true}
                  isAsync={true}
                  pageSize={20}
                  excludeRecords={[formData?.aed_auditor?.primary]}
                  defaultValue={{
                    contact_id:
                      aedAuditorDefaultValue?.backup?.account_main_contact_id ||
                      "",
                    contact_name:
                      aedAuditorDefaultValue?.backup
                        ?.account_main_contact_firstname || "",
                  }}
                  getResults={(res) => {
                    const result = res?.data?.data?.contact_list || [];
                    const totalCountsVar = res?.data?.data?.totalCount || 0;
                    return [result, totalCountsVar];
                  }}
                />
              </Form.Group>
            </div>
          </div>

          {/* bottom buttons */}
          <div className="col-12 d-flex justify-content-end mt-5 mb-4 gap-2">
            <Button
              className={" bg-red hover-bg-red text-light"}
              variant="Cancel"
              style={{ fontSize: "16px" }}
              type="button"
              disabled={loading}
              onClick={() => {
                handleDispatch(accountId);
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              className={"bg-green hover-bg-green text-light"}
              variant="success"
              type="submit"
              // onClick={(e) => {
              //   sendData(e);
              // }}
              style={{ fontSize: "16px" }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </div>

          {/*<ProductModal
              ProductShowModal={ProductShowModal}
              setProductShowModal={setProductShowModal}
              ProductModalData={ProductModalData}
              SelectedProductsData={SelectedProductsData}
              setSelectedProductData={setSelectedProductData}
              resultData={resultData}
            />*/}

          {/* <ContactModel
            ShowRepsModal={contactShowModel}
            SetShowRepsModal={setContactShowModel}
            setSelectAccReps={setSelectContact}
            setAccReps={setContactRepsList}
            AccReps={contactRepsList}
            setAccRepsList={setContactReps}
            AccRepsList={contactReps}
            resultData={repsData}
            loadingContact={showLoadingContact}
          /> */}

          {/*<AccountReps
              ShowRepsModal={ShowAccRepsModal}
              SetShowRepsModal={setShowAccRepsModal}
              setSelectAccReps={setSelectAccReps}
              setAccReps={setAccReps}
              AccReps={AccReps} 
              setAccRepsList={setAccRepsList}
              AccRepsList={AccRepsList}
              resultData={repsData}
            />*/}
        </Form>

        {/* Overlay */}
        {loading && (
          <div style={overlay}>
            <Spinner style={{ width: "3rem", height: "3rem" }} />
          </div>
        )}
      </div>
    </>
  );
};

export default AccountEdit;
