import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { CallGETAPI, CallGETAPINEW, CallPOSTAPI } from "../../../../common/services/index";
import {
  BatteryInfocolumnList,
  BatteryTypebyModel,
  DecryptToken,
  GetAedBrands,
  GetAedModelsByBrandId,
} from "../../../../common/helper/BasicFn";
import SingleModiAed from "../components/SingleModiAed";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selecteTab } from "../../../../store/slices/TabSlice";
import { FormatDate } from "../../../../common/helper";
import { useLocation } from "react-router-dom";
import FinalNewBreadcrumbs from "../../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";
import { removeBreadcrumb } from "../../../../store/slices/breadcrumbsSlice";
import { printConsole } from "../../../../common/helper/Common";
import moment from "moment";

const AedServiceCheckService2 = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { serviceQuestionID } = useParams();
  const [formData, setFormData] = useState({
    Brand: "",
    Serial: "",
    Placement: "",
    AedReadyStatusToggle: 1,
    RecueKitToggle: [0],
    AlarmBatteryToggle: [0],
    ReplaceAccessoriesToggle: [0],
    SupportTicketToggle: [0],
    aedDataArray: [],
  });

  const location = useLocation();
  let isInventory = location?.state?.isInventory;
  const navigateAedDetails = location?.state?.navigateAedDetails;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [serviceQuestionData, setServiceQuestionData] = useState({});
  // const [rmsBrandList, setBrandList] = useState([]);
  const [aedDetails, setAedDetails] = useState([]);
  const [showAdditionalBatteryRows, setShowAdditionalBatteryRows] =
    useState(false);
  const [showAdditionalPadsRows, setShowAdditionalPadsRows] = useState(false);
  const [batteryInfo, setBatteryInfo] = useState([]);
  const [padInfo, setPadInfo] = useState([]);
  const [selectedBatteryValue, setSelectedBatteryValue] = useState("");
  const [selectedPadiatricValue, setSelectedPadiatricValue] = useState("");
  const [batteryTypeList, setBatteryTypeList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [brandName, setBrandname] = useState([]);
  const [ShowEmptyMainBtryRow, setShowEmptyMainBtryRow] = useState(false);
  const [removedBatteryInfo, setRemovedBatteryInfo] = useState([]);
  const [removedSpareRows, setRemovedSpareRows] = useState([]);
  const [moveSpareBattery, setMoveSpareBattery] = useState(false);
  const [moveSpareBatteryIndex, setMoveSpareBatteryIndex] = useState([]);
  const [moveSpareToPadPadiatric, setMoveSpareToPadPadiatric] = useState(false);
  const [moveSpareToPadPadiatricIndex, setMoveSpareToPadPadiatricIndex] =
    useState();
  const [moveSpareToPad, setMoveSpareToPad] = useState(false);
  const [moveSpareToPadIndex, setMoveSpareToPadIndex] = useState();
  const [additionalBatteryRows, setAdditionalBatteryRows] = useState(0);
  const [additionalPadRows, setAdditionalPadRows] = useState(0);
  const [deleteNewBattery, setDeleteNewBattery] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [resetPadButton, setResetPadButton] = useState(false);
  const [batteryList, setBatteryList] = useState([]);
  const [padList, setPadList] = useState([]);
  const [resetBtnLoading, setResetBtnLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [models, setModels] = useState([])
  const [permission, setPermission] = useState([])
  const user = DecryptToken();
  const aedID = location?.aedId
  // Always call useSelector unconditionally
  const selectedAedId = useSelector((state) => state.AED_manager?.editAedData?.aedGeneralInfo?.aed_id);

  // Then use a conditional expression to determine the value of aed_id
  const aed_id = aedID || selectedAedId;


  // printConsole({ serial_Number })
  const [newFormData, setNewFormData] = useState([
    {
      accessories_key: "AED",
      all_pads: [],
      default_all_pads: [],
      battery_info: [],
      default_battery_info: [],
      battery_section_name: "",
      deletedBatteried: [],
      deletedPads: [],
      deletedChargePak: [],
      RecueKitToggle: 0,
      AlarmBatteryToggle: 0,
      ReplaceAccessoriesToggle: 0,
      SupportTicketToggle: 0,
      AedReadyStatusToggle: 1,
      resetButton: 0,
      resetPadButton: 0,
      aedPadTypeList: [],
      battery_type_list: [],
      dni_array_list: [],
      charge_pak_arr: [],
      charge_pack_list: [],
      useSpidInventory: [],
      useSbidInventory: [],
    },
  ]);
  // const IsColumnVisible = (key, data) => {
  //   return data.some((row) => row[key] !== null && row[key] !== undefined);
  // };
  const [serialNumbersData, setSerialNumbersData] = useState([]);
  const [isPresentText, setIsPresentText] = useState("");
  const [presentError, setPresentError] = useState(false);
  const [contactId, setContactId] = useState("");

  var contact_id = serviceQuestionData?.contact_id;
  var inspection_by = serviceQuestionData?.inspection_by;

  const fetchSerialNumbers = async (Cid) => {
    var response = await CallGETAPI(`aed/get-replacing-serial-modification/${Cid}`);
    if (response.status === true) {
      var data = response?.data?.data;
      setSerialNumbersData(data);
    }
  };

  const IsColumnVisible = (key, data) => {
    if (key === "battery_serial") {
      return false;
    }
    return data.some((row) => isValidValue(row[key]));
  };

  const isValidValue = (value) => {
    // Add additional checks for specific invalid values
    return (
      value !== null &&
      value !== undefined &&
      value !== "0000-00-00" &&
      value != ""
    );
  };

  const fetchData = async () => {
    // setLoading(true);
    setPageLoading(true);
    const res = await CallGETAPI(
      `aed/get-service-questions-by-id/${serviceQuestionID}`
    );
    printConsole({ res }) // print console.log
    if (res.status) {
      const serviceQuestions = res?.data?.data?.service_questions;
      const AEDData = res?.data?.data?.aed_data;
      printConsole({ AEDData })  // print console.log
      setServiceQuestionData(serviceQuestions);
      fetchSerialNumbers(serviceQuestions?.contact_id);

      const arr = [];
      for (let i = 0; i < AEDData?.length; i++) {
        const element = AEDData[i];
        const aed_data = element.aed_data;
        const BiArr = element.battery_info.filter(
          (it) => it.section_name != "charge_pack"
        );

        console.log({ BatteryInfocolumnList })

        const visibleColumns = BatteryInfocolumnList.filter((column) => {
          return column.is_default === 1 || IsColumnVisible(column.key, BiArr);
        });

        const ChargePackBattery = element.battery_info.filter(
          (it) => it.section_name == "charge_pack"
        );
        const ChargePackPadInfo = element.all_pads.filter(
          (it) => it.section_name == "charge_pack"
        );
        const ChargePackInfoArr = [];
        if (ChargePackBattery?.length > 0) {
          for (let k2 = 0; k2 < ChargePackBattery?.length; k2++) {
            const bCERow = ChargePackBattery[k2];
            const Pad1Info = ChargePackPadInfo?.find(
              (it) => Number(it.pid) === Number(bCERow.charge_pak_pad_1_id)
            );
            const Pad2Info = ChargePackPadInfo.find(
              (it) => Number(it.pid) === Number(bCERow.charge_pak_pad_2_id)
            );
            const ChargePakObj = {
              battery_info: { ...bCERow },
              pad_1_info: { ...Pad1Info },
              pad_2_info: { ...Pad2Info },
              is_readonly: 0,
              is_new: 0,
              bid: bCERow.bid,
              p1id: bCERow.charge_pak_pad_1_id,
              p2id: bCERow.charge_pak_pad_2_id,
              aed_id: bCERow.aed_id,
            };
            ChargePackInfoArr.push(ChargePakObj);
          }
        }

        const obj = {
          ...aed_data,
          accessories_key: "AED",
          all_pads: element.all_pads.filter(
            (it) => it.section_name != "charge_pack"
          ),
          default_all_pads: element.all_pads.filter(
            (it) => it.section_name != "charge_pack"
          ),
          battery_info: BiArr,
          default_battery_info: BiArr,
          battery_section_name: BiArr?.[0]?.section_name,
          batteryvisibleColumns: visibleColumns,
          charge_pak_info: element.battery_info.filter(
            (it) => it.section_name == "charge_pack"
          ),
          default_charge_pak_info: ChargePackInfoArr || [], //element.battery_info.filter(it => it.section_name == 'charge_pack'),
          charge_pak_pad_info: element.all_pads.filter(
            (it) => it.section_name == "charge_pack"
          ),
          charge_pack_list: ChargePackInfoArr || [],
          deletedBatteried: [],
          deletedPads: [],
          deletedChargePak: [],
          RecueKitToggle: 0,
          AlarmBatteryToggle: 0,
          ReplaceAccessoriesToggle: 0,
          SupportTicketToggle: 0,
          AedReadyStatusToggle: 1,
          resetButton: 0,
          resetPadButton: 0,
          aedPadTypeList: [],
          battery_type_list: [],
          dni_array_list: [],
          charge_pak_arr: [],
          useSpidInventory: [],
          useSbidInventory: [],
        };

        let res = await CallGETAPINEW(
          "equipment/aed-pad-type-by-model/" + aed_data?.aed_model_id
        );
        if (res.data.status) {
          obj.aedPadTypeList = res?.data?.data || [];
        }
        const BatteryRes = await BatteryTypebyModel(aed_data?.aed_model_id);
        obj.battery_type_list = BatteryRes || [];
        arr.push(obj);
      }

      printConsole({ res })  // print console.log
      setNewFormData(arr);
    }
    // setLoading(false);
    setPageLoading(false);
  };

  const brandListfn = async () => {
    let brandList = await GetAedBrands();
    setBrandList(brandList);
  };

  const fetchBatteryModel = async (aed_model_id) => {
    const BatteryRes = await BatteryTypebyModel(aed_model_id);
    if (BatteryRes) {
      setBatteryTypeList(BatteryRes);
    }
    if (aedDetails.length === 0) {
      return "";
    }
  };

  useEffect(() => {
    fetchData();

    // fetch Brand
    brandListfn();
  }, []);
  printConsole({ newFormData })  // print console.log

  const finalFormaData = () => {
    const finalObject = [];
    newFormData.map((item, i) => {
      let aedPads = item?.all_pads;
      aedPads.forEach((pad) => {
        if (pad.is_spare === 0 && pad.is_pediatric === 1) {
          pad.section_name = "pediatric_pad_info";
        } else if (pad.is_spare === 1 && pad.is_pediatric === 0) {
          pad.section_name = "spare_adult_pad_info";
        } else if (pad.is_spare === 1 && pad.is_pediatric === 1) {
          pad.section_name = "spare_padric_pad_info";
        } else if (pad.is_spare === 0 && pad.is_pediatric === 0) {
          pad.section_name = "adult_pad_info";
        }
      });

      const obj = {
        aed_id: item?.aed_id,
        account_id: item?.account_id,
        site_id: item?.site_id,
        aed_brand_id: item?.aed_brand_id,
        aed_model_id: item?.aed_model_id,
        sub_model_id: item?.sub_model_id,
        part_number: item?.part_number,
        serial_number: item?.serial_number,
        asset: item?.asset,
        other: item?.other,
        aed_image: item?.aed_image,
        placement: item?.placement,
        purchase_type: item?.purchase_type,
        purchase_date: item?.purchase_date,
        warranty_date: item?.warranty_date,
        no_spares_toggle: item?.no_spares_toggle,
        no_pediatric_toggle: item?.no_pediatric_toggle,
        RMS_toggle: item?.RMS_toggle,
        out_of_service_toggle: item?.out_of_service_toggle,
        rms_info: item?.rms_info,
        storage_info: item?.storage_info,
        battery_info: item?.battery_info,
        spare_battery_info: item?.spare_battery_info,
        charge_pack: item?.charge_pak_info,
        spare_charge_pak_info: item?.spare_charge_pak_info,
        adult_pad_info: item?.adult_pad_info,
        spare_adult_pad_info: item?.spare_adult_pad_info,
        adult_pad_pak_info: item?.adult_pad_pak_info,
        spare_adult_pad_pak_info: item?.spare_adult_pad_pak_info,
        pediatric_pad_info: item?.pediatric_pad_info,
        spare_padric_pad_info: item?.spare_padric_pad_info,
        pediatric_pak_pad_info: item?.pediatric_pak_pad_info,
        spare_padric_pak_pad: item?.spare_padric_pak_pad,
        gateway_info: item?.gateway_info,
        builtin_RMS_info: item?.builtin_RMS_info,
        pediatric_key: item?.pediatric_key,
        ready_status: item?.ready_status,
        expiration_date: item?.expiration_date,
        last_check: item?.last_check,
        last_service: item?.last_service,
        rms_check: item?.rms_check,
        created_by_id: item?.created_by_id,
        created_date: item?.created_date,
        modified_by_id: item?.modified_by_id,
        modified_date: item?.modified_date,
        active: item?.active,
        created_by: item?.created_by,
        modified_by: item?.modified_by,
        assign_user: item?.assign_user,
        all_pads: item?.all_pads,
        battery_section_name: item?.battery_section_name,
        deletedBatteried: item?.deletedBatteried,
        deletedPads: item?.deletedPads,
        deletedChargePak: item?.deletedChargePak,
        charge_pak_list: item.charge_pack_list,
        RecueKitToggle: item?.RecueKitToggle,
        AlarmBatteryToggle: item?.AlarmBatteryToggle,
        ReplaceAccessoriesToggle: item?.ReplaceAccessoriesToggle,
        SupportTicketToggle: item?.SupportTicketToggle,
        AedReadyStatusToggle: item?.AedReadyStatusToggle,
        resetButton: item?.resetButton,
        resetPadButton: item?.resetPadButton,
        dni_array_list: item?.dni_array_list,
        charge_pak_arr: item?.charge_pak_arr,
        brandName: item?.brandName,
        modalName: item?.modalName,
        support_description: item.support_description,

        out_of_service_info: [
          {
            replacement_aed_toggle: item?.ReplacingAeds,
            servicing_notes: item.servicing_notes,
            replaced_serial: item?.ReplacementAedId,
            replaced_serial_name: item?.ReplacementSerial,
            loaner_serial_id: item?.loanerAedId,
            loaner_serial_name: item?.loanerSerial,
            loaner_toggle: item?.loaner_toggle,
            loaner_serial: item?.loaner_serial,
          },
        ],

        replacement_aed_toggle: item?.ReplacingAeds,
        servicing_notes: item.servicing_notes,
        replacement_aed_id: item?.ReplacementAedId,
        replacement_aed_serial: item?.ReplacementSerial,

        questions_id: serviceQuestionData.questions_id,
        inspection_by: serviceQuestionData.inspection_by,
        inspection_date: serviceQuestionData.inspection_date,
        contact_id: serviceQuestionData.contact_id,
        useSbidInventory: item?.useSbidInventory || [],
        useSpidInventory: item?.useSpidInventory || [],
      };
      finalObject.push(obj);
    });
    return finalObject;
  };

  const isPresentErrorFunc = (inputValue, index, name) => {
    var error = false;
    const Fd = [...newFormData];
    if (Fd[index].AedReadyStatusToggle === 0 && Fd[index][name] === "") {
      Fd[index].isPresentError = 1;
      error = true;
    }
    setNewFormData(Fd);
    return error;
  };

  const dispatch = useDispatch();

  const calculateBatteryDates = (formData) => {
    return formData.map(aed => ({
      ...aed,
      battery_info: aed.battery_info.map(battery => {
        if (battery.type === "main") {
          const updatedBattery = { ...battery };

          // Calculate battery expiration based on install_date and lifespan
          if (battery.install_date && batteryList[0]?.lifespan) {
            updatedBattery.battery_expiration = moment(battery.install_date)
              .add(batteryList[0].lifespan, 'years')
              .toDate();
          }

          // Calculate 9V expiry if 9V install date exists
          if (battery.install_9v_date) {
            updatedBattery.v9_expiry_date = moment(battery.install_9v_date)
              .add(1, 'year')
              .toDate();
          }

          return updatedBattery;
        }
        return battery;
      })
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const FD = finalFormaData();
  //   const SendObj = { allAeds: FD };
  //   printConsole({ SendObj });
  //   const result = await CallPOSTAPI(
  //     "account/save-aed-modification-data-v2/",
  //     SendObj
  //   );
  //   if (result?.status) {
  //     dispatch(selecteTab("Equipment"));
  //     toast.success("Service Modification Added  Successfully!");
  //     navigate(`/account-details/${newFormData[0]?.account_id}`);
  //   } else {
  //     toast.error(result?.msg);
  //   }
  //   setLoading(false);
  // };
  const handleRemoveserviceDispatch = () => {
    const editPageUrl = "/account/aed/service-check/" + newFormData?.[0]?.account_id + "/" + newFormData?.[0]?.site_id
    dispatch(removeBreadcrumb(editPageUrl));

  }

  const handleRemoveDispatch = () => {
    const url = `/account/aed/service-check/service2/${serviceQuestionID}`
    printConsole(url)  // print console.log
    dispatch(removeBreadcrumb(url))
  }
  const handleRemove1Dispatch = () => {
    printConsole({ newFormData })  // print console.log
    const url1 = `/account/aed/service-check/service1/${newFormData?.[0]?.account_id}/${newFormData?.[0]?.site_id}/aedId?aedId=${newFormData?.[0]?.aed_id}`
    printConsole(url1)  // print console.log
    dispatch(removeBreadcrumb(url1))

  }
  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return false;
    } else {
      setValidated(false);
    }
    e.preventDefault();
    setLoading(true);
    setFormSubmitted(true);

    const FD = finalFormaData();
    printConsole({ finalformadatasubmit: FD })  // print console.log
    const updatedFormData = calculateBatteryDates(FD);
    console.log({ updatedFormData })

    const SendObj = { allAeds: updatedFormData };
    const result = await CallPOSTAPI(
      "aed/save-aed-modification-data",
      SendObj
    );
    if (result?.status) {
      dispatch(selecteTab("Equipment"));
      toast.success("Service Modification Added  Successfully!");
      if (user?.user_type === 3) {
        handleRemoveserviceDispatch()
        handleRemove1Dispatch()
        handleRemoveDispatch()
        // return ""
        navigate(`/account-details/${newFormData[0]?.account_id}/Equipment`);
      } else {
        if (navigateAedDetails) {
          handleRemoveserviceDispatch()
          handleRemove1Dispatch()
          handleRemoveDispatch()
          // return ""
          navigate(`/account/aed-details/${aed_id}/Equipment`);
        } else {
          handleRemoveserviceDispatch()
          handleRemove1Dispatch()
          handleRemoveDispatch()
          // return ""
          navigate(`/account-details/${newFormData[0]?.account_id}/Equipment`);
        }
      }
    } else {
      toast.error(result?.data?.msg);
    }
    setLoading(false);
  };

  const print_battery_part = (bid) => {
    let findName = batteryTypeList.find(
      (item) => parseInt(item?.battery_type_id) === parseInt(bid)
    );
    return findName?.battery_part_number || bid;
  };

  const alarmbatteryToggleChange = (index) => {
    let currentDate = new Date();
    const fd = [...newFormData];
    let storage_info_array;

    if (typeof fd[index].storage_info === "string") {
      storage_info_array = JSON.parse(fd[index].storage_info);
      if (typeof storage_info_array === "string") {
        storage_info_array = JSON.parse(storage_info_array)
      }
    } else {
      storage_info_array = fd[index].storage_info;
    }

    if (storage_info_array[0].storage_type === "") {
      storage_info_array[0].alarmed = 1;
      storage_info_array[0].alarm_status = 1;
      storage_info_array[0].storage_type = 1;
    }

    storage_info_array[0].v9_Installed_Date = FormatDate(currentDate);
    let expiryDate = new Date(currentDate);
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);
    storage_info_array[0].expiry_date = FormatDate(expiryDate);

    fd[index].storage_info = storage_info_array;

    setNewFormData(fd);
  };

  const handleToggleChange = (type, is_checked, index) => {
    const fd = [...newFormData];
    fd[index].isPresentError = 0;
    fd[index][type] = is_checked ? 1 : 0;
    setNewFormData(fd);

    if (type === "AlarmBatteryToggle") {
      alarmbatteryToggleChange(index);
    }
  };

  const filterData = (arr, key, value) => {
    if (!arr) {
      return "";
    }
    const data = arr.find((a) => a[key] == value);
    return data;
  };

  const renderBrandName = (aed_brand_id, brandList) => {
    const filteredBrand = filterData(brandList.data, "id", aed_brand_id);
    return filteredBrand?.AED_brands;
  };
  const default_conditoions = {
    auto: 0,
    brand_id: 0,
    created_by_id: null,
    created_date: "2023-05-04T19:51:56.000Z",
    discontinued: 0,
    display: 0,
    has_9v: 0,
    has_10pk: 0,
    has_battery: 0,
    has_builtin_rms: 0,
    has_chargepak: 0,
    has_gateway: 0,
    has_installby: 0,
    has_man: 0,
    has_pad: 0,
    has_padpak: 0,
    has_ped_key: 0,
    has_ped_pad: 0,
    has_pedpak: 0,
    id: 0,
    image_file_name: "",
    model_name: "",
    model_partnumber: null,
    modified_by_id: null,
    modified_date: null,
    semi: 0,
  };
  const handleBrandChange = async (bId, ModelId) => {
    try {
      if (!bId) {
        return "";  // Return early if bId is falsy
      }

      // Fetch the models based on brand ID
      const ModelRes = await GetAedModelsByBrandId(bId);
      const modelResult = ModelRes?.data || [];

      // Set default conditions initially
      setPermission(default_conditoions);

      if (!ModelId) {
        return "";  // Return early if ModelId is falsy
      }

      // Find the model by ModelId in the fetched models
      const partDetails = modelResult.find(
        (item) => parseInt(item.id) === parseInt(ModelId)
      );

      // Set the permission based on the partDetails, if found
      if (partDetails) {
        setPermission(partDetails);
      } else {
        setPermission(default_conditoions);  // Set default if no match is found
      }
    } catch (error) {
      console.error("Error fetching models or setting permissions:", error);
    }
  };

  return (
    <>
      {/* {loading && <div className="showloading"> <Loading /> </div>} */}

      <div
        className="mt-6 table-main-20002"
        style={{ width: "100%" }}
      >
        <div style={{ marginLeft: "34px" }}>
          <FinalNewBreadcrumbs />
        </div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <SingleModiAed
            handleBrandChange={handleBrandChange}
            permission={permission}
            fetchBatteryModel={fetchBatteryModel}
            brandList={brandList}
            AedFormData={newFormData}
            serviceQuestionData={serviceQuestionData}
            handleToggleChange={handleToggleChange}
            renderBrandName={renderBrandName}
            setNewFormData={setNewFormData}
            print_battery_part={print_battery_part}
            padList={padList}
            setPresentError={setPresentError}
            presentError={presentError}
            setIsPresentText={setIsPresentText}
            isPresentText={isPresentText}
            contact_id={contact_id}
            inspection_by={inspection_by}
            serialNumbersData={serialNumbersData}
            isPresentErrorFunc={isPresentErrorFunc}
            isInventory={isInventory}
            accountId={newFormData[0]?.account_id}
            siteId={newFormData[0]?.site_id}
          />

          <div
            className="row pb-3 py-4"
            style={{ marginBottom: "6%", marginRight: "1%" }}
          >
            <div className="col-12 content-flex-right">
              <button
                className="btn text-uppercase cancel-button"
                type="button"
                onClick={() => {
                  handleRemoveDispatch()
                  navigate(-1)
                }}
              >
                Cancel
              </button>
              <button
                className="btn text-uppercase ms-4 submit-button"
                type="submit"
              //disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </Form>
      </div>

    </>
  );
};

export default AedServiceCheckService2;
