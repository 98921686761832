import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { Button, Form } from "react-bootstrap";
import { usePagination, useSortBy, useTable } from "react-table";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { BASE_API } from "../../services";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Skeleton } from "@mui/material";

const cache = new Map();

const NewGlobalTable = ({
  tableData = [],
  payloadData = null,
  apiEndPoint, // Api End Point
  keyAPiName = null, // KeyName for Nested data
  pageSize = 50, // Shows number of records on one page
  tokenKey = "ross_token", // Token for Authorized api calls
  headerColumns = [], // Columns
  method = "POST", // Api method
  isSearchable = false,
  isCache = false,
  onDataFetched,
  customRenderData = null,
  isTblUpdated = false,
}) => {
  const prevPayloadData = useRef(payloadData);
  const prevApiEndPoint = useRef(apiEndPoint);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearchReset, setShowSearchReset] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Function to calculate column width based on header length
  const calculateWidth = (header) => `${header.length * 15 + 20}px`;

  // Dynamically fetching Columns
  const columns = useMemo(() => {
    return headerColumns.map((column) => ({
      ...column,
      width: column.width || calculateWidth(column.Header),
    }));
  }, [headerColumns]);

  // Clear the relevant cache entries for the current endpoint on render page.
  useEffect(() => {
    if (isCache || apiEndPoint !== prevApiEndPoint.current) {
      cache.clear();
      prevApiEndPoint.current = apiEndPoint;
    }
  }, [isCache, apiEndPoint]);

  // function for fetch data from api
  const fetchData = useCallback(
    async (searchTerm = "", page = 1) => {
      // Clear the cache when the API endpoint changes
      if (apiEndPoint !== prevApiEndPoint.current) {
        cache.clear();
        prevApiEndPoint.current = apiEndPoint;
      }

      // Checks already cached page
      const cacheKey = `${apiEndPoint}-${searchTerm}-${page}-${JSON.stringify(
        prevPayloadData.current
      )}`;
      if (isCache && cache.has(cacheKey)) {
        const cachedData = cache.get(cacheKey);
        setData(cachedData.data);
        setTotalPages(cachedData.totalPages);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError("");
      try {
        const token = localStorage.getItem(tokenKey);
        if (!token) throw new Error("No Token found");

        let payload = {
          ...prevPayloadData.current,
          page,
          search: searchTerm,
          pageSize,
        };

        let options = {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        if (method === "POST" && payload) {
          options.body = JSON.stringify(payload);
        }
        let SearchQuqery = "";
        if (method === "GET" && searchTerm) {
          SearchQuqery = `&search=` + searchTerm;
        }

        let response = await fetch(
          `${BASE_API}${apiEndPoint}?page=${page}&pageSize=${pageSize}${SearchQuqery}`,
          options
        );
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        const resultData = await response.json();

        let newData = keyAPiName
          ? resultData?.data?.[keyAPiName] || []
          : resultData?.data;
        let totalPages = resultData?.data?.totalPages || 0;

        if (customRenderData) {
          newData = await customRenderData(newData);
        }

        // Cache the data
        if (newData || totalPages === 0) {
          setData(newData);
          setTotalPages(totalPages);
          if (isCache) {
            cache.set(cacheKey, { data: newData, totalPages });
          }
        }

        if (onDataFetched) {
          onDataFetched(newData);
        }
      } catch (error) {
        setError(error.message);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiEndPoint,
      tokenKey,
      keyAPiName,
      method,
      pageSize,
      isCache,
      prevPayloadData.current,
      onDataFetched,
      customRenderData,
    ]
  );

  // Reset page and fetch data
  useEffect(() => {
    if (
      JSON.stringify(prevPayloadData.current) !== JSON.stringify(payloadData)
    ) {
      prevPayloadData.current = payloadData;
      setCurrentPage(1); // Reset page to 1 when filters change
    } else {
      const searchTerm = search && showSearchReset ? search : "";
      fetchData(searchTerm.trimEnd(), currentPage);
    }
  }, [payloadData, currentPage, fetchData]);
  // search, showSearchReset
  useEffect(() => {
    if (isTblUpdated) {
      // Clear the entire cache
      handleReset();
      // cache.clear();
      // // Reset the current page to 1
      // setCurrentPage(1);
      // // Fetch data again
      // fetchData("", 1);
    }
  }, [isTblUpdated, fetchData]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data: data || [],
        initialState: { pageIndex: 0, pageSize: pageSize },
      },
      useSortBy,
      usePagination
    );

  // Handles page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handles input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // Handles searching
  const handleSearch = useCallback(
    (e) => {
      e.preventDefault();
      if (search?.trim().length > 0) {
        setShowSearchReset(true);
        setCurrentPage(1);
        fetchData(search.trimEnd(), 1);
      }
    },
    [fetchData, search]
  );

  // Handles reset search
  const handleReset = useCallback(() => {
    // Clear the relevant cache entries for the current endpoint
    [...cache.keys()].forEach((key) => {
      if (key.startsWith(`${apiEndPoint}-`)) {
        cache.delete(key);
      }
    });
    setSearch("");
    setShowSearchReset(false);
    setCurrentPage(1);
    fetchData("", 1);
  }, [fetchData, apiEndPoint]);

  return (
    <div className="bg-light base-table">
      {isSearchable && (
        <Form onSubmit={handleSearch}>
          <div className="" style={{ display: "flex", gap: 5 }}>
            <input
              name="search"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by keywords..."
              className="form-control w-25"
            />
            <Button variant="primary" type="submit">
              Search
            </Button>

            {showSearchReset && (
              <Button onClick={handleReset} variant="danger" type="button">
                Reset
              </Button>
            )}
          </div>
        </Form>
      )}

      {error && <div className="alert alert-danger">{error}</div>}

      <div className="table-responsive">
        <table
          {...getTableProps()}
          className="table table-bordered table-hover"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <td
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      width: column.width,
                      backgroundColor: "#999",
                    }}
                    className="tb-td"
                  >
                    <div className="d-flex align-items-center th-d">
                      {column.render("Header")}
                      <span className="ml-1">
                        {!column.disableSortBy && // Only show icon if sorting is not disabled
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <SouthIcon size={2} />
                            ) : (
                              <NorthIcon size={2} />
                            )
                          ) : (
                            <SouthIcon size={2} /> // Default icon if not sorted
                          ))}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              [...Array(15)].map((_, i) => (
                <tr key={i}>
                  {columns.map((col, j) => (
                    <td
                      key={j}
                      style={{
                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                      }}
                      className="tb-td"
                    >
                      <Skeleton
                        width={"90%"}
                        height={20}
                        style={{ margin: "10px" }}
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : data?.length === 0 && !isLoading ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              page?.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                        }}
                        className="tb-td"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateBefore />
                </button>
              </li>
              {currentPage > 3 && (
                <>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                </>
              )}
              {[...Array(5)].map((_, idx) => {
                const pageNumber = currentPage - 2 + idx;
                return pageNumber > 0 && pageNumber <= totalPages ? (
                  <li key={pageNumber} className="page-item">
                    <button
                      className={`btn mx-1 ${
                        currentPage === pageNumber ? "btn-primary" : "btn-light"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ) : null;
              })}
              {currentPage < totalPages - 2 && (
                <>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                </>
              )}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateNext />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default NewGlobalTable;
