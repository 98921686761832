import React from "react";
import "../../../global.css";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { addItem } from "../../../redux/slices/BreadCrumbsSlice";
import Loading from "../../../common/components/Loader/Loading";
import { setContactActiveTab } from "../../sitesManagments/slices/siteSlice";
import { DecryptToken, GetProfile } from "../../../common/helper";
import { isContactPermission } from "../../../common/helper/permission";
import {
  AccountContactTbWithPermission,
  AccountContactsTab,
} from "../../../common/hooks";
import { GetAccountContactList, getPer } from "../../../common/helper/BasicFn";
import { CallGETAPI } from "../../../common/services";
import ContactsTab from "../components/ContactsTab";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import { setEditContactDetails } from "../../../store/slices/TabSlice";
import SupportSymbol from "../../Support/Component/SupportSymbol";

const ContactDetails = (
  {
    //  handleSetToken
  }
) => {
  const [currentTab, setCurrentTab] = useState(AccountContactsTab.Details);
  const [showLoading, setShowLoading] = React.useState(true);
  const [accountContactList, setAccountContactList] = useState({});
  const [pageTitle, setPageTitle] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { contactId, accountId } = useParams();
  const [showBtnTab, setShowBtnTab] = useState(false);
  const [clickable, setClickable] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [contactInfoDetails, setContactInfoDetails] = useState({});

  const [filteredTabItems, setfilteredTabItems] = useState(
    AccountContactTbWithPermission
  );

  let privileges = location?.state?.privileges;
  let is_user = location?.state?.is_user;

  const dispatch = useDispatch();
  const handleTab = (item) => {
    if (clickable) {
      dispatch(setContactActiveTab(item));
      setCurrentTab(item);
    }
  };

  const user = DecryptToken();

  const fetchOnLoad = async () => {
    var data = await GetAccountContactList(contactId);
    if (data) {
      setAccountContactList(data?.data?.data);
      const contactData = data?.data?.data;

      setContactInfoDetails({
        contact_details: {
          account_id: contactData?.contact_details?.account_id,
          account_main_contact_salutation:
            contactData?.contact_details?.account_main_contact_salutation,
          account_main_contact_firstname:
            contactData?.contact_details?.account_main_contact_firstname,
          account_main_contact_middlename:
            contactData?.contact_details?.account_main_contact_middlename,
          account_main_contact_lastname:
            contactData?.contact_details?.account_main_contact_lastname,
          account_main_contact_suffix:
            contactData?.contact_details?.account_main_contact_suffix,
          account_main_contact_title:
            contactData?.contact_details?.account_main_contact_title || "",
          account_main_contact_department:
            contactData?.contact_details?.account_main_contact_department,
          contact_status_id: contactData?.contact_details?.contact_status_id,
          contact_status_name:
            contactData?.contact_details?.contact_status_name,
          contact_address1: contactData?.contact_details?.contact_address1,
          contact_address2: contactData?.contact_details?.contact_address2,
          contact_city: contactData?.contact_details?.contact_city,
          contact_state: contactData?.contact_details?.contact_state,
          contact_state_name: contactData?.contact_details?.contact_state_name,
          contact_country: contactData?.contact_details?.contact_country,
          contact_country_name:
            contactData?.contact_details?.contact_country_name,
          contact_zipcode: contactData?.contact_details?.contact_zipcode,
          training_optout: contactData?.contact_details?.training_optout,
        },
        phone_numbers: contactData?.phone_numbers,
        emails: contactData?.emails,
        held_position: contactData?.held_position,
      });

      const FTT =
        contactData?.instructorDetails?.length < 1
          ? AccountContactTbWithPermission.filter(
              (tabItem) => tabItem.title !== "Instructor"
            )
          : AccountContactTbWithPermission;
      setfilteredTabItems(FTT);
      var title = "";
      title += contactData?.contact_details?.account_main_contact_salutation
        ? " " + contactData?.contact_details?.account_main_contact_salutation
        : "";
      title += contactData?.contact_details?.account_main_contact_firstname
        ? " " + contactData?.contact_details?.account_main_contact_firstname
        : "";
      title += contactData?.contact_details?.account_main_contact_middlename
        ? " " + contactData?.contact_details?.account_main_contact_middlename
        : "";
      title += contactData?.contact_details?.account_main_contact_lastname
        ? " " + contactData?.contact_details?.account_main_contact_lastname
        : "";
      title += contactData?.contact_details?.account_main_contact_suffix
        ? " " + contactData?.contact_details?.account_main_contact_suffix
        : "";

      setPageTitle(title);

      if (data?.data?.data?.instructorDetails?.length > 0) {
        setShowBtnTab(true);
      }
      // else {
      // let key = "Instructor";
      // delete AccountContactsTab[key];
      // setShowBtnTab(false);
      // }
      // setfilteredTabItems
      setShowLoading(false);
    }
  };

  useEffect(() => {
    // handleSetToken();
    fetchOnLoad();

    const profile = GetProfile();

    if (profile?.user_type == 1) {
      setClickable(false);
    }
  }, []);
  const activeSiteTab = useSelector(
    (state) => state?.TAB_manager?.contactActiveTab
  );
  useEffect(() => {
    if (activeSiteTab) {
      setCurrentTab(activeSiteTab);
    } else if (location?.state?.tab) {
      setCurrentTab(location?.state?.tab);
    }
  }, [location]);

  const supportRedirect = () => {
    const mainAccountId = accountContactList?.contact_details?.account_id || 0;
    const stateData = {
      type: "Contact",
      site_id: 0,
      accountId: accountContactList?.contact_details?.account_id || 0,
      contactId: contactId,
      accountName: "",
      support_name:
        accountContactList?.contact_details?.account_main_contact_firstname +
        " " +
        accountContactList?.contact_details?.account_main_contact_lastname,
    };

    navigate("/account/new-support/" + mainAccountId, { state: stateData });
  };

  const notesRedirect = () => {
    const accountId = accountContactList?.contact_details?.account_id || 0;

    navigate(
      `/account/new-note?account_id=${accountId}&contact_id=${contactId}`
    );
  };

  const [documentData, setDocumentData] = useState(null);

  const payload = {
    contact_id: contactId,
    account_id: "0",
  };

  // const getDocumentsData = async () => {
  //   const params = new URLSearchParams(payload); // Convert payload to URL parameters
  //   const url = `get-all-contact-documents/${contactId}?${params}`; // Append parameters to the URL
  //   const response = await CallGETAPI(url);
  //   // const response = await CallGETAPI('get-all-contact-documents/' +contactId, payload);

  //   if (response?.status) {
  //     setDocumentData(response.data.data);
  //   }
  // };

  // useEffect(() => {
  //   getDocumentsData();
  // }, []);

  const documentRedirect = () => {
    navigate("/account-document-upload", {
      state: {
        accountId: accountId,
        type: "contact",
        contactId,
      },
    });
  };

  //   useEffect(() => {
  //     const state = location?.state;
  //     const type = state ? state?.type : null;
  //     dispatch(
  //       addItem({ title: type, path: location?.pathname, tab: currentTab })
  //     );
  //   }, [currentTab]);

  const breadcrumbs = useSelector((state) => state.BreadCrumbs.items);

  const [tabTbldata, setTabTbldata] = useState({
    site: false,
    contact: false,
    equipment: false,
    note: false,
    support: false,
  });

  const handleHoverFloating = () => {
    setIsOpen(true);
  };

  const handleLeaveFloating = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (contactInfoDetails && Object.keys(contactInfoDetails).length > 0) {
      dispatch(setEditContactDetails(contactInfoDetails));
    }
  }, [contactInfoDetails]);

  return (
    <>
      {/* loading */}
      {/* {showLoading ? (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      ) : (
        <> */}
      <div className="mt-4" style={{ width: "100%" }}>
        {/* top heading */}
        <SubHeadingOther
          title={"Contact: " + pageTitle}
          hideNew={true}
          editUrl={"/account/contact-details-edit/" + contactId}
          contactInfoDetails={contactInfoDetails}
          hideHierarchy={true}
          hideInstructor={showBtnTab}
          subHeading={true}
          bottomLinks={true}
          account={accountContactList?.contact_details?.account_id}
          support_type="Contact"
          support_name={
            accountContactList?.contact_details
              ?.account_main_contact_firstname +
            " " +
            accountContactList?.contact_details?.account_main_contact_lastname
          }
          site_id={0}
          account_id={accountContactList?.contact_details?.account_id}
          privileges={privileges}
          is_user={is_user}
          breadcrumbs={breadcrumbs}
          exportReport={true}
          currentTab={currentTab}
          tabTbldata={tabTbldata}
        />

        {/* tabs */}
        <Box className="">
          <div className="d-flex border-bottom border-secondary">
            {/* {filteredTabItems.map(
                  (tabItem, i) =>
                    isContactPermission(tabItem.permission) && (
                      <div
                        role="button"
                        key={i}
                        className={"text-light py-2 px-3"}
                        style={{
                          backgroundColor: `${
                            tabItem.title == currentTab ? "#26AEE0" : "#0C71C3"
                          }`,
                        }}
                        onClick={() => handleTab(tabItem.title)}
                      >
                        {tabItem.title == "Instructor" &&
                        accountContactList?.instructorDetails.length < 1
                          ? ""
                          : tabItem.title}
                      </div>
                    )
                )} */}

            <ContactsTab
              newSupportUrl={`/account/new-support/?accountId=${accountContactList?.contact_details?.account_id}&contactId=${contactId}sectionName=contact`}
              // support_api={"support/ticket-by-contact/" + contactId}
              support_api={
                Number(getPer()) === 1
                  ? "support/ticket-by-contact/" + contactId
                  : "user/support-list-for-contact/" + contactId
              }
              name={
                accountContactList?.contact_details
                  ?.account_main_contact_salutation +
                " " +
                accountContactList?.contact_details
                  ?.account_main_contact_firstname +
                " " +
                accountContactList?.contact_details
                  ?.account_main_contact_middlename +
                " " +
                accountContactList?.contact_details
                  ?.account_main_contact_lastname +
                " " +
                accountContactList?.contact_details?.account_main_contact_suffix
              }
              account_id={accountId}
              setTabTbldata={setTabTbldata}
              stateData={{
                type: "contact",
                site_id: 0,
                accountId: accountContactList?.contact_details?.account_id || 0,
                contactId: contactId,
                accountName: "",
                support_name:
                  accountContactList?.contact_details
                    ?.account_main_contact_firstname +
                  " " +
                  accountContactList?.contact_details
                    ?.account_main_contact_lastname,
              }}
            />
          </div>
        </Box>
        <SupportSymbol
          newSupportUrl={`/account/new-note?account_id=${accountContactList?.contact_details?.account_id}&contact_id=${contactId}`}
          note_api={`/account/new-note?account_id=${accountContactList?.contact_details?.account_id}&contact_id=${contactId}`}
          document_url={`/account/${accountContactList?.contact_details?.account_id}/contact-details/${contactId}/Documents`}
          stateData={{
            type: "CONTACT",
            site_id: 0,
            accountId: accountContactList?.contact_details?.account_id || 0,
            contactId: contactId,
            accountName: "",
            support_name:
              accountContactList?.contact_details
                ?.account_main_contact_firstname +
              " " +
              accountContactList?.contact_details
                ?.account_main_contact_lastname,
          }}
        />
        {/* Details */}
        {/* {currentTab === AccountContactsTab.Details && <Details />} */}

        {/* Classes */}
        {/* {currentTab === AccountContactsTab.Classes && <Classes />} */}

        {/* Instructor */}
        {/* {currentTab === AccountContactsTab.Instructor && (
              <Instructor
                InstructorData={accountContactList?.instructorDetails[0] || []}
                ahaDetails={accountContactList?.ahaDetails[0] || []}
                hsiDetails={accountContactList?.hsiDetails[0] || []}
              />
            )} */}

        {/* DOCUMENTS */}
        {/* {currentTab === AccountContactsTab.Documents && (
              <Documents
                accountId={accountId}
                contact_id={contactId}
                type={"Contact"}
                // siteName={siteData?.account_site_name}
                documentData={documentData}
              />
            )} */}

        {/* SUPPORT */}
        {/* {currentTab === AccountContactsTab.Support && (
              <Support
                stateData={{
                  type: "Contact",
                  site_id: 0,
                  accountId:
                    accountContactList?.contact_details?.account_id || 0,
                  contactId: contactId,
                  accountName: "",
                  support_name:
                    accountContactList?.contact_details
                      ?.account_main_contact_firstname +
                    " " +
                    accountContactList?.contact_details
                      ?.account_main_contact_lastname,
                }}
                setTabTbldata={setTabTbldata}
              />
            )} */}

        {/* Notes */}
        {/* {currentTab === AccountContactsTab.Notes && (
              <Notes
                accountId={accountContactList?.contact_details?.account_id}
                type="CONTACT"
                contact_id={contactId}
                setTabTbldata={setTabTbldata}
              />
            )} */}

        {/* Emails */}
        {/* {currentTab === AccountContactsTab.Emails && <Emails />} */}

        {/* rfi */}
        {/* {currentTab === AccountContactsTab.RFI && (
              <RFI
                accountId={accountContactList?.contact_details?.account_id}
              />
            )} */}
      </div>
      {/* <div
            className="floating-menu-btn d-flex flex-column gap-2"
            onMouseEnter={handleHoverFloating}
            onMouseLeave={handleLeaveFloating}
          >
            {isOpen && (
              <>
                {(isSubAdminPermission("new-document") === 1 ||
                  isUserPermission("new-document") === 1) && (
                  <img
                    src="/NewDocument.svg"
                    width={60}
                    height={60}
                    style={{
                      padding: "2px",
                      borderRadius: "50%",
                      borderColor: "#0c71c3",
                      borderWidth: "3px",
                      borderStyle: "solid",
                    }}
                    className="pointer bg-white"
                    onClick={documentRedirect}
                  />
                )}

                {(isSubAdminPermission("new-support") === 1 ||
                  isUserPermission("new-support") === 1) && (
                  <img
                    src="/NewSupport.svg"
                    width={60}
                    height={60}
                    style={{
                      padding: "2px",
                      borderRadius: "50%",
                      borderColor: "#0c71c3",
                      borderWidth: "3px",
                      borderStyle: "solid",
                    }}
                    className="pointer bg-white"
                    onClick={supportRedirect}
                  />
                )}

                {(isSubAdminPermission("new-note") === 1 ||
                  isUserPermission("new-note") === 1) && (
                  <img
                    src="/NewNote.svg"
                    width={60}
                    height={60}
                    style={{
                      padding: "2px",
                      borderRadius: "50%",
                      borderColor: "#0c71c3",
                      borderWidth: "3px",
                      borderStyle: "solid",
                    }}
                    className="pointer bg-white"
                    onClick={notesRedirect}
                  />
                )}
              </>
            )}

            <img
              src="/Plus.svg"
              width={60}
              height={60}
              style={{
                padding: "2px",
                borderRadius: "50%",
                borderColor: "#0c71c3",
                borderWidth: "3px",
                borderStyle: "solid",
              }}
              className="pointer bg-white"
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            />
          </div> */}
      {/* </>
      )} */}
    </>
  );
};

export default ContactDetails;
