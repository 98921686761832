import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { Button, Form } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const ResponseTable = ({
  tableData = [],
  headerColumns,
  rowsPerPage = 50, // Default rows per page
  isSearchable = false,
  setUpdatedData,
}) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearchReset, setShowSearchReset] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      if (tableData?.length > 0) {
        setData(tableData);
        setUpdatedData(tableData);
        setTotalPages(Math.ceil(tableData.length / rowsPerPage));
        setEditedData({});
      }
      setIsLoading(false);
    }, 1000);
  }, [tableData, rowsPerPage]);

  const handleEdit = (rowIndex, columnId, value) => {
    const updatedData = [...data];
    updatedData[rowIndex][columnId] = value;
    setData(updatedData);

    // Track changes for saving
    setEditedData((prev) => ({
      ...prev,
      [rowIndex]: { ...prev[rowIndex], [columnId]: value },
    }));
  };

  // Update setUpdatedData only if there are any changes in the editedData
  useEffect(() => {
    if (Object.keys(editedData).length > 0) {
      setUpdatedData(data);
    }
  }, [editedData, data, setUpdatedData]);

  const calculateWidth = (header) => `${header.length * 15 + 20}px`;

  // Dynamically fetching Columns
  const columns = useMemo(() => {
    return headerColumns.map((column) => ({
      ...column,
      width: column.width || calculateWidth(column.Header),
    }));
  }, [headerColumns]);

  // Get paginated data
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    return data.slice(startIndex, startIndex + rowsPerPage);
  }, [data, currentPage, rowsPerPage]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: paginatedData,
      },
      useFilters,
      useSortBy
    );

  // const totalPages = Math.ceil(data.length / rowsPerPage);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    setIsLoading(true);
    
    if (search?.trim().length > 0) {
      setTimeout(() => {
        const filteredData = tableData.filter((item) =>
          Object.values(item).some((value) =>
            value
              ? value.toString().toLowerCase().includes(search.toLowerCase())
              : ""
          )
        );

        setShowSearchReset(true);
        setData(filteredData);
        setCurrentPage(1);
        setTotalPages(Math.ceil(filteredData.length / rowsPerPage));

        setIsLoading(false);
      }, 500);
    }
  }, [search]);

  const handleReset = () => {
    setIsLoading(true);
    setSearch("");
    setShowSearchReset(false);

    setTimeout(() => {
      setData(tableData);
      setTotalPages(Math.ceil(tableData.length / rowsPerPage));
      setCurrentPage(1);
      setIsLoading(false);
    }, 500);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="bg-light base-table">
      {/* Search Bar */}
      {isSearchable && (
        <Form onSubmit={handleSearch}>
          <div className="" style={{ display: "flex", gap: 5 }}>
            <input
              name="search"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by keywords..."
              className="form-control w-25"
            />
            <Button variant="primary" type="submit">
              Search
            </Button>

            {showSearchReset && (
              <Button onClick={handleReset} variant="danger" type="button">
                Reset
              </Button>
            )}
          </div>
        </Form>
      )}

      <div className="table-responsive">
        <table
          className="table table-bordered table-hover"
          {...getTableProps()}
          style={{ borderCollapse: "collapse", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      padding: "8px",
                      backgroundColor: "#999",
                    }}
                    className="tb-td"
                  >
                    {column.render("Header")}
                    <span>
                      {!column.disableSortBy &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <SouthIcon size={2} />
                          ) : (
                            <NorthIcon size={2} />
                          )
                        ) : (
                          <SouthIcon size={2} />
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              [...Array(15)].map((_, i) => (
                <tr key={i}>
                  {headerColumns.map((col, j) => (
                    <td
                      key={j}
                      style={{
                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                      }}
                      className="tb-td"
                    >
                      <Skeleton
                        width={"90%"}
                        height={20}
                        style={{ margin: "10px" }}
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : data?.length === 0 && !isLoading ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={rowIndex}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "white" : "#e4e4e4",
                          position: "relative",
                        }}
                        className="tb-td"
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => {
                          const newValue = e.target.textContent;
                          if (newValue !== data[rowIndex][cell.column.id]) {
                            handleEdit(rowIndex, cell.column.id, newValue);
                          }
                        }}
                      >
                        {data[rowIndex][cell.column.id]}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div
          className="mt-4 d-flex justify-content-end align-items-center"
          style={{ marginBottom: "20px" }}
        >
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateBefore />
                </button>
              </li>
              {currentPage > 3 && (
                <>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                </>
              )}
              {[...Array(5)].map((_, idx) => {
                const pageNumber = currentPage - 2 + idx;
                return pageNumber > 0 && pageNumber <= totalPages ? (
                  <li key={pageNumber} className="page-item">
                    <button
                      className={`btn mx-1 ${
                        currentPage === pageNumber ? "btn-primary" : "btn-light"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ) : null;
              })}
              {currentPage < totalPages - 2 && (
                <>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                </>
              )}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateNext />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default ResponseTable;
