import { Box, Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
// import SubHeadingOther from "../../../components/header/SubHeadingOther";
// import DataGrid, {
//     Scrolling,
//     Paging,
//     Column,
// } from "devextreme-react/data-grid";
import Container from "react-bootstrap/Container";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
// import { CallGETAPI } from "../../../helper/API";
// import { FormatDateWithTime, GetProfile, getPermission } from "../../../helper/Common";
// import Loading from "../Loading";
// import { addItem } from "../../../redux/slices/BreadCrumbsSlice";
import { useDispatch, useSelector } from "react-redux";
import { CallGETAPI } from "../../../common/services";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import {
  convertToUserTime,
  FormatDateWithTime,
  GetProfile,
  getPermission,
} from "../../../common/helper/Common";
import Loading from "../../../common/components/Loader/Loading";
import { DecryptToken } from "../../../common/helper";
import Edit from "../../../common/img/Edit.png";
import Delete from "../../../common/img/Delete.png";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { isPermission } from "../../../common/helper/PermissionManager";
export default function NoteDetails() {
  const { noteId } = useParams();
  const navigate = useNavigate();
  const [NotesData, setNotesData] = React.useState([]);
  const [notes, setNotes] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();

  // useEffect(() => {
  //     dispatch(addItem({ title: 'Notes', path: location?.pathname, tab: "" }));
  // }, [])

  // const breadcrumbs = useSelector(state => state.BreadCrumbs.items); // Accessing breadcrumbs from Redux store state
  const handleDispatch = () => {
    const BreadCrumbsObject = {
      title: "",
    };
    BreadCrumbsObject.title = "Note Details Edit";
    // BreadCrumbsObject.tab = "Details";
    // BreadCrumbsObject.type = "contact";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "account/edit-note/" + noteId,
      })
    );
  };

  const handleNotesChange = (event) => {
    const newNotes = event.target.value;
    setNotes(newNotes);

    if (newNotes.length > 1500) {
      setErrorMessage("Notes cannot exceed 1500 characters.");
    } else {
      setErrorMessage("");
    }
  };

  // console.log(NotesData);
  const onLoad = async () => {
    const response = await CallGETAPI("notes/fetch_notes_details/" + noteId);
    const data = response?.data?.data || [];
    setNotesData(data);
    setLoading(false);
  };

  React.useEffect(() => {
    onLoad();
  }, []);

  // let profile = GetProfile(); //JSON.parse(localStorage.getItem("ross-profile"))
  // let account_id = profile?.account_id
  // let contact_id = profile?.contact_id

  return (
    <>
      <div className="mt-4" style={{ width: "100%", paddingInline: "0px" }}>
        <diV>
          <SubHeadingOther
            title={"Note"}
            subTitle={NotesData?.title}
            newUrl=""
            editUrl={"/account/edit-note/" + noteId}
            subHeading={true}
            hideHierarchy={true}
            hideInstructor={true}
            // breadcrumbs={breadcrumbs}
          />
        </diV>
        {/* heading */}
        <div className="row mb-2">
          {Number(isPermission({ type: "btn", name: "NoteEdit" })) === 1 ? (
            <>
              <div className="col-md-6 text-primary text-start">
                {" "}
                {/* Align Edit button to the left */}
                <button
                  className="btn text-primary"
                  type="button"
                  onClick={() => {
                    handleDispatch();
                    navigate(`/account/edit-note/${noteId}`);
                  }}
                >
                  <img src={Edit} alt="edit" style={{ marginRight: "0" }} />
                  <span className="ms-2">Edit</span>
                </button>
              </div>

              <div className="col-md-6 text-danger text-end">
                {" "}
                {/* Align Delete button to the right
                                    <img src={Delete} style={{ width: '20px', marginRight: '5px' }} alt="delete" />
                                    <span>Delete</span> */}
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        <Box className="text-left pt-1 pb-1">
          <h4 className="heading">General Information</h4>
        </Box>

        <table className="theme-table">
          <thead>
            <tr>
              {/* <td>Notes Id</td> */}
              {/* <td>Account ID</td> */}
              {/* <td>Site ID</td> */}
              {/* <td>Contact ID</td> */}
              <td>title</td>
              {/* <td>notes</td> */}
              <td>related to</td>
              {/* <td>access</td> */}
              <td>Visibility</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {/* {NotesData.map((item)=>( */}
            <tr>
              {loading ? (
                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                  <Skeleton
                    count={1}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </td>
              ) : (
                <td>{NotesData?.title}</td>
              )}
              {loading ? (
                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                  <Skeleton
                    count={1}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </td>
              ) : (
                <td>{NotesData?.related_to}</td>
              )}
              {loading ? (
                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                  <Skeleton
                    count={1}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </td>
              ) : (
                <td>{NotesData?.access}</td>
              )}
              {loading ? (
                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                  <Skeleton
                    count={1}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </td>
              ) : (
                <td>{NotesData?.active ? "Active" : "inactive"}</td>
              )}
            </tr>
            {/* ))} */}
          </tbody>
          <tbody>
            <tr className="">
              <th
                colSpan={5}
                className="border border-2 py-1 px-2 bg-tbl-border"
              >
                Notes{" "}
              </th>
            </tr>
            <tr className="" style={{ overflowWrap: "break-word" }}>
              {loading ? (
                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                  <Skeleton
                    count={1}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </td>
              ) : (
                <td
                  colSpan={7}
                  className="border border-2 px-2"
                  value={notes}
                  onChange={handleNotesChange}
                  style={{ maxWidth: "500px", overflowWrap: "break-word" }}
                >
                  {NotesData?.notes}
                  {errorMessage && (
                    <div className="error-message">{errorMessage}</div>
                  )}
                </td>
              )}
            </tr>
          </tbody>
        </table>

        <div
          className="pb-5"
          style={{
            marginTop: "96px",
            height: "35vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            className="d-flex justify-content-between align-items-end"
            style={{ marginTop: "auto" }}
          >
            <span>
              Created : {NotesData?.created_by}{" "}
              {FormatDateWithTime(convertToUserTime(NotesData?.created_date))}
            </span>

            <span>
              Modified:{" "}
              {NotesData?.modified_date || NotesData?.modified_by
                ? `${NotesData?.modified_by} ${FormatDateWithTime(
                    NotesData?.modified_date
                  )}`
                : ""}
            </span>
          </Box>
        </div>
      </div>
    </>
  );
}
