// const EditBatteryComp = ({
//   formData,
//   crrFormData,
//   BatteryList,
//   handleInputChange,
//   crrIndex,
//   is_readonly = 0,
// }) => {
//   console.log("BatteryList", formData?.model_name, crrFormData);

//   useEffect(() => {}, [formData?.model_name]);

//   return (
//     <>
//       <div className="col form-group" style={{ maxWidth: "300px" }}>
//         <label htmlFor="">
//           Battery Part # {crrFormData?.battery_part_number}
//         </label>
//         <GlobalDropdown
//           dropDownName={"battery_type_id"}
//           apiEndpoint={
//             "equipment/aed-battery-type-by-model/" + formData?.model_name
//           }
//           idKey={"battery_type_id"}
//           valueKey={"battery_part_number"}
//           parentKey={""}
//           onSelect={(e) => {
//             handleInputChange(e, crrIndex);
//           }}
//           shouldFetchOnOpen={true}
//           isCache={false}
//           disabled={is_readonly}
//           initialSelectedValue={crrFormData?.battery_type_id || ""}
//           defaultValue={{
//             id: crrFormData?.battery_type_id || "",
//             value: crrFormData?.battery_part_number || "",
//           }}
//         />
//       </div>
//     </>
//   );
// };

// export default EditBatteryComp;

// ------------------------------------------------------------

import React, { useEffect } from "react";
import GlobalDropdown from "../../../../../common/components/custom-ui/GlobalDropdown";

const EditBatteryComp = ({
  formData,
  crrFormData,
  BatteryList,
  handleInputChange,
  crrIndex,
  is_readonly = 0,
}) => {
  // Add a key prop that changes when model_name changes
  const dropdownKey = formData?.model_name || "empty";

  return (
    <>
      <div className="col form-group" style={{ maxWidth: "300px" }}>
        <label htmlFor="">Battery Part #</label>
        <GlobalDropdown
          key={dropdownKey} // Add this key prop
          dropDownName={"battery_type_id"}
          apiEndpoint={
            "equipment/aed-battery-type-by-model/" + formData?.model_name
          }
          idKey={"battery_type_id"}
          valueKey={"battery_part_number"}
          parentKey={""}
          onSelect={(e) => {
            handleInputChange(e, crrIndex);
          }}
          shouldFetchOnOpen={true}
          isCache={false}
          disabled={is_readonly}
          initialSelectedValue={crrFormData?.battery_type_id || ""}
          defaultValue={{
            id: crrFormData?.battery_type_id || "",
            value: crrFormData?.battery_part_number || "",
          }}
        />
      </div>
    </>
  );
};

export default EditBatteryComp;
