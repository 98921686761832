import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import {
  CheckADTable,
  CheckDate,
  RenderDate,
  RenderDiffDate,
  truncateText,
} from "../../../../../common/helper/BasicFn";
import { useNavigate } from "react-router-dom";
import { convertToUserTime, FormatDate } from "../../../../../common/helper/Common";
import "../../../../../global.css";
import Moment from "react-moment";
import BatteryInformationTr from "../../components/BatteryInformationTr";
import SpareBatteryinformation from "../../../AedDetails/components/SpareBatteryinformation";
import ContactName from "../../../AedDetails/components/anchorTags/ContactName";
import { CallGETAPI } from "../../../../../common/services";

const CheckAEdCheckers = (data) => {

  if (!data) {
    return 0;
  }
  let is_found = 0;
  for (let index = 0; index < data.length; index++) {
    const element = data[index];

    if (
      element?.primary ||
      element?.backup1 ||
      element?.backup2 ||
      element?.backup3
    ) {
      is_found = 1;
      break;
    }
  }
  return is_found;
};

const BatteryExpTbl = [
  {
    key: "battery_type_id",
    is_default: 1,
    title: "Battery Part #",
    is_first: 1,
  },
  {
    key: "battery_expiration",
    is_default: 0,
    title: "Expiration Date",
    is_first: 1,
  },
  { key: "battery_serial", is_default: 0, title: "Serial", is_first: 1 },

  {
    key: "install_9v_date",
    is_default: 0,
    title: "9V install Date",
    is_first: 1,
  },
  {
    key: "install_9v_date",
    is_default: 0,
    title: "9V Expiry Date",
    is_first: 2,
  },
  {
    key: "manufactured_date",
    is_default: 0,
    title: "Manufactured Date",
    is_first: 1,
  },
  {
    key: "install_date",
    is_default: 0,
    title: "Installed Date",
    is_first: 1,
  },

  { key: "battery_lot", is_default: 1, title: "Battery Lot", is_first: 1 },
  { key: "battery_udi", is_default: 1, title: "Battery UDI", is_first: 1 },

  {
    key: "install_before_date",
    is_default: 0,
    title: "Install Before Date",
    is_first: 1,
  },
];

const SpareBatteryExpTbl = [
  {
    key: "battery_type_id",
    is_default: 1,
    title: "Battery Part #",
    is_first: 1,
  },
  {
    key: "battery_expiration",
    is_default: 0,
    title: "Expiration Date",
    is_first: 1,
  },

  { key: "battery_serial", is_default: 0, title: "Serial", is_first: 1 },

  {
    key: "install_9v_date",
    is_default: 0,
    title: "9V install Date",
    is_first: 1,
  },
  {
    key: "install_9v_date",
    is_default: 0,
    title: "9V Expiry Date",
    is_first: 2,
  },
  {
    key: "manufactured_date",
    is_default: 0,
    title: "Manufactured Date",
    is_first: 1,
  },
  {
    key: "installed_date",
    is_default: 0,
    title: "Installed Date",
    is_first: 1,
  },

  { key: "battery_lot", is_default: 0, title: "Battery Lot", is_first: 1 },
  { key: "battery_udi", is_default: 1, title: "Battery UDI", is_first: 1 },

  {
    key: "install_before_date",
    is_default: 0,
    title: "Install Before Date",
    is_first: 1,
  },
];
const ContactCell = ({ label, contactArr, accountId, field, loading }) => {
  const contact = contactArr?.[0]?.[field] || contactArr?.[1]?.[field] || "";

  return (
    <td className="border py-1 px-2 border-r-blue">
      {loading ? (
        <Skeleton
          count={5}
          width={"90%"}
          height={20}
          style={{ marginLeft: "2px", marginRight: "2px" }}
        />
      ) : (
        <>
          {label}:{" "}
          <ContactName
            url={`/account/${accountId}/contact-details/${contactArr?.[0]?.contact_id || contactArr?.[1]?.contact_id}`}
            locationState={{
              state: {
                siteTitle: `Contact: ${contact}`,
                editUrl: `/account/contact-details-edit/${contactArr?.[0]?.contact_id || contactArr?.[1]?.contact_id}`,
                deleteUrl: `/account/contact-details-edit/${contactArr?.[0]?.contact_id || contactArr?.[1]?.contact_id}`,
              },
            }}
            name={truncateText(contact, 15)}
          />
        </>
      )}
    </td>
  );
};
const CheckBatteryInfo = (batteryInfo, is_spare = 0) => {
  if (!batteryInfo || batteryInfo?.length === 0) {
    return 0;
  }
  let is_found = 0;
  for (let index = 0; index < batteryInfo.length; index++) {
    const el = batteryInfo[index];
    if (el.section_name != "charge_pack") {
      if (is_spare) {
        for (let i2 = 0; i2 < BatteryExpTbl.length; i2++) {
          const el2 = BatteryExpTbl[i2];
          if (el.is_spare) {
            if (el[el2.key]) {
              is_found = 1;
              break;
            }
          }
        }
      } else {
        if (!el.is_spare) {
          for (let i2 = 0; i2 < BatteryExpTbl.length; i2++) {
            const el2 = BatteryExpTbl[i2];
            if (el[el2.key]) {
              is_found = 1;
              break;
            }
          }
        }
      }
    }
    if (is_found) {
      break;
    }
  }
  return is_found;
};

export default function Details({
  assignedData,
  aedDetails,
  loading,
  cordinatorInfo,
  storageInfo,
  batteryInfo,
  sphereBatteryInfo,
  adultPadInfo,
  spareAdultPadInfo,
  pediatricPadInfo,
  sparePadricPadInfo,
  gatewayInfo,
  adultPadPakInfo,
  spareAdultPadPakInfo,
  pediatricPadPakInfo,
  sparePadricPadPakInfo,
  chargePakInfo,
  spareChargePakInfo,
  batteryTypeList,
  outOfServiceInfo,
  out_of_service_toggle,
  storageInfoList,
  rmsBrandList,
  aedPadTypeList,
  aedBattryTypeList,
  OtherFiled,
  allPads,
  showAedGeneralInfo,
  showAedStorageInfo,
  showAedBatteryInfo,
  showAedPadInfo,
  showAedCoordinatorInfo,
}) {


  const [aedCheckLength, setAedCheckLength] = useState("");
  const accountId = aedDetails?.account_id;

  useEffect(() => {
    const fetchData = async () => {
      if (!accountId) {
        return "";
      }
      const result = await CallGETAPI('account/acc-details-programs/' + accountId);

      if (result?.data?.status) {
        const newData = result?.data?.data?.aed_check_length;
        const aed_check_length = newData.match(/\d+/)[0];
        setAedCheckLength(aed_check_length);
      }
    }

    fetchData();
  }, [accountId])


  const filteredAdultPadInfo = [
    ...(adultPadInfo?.filter(
      (obj) => Number(obj?.is_spare) === 0 && obj?.is_pediatric === 0
    ) || []),
    ...(adultPadInfo?.filter(
      (obj) => Number(obj?.is_spare) === 1 && obj?.is_pediatric === 0
    ) || []),
    ...(adultPadInfo?.filter(
      (obj) => Number(obj?.is_spare) === 0 && obj?.is_pediatric === 1
    ) || []),
    ...(adultPadInfo?.filter(
      (obj) => Number(obj?.is_spare) === 1 && obj?.is_pediatric === 1
    ) || []),
  ];
  // console.log({filteredAdultPadInfo})
  const handleRmsBrand = (selectedBrandId) => {
    if (rmsBrandList && rmsBrandList[selectedBrandId]) {
      const rmsBrandName = rmsBrandList[selectedBrandId].rms_brand_name;
      return rmsBrandName;
    } else {
      return "";
    }
  };


  const adultPakPadIndex = adultPadPakInfo?.findIndex(
    (item) => item.section_name === "adult_pad_pak_info"
  );
  const spareAdultPakPadIndex = adultPadPakInfo?.findIndex(
    (item) => item.section_name === "spare_adult_pad_pak_info"
  );
  const padiatricPakPadIndex = adultPadPakInfo?.findIndex(
    (item) => item.section_name === "pediatric_pak_pad_info"
  );
  const sparePadiatricPakPadIndex = adultPadPakInfo?.findIndex(
    (item) => item.section_name === "spare_padric_pak_pad"
  );

  const adultPadPakData = adultPadPakInfo?.[adultPakPadIndex];
  const spareAdultPadPakData = adultPadPakInfo?.[spareAdultPakPadIndex];
  const padiatricPadPakData = adultPadPakInfo?.[padiatricPakPadIndex];
  const sparePadiatricPadPakData = adultPadPakInfo?.[sparePadiatricPakPadIndex];

  const print_battery_part = (bid) => {
    let findName = batteryTypeList.find(
      (item) => parseInt(item?.battery_type_id) === parseInt(bid)
    );
    return findName?.battery_part_number || bid;
  };

  const print_storage_type = (bid) => {
    let findName = storageInfoList?.find(
      (item) => parseInt(item?.storage_info_id) === parseInt(bid)
    );
    return findName?.storage_info_name || bid;
  };

  const print_aed_pad_type = (bid) => {
    if (bid === "unknown") return "unknown";
    let findName = aedPadTypeList.find(
      (item) => parseInt(item?.pad_type_id) === parseInt(bid)
    );
    return findName?.pad_part_number || bid || "NA";
  };

  // function parseStringToJsonArray(str) {
  //   try {
  //     // Parse the string to convert it into a JSON array
  //     const jsonArray = JSON.parse(str);
  //     return jsonArray;
  //   } catch (error) {
  //     console.error("Invalid JSON string:", error);
  //     return [];
  //   }
  // }

  function parseStringToJsonArray(str) {
    try {
      // If it's already an object, just return it as an array
      if (typeof str === "object") {
        return Array.isArray(str) ? str : [str];
      }
  
      return JSON.parse(str);
    } catch (error) {
      console.error("Invalid JSON string:", error);
      return [];
    }
  }

  const inputStr = assignedData;
  const result = parseStringToJsonArray(inputStr);
  return (
    <div className="relative">
      {/* outOfService Information */}
      {out_of_service_toggle && out_of_service_toggle == "1" ? (
        <>
          <div className="account-info py-2">
            {/* heading */}
            <Box className="text-left">
              <h4 className="heading">Out of Service Information</h4>
            </Box>

            <table className="w-100">
              <thead>
                <tr className="">
                  <th
                    scope="col"
                    width="5%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Replacing
                  </th>
                  <th
                    scope="col"
                    width="25%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Replaced Serial #
                  </th>
                  <th
                    scope="col"
                    width="10%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Date Sent To Manufacturer
                  </th>
                  <th
                    scope="col"
                    width="25%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
                  >
                    Loaner Rental Serial
                  </th>
                  <th
                    scope="col"
                    width="35%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
                  >
                    Reason
                  </th>
                </tr>
              </thead>
              {/* second row */}
              <tbody className="odd-even-row border-b-blue">
                <tr className="">
                  <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {outOfServiceInfo[0]?.not_replacing == 1 ||
                      outOfServiceInfo[0]?.not_replacing ? (
                      <CheckIcon sx={{ color: "#00FF00" }} />
                    ) : (
                      <CloseIcon color={"error"} />
                    )}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {outOfServiceInfo[0]?.replaced_serial_name}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {outOfServiceInfo[0]?.date_sent_to_manufacturer ===
                      "unknown"
                      ? "unknown"
                      : RenderDate(
                        outOfServiceInfo[0]?.date_sent_to_manufacturer,
                        false
                      )}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border">
                    {outOfServiceInfo[0]?.loaner_rental_serial_name}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border">
                    {outOfServiceInfo[0]?.reason}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}

      {/* general information */}
      <div className="general-info pt-1">
        {/* heading */}
        <Box className="text-left">
          <h4 className="heading">General Information</h4>
        </Box>

        {/* table */}
        <table className="w-100">
          <thead>
            <tr className="">
              <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                Brand / Model
              </th>
              <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                Sub-Model
              </th>
              <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                Part #
              </th>
              <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                Purchased Date{" "}
              </th>
              <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                Purchased Type{" "}
              </th>
              <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                Warranty Date{" "}
              </th>
              <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                Last Check
              </th>
              <th className="border border-2 py-1 px-2 bg-tbl-border border-t-blue">
                Last Service
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="">
              <td className="border border-2 py-1 px-2 border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>
                    {aedDetails?.brandName}
                    {aedDetails?.modelName ? " / " + aedDetails?.modelName : ""}
                  </>
                )}
              </td>

              <td className="border border-2 py-1 px-2 border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{aedDetails?.sub_model_name}</>
                )}
              </td>
              <td className="border border-2 py-1 px-2 border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{aedDetails?.part_number}</>
                )}
              </td>
              <td className="border border-2 py-1 px-2 border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{RenderDate(aedDetails?.purchase_date, 0)}</>
                )}
              </td>
              <td className="border border-2 py-1 px-2 border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{aedDetails?.purchase_type}</>
                )}
              </td>
              <td className="border border-2 py-1 px-2 border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{RenderDate(aedDetails?.warranty_date)}</>
                )}
              </td>
              <td className="border border-2 py-1 px-2 border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{RenderDiffDate(aedDetails?.last_check, aedCheckLength)}</>
                )}
              </td>
              <td className="border border-2 py-1 px-2">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{RenderDate(aedDetails?.last_service)}</>
                )}
              </td>
            </tr>
          </tbody>
          <tbody className="">
            {/* second row */}
            <tr className="3">
              <th
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
              >
                Placement
              </th>
              <th
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
              >
                Installed
              </th>
              <th
                colSpan="2"
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
              >
                Invoice #
              </th>
              <th
                colSpan="2"
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
              >
                Asset
              </th>
              <th
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
              >
                Spares
              </th>
              <th
                className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
              >
                Pediatrics
              </th>
            </tr>
            <tr className="border-b-blue">
              <td className="border border-2 py-1 px-2 border-t-blue border-r-blue">
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{aedDetails?.placement}</>
                )}
              </td>
              <td
                className="border border-2 py-1 px-2 border-t-blue border-r-blue"
              >
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{FormatDate(aedDetails?.installed)}</>
                )}
              </td>
              <td
                colSpan="2"
                className="border border-2 py-1 px-2 border-t-blue border-r-blue"
              >
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{aedDetails?.invoice}</>
                )}
              </td>
              <td
                colSpan="2"
                className="border border-2 py-1 px-2 border-t-blue border-r-blue"
              >
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>{aedDetails?.asset}</>
                )}
              </td>
              <td
                className="border border-2 py-1 px-2 border-t-blue border-r-blue"
              >
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>
                    {Number(aedDetails?.no_spares_toggle) == 1 ? (
                      <CheckIcon sx={{ color: "#00FF00" }} />
                    ) : (
                      <CloseIcon color={"error"} />
                    )}
                  </>
                )}
              </td>
              <td
                className="border border-2 py-1 px-2 border-t-blue"
              >
                {loading ? (
                  <>
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  </>
                ) : (
                  <>
                    {aedDetails?.no_pediatric_toggle == 1 ? (
                      <CheckIcon sx={{ color: "#00FF00" }} />
                    ) : (
                      <CloseIcon color={"error"} />
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {OtherFiled?.[0]?.label && (
          <>
            <table className="w-100">
              <thead>
                <tr>
                  {OtherFiled?.map((item) => (
                    <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue ">
                      {item?.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {OtherFiled?.map((item) => (
                    <td className="border border-2 py-1 px-2 border-r-blue ">
                      {item?.val ? item.val : "N/A"}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>

      {/* Storage Information */}
      <div className="account-info py-4">
        {/* heading */}
        {storageInfo?.[0]?.storage_type === "" ||
          !storageInfo?.[0]?.storage_type ? null : (
          <Box className="text-left">
            <h4 className="heading">Storage Information</h4>
          </Box>
        )}

        {storageInfo?.[0]?.storage_type !== "" &&
          storageInfo?.[0]?.storage_type ? (
          <table className="w-100">
            <thead>
              <tr className="">
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Storage Type{" "}
                </th>
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Alarmed
                </th>
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Alarm Status
                </th>
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  9v Installed Date
                </th>
                <th
                  scope="col"
                  width="55%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
                >
                  9v Expiration Date
                </th>
              </tr>
            </thead>
            {/* second row */}
            <tbody className="odd-even-row border-b-blue">
              {storageInfo?.map((SI, i) => (
                <tr className="" key={i}>
                  <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {loading ? (
                      <>
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      </>
                    ) : (
                      <>{SI?.storage_part_name}</>
                    )}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {loading ? (
                      <>
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      </>
                    ) : (
                      <>
                        {SI?.alarmed === 1 ? (
                          <CheckIcon sx={{ color: "#00FF00" }} />
                        ) : (
                          <CloseIcon color={"error"} />
                        )}
                      </>
                    )}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {SI?.alarm_status === 1 ? (
                      <CheckIcon sx={{ color: "#00FF00" }} />
                    ) : (
                      <CloseIcon color={"error"} />
                    )}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {SI?.v9_Installed_Date === "unknown"
                      ? "unknown"
                      : RenderDate(SI?.v9_Installed_Date, 0)}
                  </td>
                  <td className="border border-2 py-1 px-2 bg-tbl-border">
                    {SI?.expiry_date === "unknown"
                      ? "unknown"
                      : RenderDate(SI?.expiry_date, 0)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>

      {/* Battery Information */}
      <div className="account-info pb-4">
        {/* heading */}
        {CheckBatteryInfo(batteryInfo) ? (
          <>
            <Box className="text-left">
              <h4 className="heading">Main Battery Information</h4>
            </Box>
            <table className="w-100 border-l-blue border-b-blue border-r-blue">
              <thead>
                <tr className="">
                  {BatteryExpTbl?.map((item) =>
                    item?.is_default ||
                      CheckADTable(
                        batteryInfo.filter((it) => !it.is_spare),
                        item?.key
                      ) === 1 ? (
                      <th
                        scope="col"
                        className="border border-2 py-1 px-2 bg-tbl-border border-l-blue border-t-blue border-r-blue"
                      >
                        {item?.title}
                      </th>
                    ) : (
                      ""
                    )
                  )}
                </tr>
              </thead>

              <tbody className="odd-even-row border-b-blue">
                {batteryInfo.map(
                  (item, index) =>
                    Number(item.is_spare) == 0 && (
                      <>
                        {item.section_name === "has_battery" && (
                          <>
                            <BatteryInformationTr
                              batteryInfo={batteryInfo.filter(
                                (it) => !it.is_spare
                              )}
                              print_battery_part={print_battery_part}
                              RenderDate={RenderDate}
                              DataValue={item}
                              batteryIndex={index}
                            />
                          </>
                        )}
                        {item.section_name === "has_9v" && (
                          <>
                            <BatteryInformationTr
                              batteryInfo={batteryInfo.filter(
                                (it) => !it.is_spare
                              )}
                              print_battery_part={print_battery_part}
                              RenderDate={RenderDate}
                              DataValue={item}
                              batteryIndex={index}
                            />
                          </>
                        )}

                        {item.section_name === "has_installby" && (
                          <>
                            <BatteryInformationTr
                              batteryInfo={batteryInfo.filter(
                                (it) => !it.is_spare
                              )}
                              print_battery_part={print_battery_part}
                              RenderDate={RenderDate}
                              DataValue={item}
                              batteryIndex={index}
                            />
                          </>
                        )}

                        {item.section_name === "has_man" && (
                          <>
                            <BatteryInformationTr
                              batteryInfo={batteryInfo.filter(
                                (it) => !it.is_spare
                              )}
                              print_battery_part={print_battery_part}
                              RenderDate={RenderDate}
                              DataValue={item}
                              batteryIndex={index}
                              no_exp={1}
                            />
                          </>
                        )}

                        {item.section_name === "has_10pk" && (
                          <>
                            <BatteryInformationTr
                              batteryInfo={batteryInfo.filter(
                                (it) => !it.is_spare
                              )}
                              print_battery_part={print_battery_part}
                              RenderDate={RenderDate}
                              DataValue={item}
                              batteryIndex={index}
                            />
                          </>
                        )}

                        {item.section_name === "charge_pack" && (
                          <>
                            <BatteryInformationTr
                              batteryInfo={batteryInfo.filter(
                                (it) => !it.is_spare
                              )}
                              print_battery_part={print_battery_part}
                              RenderDate={RenderDate}
                              DataValue={item}
                              batteryIndex={index}
                            />
                          </>
                        )}
                      </>
                    )
                )}
              </tbody>
            </table>
          </>
        ) : (
          ""
        )}

        {CheckBatteryInfo(batteryInfo, 1) ? (
          <>
            <Box className="text-left mt-4">
              <h4 className="heading">Spare Battery Information</h4>
            </Box>

            <table className="w-100 border-b-blue">
              <thead>
                <tr className="">
                  {SpareBatteryExpTbl?.map((item) =>
                    item?.is_default ||
                      CheckADTable(
                        batteryInfo.filter((it) => Number(it.is_spare)),
                        item?.key
                      ) === 1 ? (
                      <th
                        scope="col"
                        className="border border-2 py-1 px-2 bg-tbl-border border-l-blue border-t-blue border-r-blue"
                      >
                        {item?.title}
                      </th>
                    ) : (
                      ""
                    )
                  )}
                </tr>
              </thead>

              <tbody className="odd-even-row">
                {batteryInfo.map(
                  (item, index) =>
                    Number(item.is_spare) === 1 && (
                      <>
                        {item.section_name === "has_battery" &&
                          (item.battery_type_id === "" &&
                            item.manufactured_date === "" &&
                            item.battery_expiration === "" &&
                            item.battery_lot === "" &&
                            item.battery_udi === "" ? (
                            ""
                          ) : (
                            <>
                              <SpareBatteryinformation
                                batteryInfo={batteryInfo.filter((it) =>
                                  Number(it.is_spare)
                                )}
                                print_battery_part={print_battery_part}
                                RenderDate={RenderDate}
                                DataValue={item}
                                batteryIndex={index}
                              />
                            </>
                          ))}

                        {item.section_name === "has_9v" &&
                          (item.battery_type_id === "" &&
                            item.manufactured_date === "" &&
                            item.battery_expiration === "" &&
                            item.battery_lot === "" &&
                            item.battery_udi === "" ? (
                            ""
                          ) : (
                            <>
                              <SpareBatteryinformation
                                batteryInfo={batteryInfo.filter((it) =>
                                  Number(it.is_spare)
                                )}
                                print_battery_part={print_battery_part}
                                RenderDate={RenderDate}
                                DataValue={item}
                                batteryIndex={index}
                              />
                            </>
                          ))}

                        {item.section_name === "has_installby" &&
                          (item.battery_type_id === "" &&
                            item.manufactured_date === "" &&
                            item.battery_expiration === "" &&
                            item.battery_lot === "" &&
                            item.battery_udi === "" ? (
                            ""
                          ) : (
                            <>
                              <SpareBatteryinformation
                                batteryInfo={batteryInfo.filter((it) =>
                                  Number(it.is_spare)
                                )}
                                print_battery_part={print_battery_part}
                                RenderDate={RenderDate}
                                DataValue={item}
                                batteryIndex={index}
                              />
                            </>
                          ))}

                        {item.section_name === "has_man" &&
                          (item.battery_type_id === "" &&
                            item.manufactured_date === "" &&
                            item.battery_expiration === "" &&
                            item.battery_lot === "" &&
                            item.battery_udi === "" ? (
                            ""
                          ) : (
                            <>
                              <SpareBatteryinformation
                                batteryInfo={batteryInfo.filter((it) =>
                                  Number(it.is_spare)
                                )}
                                print_battery_part={print_battery_part}
                                RenderDate={RenderDate}
                                DataValue={item}
                                batteryIndex={index}
                              />
                            </>
                          ))}

                        {item.section_name === "has_10pk" &&
                          (item.battery_type_id === "" &&
                            item.manufactured_date === "" &&
                            item.battery_expiration === "" &&
                            item.battery_lot === "" &&
                            item.battery_udi === "" ? (
                            ""
                          ) : (
                            <>
                              <SpareBatteryinformation
                                batteryInfo={batteryInfo.filter((it) =>
                                  Number(it.is_spare)
                                )}
                                print_battery_part={print_battery_part}
                                RenderDate={RenderDate}
                                DataValue={item}
                                batteryIndex={index}
                              />
                            </>
                          ))}

                        {item.section_name === "charge_pack" &&
                          (item.battery_type_id === "" &&
                            item.manufactured_date === "" &&
                            item.battery_expiration === "" &&
                            item.battery_lot === "" &&
                            item.battery_udi === "" ? (
                            ""
                          ) : (
                            <>
                              <SpareBatteryinformation
                                batteryInfo={batteryInfo.filter((it) =>
                                  Number(it.is_spare)
                                )}
                                print_battery_part={print_battery_part}
                                RenderDate={RenderDate}
                                DataValue={item}
                                batteryIndex={index}
                              />
                            </>
                          ))}
                      </>
                    )
                )}
              </tbody>
            </table>
          </>
        ) : (
          ""
        )}
      </div>

      {/* Charge-Pak Information */}
      {((Array.isArray(chargePakInfo) && chargePakInfo.length > 0) ||
        (Array.isArray(spareChargePakInfo) &&
          spareChargePakInfo.length > 0)) && (
          <div className="account-info pb-4">
            {/* heading */}
            <Box className="text-left">
              <h4 className="heading">Charge-Pak Information</h4>
            </Box>
            <table className="w-100">
              {/* main adult head */}
              <thead>
                <tr className="">
                  <th
                    scope="col"
                    colSpan={1}
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Charge-Pak Type
                  </th>
                  <th
                    scope="col"
                    colSpan={2}
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Charge-Pak Part #
                  </th>
                  <th
                    scope="col"
                    colSpan={2}
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                    style={{
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    }}
                  >
                    Charge-Pak UDI
                  </th>
                  <th
                    scope="col"
                    colSpan={1}
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Battery Expiration
                  </th>
                  <th
                    scope="col"
                    colSpan={2}
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
                  >
                    Battery Lot
                  </th>
                </tr>
              </thead>

              {/* main adult body */}
              {Array.isArray(chargePakInfo) && chargePakInfo.length > 0 && (
                <tbody className="odd-even-row border-b-blue">
                  {/* main adult */}
                  {chargePakInfo?.map((chargeItem, chargeKey) => (
                    <>
                      <tr className="">
                        <td
                          colSpan={1}
                          className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                        >
                          Main Adult
                        </td>
                        <td
                          colSpan={2}
                          className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                        >
                          {print_battery_part(chargeItem?.charge_pak_part)}
                        </td>
                        <td
                          colSpan={2}
                          className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                          style={{
                            maxWidth: "300px",
                            overflowWrap: "break-word",
                          }}
                        >
                          {chargeItem?.charge_pak_uiid}
                        </td>
                        <td
                          colSpan={1}
                          className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                        >
                          {chargeItem?.battery_expiration === "unknown"
                            ? "unknown"
                            : RenderDate(chargeItem?.battery_expiration, true)}
                        </td>
                        <td
                          colSpan={2}
                          className="border border-2 py-1 px-2 bg-tbl-border"
                          style={{
                            maxWidth: "300px",
                            overflowWrap: "break-word",
                          }}
                        >
                          {chargeItem?.battery_lot}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              )}
              {/* sphere adult head */}
              {Array.isArray(spareChargePakInfo) &&
                spareChargePakInfo.length > 0 && (
                  <>
                    {/* sphere adult body */}
                    <tbody className="odd-even-row border-b-blue">
                      {spareChargePakInfo.map((SpareChargItem, SpareCharKey) => (
                        <>
                          <tr className="">
                            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                              Spare Adult
                            </td>
                            <td
                              colSpan={2}
                              className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                            >
                              {print_battery_part(
                                SpareChargItem?.charge_pak_part
                              )}
                            </td>
                            <td
                              colSpan={2}
                              className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                            >
                              {SpareChargItem?.charge_pak_uiid}
                            </td>
                            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                              {SpareChargItem?.battery_expiration === "unknown"
                                ? "unknown"
                                : RenderDate(
                                  SpareChargItem?.battery_expiration,
                                  true
                                )}
                            </td>
                            <td
                              colSpan={2}
                              className="border border-2 py-1 px-2 bg-tbl-border"
                            >
                              {SpareChargItem?.battery_lot}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </>
                )}
            </table>
          </div>
        )}

      {/* Pad Information */}
      <div className="account-info pb-4">
        {/* heading */}
        {(adultPadInfo?.length > 0 &&
          adultPadInfo?.[0]?.adult_pad_part != "") ||
          chargePakInfo?.charge_pak_part ||
          (spareAdultPadInfo?.length > 0 &&
            spareAdultPadInfo?.[0]?.spare_adult_pad_part != "") ||
          (pediatricPadInfo?.length > 0 &&
            pediatricPadInfo?.[0]?.pediatric_pad_part != "") ||
          (sparePadricPadInfo?.length > 0 &&
            sparePadricPadInfo?.[0]?.spare_pediatric_pad_part != "") ? (
          <Box className="text-left d-flex align-items-center">
            <h4 className="heading">Pad Information</h4>
            {aedDetails?.pediatric_key_child ? (
              ""
            ) : (
              <img src={"/assets/images/NOPED.svg"} width={20} />
            )}

            {/* {aedDetails?.pediatric_key ? ( */}
            <p className="m-0">
              &nbsp;
              {aedDetails?.pediatric_key_icon ? (
                <img src={"/assets/images/Baby.svg"} width={20} />
              ) : (
                ""
              )}
              {aedDetails?.pediatric_key_icon ? (
                <img src="/assets/images/PedKey.svg" width={16} />
              ) : (
                ""
              )}
            </p>
          </Box>
        ) : (
          ""
        )}
        {(adultPadInfo?.length > 0 &&
          adultPadInfo?.[0]?.adult_pad_part != "") ||
          chargePakInfo?.charge_pak_part ||
          (spareAdultPadInfo?.length > 0 &&
            spareAdultPadInfo?.[0]?.spare_adult_pad_part != "") ||
          (pediatricPadInfo?.length > 0 &&
            pediatricPadInfo?.[0]?.pediatric_pad_part != "") ||
          (sparePadricPadInfo?.length > 0 &&
            sparePadricPadInfo?.[0]?.spare_pediatric_pad_part != "") ? (
          <table className="w-100">
            <thead>
              <tr className="">
                <th
                  scope="col"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Pad Type
                </th>
                <th
                  scope="col"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Part #{" "}
                </th>
                <th
                  scope="col"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Expiration Date
                </th>
                <th
                  scope="col"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Pad Lot{" "}
                </th>

                {/* {(adultPadInfo?.length > 0 &&
                  adultPadInfo.some((item) => item?.pad_udi)) ||
                chargePakInfo?.pad_udi ||
                (spareAdultPadInfo?.length > 0 &&
                  spareAdultPadInfo.some((item) => item?.pad_udi)) ||
                (pediatricPadInfo?.length > 0 &&
                  pediatricPadInfo.some((item) => item?.pad_udi)) ||
                (sparePadricPadInfo?.length > 0 &&
                  sparePadricPadInfo.some((item) => item?.pad_udi)) ? ( */}
                <th
                  scope="col"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
                >
                  Pad UDI
                </th>
                {/* ) : (
                  ""
                )} */}
              </tr>
            </thead>
            {/* second row */}
            <tbody className="odd-even-row border-b-blue">
              {filteredAdultPadInfo?.map(
                (API, i) =>
                  API.section_name === "adult_pad_info" &&
                  (API.pad_type_id === "" &&
                    API.pad_expiration === "" &&
                    API.pad_lot === "" &&
                    API.pad_udi === "" ? (
                    ""
                  ) : (
                    <tr className="" key={i}>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {/* Main Adult */}
                        {API.is_spare ? "Spare Adult" : "Main Adult"}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(API?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {API?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(FormatDate(API?.pad_expiration), 1)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {API?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {API?.pad_udi ? API?.pad_udi : "N/A"}
                      </td>
                    </tr>
                  ))
              )}
              {/*  handle Printing charge pack according to new code */}
              {filteredAdultPadInfo?.map(
                (API, i) =>
                  API.section_name === "charge_pack" &&
                  (API.pad_type_id === "" &&
                    API.pad_expiration === "" &&
                    API.pad_lot === "" &&
                    API.pad_udi === "" ? (
                    ""
                  ) : (
                    <tr className="" key={i}>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {/* Main Adult */}
                        {API.is_spare ? "Spare Adult" : "Main Adult"}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(API?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {API?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(FormatDate(API?.pad_expiration), 1)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {API?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {API?.pad_udi ? API?.pad_udi : "N/A"}
                      </td>
                    </tr>
                  ))
              )}

              {chargePakInfo?.charge_pak_part &&
                chargePakInfo?.spare_pediatric_pad_part != "" &&
                (chargePakInfo?.pad_1_part === "" &&
                  chargePakInfo?.pad_1_expiration === "" &&
                  chargePakInfo?.pad_1_lot === "" ? (
                  ""
                ) : (
                  <>
                    <tr className="">
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        Main Adult
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {print_aed_pad_type(chargePakInfo?.pad_1_part) || "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {chargePakInfo?.pad_1_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(chargePakInfo?.pad_1_expiration),
                            1
                          )}
                      </td>
                      <td
                        style={{
                          minHeight: "10px",
                          maxWidth: "200px",
                          overflowWrap: "break-word",
                        }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {chargePakInfo?.pad_1_lot || "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        NA
                      </td>
                    </tr>
                  </>
                ))}

              {chargePakInfo?.charge_pak_part &&
                chargePakInfo?.spare_pediatric_pad_part != "" &&
                (chargePakInfo?.pad_2_part === "" &&
                  chargePakInfo?.pad_2_expiration === "" &&
                  chargePakInfo?.pad_2_lot === "" ? (
                  ""
                ) : (
                  <>
                    <tr>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        Main Adult
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {print_aed_pad_type(chargePakInfo?.pad_2_part) || "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {chargePakInfo?.pad_2_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(chargePakInfo?.pad_2_expiration),
                            1
                          )}
                      </td>
                      <td
                        style={{
                          minHeight: "10px",
                          maxWidth: "200px",
                          overflowWrap: "break-word",
                        }}
                        className="border border-2 py-1 px-2 bg-tbl-border"
                      >
                        {chargePakInfo?.pad_2_lot || "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        NA
                      </td>
                    </tr>
                  </>
                ))}

              {spareAdultPadInfo?.map(
                (SAPI, i) =>
                  SAPI.section_name === "spare_adult_pad_info" &&
                  (SAPI.pad_type_id === "" &&
                    SAPI.pad_expiration === "" &&
                    SAPI.pad_lot === "" &&
                    SAPI.pad_udi === "" ? (
                    ""
                  ) : (
                    <tr className="" kye={i}>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        Spare Adult
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(SAPI?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SAPI?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(FormatDate(SAPI?.pad_expiration), 1)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SAPI?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {SAPI?.pad_udi ? SAPI?.pad_udi : "N/A"}
                      </td>
                    </tr>
                  ))
              )}

              {spareChargePakInfo?.map((SpareChargItem, SpareCharKey) =>
                SpareChargItem?.pad_1_part === "" &&
                  SpareChargItem?.pad_1_expiration === "" &&
                  SpareChargItem?.pad_1_lot === "" ? (
                  ""
                ) : (
                  <>
                    <tr key={SpareCharKey} className="">
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        <span>Spare Adult</span>
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {print_aed_pad_type(SpareChargItem?.pad_1_part)
                          ? print_aed_pad_type(SpareChargItem?.pad_1_part)
                          : "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {SpareChargItem?.pad_1_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(SpareChargItem?.pad_1_expiration),
                            1
                          )}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {SpareChargItem?.pad_1_lot
                          ? SpareChargItem?.pad_1_lot
                          : "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        NA
                      </td>
                    </tr>
                  </>
                )
              )}

              {spareChargePakInfo?.map((SpareChargItem, SpareCharKey) =>
                SpareChargItem?.pad_2_part === "" &&
                  SpareChargItem?.pad_2_expiration === "" &&
                  SpareChargItem?.pad_2_lot === "" ? (
                  ""
                ) : (
                  <>
                    <tr key={SpareCharKey}>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        <span>Spare Adult</span>
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {print_aed_pad_type(SpareChargItem?.pad_2_part) || "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        {SpareChargItem?.pad_2_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(SpareChargItem?.pad_2_expiration),
                            1
                          )}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border"
                      >
                        {SpareChargItem?.pad_2_lot || "NA"}
                      </td>
                      <td
                        style={{ minHeight: "10px" }}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        NA
                      </td>
                    </tr>
                  </>
                )
              )}

              {pediatricPadInfo &&
                pediatricPadInfo?.map(
                  (ppitem, i) =>
                    ppitem.section_name === "pediatric_pad_info" &&
                    (ppitem.pad_type_id === "" &&
                      ppitem.pad_expiration === "" &&
                      ppitem.pad_lot === "" &&
                      ppitem.pad_udi === "" ? (
                      ""
                    ) : (
                      <tr className="" key={i}>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          Pediatric
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {print_aed_pad_type(ppitem?.pad_type_id)}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {/* {ppitem?.pad_expiration === 'unknown' ? 'unknown' : RenderDate(FormatDate(ppitem?.pad_expiration), 1)} */}
                          {ppitem?.pad_expiration === "unknown"
                            ? "unknown"
                            : RenderDate(FormatDate(ppitem?.pad_expiration), 1)}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {ppitem?.pad_lot}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border">
                          {ppitem?.pad_udi ? ppitem?.pad_udi : "N/A"}
                        </td>
                      </tr>
                    ))
                )}

              {sparePadricPadInfo?.map(
                (SPPi, i) =>
                  SPPi.section_name === "spare_padric_pad_info" &&
                  (SPPi.pad_type_id === "" &&
                    SPPi.pad_expiration === "" &&
                    SPPi.pad_lot === "" &&
                    SPPi.pad_udi === "" ? (
                    ""
                  ) : (
                    <tr className="" key={i}>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        Spare Pediatric
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(SPPi?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SPPi?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(FormatDate(SPPi?.pad_expiration), 1)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SPPi?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {SPPi?.pad_udi ? SPPi?.pad_udi : "N/A"}
                      </td>
                    </tr>
                  ))
              )}

              {pediatricPadInfo &&
                pediatricPadInfo?.map(
                  (ppitem, i) =>
                    ppitem.section_name === "adult_pad_pak_info" &&
                    (ppitem.pad_type_id === "" &&
                      ppitem.pad_expiration === "" &&
                      ppitem.pad_lot === "" &&
                      ppitem.pad_udi === "" ? (
                      ""
                    ) : (
                      <tr className="" key={i}>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          Pad Pak
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {print_aed_pad_type(ppitem?.pad_type_id)}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {ppitem?.pad_expiration === "unknown"
                            ? "unknown"
                            : RenderDate(FormatDate(ppitem?.pad_expiration), 1)}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {ppitem?.pad_lot}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border">
                          {ppitem?.pad_udi ? ppitem?.pad_udi : "N/A"}
                        </td>
                      </tr>
                    ))
                )}

              {sparePadricPadInfo?.map(
                (SPPi, i) =>
                  SPPi.section_name === "spare_adult_pad_pak_info" &&
                  (SPPi.pad_type_id === "" &&
                    SPPi.pad_expiration === "" &&
                    SPPi.pad_lot === "" &&
                    SPPi.pad_udi === "" ? (
                    ""
                  ) : (
                    <tr className="" key={i}>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        Spare Pad-Pak
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(SPPi?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SPPi?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(FormatDate(SPPi?.pad_expiration), 1)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SPPi?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {SPPi?.pad_udi ? SPPi?.pad_udi : "N/A"}
                      </td>
                    </tr>
                  ))
              )}

              {pediatricPadInfo &&
                pediatricPadInfo?.map(
                  (ppitem, i) =>
                    ppitem.section_name === "pediatric_pak_pad_info" &&
                    (ppitem.pad_type_id === "" &&
                      ppitem.pad_expiration === "" &&
                      ppitem.pad_lot === "" &&
                      ppitem.pad_udi === "" ? (
                      ""
                    ) : (
                      <tr className="" key={i}>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          Pediatric Pak
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {print_aed_pad_type(ppitem?.pad_type_id)}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {/* {RenderDate(FormatDate(ppitem?.pad_expiration), 1)} */}
                          {ppitem?.pad_expiration === "unknown"
                            ? "unknown"
                            : RenderDate(FormatDate(ppitem?.pad_expiration), 1)}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                          {ppitem?.pad_lot}
                        </td>
                        <td className="border border-2 py-1 px-2 bg-tbl-border">
                          {ppitem?.pad_udi ? ppitem?.pad_udi : "N/A"}
                        </td>
                      </tr>
                    ))
                )}

              {sparePadricPadInfo?.map(
                (SPPi, i) =>
                  SPPi.section_name === "spare_padric_pak_pad" &&
                  (SPPi.pad_type_id === "" &&
                    SPPi.pad_expiration === "" &&
                    SPPi.pad_lot === "" &&
                    SPPi.pad_udi === "" ? (
                    ""
                  ) : (
                    <tr className="" key={i}>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        Spare Pediatric-Pak
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(SPPi?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SPPi?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(FormatDate(SPPi?.pad_expiration), 1)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {SPPi?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {SPPi?.pad_udi ? SPPi?.pad_udi : "N/A"}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>

      {adultPadPakData === "" &&
        spareAdultPadPakData === "" &&
        padiatricPadPakData === "" &&
        sparePadiatricPadPakData === "" ? (
        <>
          {/* Pad Pak Information */}
          <div className="account-info pb-4">
            {/* heading */}
            <Box className="text-left">
              <h4 className="heading">Pad Pak Information</h4>
            </Box>

            <table className="w-100">
              <thead>
                <tr className="">
                  <th
                    scope="col"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Pad Type
                  </th>
                  <th
                    scope="col"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Part #{" "}
                  </th>
                  <th
                    scope="col"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Expiration Date
                  </th>
                  <th
                    scope="col"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Pad Lot{" "}
                  </th>
                  <th
                    scope="col"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
                  >
                    Pad UDI
                  </th>
                </tr>
              </thead>
              <tbody className="odd-even-row border-b-blue">
                {adultPadPakData &&
                  CheckDate(adultPadPakData.pad_expiration) ? (
                  <>
                    <tr className="">
                      <td
                        width={"25%"}
                        className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"
                      >
                        Adult Pad Pak
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(adultPadPakData.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {adultPadPakData.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(adultPadPakData.pad_expiration),
                            1
                          )}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {adultPadPakData.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {adultPadPakData.pad_udi
                          ? adultPadPakData.pad_udi
                          : "N/A"}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}

                {spareAdultPadPakData &&
                  CheckDate(spareAdultPadPakData.pad_expiration) ? (
                  <>
                    <tr className="">
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        Spare Adult Pad Pak
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(spareAdultPadPakData?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {spareAdultPadPakData?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(spareAdultPadPakData?.pad_expiration),
                            1
                          )}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {spareAdultPadPakData?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {spareAdultPadPakData?.pad_udi
                          ? spareAdultPadPakData?.pad_udi
                          : "N/A"}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}

                {padiatricPadPakData &&
                  CheckDate(padiatricPadPakData.pad_expiration) ? (
                  <>
                    <tr className="">
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        Pediatric Pad Pak
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(padiatricPadPakData?.pad_type_id)}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {padiatricPadPakData?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(padiatricPadPakData?.pad_expiration),
                            1
                          )}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {padiatricPadPakData?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {padiatricPadPakData?.pad_udi
                          ? padiatricPadPakData?.pad_udi
                          : "N/A"}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}

                {sparePadiatricPadPakData &&
                  CheckDate(sparePadiatricPadPakData.pad_expiration) ? (
                  <>
                    <tr className="">
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        Spare Pediatric Pad Pak
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {print_aed_pad_type(
                          sparePadiatricPadPakData?.pad_type_id
                        )}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {sparePadiatricPadPakData?.pad_expiration === "unknown"
                          ? "unknown"
                          : RenderDate(
                            FormatDate(
                              sparePadiatricPadPakData?.pad_expiration
                            ),
                            1
                          )}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {sparePadiatricPadPakData?.pad_lot}
                      </td>
                      <td className="border border-2 py-1 px-2 bg-tbl-border">
                        {sparePadiatricPadPakData?.pad_udi
                          ? sparePadiatricPadPakData?.pad_udi
                          : "N/A"}
                      </td>
                    </tr>
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}

      {/* gateway Information */}
      {gatewayInfo?.installed ? (
        <div className="account-info py-4">
          {/* heading */}
          <Box className="text-left">
            <h4 className="heading">Gateway Information</h4>
          </Box>

          <table className="w-100">
            <thead>
              <tr className="">
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Connected
                </th>
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Serial #
                </th>
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Mac Address
                </th>
                <th
                  scope="col"
                  width="15%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                >
                  Battery Install Date
                </th>
                <th
                  scope="col"
                  width="55%"
                  className="border border-2 py-1 px-2 bg-tbl-border border-t-blue"
                >
                  Battery Expiration
                </th>
              </tr>
            </thead>
            {/* second row */}
            <tbody className="odd-even-row border-b-blue">
              <tr className="">
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                  {gatewayInfo?.connected == 1 ? (
                    <CheckIcon sx={{ color: "#00FF00" }} />
                  ) : (
                    <CloseIcon color={"error"} />
                  )}
                </td>
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                  {gatewayInfo?.gateway_serial}
                </td>
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                  {gatewayInfo?.gateway_Mmac_address}
                </td>
                <td className="border border-2 py-1 px-2 bg-tbl-border">
                  {RenderDate(gatewayInfo?.battery_install_date, 0)}
                </td>
                <td className="border border-2 py-1 px-2 bg-tbl-border">
                  {gatewayInfo?.expiry_date === "unknown"
                    ? "unknown"
                    : RenderDate(gatewayInfo?.expiry_date, 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        ""
      )}

      {aedDetails?.builtin_RMS_info.length > 0 &&
        aedDetails?.builtin_RMS_info?.map(
          (item, index) =>
            item?.mac_address != "" && (
              <div className="account-info pb-4" key={index}>
                {/* heading */}
                <Box className="text-left">
                  <h4 className="heading">Built In RMS Information</h4>
                </Box>

                <table className="w-100 border border-l-blue border-r-blue">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        width="15%"
                        className="border border-2 py-1 px-2 bg-tbl-border border-l-blue border-t-blue border-r-blue"
                      >
                        Brand
                      </th>
                      <th
                        scope="col"
                        width="15%"
                        className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                      >
                        Connected
                      </th>
                      <th
                        scope="col"
                        width="15%"
                        className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                      >
                        Mac Address
                      </th>
                    </tr>
                  </thead>

                  {aedDetails?.builtin_RMS_info.length > 0 &&
                    aedDetails?.builtin_RMS_info.map(
                      (innerItem, innerIndex) => (
                        <tbody
                          className="odd-even-row border-b-blue"
                          key={innerIndex}
                        >
                          <tr className="">
                            {aedDetails?.rms_info.length > 0 &&
                              aedDetails?.rms_info.map((i, ind) => (
                                <td
                                  className="border border-2 py-1 px-2 bg-tbl-border border-l-blue border-r-blue"
                                  key={ind}
                                >
                                  {/* {handleRmsBrand(i.rms_name)} */}
                                  {i?.rms_name ? i?.rms_name : ""}
                                </td>
                              ))}
                            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                              {aedDetails?.builtin_RMS_info[0]?.connected ===
                                false ? (
                                <CloseIcon color={"error"} />
                              ) : (
                                <CheckIcon sx={{ color: "#00FF00" }} />
                              )}
                            </td>
                            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                              {aedDetails?.builtin_RMS_info[0]?.mac_address}
                            </td>
                          </tr>
                        </tbody>
                      )
                    )}
                </table>
              </div>
            )
        )}

      {CheckAEdCheckers(result) ? (
        <>
          <div className="account-info pb-4">
            {/* heading */}
            <Box className="text-left">
              <h4 className="heading">AED Checkers</h4>
            </Box>

            <table className="w-100 border border-l-blue border-r-blue">
              <thead>
                <tr className="">
                  <th
                    scope="col"
                    width="15%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-l-blue border-t-blue border-r-blue"
                  >
                    Primary
                  </th>
                  <th
                    scope="col"
                    width="15%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Backup 1
                  </th>
                  <th
                    scope="col"
                    width="15%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Backup 2
                  </th>
                  <th
                    scope="col"
                    width="15%"
                    className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  >
                    Backup 3
                  </th>
                </tr>
              </thead>
              {/* second row */}
              <tbody className="odd-even-row border-b-blue">
                <tr className="">
                  {result?.map((it) => (
                    <td className="border border-2 py-1 px-2 bg-tbl-border border-l-blue border-r-blue">
                      {it?.primary} {it?.backup1} {it?.backup2} {it?.backup3}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        ""
      )}

      {/* Coordinator Information */}
      <Box>
        <h4 className="heading mt-3">Coordinator Information</h4>
        <table className="border w-100 last-table-border-hide last-r-border-none">
          <tbody>
            <tr>
              {cordinatorInfo?.map((CoorD, index) => (
                <th
                  className="border py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                  key={index}
                >
                  {CoorD?.title}
                </th>
              ))}
            </tr>
            <tr>
              {cordinatorInfo?.map((CoorD, i1) => (
                <ContactCell
                  key={i1}
                  label="P"
                  contactArr={CoorD?.arr}
                  accountId={aedDetails?.account_id}
                  field="primary"
                  loading={loading}
                />
              ))}
            </tr>
            <tr>
              {cordinatorInfo?.map((CoorD, i1) => (
                <ContactCell
                  key={i1}
                  label="B"
                  contactArr={CoorD?.arr}
                  accountId={aedDetails?.account_id}
                  field="backup"
                  loading={loading}
                />
              ))}
            </tr>
          </tbody>
        </table>
      </Box>
      {/* <div style={{ marginTop: "70px", marginBottom: "10px" }}>
        <Box
          className="d-flex justify-content-evenly align-items-center"
          style={{ gap: "50px" }}
        >
          <p>
            Created Date:{" "}
            {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) :(
                <>
            {aedDetails?.created_date ? (
              <Moment
                date={aedDetails?.created_date}
                format={"MM/DD/YYYY h:mm A"}
              />
            ) : (
              ""
            )}
            </>
              )}
          </p>
          <p>Created By: {aedDetails?.created_by}</p>
          <p>
            Modified Date:{" "}
            {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) :(
                <>
            {aedDetails?.modified_date ? (
              <Moment
                date={aedDetails?.modified_date}
                format={"MM/DD/YYYY h:mm A"}
              />
            ) : (
              ""
            )}{" "}
            </>
              )}
          </p>
          <p>Modified By: 
          {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) :(
                <>
            {aedDetails?.modified_by}
            </>
              )}
            </p>
          <p>
            Last Touch Date:{" "}
            {aedDetails?.last_check ? FormatDate(aedDetails?.last_check) : ""}
          </p>
        </Box>
      </div> */}
      <div className="pb-0" style={{ marginTop: "30px", marginBottom: "50px" }}>
        <div className="d-flex Created-Modified">
          <div className="CreatedDiv">
            <span>
              Created :{" "}
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                <>
                  {aedDetails?.created_by}{" "}
                  {aedDetails?.created_date ? (
                    <Moment
                      date={convertToUserTime(aedDetails?.created_date)}
                      format={"MM/DD/YYYY h:mm A"}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </span>
          </div>

          <div className="ModifiedDiv">
            <span>
              Modified :{" "}
              {loading ? (
                <>
                  <Skeleton
                    count={5}
                    width={"90%"}
                    height={20}
                    style={{ marginLeft: "2px", marginRight: "2px" }}
                  />
                </>
              ) : (
                <>
                  {aedDetails?.modified_by}{" "}
                  {aedDetails?.modified_date ? (
                    <Moment
                      date={aedDetails?.modified_date}
                      format={"MM/DD/YYYY h:mm A"}
                    />
                  ) : (
                    ""
                  )}{" "}
                </>
              )}
            </span>
            {/* <span>
              Modified By:{" "}
              {accountDetails?.modifiedBy?.account_name
                ? accountDetails?.modifiedBy?.account_name
                : accountDetails?.modifiedBy}
            </span> */}
          </div>
          {/* <span>Last Touch Date: 11-14-2020 12:00:00</span> */}
        </div>
      </div>
    </div>
  );
}
