import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ImportCsvTab } from "../../../common/hooks";
import { addNewBreadcrumbs, handleBreadcrumbsClick } from "../../../store/slices/breadcrumbsSlice.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AccessoriesTab from "../Components/Tabs/AccessoriesTab.jsx";
import AedsTab from "../Components/Tabs/AedsTab.jsx";
import AccountTab from "../Components/Tabs/AccountTab.jsx";
import SiteTab from "../Components/Tabs/SiteTab.jsx";
import ContactTab from "../Components/Tabs/ContactTab.jsx";

const ImportCsv = () => {
  const { tab } = useParams();
  const currentTab = tab || "Accounts";
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const breadcrumbsFromStore = useSelector(
  //   (state) => state.BreadCrumbs.breadcrumbs
  // );

  // const BreadCrumbsObject = {
  //   title: "",
  //   tab: "",
  // };
  // const handleDispatch = (item) => {
  //   dispatch(
  //     addNewBreadcrumbs({
  //       ...BreadCrumbsObject,
  //       url: `/import-csv/${item}`,
  //     })
  //   );
  // };

  const handleTab = (item) => {
    // BreadCrumbsObject.title = item;
    navigate(`/import-csv/${item}`);
    // handleDispatch(item);
  };

  return (
    <>
      <div
        className="mt-4"
        style={{ position: "relative", width: "100%", paddingInline: "0px" }}
      >
        <Box className="text-left pt-3 pb-1 mb-2">
          <h4 className="heading">Import CSV File</h4>
        </Box>
        <Box>
          <div className="d-flex border-bottom border-secondary bg-primary">
            {Object.values(ImportCsvTab).map((tabItem, i) => (
              <div
                role="button"
                key={i}
                className="text-light tab-button"
                onClick={() => handleTab(tabItem)}
                style={{
                  backgroundColor: `${
                    tabItem === currentTab ? "#26AEE0" : "#0C71C3"
                  }`,
                }}
              >
                {tabItem}
              </div>
            ))}
          </div>
        </Box>

        {currentTab == ImportCsvTab.ACCOUNT && (
          <AccountTab />
        )}

        {currentTab == ImportCsvTab.SITES && (
          <SiteTab />
        )}

        {currentTab == ImportCsvTab.CONTACTS && (
          <ContactTab />
        )}

        {currentTab == ImportCsvTab.AEDS && (
          <AedsTab />
        )}

        {currentTab == ImportCsvTab.ACCESSORY && (
          <AccessoriesTab />
        )}
      </div>
    </>
  );
};

export default ImportCsv;
