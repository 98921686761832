import React from "react";
import { Box, Button } from "@mui/material";

const DownloadCSV = () => {
  const batteryColumns = ["brand", "model", "dni", "battery_part", "battery_lot",	"battery_udi", "install_9v_date",	"install_before_date", "install_date",	"manufactured_date", "battery_expiration", "quantity", "battery_serial"];
  const padColumns = ["brand", "model", "dni", "pad_part",	"pad_expiration",	"pad_lot", "pad_udi", "quantity"];
  const ChargePakColumns = ["brand", "model", "dni", "battery_expiration",	"battery_part",	"battery_lot", "charge_pak_uid", "pad_1_lot",	"pad_1_expiration",	"pad_1_pad_part",	"pad_2_lot", "pad_2_expiration", "pad_2_pad_part", "quantity"];

  const convertToCSV = (columns) => {
    // Create a single row for the columns (no data, just the headers)
    return columns.join(",") + "\n";
  };

  const handleDownload = (columns, filename = "sample.csv") => {
    const csvContent = convertToCSV(columns);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };


  return (
    <>
      <Box className="d-flex mt-2" style={{ gap: "1rem" }}>
        {/* Download Button */}
        <Button
          variant="contained"
          color="info"
          size="small"
          sx={{ fontSize: '0.75rem' }}
          onClick={() => handleDownload(batteryColumns, "accessory-battery.csv")}
        >
          Sample Battery CSV File
        </Button>

        <Button
          variant="contained"
          color="info"
          size="small"
          sx={{ fontSize: '0.75rem' }}
          onClick={() => handleDownload(padColumns, "accessory-pad.csv")}
        >
          Sample Pad CSV File
        </Button>

        <Button
          variant="contained"
          color="info"
          size="small"
          sx={{ fontSize: '0.75rem' }}
          onClick={() => handleDownload(ChargePakColumns, "accessory-charge-pak.csv")}
        >
          Sample Charge-Pak CSV File
        </Button>
      </Box>
    </>
  );
};

export default DownloadCSV;