import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SwitchUserModel from "../../modals/switchUser/switchUser";

const MainAdminMenuBar = ({
  user,
  anchorEl,
  open,
  handleClose,
  moveSubAdminToUser,
  handleSwitchSubAdmin,
  handleLogout,
}) => {
  const [openSwitchUser, setOpenSwitchUser] = useState(false);

  return (
    <div>
      <Menu
        className="user-dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: { position: "absolute", backgroundColor: "black" },
        }}
      >
        <></>
        <MenuItem
          onClick={() => {
            setOpenSwitchUser(true);
            handleClose();
          }}
        >
          Switch User
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      {openSwitchUser && (
        <SwitchUserModel
          openSwitchUser={openSwitchUser}
          setOpenSwitchUser={setOpenSwitchUser}
        />
      )}
    </div>
  );
};

export default MainAdminMenuBar;
