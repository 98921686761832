import { useState } from "react";
import { Box } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getPermission } from "../../../../common/helper/Common";
import { DecryptToken } from "../../../../common/helper/BasicFn";
import New from "../../../../common/img/New.png";
import NoteListing from "../Note-Listing";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

export default function Notes({
  accountId,
  site_id = 0,
  contact_id = 0,
  aed_id = 0,
  type,
  note_api,
  inperson_id = 0,
  fetch_key,
}) {
  const [noteApi, setNoteApi] = useState();

  let redirectUrl = ``;
  if (type === "ACCOUNT" || type === "account") {
    redirectUrl = `/account/new-note?account_id=${accountId}`;
  }

  if (type === "CONTACT" || type === "contact") {
    redirectUrl = `/account/new-note?account_id=${accountId}&contact_id=${contact_id}`;
  }
  if (type === "SITE" || type === "site") {
    redirectUrl = `/account/new-note?account_id=${accountId}&site_id=${site_id}`;
  }
  if (type === "AED" || type === "aed") {
    redirectUrl = `/account/new-note?account_id=${accountId}&aed_id=${aed_id}`;
  }

  // if (type === 'INPERSON') {
  //   redirectUrl = `/account/new-note?account_id=${accountId}&inperson_id=${inperson_id}`;
  // }
  const user = DecryptToken();
  const privilege = getPermission();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNewNoteDispatch = ({ url }) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
    };
    BreadCrumbsObject.title = "New Note Form";
    BreadCrumbsObject.tab = "note";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: url,
      })
    );
  };

  return (
    <div className="relative" style={{ marginBottom: "5%" }}>
      {/* heading */}

      <Box
        className="d-flex justify-content-between align-items-center mt-2"
        style={{ marginBottom: "0px !important" }}
      >
        <h3 className="heading">{/* Notes Information */}</h3>
        <div style={{ display: "flex", flexDirection: "row", gap: "0px" }}>
          {Number(isPermission({ type: "btn", name: "newNote" })) ? (
            <Link
              style={{ textDecoration: "none", marginRight: "10px" }}
              to={redirectUrl}
              onClick={() => handleNewNoteDispatch({ url: redirectUrl })}
            >
              <img src={New} alt="New" />
              <span style={{ color: "#0C71C3" }}> New</span>
            </Link>
          ) : (
            ""
          )}
        </div>
      </Box>

      {/* data grid table*/}
      <div className="data-table pb-3">
        <NoteListing
          note_api={note_api}
          type={type}
          accountId={accountId}
          site_id={site_id}
          contact_id={contact_id}
          aed_id={aed_id}
          fetch_key={fetch_key}
        />
      </div>
    </div>
  );
}
