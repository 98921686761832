import React from "react";

function SingleSiteName({ equipmentFilterData, item }) {
  let show_site_name = 0;

  if (Object.keys(equipmentFilterData).length !== 0) {
    if (equipmentFilterData?.aed && item?.data.length > 0) {
      show_site_name = 1;
    }
    if (equipmentFilterData?.accessories && item?.standalone_data.length > 0) {
      show_site_name = 1;
    }
  } else {
    if (item?.data.length === 0 && item?.standalone_data.length === 0) {
      show_site_name = 1;
    }
    if (item?.data.length > 0) {
      show_site_name = 1;
    }
    if (item?.standalone_data.length > 0) {
      show_site_name = 1;
    }
  }

  return (
    <>
      <div className="">
        {show_site_name ? (
          <div className="text-title">
            <h2 className="aed-title" style={{ fontSize: "15px" }}>
              {item?.site_name}
            </h2>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default SingleSiteName;
