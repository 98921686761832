import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import PreviewTable from "../../Table/PreviewTable";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Form } from "react-bootstrap";
import PaginateAutoComplete from "../../../../common/components/custom-ui/PaginateAutoComplete";
import { BASE_API } from "../../../../common/services";
import ImportResultsModal from "../Modal/ImportResultsModal";
import ProgressBar from "../ProgressBar";
import ResponseTable from "../../Table/ResponseTable";

const AedGeneralInfo = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [resultData, setResultData] = useState([]);
  const [reportModal, setreportModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);

  const [rejectedRecords,setRejectedRecords] = useState([]); // stores rejcteded and failed records 

  const handleFileChange = (e) => {
    // const file = e.target.files[0];
    const fileInput = e.target;
    const file = fileInput.files[0];

    fileInput.value = null; // Reset file input

    if (file) {
      const maxSizeInBytes = 26214400; // 25 MB

      if (file.size > maxSizeInBytes) {
        toast.error("File size must be less than 25MB.");
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
      }
      setResultData([]);
    } else {
      setSelectedFile(null);
    }
  };

  // this function stand for  date convert into proper date format from the excel
  // function excelSerialDateToJSDate(serial) {
  //   if (serial === "unknown") {
  //     return "unknown";
  //   }
    
  //   if (serial === "" || serial === null || serial === undefined) {
  //     return "";
  //   }

  //   try {
  //     // console.log("Initial serial value:", serial);
  //     // console.log("Type of initial serial value:", typeof serial);

  //     // If the serial is already a Date object, format it
  //     if (serial instanceof Date && !isNaN(serial.getTime())) {
  //       return formatDate(serial);
  //     }

  //     // Parse string to number if needed
  //     if (typeof serial === "string") {
  //       const dateFromString = new Date(serial);
  //       if (!isNaN(dateFromString.getTime())) {
  //         return formatDate(dateFromString);
  //       }
  //       serial = parseFloat(serial);
  //     }

  //     // Validate serial number
  //     if (typeof serial !== "number" || serial <= 0 || isNaN(serial)) {
  //       console.error(`Invalid serial number detected: ${serial}`);
  //       return "";
  //     }

  //     // Adjust for Excel's leap year bug
  //     if (serial > 59) {
  //       serial -= 1;
  //     }

  //     // Excel epoch (January 1, 1900 at midnight UTC)
  //     const EXCEL_EPOCH = new Date(Date.UTC(1900, 0, 1));
  //     const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

  //     // Calculate days and fractional days
  //     const totalDays = Math.floor(serial);
  //     const fractionalDay = serial - totalDays;

  //     // Calculate the date
  //     const dateValue = new Date(
  //       EXCEL_EPOCH.getTime() + (totalDays - 1) * MILLISECONDS_PER_DAY
  //     );

  //     // Add the time portion
  //     if (fractionalDay > 0) {
  //       dateValue.setMilliseconds(fractionalDay * MILLISECONDS_PER_DAY);
  //     }

  //     return formatDate(dateValue);
  //   } catch (error) {
  //     console.error("Error converting Excel serial to date:", error);
  //     return "";
  //   }
  // }

  // // Helper function to format the date
  // function formatDate(date) {
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   return `${month}/${day}/${year}`;
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedAccount) {
      toast.error("Please select a account.");
      return;
    }

    if (!selectedSite) {
      toast.error("Please select a site.");
      return;
    }

    if (!selectedFile) {
      toast.error("Please select a file.");
      return;
    }

    setLoading(true);
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { defval: null, raw: false }); // Convert sheet to JSON data

        // const dateFields = [
        //   "purchase_date",
        //   "warranty_date",
        //   "last_check",
        //   "last_service",
        //   "rms_check"
        // ];

        // const formattedData = jsonData.map((row) => {
        //   const updatedRow = { ...row };

        //   dateFields.forEach((field) => {
        //     if (updatedRow[field]) {
        //       updatedRow[field] = excelSerialDateToJSDate(updatedRow[field]);
        //     }
        //   });

        //   return updatedRow;
        // });

        // console.log({ formattedData });
        
        // setFileData(jsonData); // Store data in the state
        if (jsonData.length === 0) {
          // If no data, generate an object with columns as keys and null as values
          const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] || [];
          const emptyRow = headers.reduce((acc, header) => {
            acc[header] = null;
            return acc;
          }, {});
          setFileData([emptyRow]); 
        } else {
          setFileData(jsonData); // Store data with valid values
        }
      };

      reader.readAsBinaryString(selectedFile); // Read the file as binary string
    } catch (error) {
      toast.error("Error processing file.");
    } finally {
      setLoading(false);
    }
  };


  const handleUpload = async () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      const res = await fetch(
        `${BASE_API}import/aed-import/${selectedAccount?.accountId}/${selectedSite?.siteId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (res.status) {
        const result = await res.json();
        const successRecords = result?.data?.successful_records || [];
        if (successRecords.length > 0) {
          toast.success("File uploaded successfully!");
        } else {
          toast.error(result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records ? rsData?.rejected_records.map((item) => item.record) : [];
        const failedData = rsData?.failed_records ? rsData?.failed_records.map((item) => item.record) : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);
        setResultData(result.data);
        setSelectedFile(null);
        setFileData([]);

        let rejected = result?.data?.summary?.rejected_records || 0;
        let failed = result?.data?.summary?.failed_records || 0;
        if (rejected === 0 && failed === 0) {
          setSelectedAccount("");
          setSelectedSite("");
        }
      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    "aed_brand",
    "aed_model",
    "sub_model",
    "part_number",
    "serial_number",
    "asset",
    "installed",
    "invoice",
    "placement",
    "purchase_type",
    "purchase_date",
    "warranty_date",
    "no_spares_toggle",
    "no_pediatric_toggle",
    "rms_toggle",
    "out_of_service_toggle",
    "pediatric_key",
    "ready_status",
    "last_check",
    "last_service",
    "rms_check",
  ];

  const convertToCSV = (columns) => {
    // Create a single row for the columns (no data, just the headers)
    return columns.join(",") + "\n";
  };

  const handleDownload = (columns, filename = "aed-upload.csv") => {
    const csvContent = convertToCSV(columns);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  const headerColumns = [
    {
      Header: "aed_brand",
      accessor: "aed_brand",
      disableSortBy: true,
    },
    {
      Header: "aed_model",
      accessor: "aed_model",
      disableSortBy: true,
    },
    {
      Header: "sub_model",
      accessor: "sub_model",
      disableSortBy: true,
    },
    {
      Header: "part_number",
      accessor: "part_number",
      disableSortBy: true,
    },
    {
      Header: "serial_number",
      accessor: "serial_number",
      disableSortBy: true,
    },
    {
      Header: "asset",
      accessor: "asset",
      disableSortBy: true,
    },
    {
      Header: "installed",
      accessor: "installed",
      disableSortBy: true,
    },
    {
      Header: "invoice",
      accessor: "invoice",
      disableSortBy: true,
    },
    {
      Header: "placement",
      accessor: "placement",
      disableSortBy: true,
    },
    {
      Header: "purchase_type",
      accessor: "purchase_type",
      disableSortBy: true,
    },
    {
      Header: "purchase_date",
      accessor: "purchase_date",
      disableSortBy: true,
    },
    {
      Header: "warranty_date",
      accessor: "warranty_date",
      disableSortBy: true,
    },
    {
      Header: "no_spares_toggle",
      accessor: "no_spares_toggle",
      disableSortBy: true,
    },
    {
      Header: "no_pediatric_toggle",
      accessor: "no_pediatric_toggle",
      disableSortBy: true,
    },
    {
      Header: "rms_toggle",
      accessor: "rms_toggle",
      disableSortBy: true,
    },
    {
      Header: "out_of_service_toggle",
      accessor: "out_of_service_toggle",
      disableSortBy: true,
    },
    {
      Header: "pediatric_key",
      accessor: "pediatric_key",
      disableSortBy: true,
    },
    {
      Header: "ready_status",
      accessor: "ready_status",
      disableSortBy: true,
    },
    {
      Header: "last_check",
      accessor: "last_check",
      disableSortBy: true,
    },
    {
      Header: "last_service",
      accessor: "last_service",
      disableSortBy: true,
    },
    {
      Header: "rms_check",
      accessor: "rms_check",
      disableSortBy: true,
    },
  ];


  const handleEdit = async () => {
    const convertToCSV = (data) => {
      const entries = Object.values(data);
      const headers = Object.keys(entries[0]);

      const rows = entries.map((row) =>
        headers.map((header) => `"${row[header] ?? ""}"`).join(",")
      );
      return [headers.join(","), ...rows].join("\n");
    };

    const csvString = convertToCSV(updatedData);

    const blob = new Blob([csvString], { type: "text/csv" });
    const formData = new FormData();
    formData.append("file", blob, "editedData.csv");

    try {
      setLoading(true);
      const token = localStorage.getItem("ross_token");
      if (!token) throw new Error("No Token found");

      const res = await fetch(
        `${BASE_API}import/aed-import/${selectedAccount?.accountId}/${selectedSite?.siteId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (res.status) {
        const result = await res.json();

        const successRecords = result?.data?.successful_records || [];
        if (successRecords.length > 0) {
          toast.success("File uploaded successfully!");
        } else {
          toast.error(result?.msg || "No Records Successfully Imported!");
        }

        const rsData = result.data || [];

        // Extracting the "record" only from the rejected_records.
        const rejectedData = rsData?.rejected_records ? rsData?.rejected_records.map((item) => item.record) : [];
        const failedData = rsData?.failed_records ? rsData?.failed_records.map((item) => item.record) : [];
        const rejectRecords = [...rejectedData, ...failedData];
        setRejectedRecords(rejectRecords);
        setResultData(result?.data);
        setUpdatedData([]);

        let rejected = result?.data?.summary?.rejected_records || 0;
        let failed = result?.data?.summary?.failed_records || 0;
        if (rejected === 0 && failed === 0) {
          setSelectedAccount("");
          setSelectedSite("");
        }
      } else {
        toast.error("Failed to upload file.");
      }
    } catch (error) {
      toast.error("Error during file upload.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box className="d-flex mt-2" style={{ gap: "1rem" }}>
        {/* File Input Section */}

        <Form onSubmit={handleSubmit}>
          <div className="d-flex">
            <Form.Group style={{ width: "250px", marginRight: "5px" }}>
              <Form.Label>Select Account*</Form.Label>
              <PaginateAutoComplete
                dropDownName={"account_id"}
                apiEndpoint={"account/account-list-dropdown"}
                idKey={"account_id"}
                valueKey={"account_name"}
                parentKey={"allAccount"}
                onSelect={(e, val) => {
                  setSelectedAccount({
                    accountId: val?.account_id,
                    accountName: val?.account_name,
                  });
                }}
                className={"mt-1"}
                shouldFetchOnOpen={false}
                isCache={true}
                selectDisabled={false}
                isSearchable={true}
                isAsync={true}
                pageSize={20}
                defaultValue={{
                  account_id: selectedAccount?.accountId,
                  account_name: selectedAccount?.accountName,
                }}
              />
            </Form.Group>

            <Form.Group style={{ width: "250px", marginRight: "5px" }}>
              <Form.Label>Select Site*</Form.Label>
              <PaginateAutoComplete
                dropDownName="sites"
                apiEndpoint={`site/account-site-list/${selectedAccount?.accountId}?site_account_info=1`}
                idKey={"account_site_info_id"}
                valueKey={"account_site_name"}
                parentKey={"site_details"}
                onSelect={(e, val) => {
                  setSelectedSite({
                    siteId: val?.account_site_info_id,
                    siteName: val?.account_site_name,
                  });
                }}
                className={"mt-1"}
                shouldFetchOnOpen={true}
                isCache={true}
                selectDisabled={false}
                isSearchable={true}
                isAsync={true}
                multiple={false}
                defaultValue={{
                  account_site_info_id: selectedSite?.siteId,
                  account_site_name: selectedSite?.siteName,
                }}
                getResults={(res) => {
                  const result = res?.data?.data?.site_details || [];
                  const totalCountsVar = res?.data?.data?.totalCount || 0;
                  return [result, totalCountsVar];
                }}
              />
            </Form.Group>

            <Form.Group
              controlId="fileInput"
              className="d-flex align-items-center"
            >
              <div className="d-flex flex-column">
                <Form.Label>AED File*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Select Input File"
                  defaultValue={selectedFile?.name}
                  readOnly
                  style={{ width: "250px" }}
                />
              </div>
              <div className="ms-2 file-input-div">
                <Button
                  variant="link"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    padding: 0,
                  }}
                >
                  <img
                    src="/assets/images/upload.svg"
                    alt="upload"
                    style={{ width: "30px", height: "30px", marginTop: "30px" }}
                  />
                  <Form.Control
                    type="file"
                    name="file_name"
                    accept=".xlsx, xls, .csv"
                    style={{
                      opacity: 0,
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
            </Form.Group>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{ marginTop: "35px" }}
            >
              Submit
            </Button>
          </div>
        </Form>

        {/* Download Button */}
        <Button
          variant="contained"
          color="info"
          sx={{ marginTop: "35px" }}
          onClick={() => handleDownload(columns)}
        >
          Download Sample CSV File
        </Button>
      </Box>

      <ImportResultsModal
        show={reportModal}
        onHide={() => setreportModal(false)}
        results={resultData}
      />

      {resultData && resultData?.summary?.total_records > 0 && (
        <div className="mt-4">
          <div style={{ display: "flex", gap: "0.5rem" }}>
            {resultData?.summary?.rejected_records > 0 ||
            resultData?.summary?.failed_records > 0 ? (
              <button
                className="btn btn-warning"
                onClick={() => setreportModal(true)}
                style={{
                  padding: "0.5rem",
                  borderRadius: "4px",
                  textAlign: "center",
                  width: "180px",
                  fontSize: "1rem",
                }}
              >
                See Details
              </button>
            ) : null}

            {[
              {
                label: "Total Records",
                value: resultData?.summary?.total_records || 0,
                color: "#0d6efd",
              },
              {
                label: "Successful Records",
                value: resultData?.summary?.successful_records || 0,
                color: "#2e7d32",
              },
              {
                label: "Rejected Records",
                value:
                  (resultData?.summary?.rejected_records || 0) +
                  (resultData?.summary?.failed_records || 0),
                color: "#D32F2F",
              },
            ].map((item, idx) => (
              <div
                key={idx}
                style={{
                  backgroundColor: item.color,
                  color: "white",
                  padding: "0.5rem",
                  borderRadius: "4px",
                  textAlign: "center",
                  width: "auto",
                  fontSize: "1rem",
                }}
              >
                <span>
                  {item.label} : {item.value}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {fileData.length > 0 && (
        <div className="mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h5> Preview </h5>
            <button
              style={{
                padding: "0.5rem 1rem",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleUpload}
            >
              {loading ? "Uploading..." : "Upload File"}
            </button>
          </div>

          <PreviewTable
            tableData={fileData}
            isSearchable={true}
            headerColumns={headerColumns}
            setSelectedFile={setSelectedFile}
          />
        </div>
      )}

      {(resultData?.summary?.rejected_records > 0 ||
        resultData?.summary?.failed_records > 0) && (
        <div className="mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h5> Rejected Records: </h5>
            <button
              style={{
                padding: "0.5rem 1rem",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleEdit}
            >
              {loading ? "Uploading..." : "Upload Changes"}
            </button>
          </div>

          <ResponseTable
            isSearchable={true}
            tableData={rejectedRecords}
            headerColumns={headerColumns}
            setUpdatedData={setUpdatedData}
          />
        </div>
      )}

      {/* Progress Bar */}
      {loading && <ProgressBar />}
    </>
  );
};

export default AedGeneralInfo;
