export const AccessoryFilter = ({ aedList, equipmentFilterData }) => {
  let accData = aedList.map((acc) => acc.standalone_data);
};

export const filterPendingAedDatas = (pendingAedList, equipmentFilterData) => {
  // Return early if no data
  if (!pendingAedList || !equipmentFilterData) return [];

  // Helper function to add days to a date
  const addDays = (dateStr, days) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + days);
    return date;
  };

  // Helper function to convert string like "15 Days" to number
  const getDaysFromString = (str) => {
    if (!str) return 0;
    const match = str.match(/(\d+)/);
    return match ? parseInt(match[0]) : 0;
  };

  return pendingAedList
    .map((item) => {
      const filteredItem = {
        data: [],
        site_id: item.site_id,
        site_name: item.site_name,
        standalone_data: item.standalone_data || [],
      };

      filteredItem.data = item.data.filter((data) => {
        // Model filter
        const isModelMatch =
          equipmentFilterData.aed_brand_model?.length > 0
            ? equipmentFilterData.aed_brand_model.some(
                (model) => Number(data.aed_model_id) === Number(model.value)
              )
            : true;

        // Site filter
        const isSiteMatch =
          equipmentFilterData.sites?.length > 0
            ? equipmentFilterData.sites.some(
                (site) => Number(data.site_id) === Number(site.value)
              )
            : true;

        // State filter
        const isStateMatch =
          equipmentFilterData.state?.length > 0
            ? equipmentFilterData.state.some(
                (state) => Number(data.state_id) === Number(state.value)
              )
            : true;

        // Assigned user filter - Modified logic
        const isAssignedUserMatch =
          equipmentFilterData.aed_checkers?.length > 0
            ? (() => {
                try {
                  if (!data.assign_users) return false;
                  const assignedUsers = JSON.parse(data.assign_users);
                  if (!assignedUsers?.length) return false;
                  const firstUserContactId = assignedUsers[0].contact_id;
                  return equipmentFilterData.aed_checkers.some(
                    (checker) =>
                      Number(checker.value) === Number(firstUserContactId)
                  );
                } catch (e) {
                  console.error("Error parsing assign_users:", e);
                  return false;
                }
              })()
            : true; // Return true if no checkers selected

        // Date range filter
        let isDateRangeMatch = true;
        if (
          equipmentFilterData.expiration_date_range1 &&
          equipmentFilterData.expiration_date_range2
        ) {
          const lastCheckDate = new Date(data.last_check);
          const range1Date = new Date(
            equipmentFilterData.expiration_date_range1
          );
          const range2Date = new Date(
            equipmentFilterData.expiration_date_range2
          );
          isDateRangeMatch =
            lastCheckDate >= range1Date && lastCheckDate <= range2Date;
        }

        let isExpirationRangeMatch = true;
        if (
          Number(equipmentFilterData.expiration_range_type?.value) === 0 &&
          (data.last_check !== null || data.last_check !== "")
        ) {
          const lastCheckDate = new Date(data.last_check);
          const daysToAdd = getDaysFromString(data.aed_check_length);
          const expirationDate = addDays(lastCheckDate, daysToAdd);
          const today = new Date();

          // Only include if expiration date is in the expired
          isExpirationRangeMatch = expirationDate < today;
        } else if (
          equipmentFilterData.expiration_range_type?.value &&
          (data.last_check !== null || data.last_check !== "")
        ) {
          const lastCheckDate = new Date(data.last_check);
          const daysToAdd = getDaysFromString(data.aed_check_length);
          const expirationDate = addDays(lastCheckDate, daysToAdd);
          const today = new Date();

          if (today < expirationDate) {
            const daysDifference = Math.abs(
              Math.ceil((today - expirationDate) / (1000 * 60 * 60 * 24))
            );
            isExpirationRangeMatch =
              daysDifference <= equipmentFilterData.expiration_range_type.value;
          } else {
            isExpirationRangeMatch = false;
          }
        }

        // Return true only if all conditions match
        return (
          isModelMatch &&
          isSiteMatch &&
          isStateMatch &&
          isAssignedUserMatch &&
          isDateRangeMatch &&
          isExpirationRangeMatch
        );
      });

      return filteredItem;
    })
    .filter((item) => item.data.length > 0); // Only return items that have matching data
};

export const filterPendingAccessoryDatas = (
  pendingAedList,
  equipmentFilterData
) => {
  // Return early if no data
  if (!pendingAedList || !equipmentFilterData) return [];

  return pendingAedList
    .map((item) => {
      const filteredItem = {
        data: item.data || [],
        site_id: item.site_id,
        site_name: item.site_name,
        standalone_data: [],
      };

      // Filter standalone_data (accessories)
      filteredItem.standalone_data = (item.standalone_data || []).filter(
        (accessory) => {
          // Filter by accessory model/brand
          const isModelMatch =
            equipmentFilterData.accessory_brand_model?.length > 0
              ? equipmentFilterData.accessory_brand_model.some(
                  (model) =>
                    Number(accessory.aed_model_id) === Number(model.value)
                )
              : true;

          // Filter by accessory type
          const isTypeMatch =
            equipmentFilterData.accessory_type?.length > 0
              ? equipmentFilterData.accessory_type.some(
                  (type) => accessory.accessory_type === type.value
                )
              : true;

          // Filter by site
          const isSiteMatch =
            equipmentFilterData.sites?.length > 0
              ? equipmentFilterData.sites.some(
                  (site) => Number(accessory.site_id) === Number(site.value)
                )
              : true;

          // Filter by state
          const isStateMatch =
            equipmentFilterData.state?.length > 0
              ? equipmentFilterData.state.some(
                  (state) => Number(accessory.state_id) === Number(state.value)
                )
              : true;

          return isModelMatch && isTypeMatch && isSiteMatch && isStateMatch;
        }
      );

      return filteredItem;
    })
    .filter((item) => item.standalone_data.length > 0); // Only return items that have matching accessories
};

export const filterAedDatas = (aedList, equipmentFilterData) => {
  // Return early if no data
  if (!aedList || !equipmentFilterData) return [];

  // Helper function to add days to a date
  const addDays = (dateStr, days) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + days);
    return date;
  };

  // Helper function to convert string like "15 Days" to number
  const getDaysFromString = (str) => {
    if (!str) return 0;
    const match = str.match(/(\d+)/);
    return match ? parseInt(match[0]) : 0;
  };

  return aedList
    .map((item) => {
      const filteredItem = {
        data: [],
        is_technician: item.is_technician,
        site_id: item.site_id,
        site_name: item.site_name,
        standalone_data: item.standalone_data || [],
      };

      filteredItem.data = item.data.filter((data) => {
        // Original filters
        const isModelMatch =
          equipmentFilterData.aed_brand_model?.length > 0
            ? equipmentFilterData.aed_brand_model.some(
                (model) => Number(data.aed_model_id) === Number(model.value)
              )
            : true;

        const isSiteMatch =
          equipmentFilterData.sites?.length > 0
            ? equipmentFilterData.sites.some(
                (site) => Number(data.site_id) === Number(site.value)
              )
            : true;

        const isStateMatch =
          equipmentFilterData.state?.length > 0
            ? equipmentFilterData.state.some(
                (state) => Number(data.state_id) === Number(state.value)
              )
            : true;

        // Assigned user filter - Modified logic
        const isAssignedUserMatch =
          equipmentFilterData.aed_checkers?.length > 0
            ? (() => {
                try {
                  if (!data.assign_users) return false;
                  const assignedUsers = JSON.parse(data.assign_users);
                  if (!assignedUsers?.length) return false;
                  const firstUserContactId = assignedUsers[0].contact_id;
                  return equipmentFilterData.aed_checkers.some(
                    (checker) =>
                      Number(checker.value) === Number(firstUserContactId)
                  );
                } catch (e) {
                  console.error("Error parsing assign_users:", e);
                  return false;
                }
              })()
            : true; // Return true if no checkers selected

        // New date range filter
        let isDateRangeMatch = true;
        if (
          equipmentFilterData.expiration_date_range1 &&
          equipmentFilterData.expiration_date_range2
        ) {
          const lastCheckDate = new Date(data.last_check);
          const range1Date = new Date(
            equipmentFilterData.expiration_date_range1
          );
          const range2Date = new Date(
            equipmentFilterData.expiration_date_range2
          );
          isDateRangeMatch =
            lastCheckDate >= range1Date && lastCheckDate <= range2Date;
        }

        // Expiration range type filter
        let isExpirationRangeMatch = true;
        if (
          Number(equipmentFilterData.expiration_range_type?.value) === 0 &&
          (data.last_check !== null || data.last_check !== "")
        ) {
          const lastCheckDate = new Date(data.last_check);
          const daysToAdd = getDaysFromString(data.aed_check_length);

          const expirationDate = addDays(lastCheckDate, daysToAdd);
          const today = new Date();

          // Only include if expiration date is in the expired
          isExpirationRangeMatch = expirationDate < today;
        } else if (
          equipmentFilterData.expiration_range_type?.value &&
          (data.last_check !== null || data.last_check !== "")
        ) {
          const lastCheckDate = new Date(data.last_check);
          const daysToAdd = getDaysFromString(data.aed_check_length);
          const expirationDate = addDays(lastCheckDate, daysToAdd);
          const today = new Date();

          if (today < expirationDate) {
            const daysDifference = Math.abs(
              Math.ceil((today - expirationDate) / (1000 * 60 * 60 * 24))
            );
            isExpirationRangeMatch =
              daysDifference <= equipmentFilterData.expiration_range_type.value;
          } else {
            isExpirationRangeMatch = false;
          }
        }

        // Return true only if all conditions match
        return (
          isModelMatch &&
          isSiteMatch &&
          isStateMatch &&
          isDateRangeMatch &&
          isExpirationRangeMatch &&
          isAssignedUserMatch
        );
      });

      return filteredItem;
    })
    .filter((item) => item.data.length > 0); // Only return items that have matching data
};

export const filterAccessoryData = (aedList, equipmentFilterData) => {
  // Return early if no data
  if (!aedList || !equipmentFilterData) return [];

  return aedList.map((item) => {
    const filteredItem = {
      data: item.data || [],
      is_technician: item.is_technician,
      site_id: item.site_id,
      site_name: item.site_name,
      standalone_data: [],
    };

    // Filter standalone_data (accessories)
    filteredItem.standalone_data = (item.standalone_data || []).filter(
      (accessory) => {
        // Filter by accessory model/brand
        const isModelMatch =
          equipmentFilterData.accessory_brand_model?.length > 0
            ? equipmentFilterData.accessory_brand_model.some(
                (model) =>
                  Number(accessory.aed_model_id) === Number(model.value)
              )
            : true;

        // Filter by accessory type
        const isTypeMatch =
          equipmentFilterData.accessory_type?.length > 0
            ? equipmentFilterData.accessory_type.some(
                (type) => accessory.accessory_type === type.value
              )
            : true;

        // Filter by site
        const isSiteMatch =
          equipmentFilterData.sites?.length > 0
            ? equipmentFilterData.sites.some(
                (site) => Number(accessory.site_id) === Number(site.value)
              )
            : true;

        // Filter by state
        const isStateMatch =
          equipmentFilterData.state?.length > 0
            ? equipmentFilterData.state.some(
                (state) => Number(accessory.state_id) === Number(state.value)
              )
            : true;

        // Return true only if all conditions match
        return isModelMatch && isTypeMatch && isSiteMatch && isStateMatch;
      }
    );

    return filteredItem;
  });
  // Only return items that have matching accessories
  // .filter((item) => item.standalone_data.length > 0)
};

export const filterOutOfServiceDatas = (
  outofServiceList,
  equipmentFilterData
) => {
  // Return early if no data
  if (!outofServiceList || !equipmentFilterData) return [];

  // Transform the flat array into the expected site structure
  const siteStructuredData = outofServiceList.reduce((acc, item) => {
    const existingSite = acc.find((site) => site.site_id === item.site_id);

    if (existingSite) {
      existingSite.data.push(item);
    } else {
      acc.push({
        site_id: item.site_id,
        site_name: item.site_name,
        data: [item],
      });
    }

    return acc;
  }, []);

  // Helper function to add days to a date
  const addDays = (dateStr, days) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + days);
    return date;
  };

  // Helper function to convert string like "15 Days" to number
  const getDaysFromString = (str) => {
    if (!str) return 0;
    const match = str.match(/(\d+)/);
    return match ? parseInt(match[0]) : 0;
  };

  return siteStructuredData
    .flatMap((site) => {
      // Process each AED in the site's data array
      return site.data.map((aed) => {
        // Apply all filters
        const isModelMatch =
          equipmentFilterData.aed_brand_model?.length > 0
            ? equipmentFilterData.aed_brand_model.some(
                (model) => Number(aed.aed_model_id) === Number(model.value)
              )
            : true;

        const isSiteMatch =
          equipmentFilterData.sites?.length > 0
            ? equipmentFilterData.sites.some(
                (siteFilter) =>
                  Number(site.site_id) === Number(siteFilter.value)
              )
            : true;

        const isStateMatch =
          equipmentFilterData.state?.length > 0
            ? equipmentFilterData.state.some(
                (state) => Number(aed.state_id) === Number(state.value)
              )
            : true;

        let isAssignedUserMatch = true;
        if (equipmentFilterData.aed_checkers?.length > 0 && aed.assign_users) {
          try {
            const assignedUsers = JSON.parse(aed.assign_users);
            isAssignedUserMatch = assignedUsers.some((user) =>
              equipmentFilterData.aed_checkers.includes(user.contact_id)
            );
          } catch (e) {
            console.error("Error parsing assign_users:", e);
            isAssignedUserMatch = false;
          }
        }

        // Date range filter
        let isDateRangeMatch = true;
        if (
          equipmentFilterData.expiration_date_range1 &&
          equipmentFilterData.expiration_date_range2
        ) {
          const lastCheckDate = new Date(aed.last_check);
          const range1Date = new Date(
            equipmentFilterData.expiration_date_range1
          );
          const range2Date = new Date(
            equipmentFilterData.expiration_date_range2
          );
          isDateRangeMatch =
            lastCheckDate >= range1Date && lastCheckDate <= range2Date;
        }

        // Expiration range type filter
        let isExpirationRangeMatch = true;
        if (equipmentFilterData.expiration_range_type?.value) {
          const lastCheckDate = new Date(aed.last_check);
          const daysToAdd = getDaysFromString(aed.aed_check_length);
          const expirationDate = addDays(lastCheckDate, daysToAdd);
          const today = new Date();
          const daysDifference = Math.abs(
            Math.ceil((today - expirationDate) / (1000 * 60 * 60 * 24))
          );
          isExpirationRangeMatch =
            daysDifference <= equipmentFilterData.expiration_range_type.value;
        }

        // Return the original data if all conditions match
        if (
          isModelMatch &&
          isSiteMatch &&
          isStateMatch &&
          isAssignedUserMatch &&
          isDateRangeMatch &&
          isExpirationRangeMatch
        ) {
          return aed;
        }
        return null;
      });
    })
    .filter(Boolean); // Remove null entries
};

export const hasAccessoryData = (aedList) => {
  if (!aedList || !Array.isArray(aedList)) return 0;

  // Use some() to check if any item has standalone_data with length > 0
  return aedList.some(
    (item) =>
      Array.isArray(item.standalone_data) && item.standalone_data.length > 0
  )
    ? 1
    : 0;
};
