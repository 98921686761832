import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FormControlLabel, Radio } from "@mui/material";
import { removeBlankObj } from "../../../../common/hooks/constrants";

function AdminMainContactPhoneModal({
  // saveForm,
  // open,
  // hanldeModal,
  // emailDataList,
  // phoneDataList,
  // dataType,
  // setSubFormData,
  // setDataType,
  // mailModalTitle,
  // setNewFormData,
  // newFormData,
  // handleContinue,
  // mailObjName,

  saveForm,
  open,
  hanldeModal,
  emailDataList,
  phoneDataList,
  dataType,
  setSubFormData,
  setDataType,
  mailModalTitle,
  setNewFormData,
  newFormData,
  handleContinue,
  mailObjName
}) {
  const [allPhoneData, setAllPhoneData] = React.useState([]);
  const [filteredPhoneArr, setFilteredPhoneArr] = useState([]);

  // useEffect(() => {
  //   const updatedPhoneArr = removeBlankObj(
  //     emailDataList,
  //     "account_main_contact_phone"
  //   );
  //   if (updatedPhoneArr) {
  //     setFilteredPhoneArr(updatedPhoneArr);
  //   }
  // }, [emailDataList]);

  useEffect(() => {
    const updatedPhoneArr = removeBlankObj(emailDataList, 'account_main_contact_phone')
    if (updatedPhoneArr) {
      setFilteredPhoneArr(updatedPhoneArr)
    }
  }, [emailDataList])

  // const handleClose = () => {
  //   console.log("close");
  //   hanldeModal((prev) => ({
  //     ...prev,
  //     [mailObjName]: false,
  //   }));
  //   // setDataType('')
  // };

  const handleClose = () => {
    hanldeModal(false)
    // setDataType('')
  }

  const updateFieldChanged = (e, index) => {
    let newArr = [...filteredPhoneArr];

    if (e.target.type === "radio") {
      newArr[index][e.target.name] = e.target.checked;
    } else {
      newArr[index][e.target.name] = e.target.value;
    }

    newArr.map((data, i) => {
      if (index === i) {
        data.account_main_contact_phone_main = 1;
      } else {
        data.account_main_contact_phone_main = 0;
      }
    });
    setNewFormData({ ...newFormData, [mailObjName]: newArr });
  };

  const handleContinueBtn = () => {
    // handleContinue()
    handleClose();
    // saveForm();
  };
  console.log({ emailDataList, mailObjName });
  return (
    // <>
    //   <Modal show={open[mailObjName]} onHide={handleClose}>
    //     <Modal.Header closeButton>
    //       <Modal.Title>{mailModalTitle}</Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       <ul>
    //         {filteredPhoneArr.map((single, index) => (
    //           <li key={index}>
    //             <Form.Group className={"col d-block"}>
    //               <b className={""}>Main</b>
    //               <div className="">
    //                 <FormControlLabel
    //                   className={""}
    //                   label={`${single?.account_main_contact_phone}`}
    //                   value={true}
    //                   name="account_main_contact_phone_main"
    //                   onChange={(e) => updateFieldChanged(e, index)}
    //                   control={
    //                     <Radio
    //                       color="primary"
    //                       size="medium"
    //                       checked={
    //                         single?.account_main_contact_phone_main === 1 ||
    //                           single?.account_main_contact_phone_main
    //                           ? 1
    //                           : 0
    //                       }
    //                     />
    //                   }
    //                 />
    //               </div>
    //             </Form.Group>
    //           </li>
    //         ))}
    //       </ul>
    //     </Modal.Body>
    //     <Modal.Footer>
    //       <Button variant="primary" onClick={handleContinueBtn}>
    //         Continue
    //       </Button>
    //     </Modal.Footer>
    //   </Modal>
    // </>
    <>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{mailModalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {filteredPhoneArr.map((single, index) => (
              <li key={index} >
                <Form.Group className={'col d-block'}>
                  <b className={''}>Main</b>
                  <div className="">
                    <FormControlLabel
                      className={''}
                      label={`${single?.account_main_contact_phone}`}
                      value={true}
                      name="account_main_contact_phone_main"
                      onChange={(e) => updateFieldChanged(e, index)}
                      control={<Radio color="primary" size="medium" checked={single?.account_main_contact_phone_main === 1 || single?.account_main_contact_phone_main ? 1 : 0} />}
                    />
                  </div>
                </Form.Group>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleContinueBtn}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminMainContactPhoneModal;
