import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStorageType,
  addNewBreadcrumbs,
  clearBreadcrumbs,
} from "../../store/slices/breadcrumbsSlice";

export const useBreadcrumbPersistence = () => {
  const dispatch = useDispatch();
  const breadcrumbs = useSelector((state) => state.BreadCrumbs.breadcrumbs);
  const storageType = useSelector((state) => state.BreadCrumbs.storageType);

  useEffect(() => {
    // Check session storage for user context
    const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

    dispatch(setStorageType(isUserContext ? "sessionStorage" : "localStorage"));

    // Persist breadcrumbs based on storage type
    if (storageType === "localStorage") {
      localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
    } else {
      sessionStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
    }
  }, [breadcrumbs, storageType]);

  // Load breadcrumbs on app initialization
  useEffect(() => {
    const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
    const storedBreadcrumbs = isUserContext
      ? JSON.parse(sessionStorage.getItem("breadcrumbs") || "[]")
      : JSON.parse(localStorage.getItem("breadcrumbs") || "[]");

    storedBreadcrumbs.forEach((breadcrumb) => {
      dispatch(
        addNewBreadcrumbs({
          ...breadcrumb,
          storageType: isUserContext ? "sessionStorage" : "localStorage",
        })
      );
    });
  }, []);
};
