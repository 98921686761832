//Table for showing no data
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TopAedNewMove from "../../../../Aed/NewAed/components/AedButtons/TopAedNewMove";

export default function AccessoryNoDataTbl({
  accountId,
  accountName,
  siteId,
  siteName,
  newAed = 1,
  hideAccMove = 1,
  hideAedMove = 1,
  aedAccessoryCount,
}) {
  const navigate = useNavigate();

  return (
    <>
      <div className="table">
        <table
          // className="table data-table my-4 px-2 theme-table"
          className="theme-table w-100 mt-2"
          style={{
            marginBottom: "3%",
            // fontFamily: "sans-serif",
            fontSize: "12px",
          }}
        >
          <thead className="">
            <tr>
              <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                Part #
              </th>
              <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                Accessory Type
              </th>
              <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                Date
              </th>
              <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                Lot
              </th>
              <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                UDI
              </th>
              <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                DNI
              </th>
              <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                Quantity
              </th>
              {/* <th className="border border-2 py-1 px-2 border-r-blue border-t-blue">
                RMS Check
              </th> */}
            </tr>
          </thead>
          <tbody className="bordered-table">
            <tr>
              <td colSpan={8} className="text-center">
                No Data Found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
