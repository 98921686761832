import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { Skeleton } from "@mui/material";
import { Button, Form } from "react-bootstrap";

const PreviewTable = ({
  tableData = [],
  pageSize = 50, // Default rows per page
  headerColumns = [],
  isSearchable = false,
  isTblUpdated = false,
  setSelectedFile,
}) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [showSearchReset, setShowSearchReset] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading effect
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      if (tableData?.length > 0) {
        setData(tableData);
        setTotalPages(Math.ceil(tableData.length / pageSize));
        setEditedData({});
      }
      setIsLoading(false);
    }, 1000);
  }, [tableData, pageSize, isTblUpdated]);

  const handleEdit = (rowIndex, columnId, value) => {
    const updatedData = [...data];
    updatedData[rowIndex][columnId] = value;
    setData(updatedData);

    // Track changes for saving
    setEditedData((prev) => ({
      ...data,
      [rowIndex]: { ...data[rowIndex], [columnId]: value },
    }));
  };

  const convertToCSV = (data) => {
    const entries = Object.values(data);
    const headers = Object.keys(entries[0]);

    const rows = entries.map((row) =>
      headers.map((header) => `"${row[header] ?? ""}"`).join(",")
    );
    return [headers.join(","), ...rows].join("\n");
  };

  // Update setUpdatedData only if there are any changes in the editedData
  useEffect(() => {
    if (Object.keys(editedData).length > 0) {
      const csvString = convertToCSV(data);
      const blob = new Blob([csvString], { type: "text/csv" });
      const file = new File([blob], "editedData.csv", { type: "text/csv" });

      setSelectedFile(file);
    }
  }, [editedData, data]);

  const calculateWidth = (header) => `${header.length * 15 + 20}px`;

  // Dynamically fetching Columns
  const columns = useMemo(() => {
    return headerColumns.map((column) => ({
      ...column,
      width: column.width || calculateWidth(column.Header),
    }));
  }, [headerColumns]);

  // Get paginated data
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return data.slice(startIndex, startIndex + pageSize);
  }, [data, currentPage, pageSize]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: paginatedData,
      },
      useFilters,
      useSortBy
    );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleSearch = useCallback((e) => {
    e.preventDefault();
    setIsLoading(true);

    if (search?.trim().length > 0) {
      setTimeout(() => {
        const filteredData = tableData.filter((item) =>
          Object.values(item).some((value) =>
            value
              ? value.toString().toLowerCase().includes(search.toLowerCase())
              : ""
          )
        );

        setShowSearchReset(true);
        setData(filteredData);
        setCurrentPage(1);
        setTotalPages(Math.ceil(filteredData.length / pageSize));

        setIsLoading(false);
      }, 500);
    }
  }, [search]);

  const handleReset = () => {
    setIsLoading(true);
    setSearch("");
    setShowSearchReset(false);

    setTimeout(() => {
      setData(tableData);
      setTotalPages(Math.ceil(tableData.length / pageSize));
      setCurrentPage(1);
      setIsLoading(false);
    }, 500);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const hasOnlyNullValues = (data) => {
    return (
      Array.isArray(data) &&
      data.length === 1 &&
      Object.values(data[0]).every((value) => value === null)
    );
  };
  
  return (
    <div className="bg-light base-table">
      {/* Search Bar */}
      {isSearchable && (
        <Form onSubmit={handleSearch}>
          <div className="" style={{ display: "flex", gap: 5 }}>
            <input
              name="search"
              value={search}
              onChange={handleInputChange}
              placeholder="Search by keywords..."
              className="form-control w-25"
            />
            <Button variant="primary" type="submit">
              Search
            </Button>

            {showSearchReset && (
              <Button onClick={handleReset} variant="danger" type="button">
                Reset
              </Button>
            )}
          </div>
        </Form>
      )}

      <div className="table-responsive">
        <table
          className="table table-bordered table-hover"
          {...getTableProps()}
          style={{ borderCollapse: "collapse", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      padding: "8px",
                      backgroundColor: "#999",
                    }}
                    className="tb-td"
                  >
                    {column.render("Header")}
                    <span>
                      {!column.disableSortBy &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <SouthIcon size={2} />
                          ) : (
                            <NorthIcon size={2} />
                          )
                        ) : (
                          <SouthIcon size={2} />
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading ? (
              [...Array(15)].map((_, i) => (
                <tr key={i}>
                  {headerColumns.map((col, j) => (
                    <td
                      key={j}
                      style={{
                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                      }}
                      className="tb-td"
                    >
                      <Skeleton
                        width={"90%"}
                        height={20}
                        style={{ margin: "10px" }}
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : hasOnlyNullValues(tableData) ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  No data found
                </td>
              </tr>
            ) : (
              rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={rowIndex}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          backgroundColor:
                            rowIndex % 2 === 0 ? "white" : "#e4e4e4",
                          position: "relative",
                        }}
                        className="tb-td"
                        contentEditable
                        suppressContentEditableWarning
                        onBlur={(e) => {
                          const newValue = e.target.textContent;
                          if (newValue !== data[rowIndex][cell.column.id]) {
                            handleEdit(rowIndex, cell.column.id, newValue);
                          }
                        }}
                      >
                        {data[rowIndex][cell.column.id]}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div
          className="mt-4 d-flex justify-content-end align-items-center"
          style={{ marginBottom: "20px" }}
        >
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateBefore />
                </button>
              </li>
              {currentPage > 3 && (
                <>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                </>
              )}
              {[...Array(5)].map((_, idx) => {
                const pageNumber = currentPage - 2 + idx;
                return pageNumber > 0 && pageNumber <= totalPages ? (
                  <li key={pageNumber} className="page-item">
                    <button
                      className={`btn mx-1 ${
                        currentPage === pageNumber ? "btn-primary" : "btn-light"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ) : null;
              })}
              {currentPage < totalPages - 2 && (
                <>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                </>
              )}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{ background: "#dad7d763" }}
                >
                  <NavigateNext />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default PreviewTable;

// import React, { useEffect, useState } from "react";
// import { NavigateBefore, NavigateNext } from "@mui/icons-material";
// import Skeleton from "@mui/material/Skeleton"; // Assuming you use MUI Skeleton

// const PreviewTable = ({
//   tableData = [], // Data passed to the component
//   pageSize = 50,
//   headerColumns = [],
//   isSearchable = false,
//   isTblUpdated = false,
// }) => {
//   const [data, setData] = useState([]);
//   const [search, setSearch] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);

//   // Simulate loading effect
//   useEffect(() => {
//     setIsLoading(true);
//     setTimeout(() => {
//       setData(tableData);
//       setTotalPages(Math.ceil(tableData.length / pageSize));
//       setIsLoading(false);
//     }, 1000);
//   }, [tableData, pageSize, isTblUpdated]);

//   const handleSearch = (e) => {
//     e.preventDefault();
//     const filteredData = tableData.filter((item) =>
//       Object.values(item).some((value) =>
//         value.toString().toLowerCase().includes(search.toLowerCase())
//       )
//     );
//     setData(filteredData);
//     setCurrentPage(1);
//     setTotalPages(Math.ceil(filteredData.length / pageSize));
//   };

//   const handleReset = () => {
//     setSearch("");
//     setData(tableData);
//     setTotalPages(Math.ceil(tableData.length / pageSize));
//     setCurrentPage(1);
//   };

//   const paginatedData = data.slice(
//     (currentPage - 1) * pageSize,
//     currentPage * pageSize
//   );

//   const calculateWidth = (header) => `${header.length * 15 + 20}px`;

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   return (
//     <div className="bg-light base-table">
//       {/* Search Bar */}
//       {isSearchable && (
//         <form onSubmit={handleSearch}>
//           <div style={{ display: "flex", gap: 5 }}>
//             <input
//               type="text"
//               value={search}
//               onChange={(e) => setSearch(e.target.value)}
//               placeholder="Search..."
//               className="form-control w-25"
//             />
//             <button type="submit" className="btn btn-primary">
//               Search
//             </button>
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={handleReset}
//             >
//               Reset
//             </button>
//           </div>
//         </form>
//       )}

//       {/* Table */}
//       <div className="table-responsive">
//         <table className="table table-bordered table-hover">
//           <thead>
//             <tr>
//               {headerColumns.map((column, index) => (
//                 <th
//                   key={index}
//                   style={{
//                     width: column.width || calculateWidth(column.Header),
//                     backgroundColor: "#999",
//                     height: "40px",
//                     textAlign: "center",
//                     alignContent: "center",
//                     padding: "5px"
//                   }}
//                   className="tb-td"
//                 >
//                   {column.Header}
//                 </th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {isLoading
//               ? [...Array(15)].map((_, i) => (
//                   <tr key={i}>
//                     {headerColumns.map((col, j) => (
//                       <td
//                         key={j}
//                         style={{
//                           backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
//                         }}
//                         className="tb-td"
//                       >
//                         <Skeleton
//                           width={"90%"}
//                           height={20}
//                           style={{ margin: "10px" }}
//                         />
//                       </td>
//                     ))}
//                   </tr>
//                 ))
//               : paginatedData.length > 0
//               ? paginatedData.map((row, rowIndex) => (
//                   <tr key={rowIndex}>
//                     {headerColumns.map((column, colIndex) => (
//                       <td
//                         key={colIndex}
//                         style={{
//                           backgroundColor:
//                             rowIndex % 2 === 0 ? "white" : "#e4e4e4",
//                         }}
//                         className="tb-td"
//                       >
//                         {row[column.accessor]}
//                       </td>
//                     ))}
//                   </tr>
//                 ))
//               : (
//                 <tr>
//                   <td
//                     colSpan={headerColumns.length}
//                     style={{ textAlign: "center" }}
//                   >
//                     No data found
//                   </td>
//                 </tr>
//               )}
//           </tbody>
//         </table>
//       </div>

//       {/* Pagination */}
//       {totalPages > 1 && (
//         <div className="mt-4 d-flex justify-content-end align-items-center" style={{ marginBottom: "20px"}}>
//           <nav aria-label="Page navigation">
//             <ul className="pagination">
//               <li
//                 className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
//               >
//                 <button
//                   className="btn btn-light mx-1"
//                   onClick={() => handlePageChange(currentPage - 1)}
//                   disabled={currentPage === 1}
//                   style={{ background: "#dad7d763" }}
//                 >
//                   <NavigateBefore />
//                 </button>
//               </li>
//               {currentPage > 3 && (
//                 <>
//                   <li className="page-item">
//                     <button
//                       className="btn btn-light mx-1"
//                       onClick={() => handlePageChange(1)}
//                     >
//                       1
//                     </button>
//                   </li>
//                   <li className="page-item">
//                     <span className="mx-1">...</span>
//                   </li>
//                 </>
//               )}
//               {[...Array(5)].map((_, idx) => {
//                 const pageNumber = currentPage - 2 + idx;
//                 return pageNumber > 0 && pageNumber <= totalPages ? (
//                   <li key={pageNumber} className="page-item">
//                     <button
//                       className={`btn mx-1 ${
//                         currentPage === pageNumber
//                           ? "btn-primary"
//                           : "btn-light"
//                       }`}
//                       onClick={() => handlePageChange(pageNumber)}
//                     >
//                       {pageNumber}
//                     </button>
//                   </li>
//                 ) : null;
//               })}
//               {currentPage < totalPages - 2 && (
//                 <>
//                   <li className="page-item">
//                     <span className="mx-1">...</span>
//                   </li>
//                   <li className="page-item">
//                     <button
//                       className="btn btn-light mx-1"
//                       onClick={() => handlePageChange(totalPages)}
//                     >
//                       {totalPages}
//                     </button>
//                   </li>
//                 </>
//               )}
//               <li
//                 className={`page-item ${
//                   currentPage === totalPages ? "disabled" : ""
//                 }`}
//               >
//                 <button
//                   className="btn btn-light mx-1"
//                   onClick={() => handlePageChange(currentPage + 1)}
//                   disabled={currentPage === totalPages}
//                   style={{ background: "#dad7d763" }}
//                 >
//                   <NavigateNext />
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PreviewTable;
