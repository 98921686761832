import { Box } from '@mui/material'
import React from 'react'
import Moment from 'react-moment'
import { convertToUserTime, FormatDateWithTime } from "../../../common/helper/Common";

const SiteBottom = ({
    created_date="",
    created_by="",
    modified_date="",
    modified_by="",
}) => {
  return (
    <>
         {/* <div style={{ marginBottom: "5px",marginTop:"20px" }}>
              <Box
                className="d-flex justify-content-evenly align-items-center"
                style={{ gap: "50px" }}
              >
                <p>
                  Created Date:{" "}
                  {created_by} {" "}
                  {created_date ? (
                    <Moment
                      date={created_date}
                      format={"MM/DD/YYYY h:mm A"}
                    />
                  ) : (
                    ""
                  )}
                </p>
                <p>Created By: {created_by}</p>
                <p>
                  Modified Date:{" "}
                  {modified_by} {" "}
                  {modified_date ? (
                    <Moment
                      date={modified_date}
                      format={"MM/DD/YYYY h:mm A"}
                    />
                  ) : (
                    ""
                  )}{" "}
                </p>
                <p>Modified By: {modified_by}</p>
              </Box>
            </div> */}

            <div className="pb-0" style={{ marginTop: "40px" }}>
              <div className="d-flex Created-Modified">
                <div className="CreatedDiv">
                  <span>
                    Created:{" "}
                    {created_by} {" "}
                    {created_date ? (
                        <Moment
                          date={convertToUserTime(created_date)}
                          format={"MM/DD/YYYY h:mm A"}
                        />
                      ) : (
                        ""
                      )}
                  </span>
                  {/* <span>Created By: {accountDetails?.created_by}</span> */}
                </div>

                <div className="ModifiedDiv">
                  <span>
                    Modified :{" "}
                    {modified_by} {" "}
                      {modified_date ? (
                        <Moment
                          date={modified_date}
                          format={"MM/DD/YYYY h:mm A"}
                        />
                      ) : (
                        ""
                      )}{" "}
                  </span>
                  {/* <span>
                    Modified By:{" "}
                    {accountDetails?.modifiedBy?.account_name
                      ? accountDetails?.modifiedBy?.account_name
                      : accountDetails?.modifiedBy}
                  </span> */}
                </div>
                {/* <span>Last Touch Date: 11-14-2020 12:00:00</span> */}
              </div>
            </div>
    </>
  )
}

export default SiteBottom