import React, { useEffect } from "react";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  StorageInfoTableSkeleton,
  InfoCorrectTableSkeleton,
} from "./AedSkeletons/StorageInfoTableSkeleton";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";
import CommonDatePicker from "../../../../common/components/DatePickers/CommonDatePicker";

const AEDStorageInfoTables = ({
  formData,
  handleToggleChange,
  handleCheckBox,
  handleDateChange,
  aedDetails,
  HIC,
  showLoading,
}) => {
  useEffect(() => {
    if (formData?.v9_Installed_Date) {
      handleDateChange("v9_Installed_Date", formData?.v9_Installed_Date);
    }
  }, [formData?.v9_Installed_Date]);

  useEffect(() => {}, [formData?.aed_storage_currect]);

  if (showLoading) {
    return (
      <>
        <InfoCorrectTableSkeleton />
        <StorageInfoTableSkeleton />
      </>
    );
  }

  const isAlarmed = (val) => {
    if(!formData?.sti_alarmed) {
     return "";
    } else {
     return val;
    }
  }

  const renderStorageDate = (formData, handleDateChange) => {
    // If storage type is not 1 and not empty, return N/A
    if (
      Number(formData?.aed_storage_currect) === 1 &&
      formData?.v9_Installed_Date
    ) {
      return <td className="w-96">{formData?.v9_Installed_Date}</td>;
    }

    if (
      Number(formData?.aed_storage_currect) === 1 &&
      !formData?.v9_Installed_Date
    ) {
      return <td className="w-96">N/A</td>;
    }

    if (
      Number(formData?.aed_storage_currect) == 0 &&
      formData?.sti_alarmed == true
    ) {
      // const isDisabled =
      //   Number(formData?.aed_storage_currect) === 0 &&
      //   formData?.sti_alarmed === false;

      return (
        <td className="w-96">
          <CommonDatePicker
            calName="v9_Installed_Date"
            CalVal={isAlarmed(formData?.v9_Installed_Date) || ""}
            HandleChange={handleDateChange}
            defaultValue={formData?.v9_Installed_Date}
            disabled={!formData?.sti_alarmed}
          />
        </td>
      );
    }

    if (formData?.aed_storage_currect == 0 && formData?.sti_alarmed == false) {
      // Determine if DatePicker should be disabled
      const isDisabled =
        formData?.aed_storage_currect === 0 && formData?.sti_alarmed === false;

      return (
        <td className="w-96">
          <CommonDatePicker
            calName={"v9_Installed_Date"}
            CalVal={isAlarmed(formData?.v9_Installed_Date) || ""}
            HandleChange={handleDateChange}
            defaultValue={formData?.v9_Installed_Date}
            disabled={!formData?.sti_alarmed}
          />
        </td>
      );
    }
  };

  return (
    <>
      <table className="theme-table">
        <tbody>
          <tr
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <td className="" style={{ borderColor: "transparent" }}>
              {formData?.aed_storage_currect
                ? "Please Correct Below Information"
                : "Is below information correct?"}
            </td>
            <td
              style={{
                marginRight: "10px",
                borderColor: "transparent",
              }}
            >
              <CustomToggleButton2
                ToggleName="aed_storage_currect"
                ToggleValue={formData?.aed_storage_currect}
                changeHandler={handleToggleChange}
                is_read_only={false}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="theme-table">
        <thead>
          <tr>
            <td>Storage Type</td>
            <td>Alarmed</td>
            <td>Turned On</td>
            <td>Does the Alarm sound?</td>
            <td>9v Installation </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {formData?.aed_storage_currect ? (
                formData?.storage_part_name || "N/A"
              ) : (
                <>
                  <GlobalDropdown
                    dropDownName={"sti_storage_type"}
                    apiEndpoint={"aed/storage-info"}
                    idKey={"storage_info_id"}
                    valueKey={"storage_info_name"}
                    parentKey={""}
                    onSelect={(e, value) => {
                      HIC(e);
                    }}
                    shouldFetchOnOpen={false}
                    isCache={true}
                    initialSelectedValue={
                      parseInt(formData?.sti_storage_type) ||
                      parseInt(aedDetails?.storage_info?.[0]?.storage_type)
                    }
                  />
                </>
              )}
            </td>
            <td>
              {parseInt(formData?.sti_storage_type) != 1 ? (
                formData?.sti_storage_type == "" ? (
                  <>
                    {formData?.aed_storage_currect ? (
                      "N/A"
                    ) : (
                      <>
                        <div className="">
                          <CustomToggleButton2
                            ToggleName="sti_alarmed"
                            ToggleValue={formData?.sti_alarmed}
                            changeHandler={handleCheckBox}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  "N/A"
                )
              ) : (
                <>
                  {formData?.aed_storage_currect ? (
                    formData?.sti_alarmed ? (
                      <CheckIcon sx={{ color: "#00FF00" }} />
                    ) : (
                      <CloseIcon color={"error"} />
                    )
                  ) : (
                    <>
                      <div className="">
                        <CustomToggleButton2
                          ToggleName="sti_alarmed"
                          ToggleValue={formData?.sti_alarmed}
                          changeHandler={handleCheckBox}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {parseInt(formData?.sti_storage_type) != 1 ? (
                formData?.sti_storage_type == "" ? (
                  <>
                    {formData?.aed_storage_currect ? (
                      "N/A"
                    ) : (
                      <div className="">
                        <CustomToggleButton2
                          ToggleName="sti_turned_on"
                          ToggleValue={formData?.sti_turned_on}
                          changeHandler={handleCheckBox}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  "N/A"
                )
              ) : (
                <>
                  {formData?.aed_storage_currect ? (
                    formData?.sti_turned_on ? (
                      <CheckIcon sx={{ color: "#00FF00" }} />
                    ) : (
                      <CloseIcon color={"error"} />
                    )
                  ) : (
                    <div className="">
                      <CustomToggleButton2
                        ToggleName="sti_turned_on"
                        ToggleValue={formData?.sti_turned_on}
                        changeHandler={handleCheckBox}
                      />
                    </div>
                  )}
                </>
              )}
            </td>
            <td>
              {parseInt(formData?.sti_storage_type) != 1 ? (
                formData?.sti_storage_type == "" ? (
                  <>
                    {formData?.aed_storage_currect ? (
                      "N/A"
                    ) : (
                      <div className="">
                        <CustomToggleButton2
                          ToggleName="sti_alarme_sound"
                          ToggleValue={formData?.sti_alarme_sound}
                          changeHandler={handleCheckBox}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  "N/A"
                )
              ) : (
                <>
                  {formData?.aed_storage_currect ? (
                    formData?.sti_alarme_sound ? (
                      <CheckIcon sx={{ color: "#00FF00" }} />
                    ) : (
                      <CloseIcon color={"error"} />
                    )
                  ) : (
                    <div className="">
                      <CustomToggleButton2
                        ToggleName="sti_alarme_sound"
                        ToggleValue={formData?.sti_alarme_sound}
                        changeHandler={handleCheckBox}
                      />
                    </div>
                  )}
                </>
              )}
            </td>
            {/* <td style={{ width: "400px" }}>
              {parseInt(formData?.sti_storage_type) != 1 ? (
                formData?.sti_storage_type == "" ? (
                  <>
                    {formData?.aed_storage_currect &&
                    formData?.sti_alarmed &&
                    formData?.v9_Installed_Date == "" ? (
                      "N/A"
                    ) : (
                      <>
                        <CommonDatePicker
                          calName={"v9_Installed_Date"}
                          CalVal={formData?.v9_Installed_Date}
                          HandleChange={handleDateChange}
                          disabled={false}
                        />
                      </>
                    )}
                  </>
                ) : (
                  "N/A"
                )
              ) : (
                <>
                  {formData?.aed_storage_currect ? (
                    formData?.v9_Installed_Date
                  ) : (
                    <>
                      {" "}
                      <CommonDatePicker
                        calName={"v9_Installed_Date"}
                        CalVal={formData?.v9_Installed_Date}
                        HandleChange={handleDateChange}
                        disabled={Boolean(formData?.sti_alarmed) === false}
                      />
                    </>
                  )}
                </>
              )}
            </td> */}
            {parseInt(formData?.sti_storage_type) === 1 ? (
              renderStorageDate(formData, handleDateChange)
            ) : <td>N/A</td> }
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AEDStorageInfoTables;
