import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { DecryptToken } from "../../../helper";
import SwitchUserModel from "../../modals/switchUser/switchUser";

const UserMenuBar = ({
  anchorEl,
  open,
  handleClose,
  moveSubAdminToUser,
  handleSwitchSubAdmin,
  handleLogout,
}) => {
  const [openSwitchUser, setOpenSwitchUser] = useState(false);
  const navigate = useNavigate();
  const user = DecryptToken();
  const isUser = sessionStorage.getItem("is_user");

  return (
    <div>
      <Menu
        className="user-dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: { position: "absolute", backgroundColor: "black" },
        }}
      >
        {user?.is_admin && (
          <MenuItem
            onClick={() => {
              setOpenSwitchUser(true);
              handleClose();
            }}
          >
            Switch User
          </MenuItem>
        )}

        <MenuItem
          onClick={(e) => {
            navigate("/user-profile/" + user?.contact_id);
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      {openSwitchUser && (
        <SwitchUserModel
          openSwitchUser={openSwitchUser}
          setOpenSwitchUser={setOpenSwitchUser}
        />
      )}
    </div>
  );
};

export default UserMenuBar;
