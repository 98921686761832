import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import {
  CallGETAPI,
  CallGETAPINEW,
  CallPOSTAPI,
} from "../../../../common/services/index";
import {
  GetAedBrands,
  GetAedModelsByBrandId,
  GetAedSumModelsById,
  CheckSpareBatteryTblCol,
  RenderDate,
  CheckAEDBatteryTblCol,
} from "../../../../common/helper/BasicFn";
import { prepareOptions, printConsole } from "../../../../common/helper/Common";
import moment from "moment";
import PadsInfoEditTbl from "./PadsInfoEditTbl";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { FormatDate } from "../../../../common/helper";
import AEDInfoTable from "./AEDGenInfoTable ";
import AEDDetailsTable from "./AEDInfoTableForSelect";
import AEDStorageInfoTables from "./AEDStorageInfoTablesForSelect ";
import BatteryInfoTables from "./BatteryInfoTablesForSelect";
import FinalNewBreadcrumbs from "../../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";
import {
  addNewBreadcrumbs,
  removeBreadcrumb,
} from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

const filterData = (arr, key, value) => {
  const data = arr?.find((a) => a[key] == value);
  return data;
};

const AedCheckSelect = () => {
  const navigate = useNavigate();
  const [toggleValue, setToggleValue] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const aedId = searchParams.get("aed_id");
  const accountId = searchParams.get("account_id");
  const [aedDetails, setAedDetails] = useState({});
  const [aedData, setAEDData] = useState({});
  const [storageInfoList, setStorageInfoList] = useState([]);
  const [aedPadTypeList, setaedPadTypeList] = useState([]);
  const [OtherFiled, setOtherFileds] = useState([]);
  const [batteryTypeList, setBatteryTypeList] = useState([]);
  const [issueList, setissueList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [batteryInfo, setBatteryInfo] = useState([]);
  const [warentyYear, setWarentyYear] = useState(0);
  const [loading, setLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [showLoading, setShowLoading] = useState({
    showAedGeneralInfo: true,
    showAedStorageInfo: true,
    showAedBatteryInfo: true,
    showAedPadInfo: true,
  });
  const dispatch = useDispatch();
  const [issueTypeValid, setIssueTypeValid] = useState(false);
  const [issueValid, setIssueValid] = useState(false);
  const location = useLocation();
  const aedPresentInputRef = useRef(null);
  const statusIndicatorInputRef = useRef(null);
  const rescueKitInputRef = useRef(null);
  const issueTypeRef = useRef(null);
  const issueRef = useRef(null);
  const [formData, setFormData] = useState({
    aed_id: aedId,
    account_id: accountId,
    serial_number: "",
    is_aed_present: 0,
    status_indicator: 0,
    rescue_kit_present: 0,
    aed_storage_currect: 1,
    sti_expiry_date: "",
    sti_alarmed: 0,
    sti_alarm_status: 0,
    sti_turned_on: 0,
    sti_alarme_sound: 1,
    battery_information_toggle: 1,
    // bleeding_kit_located_toggle: 1,
    // bleeding_kit_sealed_toggle: 1,
    // oxygen_equipment_located_toggle: 1,
    // needed_supplies_toggle: 1,
    // gauge_show_toggle: 1,
    pads_info_toggle: 1,
    support_tickets_toggle: 0,
    battery_info: [],
    spare_battery_info: [],
    adult_pad_info: [],
    spare_adult_pad_info: [],
    pediatric_pad_info: [],
    spare_padric_pad_info: [],
    gateway_info: [],
    adult_pad_pak_info: [],
    spare_adult_pad_pak_info: [],
    pediatric_pak_pad_info: [],
    spare_padric_pak_pad: [],
    // bleeding_kit_located_input: "",
    // bleeding_kit_sealed_input: "",
    // oxygen_equipment_located_input: "",
    // needed_supplies_input: "",
    is_aed_present_input: "",
    status_indicator_input: "",
    rescue_kit_present_input: "",
    issue_type: "",
    issue: "",
    sti_storage_type: "",
    // gauge_show_input: "",
  });

  const handleDispatch = (accountId) => {
    const BreadCrumbsObject = {
      title: "",
    };
    BreadCrumbsObject.title = "Equipment";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-details/${accountId}/Equipment`,
      })
    );
  };
  const [toggleInvalid, setToggleInvalid] = useState({
    aed_present: false,
    status_indicator: false,
    rescue_kit: false,
  });
  const handleRemoveDispatch = () => {
    let url2 = "";
    let url1 = "";
    let url = "";
    if (key == "aedCheck") {
      url2 = "/account/aed/aed-check-card";
      url1 = "/account/aed/service-check/" + accountId + "/" + siteId;
      url = "/account/aed-details/check-select";
    } else {
      url =
        "/account/aed-details/check-select?aed_id=" +
        aedId +
        "&account_id=" +
        accountId;
    }
    dispatch(removeBreadcrumb(url2));
    dispatch(removeBreadcrumb(url));
    dispatch(removeBreadcrumb(url1));
  };

  const getAedGeneralInfo = async () => {
    const result = await CallGETAPI("aed/get-aed-general-information/" + aedId);
    if (result.status) {
      const aedDetails1 = result?.data?.data?.aed;

      if (aedDetails1?.length === 0) {
        toast.error("Something went wrong please try again");
        return "";
      }
      setAEDData(aedDetails1);

      aedDetails1.adult_pad_info = JSON.parse(aedDetails1?.adult_pad_info);
      aedDetails1.adult_pad_pak_info = JSON.parse(
        aedDetails1?.adult_pad_pak_info
      );
      aedDetails1.battery_info = JSON.parse(aedDetails1?.battery_info);
      aedDetails1.builtin_RMS_info = JSON.parse(aedDetails1?.builtin_RMS_info);
      aedDetails1.charge_pak_info = JSON.parse(aedDetails1?.charge_pak_info);
      aedDetails1.gateway_info = JSON.parse(aedDetails1?.gateway_info);
      aedDetails1.gateway_info = aedDetails1?.gateway_info[0];
      aedDetails1.other = JSON.parse(aedDetails1?.other);
      aedDetails1.out_of_service_info = JSON.parse(
        aedDetails1?.out_of_service_info
      );
      aedDetails1.pediatric_pad_info = JSON.parse(
        aedDetails1?.pediatric_pad_info
      );
      aedDetails1.pediatric_pak_pad_info = JSON.parse(
        aedDetails1?.pediatric_pak_pad_info
      );
      aedDetails1.rms_info = JSON.parse(aedDetails1?.rms_info);
      aedDetails1.spare_adult_pad_info = JSON.parse(
        aedDetails1?.spare_adult_pad_info
      );
      aedDetails1.spare_adult_pad_pak_info = JSON.parse(
        aedDetails1?.spare_adult_pad_pak_info
      );
      aedDetails1.spare_battery_info = JSON.parse(
        aedDetails1?.spare_battery_info
      );
      aedDetails1.spare_charge_pak_info = JSON.parse(
        aedDetails1?.spare_charge_pak_info
      );
      aedDetails1.spare_padric_pad_info = JSON.parse(
        aedDetails1?.spare_padric_pad_info
      );
      aedDetails1.spare_padric_pak_pad = JSON.parse(
        aedDetails1?.spare_padric_pak_pad
      );
      aedDetails1.storage_info = JSON.parse(aedDetails1?.storage_info || "[]");
      aedDetails1.site_name = result?.data?.site_name;
      aedDetails1.account_name = result?.data?.account_name;

      setOtherFileds(aedDetails1.other);

      let brandList = await GetAedBrands();
      setBrandList(brandList);

      const filteredBrand = filterData(
        brandList,
        "id",
        aedDetails1?.aed_brand_id
      );
      aedDetails1.brand_name = filteredBrand?.AED_brands;

      let ModelRes = await GetAedModelsByBrandId(filteredBrand?.id);
      let sub_model_res = await GetAedSumModelsById(aedDetails1.aed_model_id);
      let MODEL_NAME = ModelRes?.data.find(
        (item) => item.id === aedDetails1.aed_model_id
      );

      aedDetails1.model_name = MODEL_NAME?.model_name;

      const filteredSubModel = filterData(
        sub_model_res?.data?.data,
        "id",
        aedDetails1?.sub_model_id
      );
      aedDetails1.sub_model_name = filteredSubModel
        ? filteredSubModel?.sub_model_name
        : "";

      setAedDetails(aedDetails1);
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedGeneralInfo: false,
    }));
  };

  const getAedStorageInfo = async () => {
    const result = await CallGETAPI("aed/get-aed-storage-info/" + aedId);
    if (result.status) {
      setStorageInfoList(result?.data?.data?.storageInfo || []);
      setFormData((prev) => ({
        ...prev,
        sti_storage_type: result?.data?.data?.storageInfo?.[0]?.storage_type,
        storage_part_name:
          result?.data?.data?.storageInfo?.[0]?.storage_part_name,
        sti_alarmed: result?.data?.data?.storageInfo?.[0]?.alarmed || 0,
        sti_turned_on: result?.data?.data?.storageInfo?.[0]?.alarm_status || 0,
        sti_alarme_sound:
          result?.data?.data?.storageInfo?.[0]?.alarme_sound || 0,
        sti_expiry_date: FormatDate(
          result?.data?.data?.storageInfo?.[0]?.expiry_date
        ),
        v9_Installed_Date: FormatDate(
          result?.data?.data?.storageInfo?.[0]?.v9_Installed_Date
        ),
      }));
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedStorageInfo: false,
    }));
  };

  const getAedBatteryInfo = async () => {
    const result = await CallGETAPI("aed/get-aed-battery-info/" + aedId);
    if (result.status) {
      const batteryInfo = result?.data?.data?.batteryInfo || [];
      setBatteryInfo(batteryInfo);
      const BI = {
        has_battery: [],
        has_9v: [],
        has_10pk: [],
        has_installby: [],
        has_man: [],
        // charge_pack: [],
      };
      const SPBI = {
        has_battery_spare: [],
        has_9v_spare: [],
        has_10pk_spare: [],
        has_installby_spare: [],
        has_man_spare: [],
        // charge_pack: [],
      };
      const ABSpares = [];
      const ALLBatteris = [];
      for (let bii = 0; bii < batteryInfo.length; bii++) {
        const b2 = batteryInfo[bii] || false;
        b2.v9_install = b2.install_9v_date;
        if (!b2) {
          break;
        }
        ALLBatteris.push(b2);
        b2.battery_uid = b2.battery_udi;
        if (b2.is_spare) {
          const key =
            b2.section_name === "charge_pack"
              ? "charge_pack"
              : b2.section_name + "_spare";
          if (SPBI[key]) {
            ABSpares.push(b2);
            SPBI[key].push(b2);
          }
        } else {
          const key = b2.section_name;
          if (BI[key]) {
            BI[key].push(b2);
          }
        }
      }

      //Battery selector fetch data
      const BatteryRes = await CallGETAPINEW(
        "equipment/aed-battery-type-by-model/" + aedDetails?.aed_model_id
      );
      setBatteryTypeList(BatteryRes?.data?.data);

      setFormData((prev) => ({
        ...prev,
        battery_info: [BI] || [],
        spare_battery_info: [SPBI] || [],
        allBatterySpares: ABSpares || [],
        ALLBatteris: ALLBatteris || [],
      }));
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedBatteryInfo: false,
    }));
  };

  const getAedPadInfo = async () => {
    const result = await CallGETAPI("aed/get-aed-pad-info/" + aedId);
    printConsole({ result }); // print console.log
    if (result.status) {
      const allPads = result?.data?.data?.allPads || [];
      const ar1 = [];
      const SPar1 = [];
      for (let ci = 0; ci < batteryInfo.length; ci++) {
        const ell = batteryInfo[ci];
        if (ell.section_name === "charge_pack") {
          const ChargePakInfoObj = {
            battery_expiration: ell?.battery_expiration,
            battery_lot: ell?.battery_lot,
            charge_pak_part: ell?.charge_pak_uid,
            charge_pak_uiid: ell?.charge_pak_uid,
            charge_pak_uid: ell?.charge_pak_uid,
            pad_1_expiration: "",
            pad_1_lot: "",
            pad_1_part: "",
            pad_2_expiration: "",
            pad_2_lot: "",
            pad_2_part: "",
          };

          for (let i3 = 0; i3 < allPads.length; i3++) {
            const apd = allPads[i3];
            if (apd?.section_name === "charge_pack") {
              if (apd?.pid === Number(ell?.charge_pak_pad_1_id)) {
                ChargePakInfoObj.pad_1_expiration = apd?.pad_expiration;
                ChargePakInfoObj.pad_1_lot = apd?.pad_lot;
                ChargePakInfoObj.pad_1_part = apd?.pad_type_id;
              } else if (apd?.pid === Number(ell?.charge_pak_pad_2_id)) {
                ChargePakInfoObj.pad_2_expiration = apd?.pad_expiration;
                ChargePakInfoObj.pad_2_lot = apd?.pad_lot;
                ChargePakInfoObj.pad_2_part = apd?.pad_type_id;
              }
            }
          }
          if (ell.is_spare) {
            SPar1.push(ChargePakInfoObj);
          } else {
            ar1.push(ChargePakInfoObj);
          }
        }
      }
      aedDetails.charge_pak_info = ar1?.[0];
      aedDetails.spare_charge_pak_info = SPar1;

      const AP = [],
        SAP = [],
        PP = [],
        SPP = [],
        APP = [],
        SAPP = [],
        PPP = [],
        SPPP = [],
        CP = [],
        SCP = [];
      for (let api = 0; api < allPads.length; api++) {
        const APD = allPads[api] || false;

        if (!APD) {
          break;
        }
        APD.battery_uid = APD.battery_udi;
        if (
          APD.section_name === "charge_pack" ||
          APD.section_name === "spare_charge_pack"
        ) {
          if (APD.is_spare) {
            SCP.push(APD);
          } else {
            CP.push(APD);
          }
        }

        if (
          APD.section_name === "adult_pad_info" ||
          APD.section_name === "spare_adult_pad_info"
        ) {
          if (APD.is_spare) {
            SAP.push(APD);
          } else {
            AP.push(APD);
          }
        }

        if (
          APD.section_name === "adult_pad_pak_info" ||
          APD.section_name === "spare_adult_pad_pak_info"
        ) {
          if (APD.is_spare) {
            SAPP.push(APD);
          } else {
            APP.push(APD);
          }
        }

        if (
          APD.section_name === "pediatric_pad_info" ||
          APD.section_name === "spare_pediatric_pad_info" ||
          APD.section_name === "spare_padric_pad_info"
        ) {
          if (APD.is_spare) {
            SPP.push(APD);
          } else {
            PP.push(APD);
          }
        }

        if (
          APD.section_name === "pediatric_pak_pad_info" ||
          APD.section_name === "spare_pediatric_pak_pad_info" ||
          APD.section_name === "spare_padric_pak_pad"
        ) {
          if (APD.is_spare) {
            SPPP.push(APD);
          } else {
            PPP.push(APD);
          }
        }
      }

      //Pad selector fetch data
      const res = await CallGETAPINEW(
        "equipment/aed-pad-type-by-model/" + aedDetails?.aed_model_id
      );
      setaedPadTypeList(res?.data?.data);

      setFormData((prev) => ({
        ...prev,
        adult_pad_info: AP,
        spare_adult_pad_info: SAP,
        pediatric_pad_info: PP,
        spare_padric_pad_info: SPP,
        adult_pad_pak_info: APP,
        spare_adult_pad_pak_info: SAPP,
        pediatric_pak_pad_info: PPP,
        spare_padric_pak_pad: SPPP,
        charge_pak_info: CP,
        spare_charge_pak_info: SCP,
      }));
    }
    setShowLoading((prev) => ({
      ...prev,
      showAedPadInfo: false,
    }));
  };

  const BatteryExpTbl = [
    {
      key: "battery_type_id",
      is_default: 1,
      title: "Battery Part",
    },
    { key: "battery_expiration", is_default: 0, title: "Battery Expiration" },
    {
      key: "manufactured_date",
      is_default: 0,
      title: "Manufactured Date",
    },
    {
      key: "v9_install", //"install_9v_date",
      is_default: 0,
      title: "Install 9v Date",
    },
    { key: "battery_lot", is_default: 1, title: "Battery Lot" },
    { key: "battery_udi", is_default: 1, title: "Battery UDI" },
    // install_9v_date
  ];

  const print_battery_part = (bid) => {
    let findName = batteryTypeList?.find(
      (item) => parseInt(item?.battery_type_id) === parseInt(bid)
    );
    return findName?.battery_part_number || bid;
  };

  const fecthIssueType = async () => {
    const res = await CallGETAPI("aed/all-issues-type");
    if (res.data.status) {
      const arr = res.data.data.issuesList || [];
      const allIssueTypes = prepareOptions(arr, "issue_id", "issue_name");
      setissueList(allIssueTypes);
    }
  };

  const handleToggleChange = (event) => {
    const newValue = event.target.checked ? 1 : 0;

    setFormData((prev) => ({
      ...prev,
      [event.target.name]: newValue,
    }));

    setToggleValue(newValue);
  };

  const HIC = (e) => {
    const newValue = e.target.value;

    const name = e.target.name;

    const FD = { ...formData };

    if (name === "sti_storage_type") {
      if (Number(newValue) === 1) {
        FD.sti_alarmed = 0;
        FD.sti_alarm_status = 0;
        FD.sti_turned_on = 0;
        FD.sti_alarme_sound = 0;
        FD.v9_Installed_Date = "";
      }

      // Add storage_part_name to FD
      if (e.target.selectedIndex === 0) {
        FD.storage_part_name = "";
      } else {
        FD.storage_part_name = e.target.options[e.target.selectedIndex].text;
      }
    }
    FD[e.target.name] = newValue;
    setFormData(FD);
  };

  const handleDateChange = (name, val) => {
    const fd = { ...formData };
    if (name === "purchase_date") {
      let warenty_date = moment(val).add(warentyYear, "years").calendar();
      fd.warenty_date = warenty_date;
    }
    fd[name] = val;
    setFormData(fd);
    // setDefaultfromData(fd);
  };

  const handleCheckBox = (e) => {
    if (e.target.type === "checkbox") {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };
  const selectedRowId = location.state?.selectedRowId;
  const selectedaccountId = location.state?.selectedaccountId;
  const key = location.state?.key;
  const siteId = location.state?.siteId;

  function chargePakFormated(fd) {
    const spareChargePakData = fd?.spare_battery_info?.[0]?.charge_pack || [];
    const ChargePakData = fd?.battery_info?.[0]?.charge_pack || [];
    const getSparePadData = fd?.spare_charge_pak_info || [];
    const getPadData = fd?.charge_pak_info || [];

    fd.charge_pak_info = ChargePakData.map((chargePak) => {
      // Matching pad data find karna
      const matchingPad1 = getPadData.find(
        (pad) => Number(pad.pid) === Number(chargePak?.charge_pak_pad_1_id)
      );

      const matchingPad2 = getPadData.find(
        (pad) => Number(pad.pid) === Number(chargePak?.charge_pak_pad_2_id)
      );

      return {
        battery_expiration: chargePak?.battery_expiration,
        battery_lot: chargePak?.battery_lot,
        charge_pak_pad_1_id: matchingPad1?.pad_type_id,
        charge_pak_pad_2_id: matchingPad2?.pad_type_id,
        charge_pak_part: chargePak?.battery_type_id,
        charge_pak_uid: chargePak?.charge_pak_uid,
        pad_1_expiration: matchingPad1 ? matchingPad1?.pad_expiration : "",
        pad_1_lot: matchingPad1 ? matchingPad1?.pad_lot : "",
        pad_1_part: matchingPad1 ? matchingPad1?.pad_part_number : "",
        pad_2_expiration: matchingPad2 ? matchingPad2.pad_expiration : "",
        pad_2_lot: matchingPad2 ? matchingPad2.pad_lot : "",
        pad_2_part: matchingPad2 ? matchingPad2.pad_part_number : "",
      };
    });

    fd.spare_charge_pak_info = spareChargePakData.map((chargePak) => {
      // Matching pad data find karna
      const matchingPad1 = getSparePadData.find(
        (pad) => Number(pad.pid) === Number(chargePak?.charge_pak_pad_1_id)
      );

      const matchingPad2 = getSparePadData.find(
        (pad) => Number(pad.pid) === Number(chargePak?.charge_pak_pad_2_id)
      );

      return {
        battery_expiration: chargePak?.battery_expiration,
        battery_lot: chargePak?.battery_lot,
        charge_pak_pad_1_id: matchingPad1?.pad_type_id,
        charge_pak_pad_2_id: matchingPad2?.pad_type_id,
        charge_pak_part: chargePak?.battery_type_id,
        charge_pak_uid: chargePak?.charge_pak_uid,
        pad_1_expiration: matchingPad1 ? matchingPad1?.pad_expiration : "",
        pad_1_lot: matchingPad1 ? matchingPad1?.pad_lot : "",
        pad_1_part: matchingPad1 ? matchingPad1?.pad_part_number : "",
        pad_2_expiration: matchingPad2 ? matchingPad2.pad_expiration : "",
        pad_2_lot: matchingPad2 ? matchingPad2.pad_lot : "",
        pad_2_part: matchingPad2 ? matchingPad2.pad_part_number : "",
      };
    });

    return fd;
  }

  // Helper function to scroll to element
  const scrollToElement = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      // Optional: add focus for better accessibility
      ref.current.focus();
    }
  };

  // Function to find first error and scroll to it
  const scrollToFirstError = (errors) => {
    if (!formData.is_aed_present && formData.is_aed_present_input === "") {
      scrollToElement(aedPresentInputRef);
      return;
    }

    if (
      formData.is_aed_present &&
      !formData.status_indicator &&
      formData.status_indicator_input === ""
    ) {
      scrollToElement(statusIndicatorInputRef);
      return;
    }

    if (
      formData.is_aed_present &&
      !formData.rescue_kit_present &&
      formData.rescue_kit_present_input === ""
    ) {
      scrollToElement(rescueKitInputRef);
      return;
    }

    if (formData?.support_tickets_toggle && formData?.issue_type === "") {
      scrollToElement(issueTypeRef);
      return;
    }

    if (formData?.support_tickets_toggle && formData?.issue === "") {
      scrollToElement(issueRef);
      return;
    }
  };

  const removeUnwantedKeys = (FD) => {
    // Create a copy of the object to avoid modifying the original
    const cleanedData = { ...FD };

    // List of keys to remove
    const keysToRemove = [
      "ALLBatteris",
      "allBatterySpares",
      // "charge_pak_info",
      // "spare_charge_pak_info",
      "sti_storage_type",
      "sti_alarmed",
      "sti_turned_on",
      "sti_alarme_sound",
      "sti_expiry_date",
      "v9_Installed_Date",
      "storage_part_name",
      "sti_alarm_status",
    ];

    // Remove each key from the object
    keysToRemove.forEach((key) => {
      delete cleanedData[key];
    });

    return cleanedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (selectedRowId?.length === 0) {
    //   navigate(`/account-details/${accountId}/Equipment`);
    //   return;
    // }

    let toggleErrors = {};
    formData.serial_number = aedDetails?.serial_number;
    if (!formData.is_aed_present && formData.is_aed_present_input === "") {
      toggleErrors.aed_present = true;
    }
    if (
      formData.is_aed_present &&
      !formData.status_indicator &&
      formData.status_indicator_input === ""
    ) {
      toggleErrors.status_indicator = true;
    }
    if (
      formData.is_aed_present &&
      !formData.rescue_kit_present &&
      formData.rescue_kit_present_input === ""
    ) {
      toggleErrors.rescue_kit = true;
    }

    if (Object.keys(toggleErrors).length > 0) {
      setToggleInvalid({ ...toggleInvalid, ...toggleErrors });
      scrollToFirstError(toggleErrors);
      return;
    }

    if (formData?.support_tickets_toggle && formData?.issue_type === "") {
      setIssueTypeValid(true);
      setLoading(false);
      return;
    } else if (formData?.support_tickets_toggle && formData?.issue === "") {
      setIssueValid(true);
      setLoading(false);
      return;
    }

    setLoading(true);

    let FD = { ...formData };
    if (formData.v9_Installed_Date) {
      formData.sti_expiry_date = new Date(
        new Date(formData.v9_Installed_Date).setFullYear(
          new Date(formData.v9_Installed_Date).getFullYear() + 1
        )
      ).toLocaleDateString("en-US");
    }

    FD.storage_info = FD.is_aed_present
      ? [
          {
            storage_type: formData.sti_storage_type,
            alarmed:
              Number(formData.sti_storage_type) === 1
                ? formData.sti_alarmed
                  ? 1
                  : 0
                : 0,
            alarm_status:
              Number(formData.sti_storage_type) === 1
                ? formData.sti_turned_on
                  ? 1
                  : 0
                : 0,
            alarme_sound:
              Number(formData.sti_storage_type) === 1
                ? formData?.sti_alarme_sound
                  ? 1
                  : 0
                : 0,
            storage_part_name: formData?.storage_part_name || "",
            expiry_date:
              Number(formData.sti_storage_type) === 1
                ? formData?.v9_Installed_Date && formData?.sti_alarmed
                  ? formData.sti_expiry_date
                  : ""
                : "",

            v9_Installed_Date:
              Number(formData.sti_storage_type) === 1
                ? formData?.sti_alarmed
                  ? formData.v9_Installed_Date
                  : ""
                : "",
          },
        ]
      : [];

    // const payload = updateChargePakInfo(FD);

  if (!formData.is_aed_present) {
    FD = {
      aed_id: aedId,
      account_id: accountId,
      serial_number: formData?.serial_number,
      is_aed_present: 0,
      status_indicator: 0,
      rescue_kit_present: 0,
      aed_storage_currect: 1,
      // sti_expiry_date: "",
      // sti_alarmed: 0,
      // sti_alarm_status: 0,
      // sti_turned_on: 0,
      // sti_alarme_sound: 1,
      // sti_storage_type: "",
      storage_info: [],
      battery_information_toggle: 1,
      pads_info_toggle: 1,
      support_tickets_toggle: 0,
      battery_info: [],
      spare_battery_info: [],
      adult_pad_info: [],
      spare_adult_pad_info: [],
      pediatric_pad_info: [],
      spare_padric_pad_info: [],
      gateway_info: [],
      adult_pad_pak_info: [],
      spare_adult_pad_pak_info: [],
      pediatric_pak_pad_info: [],
      spare_padric_pak_pad: [],
      is_aed_present_input: formData?.is_aed_present_input,
      status_indicator_input: "",
      rescue_kit_present_input: "",
      issue_type: "",
      issue: "",
    };
  }

    const payload = chargePakFormated(FD);
    const payloadData = removeUnwantedKeys(payload);

    // const res = await CallPOSTAPI("aed/aed-checks", payload);
    const res = await CallPOSTAPI("aed/aed-checks-v2", payloadData);
    // return;
    if (res?.data?.status) {
      toast.success("AED checked Saved Successfully");
      const currentId = selectedRowId?.shift();
      setFormData({
        aed_id: currentId,
        account_id: accountId,
        serial_number: "",
        is_aed_present: 0,
        status_indicator: 0,
        rescue_kit_present: 0,
        aed_storage_currect: 1,
        // sti_expiry_date: "",
        // sti_alarmed: 0,
        // sti_alarm_status: 0,
        // sti_turned_on: 0,
        // sti_alarme_sound: 1,
        // sti_storage_type: "",
        storage_info: [],
        battery_information_toggle: 1,
        bleeding_kit_located_toggle: 1,
        bleeding_kit_sealed_toggle: 1,
        oxygen_equipment_located_toggle: 1,
        needed_supplies_toggle: 1,
        gauge_show_toggle: 1,
        pads_info_toggle: 1,
        support_tickets_toggle: 0,
        battery_info: [],
        spare_battery_info: [],
        adult_pad_info: [],
        spare_adult_pad_info: [],
        pediatric_pad_info: [],
        spare_padric_pad_info: [],
        gateway_info: [],
        adult_pad_pak_info: [],
        spare_adult_pad_pak_info: [],
        pediatric_pak_pad_info: [],
        spare_padric_pak_pad: [],
        bleeding_kit_located_input: "",
        bleeding_kit_sealed_input: "",
        oxygen_equipment_located_input: "",
        needed_supplies_input: "",
        is_aed_present_input: "",
        status_indicator_input: "",
        rescue_kit_present_input: "",
        issue_type: "",
        issue: "",
        gauge_show_input: "",
      });

      printConsole({ currentId, selectedRowId }); // print console.log

      if (currentId != null) {
        navigate(
          `/account/aed-details/check-select?aed_id=${currentId}&account_id=${accountId}`,
          {
            state: {
              selectedRowId: selectedRowId,
              selectedaccountId: selectedaccountId,
            },
          }
        );
      } else {
        handleRemoveDispatch();
        handleDispatch(accountId);
        navigate(`/account-details/${accountId}/Equipment`);
      }
    } else {
      toast.error("Something went wrong please try again");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (formData?.is_aed_present) {
      fecthIssueType();
      getAedStorageInfo();
      getAedBatteryInfo();
      getAedPadInfo();
    }
  }, [formData?.is_aed_present]);

  useEffect(() => {
    getAedGeneralInfo();
    // getAedDetails();
  }, [selectedRowId]);

  return (
    <>
      <div className="mt-3">
        <FinalNewBreadcrumbs />
      </div>
      <div className="mt-4 table-main-20002" style={{ width: "100%" }}>
        <InfoModal infoModal={infoModal} setInfoModal={setInfoModal} />
        <div className="col py-2">
          <h2 className="heading">General Information</h2>

          <AEDInfoTable
            showGeneralInfo={showLoading.showAedGeneralInfo}
            brandName={aedDetails?.brand_name}
            modelName={aedDetails?.model_name}
            aedData={aedData}
          />
        </div>
        <form className="pb-3" onSubmit={handleSubmit}>
          <div className="col py-2">
            <h2 className="heading">AED Information</h2>

            <AEDDetailsTable
              showAedGeneralInfo={showLoading.showAedGeneralInfo}
              formData={formData}
              handleToggleChange={handleToggleChange}
              HIC={HIC}
              setToggleInvalid={setToggleInvalid}
              toggleInvalid={toggleInvalid}
              setInfoModal={setInfoModal}
              ref={{
                aedPresentInputRef,
                statusIndicatorInputRef,
                rescueKitInputRef,
              }}
            />
          </div>

          {!formData?.is_aed_present ? (
            ""
          ) : (
            <>
              <div className="col py-2">
                <h2 className="heading">AED Storage</h2>

                <AEDStorageInfoTables
                  formData={formData}
                  handleToggleChange={handleToggleChange}
                  handleCheckBox={handleCheckBox}
                  handleDateChange={handleDateChange}
                  aedDetails={aedDetails}
                  HIC={HIC}
                  showLoading={showLoading.showAedStorageInfo}
                />
              </div>

              {CheckSpareBatteryTblCol(
                [
                  ...(formData?.allBatterySpares || []),
                  ...(formData?.ALLBatteris || []),
                ],
                "All"
              ) ? (
                <div className="col py-2">
                  <h2 className="heading">Battery Information</h2>

                  <BatteryInfoTables
                    showLoading={showLoading.showAedBatteryInfo}
                    formData={formData}
                    setFormData={setFormData}
                    handleToggleChange={handleToggleChange}
                    BatteryExpTbl={BatteryExpTbl}
                    batteryTypeList={batteryTypeList}
                    print_battery_part={print_battery_part}
                    RenderDate={RenderDate}
                    CheckAEDBatteryTblCol={CheckAEDBatteryTblCol}
                  />
                </div>
              ) : (
                ""
              )}
              {formData?.adult_pad_info?.length > 0 ||
              formData?.spare_adult_pad_info?.length > 0 ||
              formData?.pediatric_pad_info?.length > 0 ||
              formData?.spare_padric_pad_info?.length > 0 ||
              formData?.gateway_info?.length > 0 ||
              formData?.adult_pad_pak_info?.length > 0 ||
              formData?.spare_adult_pad_pak_info?.length > 0 ||
              formData?.pediatric_pak_pad_info?.length > 0 ||
              formData?.spare_padric_pak_pad?.length > 0 ||
              formData?.charge_pak_info?.length > 0 ||
              formData?.spare_charge_pak_info?.length > 0 ? (
                <div className="col py-2">
                  <h2 className="heading">Pads Information</h2>

                  <PadsInfoEditTbl
                    isLoading={showLoading.showAedPadInfo}
                    aedPadTypeList={aedPadTypeList}
                    RenderDate={RenderDate}
                    warentyYear={warentyYear}
                    adultPadInfo={formData?.adult_pad_info}
                    spareAdultPadInfo={formData?.spare_adult_pad_info}
                    pediatricPadInfo={formData?.pediatric_pad_info}
                    sparePadricPadInfo={formData?.spare_padric_pad_info}
                    gatewayInfo={formData?.gateway_info}
                    adultPadPakInfo={formData?.adult_pad_pak_info}
                    spareAdultPadPakInfo={formData?.spare_adult_pad_pak_info}
                    pediatricPadPakInfo={formData?.pediatric_pak_pad_info}
                    sparePadricPadPakInfo={formData?.spare_padric_pak_pad}
                    chargePakInfo={formData?.charge_pak_info}
                    spareChargePakInfo={formData?.spare_charge_pak_info}
                    formData={formData}
                    setFormData={setFormData}
                    toggle={formData?.pads_info_toggle}
                    showDisablesection={false}
                    is_read_only={false}
                    handleToggleChange={handleToggleChange}
                  />
                </div>
              ) : (
                ""
              )}
              {0 ? (
                <div className="col py-2">
                  <h2 className="heading">Oxygen Information</h2>

                  <table className="theme-table">
                    <tr>
                      <td>
                        <p>
                          Is the Oxygen equipment located in the designated
                          location?{" "}
                        </p>
                      </td>
                      <td>
                        <CustomToggleButton2
                          ToggleName="oxygen_equipment_located_toggle"
                          ToggleValue={
                            formData?.oxygen_equipment_located_toggle
                          }
                          changeHandler={handleToggleChange}
                          is_read_only={false}
                        />
                      </td>
                    </tr>
                    {!formData?.oxygen_equipment_located_toggle && (
                      <tr>
                        <td colSpan={2}>
                          <textarea
                            style={{ resize: "none" }}
                            className="form-control"
                            id="oxygen_equipment_located_input"
                            name="oxygen_equipment_located_input"
                            placeholder="Enter text Here"
                            value={formData?.oxygen_equipment_located_input}
                            onChange={HIC}
                            required
                          ></textarea>
                          {!formData?.oxygen_equipment_located_toggle &&
                            formData?.oxygen_equipment_located_input === "" && (
                              <p className="text-danger m-0 ps-1 mt-1">
                                This field is required.
                              </p>
                            )}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        <p>Does the gauge show that it is full?</p>
                      </td>
                      <td>
                        <CustomToggleButton2
                          ToggleName="gauge_show_toggle"
                          ToggleValue={formData?.gauge_show_toggle}
                          changeHandler={handleToggleChange}
                          is_read_only={false}
                        />
                      </td>
                    </tr>

                    {!formData?.gauge_show_toggle && (
                      <tr>
                        <td colSpan={2}>
                          <textarea
                            style={{ resize: "none" }}
                            className="form-control"
                            id="gauge_show_input"
                            name="gauge_show_input"
                            placeholder="Enter text Here"
                            value={formData?.gauge_show_input}
                            onChange={HIC}
                            required
                          ></textarea>
                          {!formData?.gauge_show_toggle &&
                            formData?.gauge_show_input === "" && (
                              <p className="text-danger m-0 ps-1 mt-1">
                                This field is required.
                              </p>
                            )}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td>
                        <p>
                          Are all the needed supplies available and in good
                          condition?
                        </p>
                      </td>
                      <td>
                        <CustomToggleButton2
                          ToggleName="needed_supplies_toggle"
                          ToggleValue={formData?.needed_supplies_toggle}
                          changeHandler={handleToggleChange}
                          is_read_only={false}
                        />
                      </td>
                    </tr>

                    {!formData?.needed_supplies_toggle && (
                      <tr>
                        <td colSpan={2}>
                          <textarea
                            className="form-control"
                            id="needed_supplies_input"
                            name="needed_supplies_input"
                            placeholder="Enter text Here"
                            value={formData?.needed_supplies_input}
                            onChange={HIC}
                            required
                          ></textarea>
                          {!formData?.needed_supplies_toggle &&
                            formData?.needed_supplies_input === "" && (
                              <p className="text-danger m-0 ps-1 mt-1">
                                This field is required.
                              </p>
                            )}
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              ) : (
                ""
              )}

              <div className="col py-2">
                <h2 className="heading">Support Tickets</h2>
                <table
                  className="theme-table"
                  style={{
                    maxHeight: "400px",
                    overflowY: "auto",
                    padding: "0px",
                  }}
                >
                  <>
                    <tr
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <td
                        colSpan={3}
                        style={{ borderColor: "transparent", flex: "1" }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Do you need to submit a support ticket?
                        </div>
                      </td>
                      <td
                        colSpan={1}
                        style={{
                          borderColor: "transparent",
                          flex: "0 0 100px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div style={{ display: "flex", marginRight: "10px" }}>
                          <CustomToggleButton2
                            ToggleName="support_tickets_toggle"
                            ToggleValue={formData?.support_tickets_toggle}
                            changeHandler={handleToggleChange}
                            is_read_only={false}
                          />
                        </div>
                      </td>
                    </tr>

                    {formData?.support_tickets_toggle === 1 && (
                      <tr style={{ display: "flex", width: "100%" }}>
                        <td colSpan={2} style={{ flex: "1" }}>
                          <select
                            ref={issueTypeRef}
                            onChange={HIC}
                            name="issue_type"
                            value={formData?.issue_type}
                            className="form-control"
                          >
                            <option value="">--Select-One--</option>
                            {issueList.map((it) => (
                              <option value={it.value} key={it.value}>
                                {it.label}
                              </option>
                            ))}
                          </select>
                          {formData?.support_tickets_toggle === 1 &&
                            formData?.issue_type === "" &&
                            issueTypeValid && (
                              <p className="text-danger m-0 ps-1 mt-1">
                                This field is required.
                              </p>
                            )}
                        </td>
                        <td colSpan={2} style={{ flex: "2" }}>
                          <textarea
                            ref={issueRef}
                            style={{ resize: "none" }}
                            className="form-control"
                            placeholder="Enter Text Here..."
                            name="issue"
                            value={formData?.issue}
                            onChange={HIC}
                          ></textarea>
                          {formData?.support_tickets_toggle === 1 &&
                            formData?.issue === "" &&
                            issueValid && (
                              <p className="text-danger m-0 ps-1 mt-1">
                                This field is required.
                              </p>
                            )}
                        </td>
                      </tr>
                    )}
                  </>
                </table>
              </div>
            </>
          )}
          <div className="row pb-3 py-5">
            <div className="col-12 content-flex-right">
              <button
                className="btn btn-danger text-uppercase"
                type="button"
                onClick={() => {
                  handleRemoveDispatch();
                  navigate(-1);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const InfoModal = ({ infoModal, setInfoModal }) => {
  return (
    <Modal
      centered
      show={infoModal}
      onHide={() => setInfoModal(false)}
      size="xl"
    >
      {/* <Modal.Header closeButton></Modal.Header> */}
      <Modal.Body className="">
        <div className="row">
          <div className="col-4">
            <p className="" style={{ fontWeight: "bolder" }}>
              These kits usually include:
            </p>
            <div
              className="gap-1 d-flex flex-column"
              style={{ marginLeft: "40px" }}
            >
              <p className="m-0">Razor</p>
              <p className="m-0">Scissors</p>
              <p className="m-0">CPR Barrier</p>
              <p className="m-0">Disinfectant Wipe</p>
              <p className="m-0">Pair of Gloves</p>
              <p className="m-0">Disposable Towel</p>
            </div>
          </div>
          <div className="col-7">
            <p className="text-center" style={{ fontWeight: "bolder" }}>
              These are examples of AED Rescue Kits that could be with your
              device
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <img src="/assets/images/image1.svg" alt="image1" />
              <img src="/assets/images/image2.png" alt="image2" />
              <img src="/assets/images/image3.png" alt="image3" />
              <img src="/assets/images/image4.png" alt="image4" />
            </div>
          </div>
          <div className="col-1 text-right">
            <MdClose
              role="button"
              size={30}
              onClick={() => setInfoModal(false)}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AedCheckSelect;
