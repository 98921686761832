import React from "react";
import CommonDatePicker from "../../../../common/components/DatePickers/CommonDatePicker";
import moment from "moment";
import { printConsole } from "../../../../common/helper/Common";

const SparePadsInfoEditTr = ({
  i,
  formData,
  parentName,
  setFormData,
  DataName,
  print_aed_pad_type,
  RenderDate,
  toggle,
  aedPadTypeList,
  readOnly = 0,
  warentyYear,
  show,
  title = "Spare Pad",
}) => {

  const API = formData?.[parentName]?.[i];

  const handleDateChange = (name, val) => {
    printConsole({ checking_Name: name, val }) // print console.log
    const fd = { ...formData };
    if (name === "purchase_date") {
      let warenty_date = moment(val).add(warentyYear, "years").calendar();
      fd.warenty_date = warenty_date;
    }
    // fd[name] = val;
    fd[parentName][i][name] = val;

    setFormData(fd);
    // setDefaultfromData(fd);
  };
  // const handleInput = (e) => {
  //   printConsole({ e })  // print console.log
  //   const fd = { ...formData };
  //   printConsole("checking", fd[parentName])  // print console.log

  //   if (e.target.name === "pad_type_id") {
  //     fd[parentName][i][e.target.name] = e.target.value;
  //     // fd[parentName][i]["pad_part_number"] = e.target.value;


  //   } else {
  //     fd[parentName][i][e.target.name] = e.target.value;

  //   }

  //   setFormData(fd);
  // }; commented by Abhishek

  const handleInput = (e) => {
    printConsole({ e }); // Log the event object
    const fd = { ...formData };
    printConsole("checking", fd[parentName]); // Log the current parentName data
  
    // Check if the input name is "pad_type_id"
    if (e.target.name === "pad_type_id") {
      fd[parentName][i][e.target.name] = e.target.value;
  
      // If the value of "pad_type_id" is "unknown", set both "pad_type_id" and "pad_part_number" to "unknown"
      if (e.target.value === "unknown") {
        fd[parentName][i]["pad_part_number"] = "unknown";
        fd[parentName][i]["pad_type_id"] = "unknown";
      }
    } else {
      // For other fields, update the value normally
      fd[parentName][i][e.target.name] = e.target.value;
    }
  
    setFormData(fd); // Update the state
  };
  

  return (
    <>
      <tr className="" key={i}>
        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
          {parentName === "spare_adult_pad_pak_info"
            ? "Spare Adult Pad-Pak"
            : title}
        </td>
        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
          {readOnly ? (
            <>{show ? API?.pad_part_number : API?.pad_type_id}</>
          ) : (
            <>
              {toggle ? (
                print_aed_pad_type(API?.pad_type_id)
              ) : (
                <select
                  className="form-control"
                  name="pad_type_id"
                  defaultValue={API?.pad_type_id}
                  onChange={(e) => handleInput(e)}
                  disabled={readOnly}
                >
                  <option value={"unknown"}>--Select-one--</option>
                  {aedPadTypeList.map((it) => (
                    <option value={it?.pad_type_id}>
                      {it?.pad_part_number}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
        </td>
        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
          {toggle ? (
            RenderDate(API?.pad_expiration, true)
          ) : (
            <CommonDatePicker
              calName={"pad_expiration"}
              CalVal={API?.pad_expiration}
              HandleChange={handleDateChange}
              // disabled={false}
              disabled={readOnly}
            />
          )}
        </td>
        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
          {/* { API?.pad_lot && API?.pad_lot != 'unknown' ? API?.pad_lot : '' } */}
          {toggle ? (
            API?.pad_lot
          ) : (
            <input
              type="text"
              name={"pad_lot"}
              defaultValue={API?.pad_lot}
              className="form-control"
              onChange={(e) => handleInput(e)}
              readOnly={readOnly}
            />
          )}
          {/* {API?.pad_lot} */}
        </td>
        <td className="border border-2 py-1 px-2 bg-tbl-border">
          {/* { API?.pad_udi && API?.pad_udi != 'unknown' ? API?.pad_udi : '' } */}
          {/* {API?.pad_udi} */}
          {toggle ? (
            API?.pad_udi
          ) : (
            <input
              type="text"
              name={"pad_udi"}
              defaultValue={API?.pad_udi}
              className="form-control"
              onChange={(e) => handleInput(e)}
              readOnly={readOnly}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default SparePadsInfoEditTr;
